import { AppState } from "../../../dispatcher";
import { InstitutionalDataResponse } from "../../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

/**
 * Funcion que obtiene la respuesta que esta en apelación.
 *
 * @param appState estado de app
 * @param institutionId id de institucion
 * @param variableId id de variable
 * @param setResponseVariable setter de variable de respuesta
 * @param setError setter de error
 * @param setReady setter de listo
 */
function getInstitutionalResponseVariable(
  appState: AppState,
  institutionId: string,
  variableId: string,
  setResponseVariable: React.Dispatch<
    React.SetStateAction<InstitutionalDataResponse | undefined>
  >,
  setError: React.Dispatch<React.SetStateAction<boolean>>,
  setReady: React.Dispatch<React.SetStateAction<boolean>>
): void {
  if (!appState.token) {
    return;
  }
  fetchAPI<APIActions["fetchInstitutionalDataResponses"]>(
    APIRoutes.fetchInstitutionalDataResponses,
    {
      method: "GET",
      routeParams: {
        institution_id: institutionId,
      },
    },
    appState.token
  )
    .then((responses) => {
      const response = responses.filter(
        (elem) => elem.variable_id.toString() === variableId
      );
      setResponseVariable(response[0]);
      setReady(true);
    })
    .catch(() => setError(true));
}

export default getInstitutionalResponseVariable;
