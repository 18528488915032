import React, { ReactElement, useContext } from "react";
import { useParams } from "react-router";
import "twin.macro";
import { AppContext } from "../../../dispatcher";
import useRESIESDates from "../../../hooks/useRESIESDates";
import { InstitutionalResponse } from "../../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../../utils/fetchAPI";

/**
 * Componente que muestras los archivos que se han subido como evidencia.
 *
 * @param props react props.
 * @param props.response Es la response variable de la cual se esta viendo evidencia
 * @param props.setUploadFile setear archivo a subir
 * @param props.deleteEvidence función que elimina una evidencia.
 */
function InstitutionalFilesColumn(props: {
  response: InstitutionalResponse;
  setUploadFile: React.Dispatch<React.SetStateAction<number>>;
  deleteEvidence: (e: React.MouseEvent<HTMLButtonElement>) => void;
}): ReactElement {
  const [, currentProcess, currentInstitutionProcess] = useRESIESDates();
  const { response, setUploadFile, deleteEvidence } = props;
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [appState] = useContext(AppContext);
  const { responseId } = useParams<{ responseId: string }>();

  const getActualCurrentProcess = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess.current_process;
    }

    return currentProcess;
  };

  /**
   * Función que maneja el envio de archivo al backend
   *
   * @param e Evento que desencadena el envio del archivo.
   */
  function uploadFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && appState.token) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      if (appState.token) {
        fetch(
          `${process.env.REACT_APP_API_URL}/api/institutional_data_evidences/upload/${responseId}`,
          {
            method: "POST",
            headers: {
              "api-key": appState.token,
            },
            body: formData,
          }
        )
          .then((data) => data.json())
          .then((data) => setUploadFile(data.id));
      }
    }
  }

  /**
   * descargar archivo
   *
   * @param e evento de mouse.
   */
  function onClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (!appState.token) return;
    const id = e.currentTarget.getAttribute("value")?.toString();
    if (id) {
      fetchAPI<APIActions["downloadInstitutionFile"]>(
        APIRoutes.downloadInstitutionFile,
        {
          method: "GET",
          routeParams: { response_verificator_id: id },
        },
        appState.token
      ).then((link) => {
        const newWindow = window.open(link, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
      });
    }
  }
  return (
    <div tw="w-3/12">
      <div tw="bg-resies_blue2 font-sans text-barra font-bold text-ghostwhite px-6">
        <p tw="flex justify-center">Archivos</p>
      </div>
      <div tw="bg-resies_lightpurple font-sans text-body font-normal text-black px-6 py-2 flex flex-col">
        {(appState.userType === "MDI" || appState.userType === "RDI") &&
        (getActualCurrentProcess()?.name === "Llenado de Indicadores" ||
          getActualCurrentProcess()?.name === "Rectificación de Objetados") ? (
          <button
            type="button"
            tw="bg-resies_green text-ghostwhite text-body font-normal px-2 py-3 rounded-md 2xl:w-1/2 w-10/12 mx-auto"
            onClick={() => fileInputRef?.current?.click()}
          >
            Subir Archivo
          </button>
        ) : (
          <></>
        )}
        <input
          ref={fileInputRef}
          type="file"
          hidden
          onChange={(e) => uploadFile(e)}
        />
        <div tw="bg-ghostwhite font-sans text-barra font-bold text-ghostwhite px-2 py-2 mt-2 h-32 overflow-auto">
          {response.evidences
            ?.filter((evidence) => evidence.evidence_type === "S3")
            .map((file) => (
              <div tw="flex flex-row" key={file.id}>
                {(appState.userType === "MDI" || appState.userType === "RDI") &&
                (getActualCurrentProcess()?.name ===
                  "Rectificación de Objetados" ||
                  getActualCurrentProcess()?.name ===
                    "Llenado de Indicadores") ? (
                  <button
                    type="button"
                    tw="w-1/12"
                    name={file.id.toString()}
                    onClick={(e) => deleteEvidence(e)}
                  >
                    <img src="/cancel.png" tw="" alt="cancel" />
                  </button>
                ) : (
                  <></>
                )}
                <button
                  tw="text-black text-body font-normal truncate w-11/12"
                  onClick={(e) => onClick(e)}
                  type="button"
                  value={file.id}
                >
                  {file.file_name}
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default InstitutionalFilesColumn;
