import { faSave, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Redirect, useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import EvidenceComments from "../../components/common/EvidenceView/EvidenceComments";
import EvidenceDataRow from "../../components/common/EvidenceView/EvidenceDataRow";
import EvidenceInfo from "../../components/common/EvidenceView/EvidenceInfo";
import EvidenceTitle from "../../components/common/EvidenceView/EvidenceTitle";
import VolverButton from "../../components/common/VolverButton";
import { AppContext } from "../../dispatcher";
import useRESIESDates from "../../hooks/useRESIESDates";
import Routes from "../../routers/routes";
import {
  EvaluationVariableDetails,
  ResponseVariable,
} from "../../types/backendTypes";
import getResponses from "../../utils/data/getResponses";
import getVariableDetails from "../../utils/data/getVariableDetails";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * Componente de la vista de evidencia del RDI
 */
function RDIEvidenceView(): ReactElement {
  const { variableId } = useParams<{ variableId: string }>();
  const { responseId } = useParams<{ responseId: string }>();
  const [uploadFile, setUploadFile] = useState(0);
  const [ready, setReady] = useState(false);
  const [reload, setReload] = useState(0);
  const [evaluationVariable, setEvaluationVariable] =
    useState<EvaluationVariableDetails>();
  const [error, setError] = useState(false);
  const [appState] = useContext(AppContext);
  const [responseVariable, setResponseVariable] = useState<ResponseVariable>();
  const [editable, setEditable] = useState(false);
  const [, currentProcess, currentInstitutionProcess] = useRESIESDates();
  const descriptionRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    descriptionRef.current?.focus();
  }, [descriptionRef]);

  useEffect(() => {
    getVariableDetails(appState, variableId, setEvaluationVariable, setError);
    getResponses(appState, responseId, setResponseVariable, setError, setReady);
  }, [appState, responseId, variableId, uploadFile, reload]);

  const getActualCurrentProcess = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess.current_process;
    }

    return currentProcess;
  };

  /**
   *  Función que cambia el estado de edición al salir del input
   */
  function onBlurDescription() {
    if (!appState.token || !responseVariable) return;
    fetchAPI<APIActions["putVariables"]>(
      APIRoutes.putVariables,
      {
        method: "PUT",
        body: [responseVariable],
      },
      appState.token
    ).then(() => {
      setEditable(false);
      setReload(reload + 1);
    });
  }
  // /**
  //  * Funcion que cambia el estado de edición al apretar enter.
  //  *
  //  * @param e evento que desencadena el cambio.
  //  */
  // function onKeyDownHandler(e: React.KeyboardEvent<HTMLInputElement>) {
  //   if (e.key === "Enter") {
  //     if (!appState.token || !responseVariable) return;
  //     fetchAPI<APIActions["putVariables"]>(
  //       APIRoutes.putVariables,
  //       {
  //         method: "PUT",
  //         body: [responseVariable],
  //       },
  //       appState.token
  //     ).then(() => {
  //       setEditable(false);
  //       setReload(reload + 1);
  //     });
  //   }
  // }
  /**
   * Funcion que maneja el cambio en la descripción
   *
   * @param e evento que desecandena el cambio
   */
  function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    if (responseVariable) {
      const copy = { ...responseVariable };
      copy.description = e.target.value;
      setResponseVariable(copy);
    }
  }

  return (
    <div tw="bg-ghostwhite">
      {error ? <Redirect to={Routes.indicators} /> : <></>}
      <CargandoScreen ready={ready}>
        <Container>
          <EvidenceTitle
            evaluationVariable={evaluationVariable}
            title="EVIDENCIA"
            responseVariable={responseVariable}
          />
          <div tw="bg-resies_blue2 font-sans text-barra font-bold text-ghostwhite px-6 mt-7">
            Descripción de variable
          </div>
          {getActualCurrentProcess()?.name === "Llenado de Indicadores" ? (
            <div tw="w-full flex flex-row">
              {editable ? (
                <>
                  <input
                    tw="bg-resies_lightpurple font-sans text-body font-normal text-black px-6 py-2 w-full text-left border-resies_blue2 border"
                    value={responseVariable?.description}
                    onChange={(e) => onChangeHandler(e)}
                    ref={descriptionRef}
                  />
                  <button
                    type="button"
                    tw="bg-resies_lightpurple font-sans text-body font-normal text-black px-6 py-2 text-left"
                    onClick={onBlurDescription}
                  >
                    <FontAwesomeIcon icon={faSave} />
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    tw="bg-resies_lightpurple font-sans text-body font-normal text-black px-6 py-2 w-full text-left"
                    onClick={() => setEditable(true)}
                  >
                    {responseVariable?.description}
                  </button>
                  <button
                    type="button"
                    tw="bg-resies_lightpurple font-sans text-body font-normal text-black px-6 py-2 text-left"
                    onClick={() => setEditable(true)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                </>
              )}
            </div>
          ) : (
            <div tw="bg-resies_lightpurple font-sans text-body font-normal text-black px-6 py-2">
              {responseVariable?.description}
            </div>
          )}
          <EvidenceInfo
            info={evaluationVariable?.verification_field_helper_text}
            title="Verificación Necesaria"
          />
          {responseVariable ? (
            <EvidenceDataRow
              response={responseVariable}
              setUploadFile={setUploadFile}
              reload={reload}
              setReload={setReload}
            />
          ) : (
            <></>
          )}
          <EvidenceComments />
          <VolverButton
            to={`${Routes.indicators}?category=${evaluationVariable?.indicator.subcategory?.category.id}&indicator=${evaluationVariable?.indicator.id}`}
          />
          <div tw="bg-ghostwhite h-10" />
        </Container>
      </CargandoScreen>
    </div>
  );
}

export default RDIEvidenceView;
