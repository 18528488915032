import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import { AppContext } from "../../../../dispatcher";
import {
  CategoryStatus,
  DropdownStatus,
  SubCategoryStatus,
} from "../../../../types/utilTypes";
import getCategories from "../../../../utils/data/AdmData/getCategories";
import getSubCategories from "../../../../utils/data/AdmData/getSubCategories";
import fetchAPI, { APIActions, APIRoutes } from "../../../../utils/fetchAPI";
import CargandoScreen from "../../../common/CargandoScreen";
import CategoryOption from "./CategoryOption";
import NewCategoryOption from "./NewCategoryOption";

/**
 * Componente que muestra las categorias que existen, permite editar sus nombres y crear nuevas categorias.
 */
function EditCategories(): ReactElement {
  const [ready, setReady] = useState(false);
  const [maxId, setMaxId] = useState(0);
  const [categoryStatus, setCategoryStatus] = useState<CategoryStatus[]>([]);
  const [subCategoryStatus, setSubCategoryStatus] = useState<
    SubCategoryStatus[]
  >([]);
  const [appState] = useContext(AppContext);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [reload, setReload] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [maxSubId, setMaxSubId] = useState(0);
  const [dropdownStatus, setDropdownStatus] = useState<DropdownStatus[]>([]);

  useEffect(() => {
    const optionReload = reload !== 0;
    getCategories(
      appState,
      setReady,
      setMaxId,
      setCategoryStatus,
      setDropdownStatus,
      optionReload
    );
    getSubCategories(appState, setMaxSubId, setSubCategoryStatus);
  }, [appState, reload]);

  /**
   * Funcion que crea una nueva categoria cuando al hacer click el boton +
   */
  function onClickNewCategory() {
    if (!appState.token) return;
    const newCategory: CategoryStatus = {
      name: newCategoryName,
      id: maxId + 1,
      status: "ready",
      description: "descripcion de prueba",
      score_weight: 0,
      color: "#eeeaed",
    };
    setMaxId(maxId + 1);
    const copyCategories = [...categoryStatus];
    const copyDropdown = [...dropdownStatus];
    copyCategories.push(newCategory);
    let errorNameRepeated = false;
    const names = copyCategories.map((cate) => cate.name);
    errorNameRepeated = new Set(names).size !== names.length;
    if (errorNameRepeated) {
      setErrorMessage("No se pueden tener nombres repetidos");
      return;
    }
    setReady(false);
    fetchAPI<APIActions["createCategory"]>(
      APIRoutes.createCategory,
      {
        method: "POST",
        body: {
          name: newCategory.name,
          description: newCategory.description,
          score_weight: 0,
          color: newCategory.color,
        },
      },
      appState.token
    )
      .then((cat) => {
        copyDropdown.push({ id: cat.id, status: "up" });
        setCategoryStatus([...copyCategories]);
        setDropdownStatus(copyDropdown);
        setNewCategoryName("");
        setErrorMessage("");
      })
      .then(() => setReload(reload + 1));
  }

  /**
   * Funcion que cambia el estado de la categoria para ser editable.
   *
   * @param e evento que desencadena la funcion.
   */
  function changeStatus(e: React.MouseEvent<HTMLButtonElement>) {
    if (!appState.token) return;
    const copyStatus = [...categoryStatus];
    let errorNameRepeated = false;
    const names = copyStatus.map((cate) => cate.name);
    errorNameRepeated = new Set(names).size !== names.length;
    if (errorNameRepeated) {
      setErrorMessage("No se pueden tener nombres repetidos");
      return;
    }
    const element = copyStatus.filter(
      (status) => status.id.toString() === e.currentTarget.name
    )[0];
    if (element.status === "ready") {
      element.status = "edit";
    } else {
      setReady(false);
      fetchAPI<APIActions["editCategories"]>(
        APIRoutes.editCategories,
        {
          method: "PUT",
          routeParams: { category_id: element.id.toString() },
          body: {
            name: element.name,
            description: element.description,
            score_weight: element.score_weight,
            color: element.color,
          },
        },
        appState.token
      ).then(() => {
        setReload(reload + 1);
        setErrorMessage("");
      });
      element.status = "ready";
    }
    setCategoryStatus([...copyStatus]);
  }

  /**
   * Funcion que maneja el cambio de nombre de la nueva categoria creada.
   *
   * @param e evento que desencadena la funcion.
   */
  function onChangeNewCategory(e: React.ChangeEvent<HTMLInputElement>) {
    setNewCategoryName(e.target.value);
  }

  /**
   * Funcion que maneja el cambio de nombre de las categorias existentes.
   *
   * @param e evento que desencadena la funcion.
   */
  function onChangeEditCategory(e: React.ChangeEvent<HTMLInputElement>) {
    const copyStatus = [...categoryStatus];
    const element = copyStatus.filter(
      (status) => status.id.toString() === e.target.name
    )[0];
    element.name = e.target.value;
    setCategoryStatus([...copyStatus]);
  }

  /**
   * Funcion que maneja el cambio de peso de las categorias existentes.
   *
   * @param e evento que desencadena la funcion.
   */
  function onChangeEditCategoryWeight(e: React.ChangeEvent<HTMLInputElement>) {
    const copyStatus = [...categoryStatus];
    const element = copyStatus.filter(
      (status) => status.id.toString() === e.target.name
    )[0];
    element.score_weight = parseFloat(e.target.value);
    setCategoryStatus([...copyStatus]);
  }

  /**
   * Funcion que maneja el cambio de color de las categorias existentes.
   *
   * @param e evento que desencadena la funcion.
   */
  function onChangeEditCategoryColor(e: React.ChangeEvent<HTMLInputElement>) {
    const copyStatus = [...categoryStatus];
    const element = copyStatus.filter(
      (status) => status.id.toString() === e.target.name
    )[0];
    element.color = e.target.value;
    setCategoryStatus([...copyStatus]);
  }

  /**
   * Handler para eliminar categorias
   *
   * @param id evento que desencadena la eliminación.
   */
  function onDeleteCategory(id: number) {
    if (!appState.token) return Promise.resolve();
    const copyCategories = [...categoryStatus];
    const copyDropdown = [...dropdownStatus];
    const deletedCategoryStatus = copyCategories.filter(
      (cat) => cat.id === id
    )[0];
    const deletedCategoryDropdown = copyDropdown.filter(
      (cat) => cat.id === id
    )[0];
    const indexStatus = copyCategories.indexOf(deletedCategoryStatus);
    const indexDropdown = copyDropdown.indexOf(deletedCategoryDropdown);
    setReady(false);
    return fetchAPI<APIActions["deleteCategory"]>(
      APIRoutes.deleteCategory,
      {
        method: "DELETE",
        routeParams: { category_id: deletedCategoryStatus.id.toString() },
      },
      appState.token
    )
      .then(() => {
        copyDropdown.splice(indexDropdown, 1);
        copyCategories.splice(indexStatus, 1);
        setCategoryStatus([...copyCategories]);
        setDropdownStatus(copyDropdown);
      })
      .then(() => setReload(reload + 1));
  }
  return (
    <CargandoScreen ready={ready}>
      <div tw="">
        <p tw="ml-4 text-header1 font-bold text-resies_blue1 mb-2">
          Categorías
        </p>
        <p tw="text-darkred font-bold text-header2">{errorMessage}</p>
        <CategoryOption
          categoryStatus={categoryStatus}
          onChangeEditCategory={onChangeEditCategory}
          changeStatus={changeStatus}
          subCategoryStatus={subCategoryStatus}
          dropdownStatus={dropdownStatus}
          setDropdownStatus={setDropdownStatus}
          setSubCategoryStatus={setSubCategoryStatus}
          setErrorMessage={setErrorMessage}
          setReady={setReady}
          maxSubId={maxSubId}
          setMaxSubId={setMaxSubId}
          reload={reload}
          setReload={setReload}
          ready={ready}
          onDeleteCategory={onDeleteCategory}
          onChangeEditCategoryWeight={onChangeEditCategoryWeight}
          onChangeEditCategoryColor={onChangeEditCategoryColor}
        />
        <NewCategoryOption
          newCategoryName={newCategoryName}
          onChangeNewCategory={onChangeNewCategory}
          onClickNewCategory={onClickNewCategory}
        />
      </div>
    </CargandoScreen>
  );
}

export default EditCategories;
