import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import CargandoScreen from "../common/CargandoScreen";
import Container from "../common/Container";
import { AppContext } from "../../dispatcher";
import { User } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import AuditorRow from "./AuditorRow";

/**
 * Instituciones del que tiene a cargo el auditor
 */
function AuditorsIndex(): ReactElement {
  const [state] = useContext(AppContext);
  const apiKey = state.token;
  const [ready, setReady] = useState(false);
  const [auditors, setAuditors] = useState<Array<User>>([]);
  useEffect(() => {
    if (apiKey) {
      fetchAPI<APIActions["fetchUsers"]>(
        APIRoutes.fetchUsers,
        {
          method: "GET",
        },
        apiKey
      )
        .then((users) => {
          setAuditors(users.filter((user) => user.user_type === "AUD"));
          setReady(true);
        })
        .catch(() => {}); // TODO
    }
  }, [apiKey]);
  return (
    <CargandoScreen ready={ready}>
      <Container>
        <table tw="table w-full mb-5 min-h-80 bg-resies_lightpurple">
          <thead tw=" bg-resies_blue2 flex text-white w-full">
            <tr tw="flex w-full h-5">
              <th tw="text-barra font-bold text-ghostwhite text-center w-1/4 h-5">
                Nombre
              </th>
              <th tw="text-barra font-bold text-ghostwhite text-center w-1/4 h-5">
                Email
              </th>
              <th tw="text-barra font-bold text-ghostwhite text-center w-1/4 h-5">
                Instituciones asignadas
              </th>
              <th tw="text-barra font-bold text-ghostwhite text-center w-1/4 h-5">
                {" "}
              </th>
            </tr>
          </thead>
          <tbody tw="bg-resies_lightpurple flex flex-col items-center justify-between overflow-y-scroll w-full max-h-72">
            {!auditors.length ? (
              <div tw="text-header2 my-28 text-darkgray">
                Sin auditores en la plataforma
              </div>
            ) : (
              auditors.map((a) => (
                <AuditorRow
                  id={a.id.toString()}
                  name={`${a.first_name} ${a.last_name}`}
                  email={a.email}
                  assignedInstitutions={
                    a.auduser?.institution_set.length
                      ? a.auduser?.institution_set.length
                      : 0
                  }
                  progress={0}
                  institutionsData={a.auduser?.institution_set}
                />
              ))
            )}
          </tbody>
        </table>
      </Container>
    </CargandoScreen>
  );
}

export default AuditorsIndex;
