import { Tooltip } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import Routes from "../../routers/routes";
import { Institution } from "../../types/backendTypes";

/**
 * Fila de la tabla de auditores.
 *
 * @param props react props.
 * @param props.id ID del usuario auditor.
 * @param props.name Nombre del auditor.
 * @param props.email Email del auditor.
 * @param props.assignedInstitutions número de instituciones asignadas.
 * @param props.progress porcentaje de progreso en su auditoría.
 * @param props.institutionsData información de instituciones asignadas.
 */
function AuditorRow(props: {
  id: string;
  name: string;
  email: string;
  assignedInstitutions: number;
  progress: number;
  institutionsData: Institution[] | undefined;
}): ReactElement {
  const {
    id,
    name,
    email,
    assignedInstitutions,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    progress,
    institutionsData,
  } = props;
  return (
    <tr tw="flex w-full mb-4">
      <td tw="text-center p-2 w-1/4">{name}</td>
      <td tw="text-center p-2 w-1/4">{email}</td>
      {institutionsData ? (
        <Tooltip
          title={
            institutionsData.length
              ? institutionsData.map((inst) => <div>{inst.name}</div>)
              : ""
          }
        >
          <td tw="text-center p-2 w-1/4">
            {assignedInstitutions} instituciones asignadas
          </td>
        </Tooltip>
      ) : (
        <></>
      )}
      <td tw="text-center p-2 w-1/4">
        <Link to={Routes.audProfile.replace(":audId", id)}>
          <button
            type="button"
            tw="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md"
          >
            Ir al perfil
          </button>
        </Link>
      </td>
    </tr>
  );
}

export default AuditorRow;
