import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import { ActionTypes, AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
// eslint-disable-next-line import/no-cycle
/**
 * Navbar para realizar login del usuario anonimo.
 */
function AnonLoginNavbar(): ReactElement {
  const [appState, dispatch] = useContext(AppContext);
  return (
    <div tw="flex flex-row justify-end bg-resies_blue1 py-5 pr-20 pl-28">
      <p tw="text-white px-5">|</p>
      {appState.token === null ? (
        <Link to={Routes.login}>
          <button type="button" tw="px-4 text-resies_lightpurple font-semibold">
            Iniciar Sesión
          </button>
        </Link>
      ) : (
        <button
          type="button"
          tw="px-4 text-resies_lightpurple font-semibold"
          onClick={() => dispatch({ type: ActionTypes.logout, payload: {} })}
        >
          Cerrar Sesion
        </button>
      )}
    </div>
  );
}
export default AnonLoginNavbar;
