import { ReactElement, useContext } from "react";
import { AppContext } from "../../dispatcher";
import usePagination from "../../hooks/common/usePagination";
import Routes from "../../routers/routes";
import { AdminInstitutionsOverview } from "../../types/backendTypes";
import AddButton from "./AddButton";
import InstitutionIndexWrapper from "./InstitutionIndexWrapper";
import InstitutionOverviewIndexEntry from "./InstitutionOverviewIndexEntry";
import PaginatedListNavigator from "./PaginatedListNavigator";
import "twin.macro";
import ExcelPopUp from "./ExcelPopUp";

/**
 * La lista paginada de instituciones a mostrar cuando se abre el menu de todas las instituciones
 *
 * @param props react props
 * @param props.overview overview
 */
function InstitutionOverviewIndex(props: {
  overview: AdminInstitutionsOverview;
}): ReactElement {
  const [state] = useContext(AppContext);
  const { overview } = props;
  const [visibleInstitutions, maxPages, pageNumber, setCurrentPage] =
    usePagination(overview, 6);

  return (
    <>
      <div tw="flex flex-row w-full">
        <div tw="text-header2 text-resies_blue1 font-bold mb-1 mt-6">
          Instituciones
        </div>

        <div tw="ml-auto mt-auto font-semibold text-xl z-10">
          <ExcelPopUp />
        </div>
      </div>
      {state.userType === "ADM" && (
        <AddButton to={Routes.addInstitution} text="Agregar Institución" />
      )}
      <div>
        <InstitutionIndexWrapper>
          {visibleInstitutions.map((i) => (
            <InstitutionOverviewIndexEntry
              key={i.institution_id}
              institution={i}
            />
          ))}
        </InstitutionIndexWrapper>
        <PaginatedListNavigator
          currentPage={pageNumber}
          setCurrentPage={setCurrentPage}
          maxPages={maxPages}
        />
      </div>
    </>
  );
}

export default InstitutionOverviewIndex;
