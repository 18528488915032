import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";

/**
 * Sección a mostrar en la landing page de los usuarios, que muestra la institución de un usuario y botones con acciones posibles debajo
 *
 * @param props react props.
 * @param props.title El título de la sección
 * @param props.logoUrl La url del logo
 * @param props.imageUrl La url de la imagen
 * @param props.institutionName El nombre de la institución
 * @param props.buttons El texto y vínculo de los botones de debajo
 */
function MyInstitutionColumn(props: {
  title: string;
  logoUrl: string;
  imageUrl: string;
  institutionName: string;
  buttons: Array<{ text: string; href: string }>;
}): ReactElement {
  const { title, imageUrl, buttons, logoUrl, institutionName } = props;
  // <div tw="flex flex-row justify-center bg-white py-1 text-xl w-full w-auto h-12">
  return (
    <>
      <div tw="text-darkgray font-semibold mb-12 text-header2">
        {title}
        <div tw="flex sm:col-span-5 col-span-5 bg-resies_lightpurple relative block rounded-md px-6 pb-3 shadow-md pt-2">
          <div tw="flex h-10 mb-2">
            <img tw="object-contain" src={logoUrl} alt="uc" />
            <p tw="flex flex-wrap content-center ml-2 mt-2.5 text-black font-bold text-header2 text-very_darkgray">
              {institutionName}
            </p>
          </div>
          <div tw="w-full h-64 flex">
            <img
              src={imageUrl}
              tw="object-cover w-full"
              alt="my_institution_image"
            />
          </div>
          <div tw="flex justify-around mt-3">
            {buttons.map((button) => (
              <Link to={button.href} key={button.text}>
                <button
                  tw="px-4 py-2 bg-resies_purple text-ghostwhite rounded"
                  type="button"
                >
                  {button.text}
                </button>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default MyInstitutionColumn;
