import React, { ReactElement, useContext, useState } from "react";
import "twin.macro";
import { useHistory, useParams } from "react-router-dom";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import useUser from "../../hooks/useUser";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import { AppContext, AppState, Dispatcher } from "../../dispatcher";

/**
 * Componente para eliminar auditor
 */
function ADMDeleteAUDProfile(): ReactElement {
  const [ready, setReady] = useState(false);
  const { audId } = useParams<{ audId: string }>();
  const [auditor] = useUser(setReady, audId);
  const history = useHistory();
  const [appState]: [AppState, Dispatcher] = useContext(AppContext);

  /**
   * Borrar cuenta de auditor
   */
  function deleteAudAccount() {
    if (appState.token) {
      fetchAPI<APIActions["softdeleteAccount"]>(
        APIRoutes.softdeleteAccount,
        { method: "DELETE", routeParams: { user_id: auditor.id.toString() } },
        appState.token
      ).then(() => {
        history.push(Routes.landing);
      }); // TODO: cambiar ruta
    }
  }
  return (
    <CargandoScreen ready={ready}>
      <Container>
        <div tw="w-full">
          <div tw="h-96 flex flex-wrap flex-col content-center justify-center">
            <div tw="text-header2 font-bold py-5">
              Estás seguro de querer borrar la cuenta de {auditor.first_name}{" "}
              {auditor.last_name}?
            </div>
            <div tw="flex flex-row justify-center">
              <button
                tw="py-2 px-4 bg-darkred text-white w-auto rounded-md"
                onClick={deleteAudAccount}
                type="button"
              >
                Borrar cuenta
              </button>
            </div>
          </div>
        </div>
        <VolverButton
          to={Routes.audProfile.replace(":audId", auditor.id.toString())}
        />
      </Container>
    </CargandoScreen>
  );
}

export default ADMDeleteAUDProfile;
