/* eslint-disable import/no-cycle */
import React, { ReactElement } from "react";
import AnonLandingPhoto from "../../components/anon/AnonLandingPhoto";
import "twin.macro";
/**
 * Landing page del usuario anonimo.
 */
function AnonLandingPage(): ReactElement {
  return (
    <>
      <AnonLandingPhoto />
    </>
  );
}

export default AnonLandingPage;
