import { ReactElement } from "react";
import "twin.macro";
import RDIReports from "../rdi/RDIReports";

/**
 * Página perteneciente al Auditor para ver los datos de una institución.
 */
function AUDInstitutionReport(): ReactElement {
  return (
    <>
      <RDIReports />
    </>
  );
}

export default AUDInstitutionReport;
