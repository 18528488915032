import { AppState } from "../../dispatcher";
import { ResponseVariable } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../fetchAPI";

/**
 * Función que obtiene la respuesta de la variable con id responseId
 *
 * @param appState estado de app
 * @param responseId id de respuesta
 * @param setResponseVariable setter de variable de respuesta.
 * @param setError setter de error
 * @param setReady setter de listo
 */
function getResponses(
  appState: AppState,
  responseId: string,
  setResponseVariable: React.Dispatch<
    React.SetStateAction<ResponseVariable | undefined>
  >,
  setError: React.Dispatch<React.SetStateAction<boolean>>,
  setReady: React.Dispatch<React.SetStateAction<boolean>>
): void {
  if (!appState.token) {
    return;
  }
  fetchAPI<APIActions["fetchResponseVariables"]>(
    APIRoutes.fetchResponseVariables,
    {
      method: "GET",
    },
    appState.token
  )
    .then((responses) => {
      const response = responses.filter(
        (elem) => elem.id.toString() === responseId
      );
      setResponseVariable(response[0]);
      setReady(true);
    })
    .catch(() => setError(true));
}

export default getResponses;
