import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import CategoriesNavbar from "../../components/common/IndicatorsView/CategoriesNavbar";
import PageTitle from "../../components/common/PageTitle";
import VolverButton from "../../components/common/VolverButton";
import AUDMainInstitutionDataMenu from "../../components/aud/InstitutionData/AUDMainInstitutionDataMenu";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import {
  Category,
  Institution,
  InstitutionalDataVariable,
} from "../../types/backendTypes";
import { InstitutionalVariableForm } from "../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import { SearchOnUrl } from "../../utils/commonFunction";

/**
 * Página perteneciente al Auditor para ver los datos de una institución.
 */
function AUDInstitutionData(): ReactElement {
  const [appState] = useContext(AppContext);
  const [ready, setReady] = useState(false);
  const { institutionId } = useParams<{ institutionId: string }>();
  const [institution, setInstitution] = useState<Institution>({
    name: "",
    id: 0,
    description: "",
    photo_url: "",
    member_since: "",
    logo_url: "",
  });
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [indicators, setIndicators] = useState<Record<string, string[]>>({});
  const [variables, setVariables] = useState<
    Record<string, InstitutionalDataVariable>
  >({});
  const [variablesForm, setVariablesForm] = useState<
    InstitutionalVariableForm[]
  >([]);
  const [categories, setCategories] = useState<Array<Category>>([]);
  const query = SearchOnUrl();

  useEffect(() => {
    if (!appState.token) return;

    const selectedCategoryFromUrl =
      query.get("category") !== null ? Number(query.get("category")) : -1;

    fetchAPI<APIActions["fetchInstitutionalDataForAdmin"]>(
      APIRoutes.fetchInstitutionalData,
      {
        method: "GET",
        queryParams: {
          institution_id: institutionId.toString(),
        },
      },
      appState.token
    ).then((data) => {
      setInstitution(data.institution);

      if (data.categories) {
        let categoryFromUrl = data.categories[0];
        if (selectedCategoryFromUrl >= 0) {
          categoryFromUrl = data.categories.filter(
            (category) => category.category_id === selectedCategoryFromUrl
          )[0];
        }

        const category: Category = {
          id: categoryFromUrl.category_id,
          name: categoryFromUrl.category_name,
          description: categoryFromUrl.category_description,
          score_weight: categoryFromUrl.category_score_weight,
          color: categoryFromUrl.category_color,
        };

        setSelectedCategory(category);

        const inds: Record<string, string[]> = {};
        const vars: Record<string, InstitutionalDataVariable> = {};
        const form: InstitutionalVariableForm[] = [];

        data.categories.forEach((cat) => {
          inds[cat.category_name] = cat.variables
            .sort((a, b) => a.variable.id - b.variable.id)
            .map((v) => v.variable.indicator_slug);
          cat.variables.forEach((v) => {
            vars[v.variable.indicator_slug] = v.variable;
            form.push({
              variable_id: v.variable.id,
              response: v.response ? v.response.response : "",
              response_id: v.response ? v.response.id : 0,
              completed: !!v.response,
              audit_status: v.response?.audit_status || "pending",
              modified: false,
              evidences: v.response?.evidences || [],
              json_config: v.variable.json_config,
              type: v.variable.type,
              description: v.response?.description
                ? v.response.description
                : "",
            });
          });
        });

        setIndicators(inds);
        setVariables(vars);
        setVariablesForm(form);
        setCategories(
          data.categories
            .sort((a, b) => a.category_id - b.category_id)
            .map((cat) => ({
              id: cat.category_id,
              name: cat.category_name,
              description: cat.category_description,
              score_weight: cat.category_score_weight,
              color: cat.category_color,
            }))
        );
      }
      setReady(true);
    });
  }, [appState.token, institutionId, query]);
  return (
    <>
      <Container>
        <PageTitle title={`Datos de ${institution.description}`} />
      </Container>
      <CargandoScreen ready={ready}>
        {categories ? (
          <>
            {selectedCategory && variablesForm.length > 0 && (
              <>
                <CategoriesNavbar
                  categories={categories}
                  selectedCategory={selectedCategory}
                />
                <AUDMainInstitutionDataMenu
                  indicators={indicators}
                  selectedCategory={selectedCategory}
                  variables={variables}
                  variablesForm={variablesForm}
                />
              </>
            )}
          </>
        ) : (
          " "
        )}
        <VolverButton to={Routes.myInstitutions} />
      </CargandoScreen>
    </>
  );
}

export default AUDInstitutionData;
