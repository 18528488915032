import React, { ReactElement } from "react";
import "twin.macro";

/**
 * Componente que muestra la opcion de crear una nueva categoria.
 *
 * @param props react props.
 * @param props.newCategoryName Estado que maneja el nombre de la nueva categoria
 * @param props.onChangeNewCategory Funcion que maneja el cambio de nombre de la nueva categoria.
 * @param props.onClickNewCategory Funcion que guarda la nueva categoria.
 */
function NewCategoryOption(props: {
  newCategoryName: string;
  onChangeNewCategory: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickNewCategory: () => void;
}): ReactElement {
  const { newCategoryName, onChangeNewCategory, onClickNewCategory } = props;
  return (
    <div tw="bg-resies_lightpurple h-16 w-5/12 mx-auto mb-10 flex flex-wrap content-center justify-between pl-4">
      <input
        tw="text-header2 text-resies_purple font-semibold w-9/12 text-center bg-resies_lightpurple"
        placeholder="Nombre de la nueva categoría..."
        value={newCategoryName}
        onChange={(e) => onChangeNewCategory(e)}
      />
      <button
        type="button"
        tw="w-2/12 h-2/6 flex flex-wrap focus:outline-none"
        onClick={() => onClickNewCategory()}
      >
        <img tw="object-contain my-auto mx-auto" src="/add.png" alt="add" />
      </button>
    </div>
  );
}

export default NewCategoryOption;
