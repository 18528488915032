import { AppState } from "../../../dispatcher";
import { OdsGoal } from "../../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

function getOdsGoalsByIndicator(
  indicator_id: number,
  appState: AppState,
  setOdsGoals: React.Dispatch<React.SetStateAction<OdsGoal[]>>
): void {
  if (!appState.token) return;
  fetchAPI<APIActions["fetchOdsGoalsByIndicator"]>(
    APIRoutes.fetchOdsGoalsByIndicator,
    {
      method: "GET",
      routeParams: {
        indicator_id: indicator_id.toString(),
      },
    },
    appState.token
  ).then((goals) => {
    setOdsGoals(goals);
  });
}

export default getOdsGoalsByIndicator;
