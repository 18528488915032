/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-plusplus */
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "twin.macro";
import BoxPlot from "../../components/adm/BoxPlot";
import { QData } from "../../components/adm/d3Graph";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import MagicTable from "../../components/common/MagicTable";
import { AppContext } from "../../dispatcher";
import {
  Category,
  CategoryQuartiles,
  Institution,
} from "../../types/backendTypes";
import { ScorePattern } from "../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * El controlador de los reportes generados
 *
 * @param param0 react props
 * @param param0.categories Las categorías existentes
 * @param param0.setAgg El setter de la forma de agregación
 * @param param0.setCat El setter de la categoría (si la hay)
 * @param param0.setCatName El setter del nombre de la categoría (si la hay)
 * @param param0.institutions Las instituciones existentes
 * @param param0.setInstitution El setter de la institución actual
 */
function ControlReporte({
  categories,
  setAgg,
  setCat,
  setCatName,
  institutions,
  setInstitution,
}: {
  categories: Category[];
  setAgg: (e: "cat" | "ind") => void;
  setCat: (e: string) => void;
  setCatName: (e: string) => void;
  institutions: Institution[];
  setInstitution: (e: Institution | undefined) => void;
}) {
  const aggRef = useRef<HTMLSelectElement>(null);
  const catRef = useRef<HTMLSelectElement>(null);
  const insRef = useRef<HTMLSelectElement>(null);
  const catSelRef = useRef(null);
  return (
    <div
      id="caja-superior"
      tw="bg-resies_lightgreen h-40 flex flex-col text-resies_blue1 px-8 pt-10 pb-3"
      style={{ minWidth: "50rem" }}
    >
      <div
        id="todo-menos-botones"
        tw="flex flex-row items-stretch justify-between pr-24"
      >
        <div
          id="header-izquierdo"
          tw="flex flex-col justify-between max-w-md"
          style={{ minWidth: "15rem" }}
        >
          <p tw="font-bold text-xl">Generar reportes y gráficos de RESIES</p>
          <p tw="text-darkgray text-sm">
            Acá puedes filtrar según varias opciones para generar gráficos con
            difrentes datos
          </p>
        </div>
        <div
          id="nivel-agregacion"
          tw="flex flex-col justify-start flex-grow mt-2"
          style={{ maxWidth: "15rem", minWidth: "10rem" }}
        >
          <p tw="text-xl font-semibold text-resies_blue2">Nivel Agregación</p>
          <select
            ref={aggRef}
            tw="text-darkgray bg-resies_lightpurple h-7"
            onChange={(e: any) => {
              if (e.target.value === "cat") {
                // @ts-ignore
                catSelRef.current.style.visibility = "hidden";
              } else {
                // @ts-ignore
                catSelRef.current.style.visibility = "visible";
              }
            }}
          >
            <option value="cat">General y Categorias</option>
            <option value="ind">Categoría y Indicadores</option>
          </select>
        </div>
        <div
          id="categoria"
          ref={catSelRef}
          tw="flex flex-col justify-start flex-grow mt-2 text-resies_blue2 ml-1"
          style={{
            maxWidth: "15rem",
            minWidth: "10rem",
            visibility: "hidden",
          }}
        >
          <p tw="text-xl font-semibold">Categoría</p>
          <select
            ref={catRef}
            tw="text-darkgray bg-resies_lightpurple text-base h-7"
          >
            {categories.map((i) => (
              <option key={i.id} value={i.id}>
                {i.name}
              </option>
            ))}
          </select>
        </div>
        <div
          id="nivel-institucion"
          tw="flex flex-col justify-start flex-grow mt-2 ml-1"
          style={{ maxWidth: "15rem", minWidth: "10rem" }}
        >
          <p tw="text-xl font-semibold text-resies_blue2">Institución</p>
          <select ref={insRef} tw="text-darkgray bg-resies_lightpurple h-7">
            <option value="">-- Seleccionar --</option>
            {institutions.map((ins) => (
              <option key={ins.id} value={ins.id}>
                {ins.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div tw="ml-auto mt-auto font-semibold text-xl z-10">
        <button
          type="button"
          tw="font-semibold"
          // @ts-ignore
          onClick={() => {
            const cat = catRef.current?.value || "";
            const agg = aggRef.current?.value;
            const catName =
              categories.find((c) => c.id.toString() === cat)?.name || "";
            const ins = insRef.current?.value;
            setCat(cat);
            setCatName(catName);
            setInstitution(institutions.find((i) => i.id.toString() === ins));
            if (agg === "cat" || agg === "ind") setAgg(agg);
          }}
        >
          Generar Gráfico
        </button>
      </div>
    </div>
  );
}
const ADMReports = (): ReactElement => {
  const [ready, setReady] = useState(false);
  const [state] = useContext(AppContext);
  const updateRef = useRef<(data: QData) => void>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [dropdownsReady, setDropdownsReady] = useState(false);
  const [agg, setAgg] = useState<"cat" | "ind">("cat");
  const [category, setCat] = useState("");
  const [catName, setCatName] = useState("");
  const [institution, setInstitution] = useState<Institution>();
  const [institutionScore, setInstitutionScore] = useState<ScorePattern>();
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [quartiles, setQuartiles] = useState<QData>({
    general: {
      name: "General",
      index: 0,
      stats: { q0: 0, q1: 1, q2: 2, q3: 3, q4: 4 },
    },
    elements: [],
  });

  useEffect(() => {
    if (!state.token) return;
    fetchAPI<APIActions["fetchCategories"]>(
      APIRoutes.fetchCategories,
      { method: "GET" },
      state.token as string
    )
      .then((i) => {
        setCategories(i);
      })
      .then(() => {
        if (!state.token) return;
        fetchAPI<APIActions["fetchInstitutions"]>(
          APIRoutes.fetchInstitutions,
          { method: "GET" },
          state.token
        ).then((ins) => {
          setInstitutions(ins);
          setDropdownsReady(true);
        });
      });
  }, [state.token]);

  useEffect(() => {
    if (!state.token || !dropdownsReady) return;
    setReady(false);
    if (agg === "cat") {
      fetchAPI<APIActions["quartiles"]>(
        APIRoutes.quartiles,
        {
          method: "POST",
          queryParams: {
            strict_mode: "false",
          },
        },
        state.token as string
      ).then((qs) => {
        const changed = {
          general: {
            name: "General",
            index: 0,
            stats: qs.general,
          },
          elements: qs.categories
            .sort((c1, c2) =>
              c1.id.toString().localeCompare(c2.id.toString(), undefined, {
                numeric: true,
                sensitivity: "base",
              })
            )
            .map((cat, i) => ({
              name: cat.name,
              index: i + 1,
              stats: cat.stats,
            })),
        };
        setQuartiles(changed);
        setReady(true);
      });
    } else if (agg === "ind") {
      fetchAPI<APIActions["quartilesCat"]>(
        APIRoutes.quartilesCat,
        {
          method: "POST",
          routeParams: {
            category_id: category,
          },
          queryParams: {
            strict_mode: "false",
          },
        },
        state.token as string
      ).then((qs) => {
        const changed = {
          general: {
            name: catName,
            index: 0,
            stats: qs.category,
          },
          elements: qs.subcategories
            .reduce(
              (acc, subc) => acc.concat(subc.indicators),
              [] as CategoryQuartiles["subcategories"][0]["indicators"]
            )
            .sort((c1, c2) =>
              c1.slug.localeCompare(c2.slug, undefined, {
                numeric: true,
                sensitivity: "base",
              })
            )
            .map((ind, i) => ({
              name: ind.slug,
              index: i + 1,
              stats: ind.stats,
            })),
        };
        setQuartiles(changed);
        setReady(true);
      });
    }
  }, [agg, catName, category, dropdownsReady, state.token]);
  useEffect(() => {
    if (!state.token) return;
    if (!institution) {
      setInstitutionScore(undefined);
      return;
    }
    if (agg === "ind") {
      fetchAPI<APIActions["fetchCategoryScores"]>(
        APIRoutes.fetchCategoryScores,
        {
          method: "GET",
          routeParams: { category_id: category },
          queryParams: {
            strict_mode: "false",
            institution_id: institution.id.toString(),
          },
        },
        state.token
      ).then((score) =>
        setInstitutionScore({
          elements: [
            ...score.indicators.map((c) => ({
              score: c.score,
              weighted_score: c.weighted_score,
              weight: c.weight,
              id: c.indicator_id,
              name: c.indicator_name,
              slug: c.indicator_slug,
              color: c.color,
              subelements: [],
            })),
          ],
          score: score.score,
        })
      );
    } else if (agg === "cat") {
      fetchAPI<APIActions["fetchInstitutionScores"]>(
        APIRoutes.fetchInstitutionScores,
        {
          method: "GET",
          routeParams: { institution_id: institution.id.toString() },
          queryParams: { strict_mode: "false" },
        },
        state.token
      ).then((score) =>
        setInstitutionScore({
          elements: [
            ...score.categories.map((c) => ({
              score: c.score,
              weighted_score: c.weighted_score,
              weight: c.weight,
              id: c.category_id,
              name: c.category_name,
              slug: c.category_slug,
              color: c.color,
              subelements: c.indicators,
            })),
          ],
          score: score.score,
        })
      );
    }
  }, [agg, category, institution, state.token]);
  return (
    <Container>
      <CargandoScreen ready={ready}>
        <div id="vista-principal" tw="flex flex-col justify-around">
          <ControlReporte
            categories={categories}
            institutions={institutions}
            setInstitution={setInstitution}
            setCat={setCat}
            setCatName={setCatName}
            setAgg={setAgg}
          />
          <div id="h-blank-space" tw="h-16" />
          <div tw="flex flex-row justify-between">
            <div tw="flex flex-row justify-center w-full">
              <div tw="bg-resies_lightpurple overflow-x-auto">
                <BoxPlot data={quartiles} ref={updateRef} />
              </div>
            </div>
          </div>
          <div tw="h-full w-full mb-8">
            <MagicTable data={quartiles} institutionScore={institutionScore} />
          </div>
        </div>
      </CargandoScreen>
    </Container>
  );
};

export default ADMReports;
