import React, { ReactElement } from "react";
import "twin.macro";
import { Indicator } from "../../../types/backendTypes";
import { useHistory } from "react-router-dom";

/**
 * Función que retorna el nombre de cada indicador junto con un color que representa su estado actual.
 *
 * @param props react props.
 * @param props.indicator Indicador recibido para ser rendereado
 * @param props.formState Estado que maneja los datos actualmente ingresados en los formularios de cada variable.
 * @param props.indicatorSelected Indicador actualmente seleccionado
 * @param props.clickHandler Funcion que maneja el cambio de indicador seleccionado.
 */
function CompleteIndicator(props: {
  indicator: Indicator;
  formState: Record<string, string>[];
  indicatorSelected: Indicator;
}): ReactElement {
  const { indicator, formState, indicatorSelected } = props;
  const indicatorVars = formState.filter((ind) => ind.name === indicator.slug);
  const nocompletedVars = indicatorVars.filter((variable) => !variable.value);
  const history = useHistory();

  let style;
  if (nocompletedVars.length === 0) {
    style = "rounded-full bg-resies_green h-3 w-3 my-auto mx-2";
  } else if (nocompletedVars.length < indicatorVars.length) {
    style = "rounded-full bg-warning_yellow h-3 w-3 my-auto mx-2";
  } else {
    style = "rounded-full bg-red h-3 w-3 my-auto mx-2";
  }
  return (
    <div
      tw="flex flex-row flex-wrap content-center"
      className={
        indicator.slug === indicatorSelected.slug
          ? "border-l-4 border-resies_blue1 bg-resies_lightgreen"
          : ""
      }
    >
      <button
        type="button"
        tw="mx-auto font-semibold text-black opacity-50"
        className={
          indicator.slug === indicatorSelected.slug ? "text-resies_blue1" : ""
        }
        onClick={() => {
          history.push(
            `/my-institution/indicators?category=${indicator.category_id}&indicator=${indicator.id}`
          );
        }}
      >
        {indicator.slug}
      </button>

      <div className={style} />
    </div>
  );
}
export default CompleteIndicator;
