import { AppState } from "../../../dispatcher";
import {
  InstitutionCategoryStatus,
  InstitutionVariableStatus,
} from "../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

/**
 * Función que obtiene todos los datos de una categoria, desde la categoria misma hasta
 * las variables de sus indicadores
 *
 * @param appState estado de app
 * @param categoryId id de subcategoria
 * @param setCategory setter de categoria
 * @param setInstitutionVariables setter de variables de la institucion
 * @param setOtherInstitutionVariables setter de variables de la institucion que no son de la categoria.
 * @param setReady setter de listo
 * @param setMaxVariableId maxima id de variable
 */
function singleInstitutionCategorieData(
  appState: AppState,
  categoryId: string,
  setCategory: React.Dispatch<
    React.SetStateAction<InstitutionCategoryStatus | undefined>
  >,
  setInstitutionVariables: React.Dispatch<
    React.SetStateAction<InstitutionVariableStatus[]>
  >,
  setOtherInstitutionVariables: React.Dispatch<
    React.SetStateAction<InstitutionVariableStatus[]>
  >,
  setReady: React.Dispatch<React.SetStateAction<boolean>>,
  setMaxVariableId: React.Dispatch<React.SetStateAction<number>>
): void {
  if (!appState.token) return;
  const insVariables: InstitutionVariableStatus[] = [];
  const otherVariables: InstitutionVariableStatus[] = [];
  fetchAPI<APIActions["fetchInstitutionCategories"]>(
    APIRoutes.fetchInstitutionCategories,
    {
      method: "GET",
    },
    appState.token
  ).then((categ) => {
    const category = categ.filter(
      (cate) => cate.id.toString() === categoryId
    )[0];
    setCategory({
      id: category.id,
      status: "ready",
      name: category.name,
    });
    if (!appState.token) return;
    fetchAPI<APIActions["fetchInstitutionVariables"]>(
      APIRoutes.fetchInstitutionVariables,
      {
        method: "GET",
      },
      appState.token
    )
      .then((variables) => {
        variables.sort((a, b) => a.id - b.id);
        let id = 0;
        variables
          .filter((vari) => vari.category_id.toString() === categoryId)
          .forEach((vari) => {
            if (vari.id >= id) {
              id = vari.id;
            }
            insVariables.push({
              indicator_slug: vari.indicator_slug,
              category_id: vari.category_id,
              id: vari.id,
              description: vari.description,
              is_omittable: vari.is_omittable,
              requires_verificator: vari.requires_verificator,
              slugStatus: "ready",
              descriptionStatus: "ready",
              generalStatus: "no modified",
              type: vari.type,
              json_config: vari.json_config,
              default_score: 0,
            });
          });
        variables
          .filter((vari) => vari.category_id.toString() !== categoryId)
          .forEach((vari) => {
            otherVariables.push({
              indicator_slug: vari.indicator_slug,
              category_id: vari.category_id,
              id: vari.id,
              description: vari.description,
              is_omittable: vari.is_omittable,
              requires_verificator: vari.requires_verificator,
              slugStatus: "ready",
              descriptionStatus: "ready",
              generalStatus: "no modified",
              type: vari.type,
              json_config: vari.json_config,
              default_score: 0,
            });
          });
        setMaxVariableId(id + 1);
        variables.sort((a, b) => a.id - b.id);
        setInstitutionVariables([...insVariables]);
        setOtherInstitutionVariables([...otherVariables]);
      })
      .then(() => setReady(true));
  });
}

export default singleInstitutionCategorieData;
