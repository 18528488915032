import React, { ReactElement } from "react";
import "twin.macro";

/**
 * Titulo de la pagina de asignar indicadores
 */
function AssignInstitutionalMdiTitle(): ReactElement {
  return (
    <div>
      <div tw="bg-resies_lightgreen font-sans text-header1 font-bold text-resies_blue1 py-2 px-6">
        Asignar Variables
      </div>
      <div tw="bg-resies_blue2 font-sans text-barra font-bold text-ghostwhite px-6 mb-10">
        Asignar Responsable de Variables
      </div>
    </div>
  );
}

export default AssignInstitutionalMdiTitle;
