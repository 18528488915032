import React, {
  ReactElement,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import "twin.macro";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Container from "../../components/common/Container";
import PageTitle from "../../components/common/PageTitle";
import VolverButton from "../../components/common/VolverButton";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import { User } from "../../types/backendTypes";
import { AppContext } from "../../dispatcher";
import CargandoScreen from "../../components/common/CargandoScreen";
import Routes from "../../routers/routes";
import ADMInstitutionAUDAssignmentRow from "./ADMInstitutionAUDAssignmentRow";

/**
 * AUD Assignment desde la institución
 */
function ADMInstitutionAUDAssignment(): ReactElement {
  const [state] = useContext(AppContext);
  const [auds, setAuds] = useState<Array<User>>([]);
  const { institutionId } = useParams<{ institutionId: string }>();
  const [ready, setReady] = useState(false);

  useLayoutEffect(() => {
    if (!state.token) return;
    fetchAPI<APIActions["fetchUsers"]>(
      APIRoutes.fetchUsers,
      { method: "GET" },
      state.token
    )
      .then((details) => {
        setAuds(details.filter((user) => user.user_type === "AUD"));
        setReady(true);
      })
      .catch(() => {}); // TODO
  }, [institutionId, state.token]);
  return (
    <CargandoScreen ready={ready}>
      <Container>
        <PageTitle title="Asignar AUD" />
        <Link to={Routes.inviteAUD.replace(":institutionId", institutionId)}>
          <button
            type="button"
            tw="text-barra bg-resies_purple text-ghostwhite py-2 px-4 font-bold rounded-md"
          >
            Invitar auditor
          </button>
        </Link>
        <div id="AUDs-to-assign">
          <div tw="bg-resies_lightpurple my-3 text-body text-center align-middle">
            <div tw="grid grid-cols-4">
              <div tw="text-barra font-bold bg-resies_blue1 text-ghostwhite text-center">
                Nombre
              </div>
              <div tw="text-barra font-bold bg-resies_blue1 text-ghostwhite text-center">
                Email
              </div>
              <div tw="text-barra font-bold bg-resies_blue1 text-ghostwhite text-center">
                Responsabilidades
              </div>
              <div tw="text-barra font-bold bg-resies_blue1 text-ghostwhite text-center">
                Acciones
              </div>
              {auds.map((aud) => (
                <ADMInstitutionAUDAssignmentRow
                  aud={aud}
                  institutionId={institutionId}
                  setAuds={setAuds}
                />
              ))}
            </div>
          </div>
        </div>
        <VolverButton
          to={Routes.admInstitutionsView.replace(
            ":institutionId",
            institutionId
          )}
        />
      </Container>
    </CargandoScreen>
  );
}

export default ADMInstitutionAUDAssignment;
