import React, { ReactElement } from "react";
import "twin.macro";

/**
 * Boton de "mini navbar" de la vista de edicion de instrumentos de evaluación.
 *
 * @param props react props.
 * @param props.navSelected numero de boton seleccionado
 * @param props.onClick funcion que maneja el cambio de boton seleccionado
 * @param props.buttonName nombre del boton
 * @param props.buttonNumber numero del boton
 * @param props.label texto del boton
 */
function NavButton(props: {
  navSelected: number;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  buttonName: string;
  buttonNumber: number;
  label: string;
}): ReactElement {
  const { navSelected, onClick, buttonName, buttonNumber, label } = props;
  return (
    <button
      type="button"
      name={buttonName}
      className={
        navSelected === buttonNumber
          ? "bg-resies_lightgreen w-6/12 border-b-4 border-resies_blue1 focus:outline-none text-header2 text-resies_blue1"
          : "bg-resies_lightpurple w-6/12 focus:outline-none text-header2 text-darkgray"
      }
      onClick={(e) => onClick(e)}
    >
      {label}
    </button>
  );
}

export default NavButton;
