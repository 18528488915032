import React, { ReactElement } from "react";
import "twin.macro";
import CircledButton from "./CircledButton";

type SetAttributeFunction<T> = (n: T) => void;

/**
 * Añade un menú para cambiar de página.
 *
 * @param props react props.
 * @param props.currentPage La página actual
 * @param props.setCurrentPage El callback para setear la página actual
 * @param props.maxPages La cantidad de páginas que existen
 */
function PaginatedListNavigator(props: {
  currentPage: number;
  setCurrentPage: SetAttributeFunction<number>;
  maxPages: number;
}): ReactElement {
  const { currentPage, setCurrentPage, maxPages } = props;
  return (
    <div tw="flex flex-row justify-center content-center mt-2 mb-2">
      {/* Hacemos una iteración de n-2 a n+2 y mostramos esos botones */}
      <CircledButton
        label="<"
        active={false}
        onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
      />
      {Array.from(
        {
          length:
            Math.min(currentPage + 2, maxPages) -
            Math.max(currentPage - 2, 1) +
            1,
        },
        (_, i) => i + Math.max(currentPage - 2, 1)
      ).map((i) => (
        <CircledButton
          active={i === currentPage}
          onClick={() => setCurrentPage(i)}
          label={i}
          key={i}
        />
      ))}
      <CircledButton
        label=">"
        active={false}
        onClick={() =>
          currentPage < maxPages && setCurrentPage(currentPage + 1)
        }
      />
    </div>
  );
}

export default PaginatedListNavigator;
