import React, { ReactElement } from "react";
import "twin.macro";

/**
 * Función que retorna el nombre de cada indicador junto con un color que representa su estado actual.
 *
 * @param props react props.
 * @param props.indicator Indicador recibido para ser rendereado
 * @param props.formState Estado que maneja los datos actualmente ingresados en los formularios de cada variable.
 * @param props.indicatorSelected Indicador actualmente seleccionado
 * @param props.clickHandler Funcion que maneja el cambio de indicador seleccionado.
 */
function AUDcomplete(props: {
  indicator: string;
  formState: Record<string, string | number | boolean | null>[];
  indicatorSelected: string;
  clickHandler: (ind: string) => void;
}): ReactElement {
  const { indicator, formState, indicatorSelected, clickHandler } = props;
  const indicatorVars = formState.filter((ind) => ind.name === indicator);
  const pendingVars = indicatorVars.filter(
    (variable) => variable.audit_status === "pending"
  );

  const completedVars = indicatorVars.filter(
    (variable) => variable.audit_status === "accepted"
  );
  let style;
  if (completedVars.length === indicatorVars.length) {
    style = "rounded-full bg-resies_green h-3 w-3 my-auto mx-2";
  } else if (
    pendingVars.length <= indicatorVars.length &&
    pendingVars.length >= 1
  ) {
    style = "rounded-full bg-warning_yellow h-3 w-3 my-auto mx-2";
  }
  return (
    <div
      tw="flex flex-row flex-wrap content-center"
      className={
        indicator === indicatorSelected
          ? "border-l-4 border-resies_blue1 bg-resies_lightgreen"
          : ""
      }
    >
      <button
        type="button"
        tw="mx-auto font-semibold text-black opacity-50"
        className={indicator === indicatorSelected ? "text-resies_blue1" : ""}
        onClick={() => {
          clickHandler(indicator);
        }}
      >
        {indicator}
      </button>

      <div className={style} />
    </div>
  );
}
export default AUDcomplete;
