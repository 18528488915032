import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import obtainCategoriesProgress from "../../utils/data/obtainCategoriesProgress";
import CargandoScreen from "./CargandoScreen";
import CategoriesProgressBar from "./ProgressBar";
import CategorieOption from "./CategoryProgress";
import { CategoryOverview } from "../../types/backendTypes";
import CategoryDetailProgress from "./CategoryDetailProgress";

type CategoryProgress = {
  categoryName: string;
  progress: { completed_indicators: number; total_indicators: number };
};
/**
 * Fila que muestra el progreso por categoria.
 *
 * @param props react props.
 * @param props.wantProgressbar Booleano que indica si se quiere ver la barra de progreso general.
 */
function CategoryProgressRow(props: {
  wantProgressbar: boolean;
}): ReactElement {
  const { wantProgressbar } = props;
  const [categoriesProgress, setCategoriesProgress] = useState<
    CategoryProgress[]
  >([]);
  const [ready, setReady] = useState(false);
  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const [appState] = useContext(AppContext);
  const { institutionId } = useParams<{ institutionId: string }>();
  const [categoriesData, setCategoriesData] = useState<CategoryOverview[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const textTraductor: Record<string, string> = {
    pending: "FALTANTE",
    complete: "COMPLETO",
    partial: "INCOMPLETO",
  };
  const styles: Record<string, string> = {
    pending: "darkred",
    complete: "resies_green",
    partial: "warning_yellow",
  };
  useEffect(() => {
    obtainCategoriesProgress(
      appState,
      setCategoriesProgress,
      setPercentageCompleted,
      institutionId,
      setCategoriesData
    ).then(() => setReady(true));
  }, [appState, institutionId]);
  return (
    <CargandoScreen ready={ready}>
      <div tw="font-semibold text-header2 text-resies_blue1">
        <div>Completitud por categoría</div>
        <div tw="flex flex-row justify-between mb-7 gap-3 h-56">
          {categoriesProgress.map((category) => {
            return (
              <CategorieOption
                label={category.categoryName}
                completed={category.progress.completed_indicators}
                total={category.progress.total_indicators}
                optionalText=""
                key={category.categoryName}
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
              />
            );
          })}
        </div>
        {selectedCategory ? (
          <CategoryDetailProgress
            categoriesData={categoriesData}
            selectedCategory={selectedCategory}
            styles={styles}
            textTraductor={textTraductor}
          />
        ) : (
          <></>
        )}
        {wantProgressbar ? (
          <div>
            <div tw="mt-20 text-black">
              <p>Progreso Total</p>
              <div tw="flex flex-row">
                <div tw="w-11/12">
                  <CategoriesProgressBar completed={percentageCompleted} />
                </div>
                <div tw="w-1/12 pl-10">{percentageCompleted}%</div>
              </div>
            </div>
            <div tw="flex justify-end">
              <Link to={Routes.indicators}>
                <button
                  type="button"
                  tw="text-barra font-bold text-white bg-resies_purple px-24 py-4 mt-4  rounded-lg"
                >
                  <p tw="">Ver más detalles</p>
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </CargandoScreen>
  );
}

export default CategoryProgressRow;
