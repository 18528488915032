import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import {
  AllInstitutionInstitutionalResponses,
  AllInstitutionResponses,
} from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "../common/CargandoScreen";
import AppealsRow from "./AppealsRow";

// const institutions = [
//   { id: 1, name: "Pontificia Universidad Católica", total: 2, pending: 2 },
//   { id: 2, name: "UTEM", total: 1, pending: 1 },
// ];
/**
 * Tabla de todas las apelaciones recibidas.
 */
function ADMAppealsIndex(): ReactElement {
  const [institutions, setInstitutions] = useState<AllInstitutionResponses>([]);
  const [institutionalInstitutions, setInstitutionalInstitutions] =
    useState<AllInstitutionInstitutionalResponses>([]);
  const [appState] = useContext(AppContext);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const tok = appState.token;
    if (!tok) return;
    fetchAPI<APIActions["adminAllResponses"]>(
      APIRoutes.adminAllResponses,
      {
        method: "GET",
      },
      tok
    ).then((ins) => {
      setInstitutions(ins);
      fetchAPI<APIActions["adminAllInstitutionalResponses"]>(
        APIRoutes.adminAllInstitutionalResponses,
        { method: "GET" },
        tok
      ).then((insins) => {
        setInstitutionalInstitutions(insins);
        setReady(true);
      });
    });
  }, [appState.token]);
  return (
    <CargandoScreen ready={ready}>
      <div tw="mb-10">
        <table tw="w-full">
          <thead tw="bg-resies_blue2 flex text-white w-full">
            <tr tw="flex w-full h-5">
              <th tw="text-barra font-bold text-ghostwhite text-left h-5 pl-10">
                INSTITUCIONES EN PROCESO DE APELACIÓN
              </th>
            </tr>
          </thead>
          <tbody tw="bg-resies_lightpurple flex flex-col justify-between overflow-y-scroll max-h-72">
            {!institutions.length && (
              <div tw="text-header2 my-28 text-darkgray">
                No hay apelaciones
              </div>
            )}
            {institutions.length &&
              institutions.map((ins) => (
                <AppealsRow
                  institutionId={ins.institution.id}
                  institutionName={ins.institution.name}
                  responses={ins.responses}
                  institutionalResponses={
                    institutionalInstitutions.find(
                      (ins2) => ins2.institution.id === ins.institution.id
                    )?.responses || []
                  }
                />
              ))}
          </tbody>
        </table>
      </div>
    </CargandoScreen>
  );
}

export default ADMAppealsIndex;
