import React, { ReactElement } from "react";
import "twin.macro";

/**
 * Componente de ResultsBox que entrega los resultados por categoría en una línea
 *
 * @param props react props.
 * @param props.categoryName nombre de la categoría
 * @param props.score puntaje de la categoría
 * @param props.color código de fondo para la categoría
 */
function ResultCategory(props: {
  categoryName: string;
  score: string;
  color: string;
}): ReactElement {
  const { categoryName, score, color } = props;
  return (
    <div
      className="w-full p-2 m-2 h-auto p-1"
      style={{ backgroundColor: color }}
    >
      <table tw="w-full">
        <tbody tw="flex flex-col items-center justify-between w-full">
          <tr tw="flex w-full font-semibold text-header2 text-very_darkgray">
            <td tw="w-5/6">{categoryName}</td>
            <td tw="w-1/6 text-right pr-10">{score}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ResultCategory;
