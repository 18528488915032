import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import LoadingFormButton from "../../components/common/LoadingFormButton";
import PageTitle from "../../components/common/PageTitle";
import VolverButton from "../../components/common/VolverButton";
import { AppContext, AppState, Dispatcher } from "../../dispatcher";
import Routes from "../../routers/routes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

type EmailInput = {
  first_name: string;
  last_name: string;
  email: string;
};

/**
 * La página de invitación de un RDI por parte de un administrador
 */
function ADMInviteRDI(): ReactElement {
  const { institutionId } = useParams<{ institutionId: string }>();
  const [institutionName, setInstitutionName] = useState("");
  const [ready, setReady] = useState(false);
  const [sentInvitation, setSentInvitation] = useState(false);
  const [appState]: [AppState, Dispatcher] = useContext(AppContext);
  const { register, handleSubmit } = useForm<EmailInput>();
  const [loading, setLoading] = useState(false);
  const [toEmail, setToEmail] = useState("");

  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["fetchInstitutionDetails"]>(
      APIRoutes.fetchInstitutionDetails,
      { method: "GET", routeParams: { institution_id: institutionId } },
      appState.token
    )
      .then((details) => {
        setInstitutionName(details.institution.name);
        setReady(true);
      })
      .catch(() => {}); // TODO
  }, [appState, institutionId]);
  /**
   * handler de formulario para invitar usuario
   *
   * @param data datos de contacto de mail
   */
  function onSubmit(data: EmailInput) {
    if (!appState.token) return;
    setLoading(true);
    setSentInvitation(false);
    setToEmail(data.email);
    fetchAPI<APIActions["fetchInvite"]>(
      APIRoutes.fetchInvite,
      {
        method: "POST",
        body: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: "+56 987654321",
          user_type: "RDI",
          institution_id: parseInt(institutionId, 10),
        },
      },
      appState.token
    )
      .then(() => {
        setSentInvitation(true);
        setLoading(false);
      })
      .catch(() => {}); // TODO
  }
  return (
    <div tw="bg-ghostwhite">
      <CargandoScreen ready={ready}>
        <Container>
          <PageTitle title={institutionName} />
          <div tw="h-96 w-full flex flex-wrap justify-center content-center">
            <div tw="flex flex-col">
              <div tw="text-header2 text-darkgray block">
                Invita a un nuevo usuario a unirse a RESIES
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                tw="flex flex-col justify-center"
              >
                <input
                  placeholder="Ingresa un nombre"
                  tw="block my-1 rounded px-2 py-1 border-darkgray border"
                  {...register("first_name", { required: true })}
                />
                <input
                  placeholder="Ingresa un apellido"
                  tw="block my-1 rounded px-2 py-1 border-darkgray border"
                  {...register("last_name", { required: true })}
                />
                <input
                  placeholder="Ingresa un email"
                  tw="block my-1 rounded px-2 py-1 border-darkgray border"
                  {...register("email", { required: true })}
                />
                {sentInvitation && (
                  <div>Invitación enviada satisfactoriamente a {toEmail}</div>
                )}
                <div tw="flex flex-row w-full justify-center mt-4">
                  <LoadingFormButton
                    loading={loading}
                    label="Enviar invitación"
                    className="bg-resies_purple text-ghostwhite text-barra rounded-3xl py-2 w-auto px-6"
                  />
                </div>
              </form>
            </div>
          </div>
          <VolverButton
            to={Routes.assignInstitutionRDI.replace(
              ":institutionId",
              institutionId
            )}
          />
        </Container>
      </CargandoScreen>
    </div>
  );
}

export default ADMInviteRDI;
