import React, { ReactElement } from "react";
import "twin.macro";
import PageSection from "../common/PageSection";

/**
 * Numero asignado para auditor
 *
 * @param props react props
 * @param props.number numero asignado
 */
function AuditorAssignedNumber(props: { number: number }): ReactElement {
  const { number } = props;
  return (
    <div tw="text-center">
      <PageSection text="Instituciones Asignadas" />
      <div tw="text-resies_blue1 text-very_big">{number}</div>
    </div>
  );
}

export default AuditorAssignedNumber;
