import React, { ReactElement, useRef } from "react";
import "twin.macro";
import { Category } from "../../../../types/backendTypes";
import {
  CategoryStatus,
  DropdownStatus,
  SubCategoryStatus,
} from "../../../../types/utilTypes";
import ConfirmationPopup from "../../../common/EvidenceView/ConfirmationPopup";
import NewSubcategoryOption from "./NewSubcategoryOption";
import SubcategoryOption from "./SubcategoryOption";

/**
 * El botón de borrar una categoría
 *
 * @param props react props
 * @param props.category La categoría a borrar (posiblemente)
 * @param props.onDeleteCategory La función a llamar cuando se borre la categoría
 */
function DeleteCategoryButton(props: {
  category: Category;
  onDeleteCategory: (e: number) => Promise<void>;
}) {
  const { category, onDeleteCategory } = props;
  const ref = useRef<() => void>(null);
  return (
    <button
      type="button"
      tw="w-1/12 flex flex-wrap focus:outline-none"
      name={category.id.toString()}
      onClick={() => {
        if (ref.current) ref.current();
      }}
    >
      <ConfirmationPopup
        popupText={`Está a punto de borrar la categoría ${category.name}. Desea continuar?`}
        errorText="Ha ocurrido un error. Por favor intente de nuevo"
        callback={() => onDeleteCategory(category.id)}
        ref={ref}
      />
      <img
        tw="object-contain my-auto mx-auto w-5/12 pt-1"
        src="/trash.png"
        alt="trash"
      />
    </button>
  );
}

/**
 * Componente que muestra las categorias que ya estan creadas
 *
 * @param props react props.
 * @param props.categoryStatus Lista con el estado de todas las categorias actuales
 * @param props.onChangeEditCategory Funcion que maneja el cambio de nombre de la categorias que estan siendo editadas.
 * @param props.changeStatus Funcion que cambia el estado de las categorias actuales para ser editadas.
 * @param props.subCategoryStatus Lista con el estado de las subCategorias actuales
 * @param props.dropdownStatus estado de los dropdown de subcategorias
 * @param props.setDropdownStatus setter del estado de los dropdown de subcategoria
 * @param props.setSubCategoryStatus setter del estado de las subcategorias
 * @param props.setErrorMessage setter de mensaje de error.
 * @param props.setReady setter para cargar la pagina
 * @param props.maxSubId id más alto de subcategoria
 * @param props.setMaxSubId setter de id más alto
 * @param props.reload numero de reloads
 * @param props.setReload setter de reloads
 * @param props.ready estado que indica si la pagina está lista
 * @param props.onDeleteCategory función para manejar eliminación de categorias
 * @param props.onChangeEditCategoryWeight función para manejar el cambio de peso de una categoria
 * @param props.onChangeEditCategoryColor función para manejar el cambio de color de una categoria
 */
function CategoryOption(props: {
  categoryStatus: CategoryStatus[];
  subCategoryStatus: SubCategoryStatus[];
  onChangeEditCategory: (e: React.ChangeEvent<HTMLInputElement>) => void;
  changeStatus: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dropdownStatus: DropdownStatus[];
  setDropdownStatus: React.Dispatch<React.SetStateAction<DropdownStatus[]>>;
  setSubCategoryStatus: React.Dispatch<
    React.SetStateAction<SubCategoryStatus[]>
  >;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setReady: React.Dispatch<React.SetStateAction<boolean>>;
  maxSubId: number;
  setMaxSubId: React.Dispatch<React.SetStateAction<number>>;
  reload: number;
  setReload: React.Dispatch<React.SetStateAction<number>>;
  ready: boolean;
  onDeleteCategory: (e: number) => Promise<void>;
  onChangeEditCategoryWeight: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeEditCategoryColor: (e: React.ChangeEvent<HTMLInputElement>) => void;
}): ReactElement {
  const {
    categoryStatus,
    onChangeEditCategory,
    changeStatus,
    subCategoryStatus,
    dropdownStatus,
    setDropdownStatus,
    setSubCategoryStatus,
    setErrorMessage,
    setReady,
    maxSubId,
    setMaxSubId,
    reload,
    setReload,
    ready,
    onDeleteCategory,
    onChangeEditCategoryWeight,
    onChangeEditCategoryColor,
  } = props;

  /**
   * Funcion que maneja el estado del dropdown
   *
   * @param category categoria a la que se le hace click
   */
  function onClickHandlerDropdown(category: CategoryStatus) {
    const dropdownStatusCopy = [...dropdownStatus];
    const categoryClicked = dropdownStatusCopy.filter(
      (cate) => cate.id === category.id
    )[0];
    if (categoryClicked.status === "up") {
      categoryClicked.status = "down";
    } else {
      categoryClicked.status = "up";
    }
    setDropdownStatus(dropdownStatusCopy);
  }

  return (
    <div>
      {ready ? (
        categoryStatus.map((category) => (
          <div tw="mb-10" key={category.id}>
            <div
              className="h-16 w-5/12 mx-auto flex flex-wrap content-center"
              style={{ backgroundColor: category.color }}
            >
              {category.status === "edit" ? (
                <>
                  <button
                    type="button"
                    tw="w-1/12 flex flex-wrap focus:outline-none"
                    onClick={() => onClickHandlerDropdown(category)}
                  >
                    <img
                      tw="object-contain my-auto mx-auto w-5/12"
                      src="/dropdown2.png"
                      alt="dropdown"
                    />
                  </button>
                  <input
                    tw="text-header2 text-resies_purple font-semibold w-6/12 text-center bg-resies_lightpurple border-2"
                    placeholder={category.name}
                    value={category.name}
                    name={category.id.toString()}
                    onChange={(e) => onChangeEditCategory(e)}
                  />
                  <div tw="w-2/12 my-auto flex flex-col">
                    <p tw="text-barra text-resies_purple font-semibold text-center">
                      Peso
                    </p>
                    <input
                      type="number"
                      tw="text-barra text-resies_purple font-semibold text-center bg-resies_lightpurple border-2"
                      value={category.score_weight}
                      name={category.id.toString()}
                      onChange={(e) => onChangeEditCategoryWeight(e)}
                    />
                  </div>
                  <div tw="w-1/12 my-auto flex flex-col">
                    <p tw="text-barra text-resies_purple font-semibold text-center">
                      Color
                    </p>
                    <input
                      type="color"
                      tw="text-barra text-resies_purple font-semibold text-center bg-resies_lightpurple border-2"
                      value={category.color}
                      name={category.id.toString()}
                      onChange={(e) => onChangeEditCategoryColor(e)}
                    />
                  </div>
                  <button
                    type="button"
                    tw="w-1/12 flex flex-wrap focus:outline-none"
                    name={category.id.toString()}
                    onClick={(e) => changeStatus(e)}
                  >
                    <img
                      tw="object-contain my-auto mx-auto"
                      src="/add.png"
                      alt="add"
                    />
                  </button>
                  <DeleteCategoryButton
                    category={category}
                    onDeleteCategory={onDeleteCategory}
                  />
                </>
              ) : (
                <>
                  <button
                    type="button"
                    tw="w-1/12 flex flex-wrap focus:outline-none"
                    onClick={() => onClickHandlerDropdown(category)}
                  >
                    <img
                      tw="object-contain my-auto mx-auto w-5/12"
                      src="/dropdown2.png"
                      alt="dropdown"
                    />
                  </button>
                  <div tw="w-7/12 my-auto">
                    <p tw="text-header2 text-resies_purple font-semibold text-center">
                      {category.name}
                    </p>
                  </div>
                  <div tw="w-2/12 my-auto flex flex-col">
                    <p tw="text-barra text-resies_purple font-semibold text-center">
                      Peso
                    </p>
                    <p tw="text-barra text-resies_purple font-semibold text-center">
                      {category.score_weight}
                    </p>
                  </div>
                  <button
                    type="button"
                    tw="w-1/12 flex flex-wrap focus:outline-none"
                    name={category.id.toString()}
                    onClick={(e) => changeStatus(e)}
                  >
                    <img
                      tw="object-contain my-auto mx-auto"
                      src="/editCategory.png"
                      alt="edit"
                    />
                  </button>
                  <DeleteCategoryButton
                    category={category}
                    onDeleteCategory={onDeleteCategory}
                  />
                </>
              )}
            </div>
            {dropdownStatus.length &&
            dropdownStatus.filter((cate) => cate.id === category.id)[0]
              .status === "down" ? (
              <div tw="bg-resies_lightgreen h-24 w-5/12 mx-auto flex flex-col content-center overflow-y-auto px-4">
                <SubcategoryOption
                  subCategoryStatus={subCategoryStatus}
                  setSubCategoryStatus={setSubCategoryStatus}
                  category={category}
                  setErrorMessage={setErrorMessage}
                  setReady={setReady}
                  reload={reload}
                  setReload={setReload}
                />
                <NewSubcategoryOption
                  maxSubId={maxSubId}
                  setMaxSubId={setMaxSubId}
                  category={category}
                  subCategoryStatus={subCategoryStatus}
                  setSubCategoryStatus={setSubCategoryStatus}
                  setReady={setReady}
                  reload={reload}
                  setReload={setReload}
                  setErrorMessage={setErrorMessage}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}

export default CategoryOption;
