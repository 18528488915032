import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import {
  EvaluationVariable,
  InstitutionalResponse,
  InstitutionVariables,
  ResponseVariable,
} from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "../common/CargandoScreen";
import EvidenceStatusRow from "./EvidenceStatusRow";
import InstitutionalEvidenceStatusRow from "./InstitutionalEvidenceStatusRow";

// const evidences: { slug: string; variable: string; description: string }[] = [];

/**
 * Display para el RDI de todas sus apelaciones y el estado de estas
 * En este componente debería ir el fetch que pide todas las apelaciones realizadas
 */
function AppealsIndex(): ReactElement {
  const [evidences, setEvidences] = useState<ResponseVariable[]>([]);
  const [evaluationVariables, setEvaluationVariables] = useState<
    EvaluationVariable[]
  >([]);
  const [institutionVariables, setInstitutionVariables] = useState<
    InstitutionVariables[]
  >([]);
  const [institutionalEvidences, setInstitutionalEvidences] = useState<
    InstitutionalResponse[]
  >([]);
  const [appState] = useContext(AppContext);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const key = appState.token;
    if (!key) return;
    fetchAPI<APIActions["fetchResponseVariables"]>(
      APIRoutes.fetchResponseVariables,
      { method: "GET" },
      key
    ).then((ev) => {
      setEvidences(
        ev.filter(
          (e) =>
            e.audit_status === "appealed" ||
            e.audit_status === "final_accepted" ||
            e.audit_status === "final_rejected"
        )
      );
      fetchAPI<APIActions["fetchEvaluationVariables"]>(
        APIRoutes.fetchEvaluationVariables,
        { method: "GET" },
        key
      ).then((vars) => {
        setEvaluationVariables(vars);
        fetchAPI<APIActions["fetchInstitutionVariables"]>(
          APIRoutes.fetchInstitutionVariables,
          { method: "GET" },
          key
        ).then((ivars) => {
          setInstitutionVariables(ivars);
          fetchAPI<APIActions["fetchInstitutionalResponses"]>(
            APIRoutes.fetchInstitutionalResponses,
            { method: "GET" },
            key
          ).then((iev) => {
            setInstitutionalEvidences(
              iev.filter(
                (e) =>
                  e.audit_status === "appealed" ||
                  e.audit_status === "final_accepted" ||
                  e.audit_status === "final_rejected"
              )
            );
            setReady(true);
          });
        });
      });
    });
  }, [appState.token]);
  return (
    <CargandoScreen ready={ready}>
      <table tw="w-full mb-4">
        <thead tw="bg-resies_blue2 flex text-white w-full">
          <tr tw="w-full h-5">
            <th tw="text-barra font-bold text-ghostwhite text-left h-5 pl-10">
              Apelaciones
            </th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody tw="bg-resies_lightpurple flex flex-col justify-between overflow-y-scroll max-h-72">
          {!evidences.length
            ? !institutionalEvidences.length && (
                <tr tw="text-header2 my-5 pl-10 text-darkgray">
                  <td colSpan={2}>No hay apelaciones</td>
                </tr>
              )
            : evidences.map((e) => {
                const evvar = evaluationVariables.find(
                  (ev) => ev.id === e.variable_id
                );
                return (
                  <EvidenceStatusRow
                    slug={evvar?.indicator_slug || ""}
                    variableId={e.variable_id}
                    responseId={e.id}
                    description={evvar?.description || ""}
                    status={e.audit_status}
                  />
                );
              })}
          {!!institutionalEvidences.length &&
            institutionalEvidences.map((e) => {
              const evvar = institutionVariables.find(
                (ev) => ev.id === e.variable_id
              );
              return (
                <InstitutionalEvidenceStatusRow
                  slug={evvar?.indicator_slug || ""}
                  variableId={e.variable_id}
                  responseId={e.id}
                  description={evvar?.description || ""}
                  status={e.audit_status}
                />
              );
            })}
        </tbody>
      </table>
    </CargandoScreen>
  );
}

export default AppealsIndex;
