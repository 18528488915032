import React, {
  useEffect,
  useState,
  ChangeEvent,
  useContext,
  ReactElement,
} from "react";
import "twin.macro";
import { Link } from "react-router-dom";
import ADMUserFilterBox from "../../components/admin/ADMUserFilterBox";
import UsersList from "../../components/admin/UsersList";
import VolverButton from "../../components/common/VolverButton";
import { AppContext } from "../../dispatcher";
import useUsers from "../../hooks/common/useUsers";
import Routes from "../../routers/routes";
import { Institution } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import ADMPanelNavbar from "../../components/admin/ADMPanelNavbar";

/**
 * Panel de usuarios del administrador
 */
function ADMPanelUsers(): ReactElement {
  const [ready, setReady] = useState(false);
  const [userSearch, setUserSearch] = useState("");
  const [institutionSearch, setInstitutionSearch] = useState<string>("");
  const [roleSearch, setRoleSearch] = useState("");
  const [institutions, setInstitutions] = useState<Institution[]>([]);

  const [appState] = useContext(AppContext);

  /**
   * Setear filtro de usuario
   *
   * @param e evento de cambio de texto
   */
  function userSearchChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setUserSearch(e.target.value);
  }
  /**
   * Setear filtro de institucion
   *
   * @param e evento de cambio de texto
   */
  function institutionSearchChangeHandler(e: ChangeEvent<HTMLSelectElement>) {
    setInstitutionSearch(e.target.value);
  }
  /**
   * setear filtro de rol
   *
   * @param e evento de cambio de texto
   */
  function roleSearchChangeHandler(e: ChangeEvent<HTMLSelectElement>) {
    setRoleSearch(e.target.value);
  }
  const [
    users,
    maxPages,
    pageNumber,
    setPageNumber,
    fetchedUsers,
    reloadUsers,
  ] = useUsers(15, appState.token, userSearch, institutionSearch, roleSearch);

  useEffect(() => {
    if (appState.token && fetchedUsers) {
      fetchAPI<APIActions["fetchInstitutions"]>(
        APIRoutes.fetchInstitutions,
        { method: "GET" },
        appState.token
      ).then((ins) => {
        setInstitutions(ins);
        setReady(true);
      });
    }
  }, [appState.token, fetchedUsers, setReady]);

  return (
    <Container>
      <ADMPanelNavbar />
      <CargandoScreen ready={ready}>
        <Link to={Routes.inviteADM}>
          <button
            type="button"
            tw="bg-resies_purple text-ghostwhite px-4 py-2 rounded-md"
          >
            Invitar nuevo administrador
          </button>
        </Link>
        <div tw="grid grid-cols-4 mt-4 mb-4 col-gap-1">
          <div>
            <ADMUserFilterBox
              userSearch={userSearch}
              userSearchChangeHandler={userSearchChangeHandler}
              institutionSearch={institutionSearch}
              institutionSearchChangeHandler={institutionSearchChangeHandler}
              roleSearch={roleSearch}
              roleSearchChangeHandler={roleSearchChangeHandler}
              institutions={institutions}
            />
          </div>
          <div tw="col-span-3">
            <UsersList
              users={users}
              institutionList={institutions}
              currentPage={pageNumber}
              setCurrentPage={setPageNumber}
              maxPages={maxPages}
              reloadUsers={reloadUsers}
            />
          </div>
        </div>
        <VolverButton to={Routes.landing} />
      </CargandoScreen>
    </Container>
  );
}

export default ADMPanelUsers;
