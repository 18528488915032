import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";

/**
 * El botón para volver de las vistas
 *
 * @param props react props.
 * @param props.to El link a donde volver
 */
function VolverButton(props: { to: string }): ReactElement {
  const { to } = props;
  return (
    <Link to={to} tw="w-auto inline-block pt-4">
      <div tw="flex flex-row flex-wrap content-center">
        <img src="/back.png" alt="back" tw="ml-3.5 mb-2" />
        <button type="button" tw="font-semibold text-resies_blue1 ml-2 text-xl">
          <p tw="mb-2">Regresar</p>
        </button>
      </div>
    </Link>
  );
}
export default VolverButton;
