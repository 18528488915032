import React, { ReactElement, useEffect } from "react";
import "twin.macro";
import { Indicator, User } from "../../../types/backendTypes";

/**
 * Checkbox de un indicador
 *
 * @param props react props.
 * @param props.indicator Indicador con el que se creara un checkbox.
 * @param props.selectedIndicators Son los indicadores seleccionados.
 * @param props.setSelectedIndicators SetState de los indicadores seleccionados.
 * @param props.selectedUser usuario seleccionado
 * @param props.userSelectedIndicators indicadores seleccionados
 * @param props.setUserSelectedIndicators setter de indicadores seleccionados
 * @param props.forCheckAll arreglo de todo los indicadores por seccion
 *
 */
function IndicatorList(props: {
  indicator: Indicator;
  selectedIndicators: Indicator[];
  setSelectedIndicators: React.Dispatch<React.SetStateAction<Indicator[]>>;
  selectedUser: User | undefined;
  userSelectedIndicators: Record<number, Indicator[]>;
  setUserSelectedIndicators: React.Dispatch<
    React.SetStateAction<Record<number, Indicator[]>>
  >;
  forCheckAll: Indicator[];
}): ReactElement {
  const {
    indicator,
    selectedIndicators,
    setSelectedIndicators,
    selectedUser,
    userSelectedIndicators,
    setUserSelectedIndicators,
    forCheckAll,
  } = props;

  /**
   * Funcion que maneja el cambio de estado de los indicadores seleccionados.
   *
   * @param ind indicador que fue checkeado.
   */
  function onChange(ind: Indicator) {
    const copy = [...selectedIndicators];
    const index = copy.findIndex((ind2) => ind2.id === ind.id);
    const userIndicators = { ...userSelectedIndicators };
    if (index !== -1) {
      copy.splice(index, 1);
      setSelectedIndicators(copy);
    } else {
      copy.push(ind);
      setSelectedIndicators(copy);
    }
    if (selectedUser) {
      userIndicators[selectedUser.id] = [...copy];
      setUserSelectedIndicators({ ...userIndicators });
    }
  }
  /**
   * Funcion que retorna si un indicador esta seleccionado.
   *
   * @param ind indicador que se vera si esta en la lista de seleccionados.
   * @returns booleano indicando si esta seleccionado o no.
   */
  function selected(ind: Indicator) {
    return selectedIndicators.filter((ind2) => ind2.id === ind.id).length > 0;
  }

  useEffect(() => {
    if (selectedUser && forCheckAll) {
      const listForCheck: Indicator[] = [];
      forCheckAll.forEach((ind) => {
        if (!selected(ind)) {
          listForCheck.push(ind);
        }
      });
      const userIndicators = { ...userSelectedIndicators };
      const indicatorSelectedConc =
        userSelectedIndicators[selectedUser.id].concat(listForCheck);
      userIndicators[selectedUser.id] = indicatorSelectedConc;

      setSelectedIndicators(indicatorSelectedConc);
      setUserSelectedIndicators({ ...userIndicators });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forCheckAll, selectedUser]);

  return (
    <div>
      <div>
        <input
          type="checkbox"
          name={indicator.slug}
          onChange={() => onChange(indicator)}
          checked={selected(indicator)}
          tw="font-normal text-body"
          data-testid={indicator.id}
        />
        <label htmlFor={indicator.slug}>
          {indicator.slug}: {indicator.title}
        </label>
      </div>
    </div>
  );
}

export default IndicatorList;
