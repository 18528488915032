import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import CategoriesNavbar from "../../components/rdi/AssignMDI/CategoriesNavbar";
import VolverButton from "../../components/common/VolverButton";
import AssignMdiTitle from "../../components/rdi/AssignMDI/AssignMdiTitle";
import IndicatorList from "../../components/rdi/AssignMDI/IndicatorsList";
import { AppContext } from "../../dispatcher";
import { Category, Indicator, User } from "../../types/backendTypes";
import dropDownData from "../../utils/data/dropDownData";
import SelectedIndicators from "../../components/rdi/AssignMDI/SelectedIndicators";
import getUsers from "../../utils/data/getUsers";
import UsersList from "../../components/rdi/AssignMDI/UsersList";
import CargandoScreen from "../../components/common/CargandoScreen";
import Routes from "../../routers/routes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * La página para asinar MDI del RDI.
 */
function RDIAssignMdiPage(): ReactElement {
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [indicators, setIndicators] = useState<Record<string, Indicator[]>>({});
  const [selectedCategory, setSelectedCategory] = useState<Category>({
    name: "",
    description: "",
    score_weight: 0,
    id: 0,
    color: "",
  });
  const [ready, setReady] = useState(false);
  const [selectedIndicators, setSelectedIndicators] = useState<
    Array<Indicator>
  >([]);
  const [userSelectedIndicators, setUserSelectedIndicators] = useState<
    Record<number, Indicator[]>
  >({});
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [appState] = useContext(AppContext);
  const [allcheck, setAllcheck] = useState<Indicator[]>([]);
  const [indicatorsReady, setIndicatorsReady] = useState(true);

  /**
   *
   */
  /*
  function handleSetSelectedUser(user: User | undefined) {
    if (!appState.token || !user) return;
    fetchAPI<APIActions["getMdiIndicators"]>(
      APIRoutes.getMdiIndicators,
      {
        method: "GET",
        routeParams: { user_id: user.id.toString() },
      },
      appState.token
    ).then((inds) => {
      setSelectedUser(user);
      setSelectedIndicators(inds);
    });
  }
  */

  useEffect(
    function loadData() {
      dropDownData(
        setCategories,
        setIndicators,
        appState.token,
        setReady,
        setSelectedCategory
      );
      getUsers(
        setUsers,
        appState.token,
        appState.institutionId,
        setUserSelectedIndicators
      );
    },
    [appState]
  );

  /**
   * Funcion que envia el POST a la api para asignar los indicadores al usuario.
   */
  function onSubmitHandler() {
    if (!appState.token || !selectedUser) return;
    const { token } = appState;
    setIndicatorsReady(false);
    Promise.all(
      Object.keys(userSelectedIndicators).map((user_id) => {
        return fetchAPI<APIActions["assignIndicatorBatch"]>(
          APIRoutes.assignIndicatorBatch,
          {
            method: "POST",
            routeParams: { user_id: user_id.toString() },
            body: {
              indicators: userSelectedIndicators[parseInt(user_id, 10)].map(
                (ind) => ind.id
              ),
            },
          },
          token
        );
      })
    ).then(() => {
      if (!selectedUser.mdiuser) return;
      setSelectedIndicators([...userSelectedIndicators[selectedUser.id]]);
      setIndicatorsReady(true);
    });
  }

  const onCheckedAllIndicators = () => {
    setAllcheck(indicators[selectedCategory.name]);
  };

  return (
    <CargandoScreen ready={ready}>
      {!ready ? (
        "??"
      ) : (
        <div tw="bg-ghostwhite">
          <Container>
            <AssignMdiTitle />
            <div tw="grid grid-cols-11 mb-10 gap-4 lg:gap-20">
              <UsersList
                users={users}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                setSelectedIndicators={setSelectedIndicators}
                userSelectedIndicators={userSelectedIndicators}
              />
              <SelectedIndicators
                selectedIndicators={selectedIndicators}
                setSelectedIndicators={setSelectedIndicators}
                indicatorsReady={indicatorsReady}
                selectedUser={selectedUser}
                userSelectedIndicators={userSelectedIndicators}
                setUserSelectedIndicators={setUserSelectedIndicators}
              />
            </div>
            <div tw="bg-resies_blue2 font-sans text-barra font-bold text-ghostwhite px-6">
              Indicadores
            </div>
            <CategoriesNavbar
              categories={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
            <div tw="flex flex-col mb-10 bg-resies_lightgreen h-52 overflow-y-auto pl-5 py-3">
              <CargandoScreen ready={indicatorsReady}>
                {indicators[selectedCategory.name].map((indicator, index) => (
                  <IndicatorList
                    indicator={indicator}
                    selectedIndicators={selectedIndicators}
                    setSelectedIndicators={setSelectedIndicators}
                    key={indicator.slug}
                    selectedUser={selectedUser}
                    userSelectedIndicators={userSelectedIndicators}
                    setUserSelectedIndicators={setUserSelectedIndicators}
                    forCheckAll={allcheck}
                  />
                ))}
              </CargandoScreen>
            </div>
            <div tw="flex flex-row justify-between">
              <div tw="flex flex-col justify-center mt-5">
                <button
                  type="button"
                  tw="bg-resies_purple text-barra font-bold rounded-3xl text-white py-5 px-10"
                  onClick={onCheckedAllIndicators}
                >
                  Seleccionar toda la categoría
                </button>
              </div>
              <div tw="flex flex-col justify-center mt-5">
                <button
                  type="button"
                  tw="bg-resies_purple text-barra font-bold rounded-3xl text-white py-5 px-10"
                  onClick={onSubmitHandler}
                >
                  Actualizar
                </button>
              </div>
            </div>
            <VolverButton to={Routes.myInstitution} />
          </Container>
        </div>
      )}
    </CargandoScreen>
  );
}

export default RDIAssignMdiPage;
