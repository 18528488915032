import { Tooltip } from "@material-ui/core";
import React, { ReactElement } from "react";
import "twin.macro";
import { Indicator, IndicatorOverview } from "../../types/backendTypes";

/**
 * Componente que muestra la ultima columna del Category Detail Progress para RDI
 *
 * @param props react props
 * @param props.indicators lista de indicadores
 * @param props.ind indicador mostrado en la fila
 */
function RDIDetailProgress(props: {
  indicators: Indicator[];
  ind: IndicatorOverview;
}): ReactElement {
  const { indicators, ind } = props;
  const indicator = indicators.filter(
    (indicator) => indicator.id === ind.id
  )[0];

  return (
    <>
      {indicator && indicator.mdiusers && (
        <>
          {indicator.mdiusers.length ? (
            <Tooltip
              title={
                indicator.mdiusers.length > 1
                  ? indicator.mdiusers.map((mdi, index) => {
                      if (index > 0) {
                        return (
                          <div>
                            {mdi.first_name} {mdi.last_name}
                          </div>
                        );
                      }
                      return <></>;
                    })
                  : ""
              }
              placement="top"
            >
              <div>
                {indicator.mdiusers[0]?.first_name}{" "}
                {indicator.mdiusers[0]?.last_name}{" "}
                {indicator.mdiusers.length > 1 ? (
                  <>y {indicator.mdiusers.length - 1} más</>
                ) : (
                  ""
                )}
              </div>
            </Tooltip>
          ) : (
            "No hay nadie asignado"
          )}
        </>
      )}
    </>
  );
}

export default RDIDetailProgress;
