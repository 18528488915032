import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import "twin.macro";

/**
 * Elemento de la navbar.
 *
 * @param props react props.
 * @param props.name Titulo del elemento.
 * @param props.href Vínculo a donde redirige el elemento
 */
function NavbarElement(props: { name: string; href: string }): ReactElement {
  const { name, href } = props;
  return (
    <NavLink
      to={href}
      tw="w-full text-xs md:text-xs xl:text-lg lg:px-3 px-0 py-3 
       font-semibold box-border text-center print:hidden"
      className="text-gray border-0"
      activeClassName={
        href === "#"
          ? "text-gray border-0"
          : "text-resies_lightpurple border-b-8 border-resies_lightpurple"
      }
    >
      {name}
    </NavLink>
  );
}

export default NavbarElement;
