import React, { ReactElement } from "react";
import "twin.macro";
/**
 * Un subtitulo formateado de las páginas.
 *
 * @param props react props.
 * @param props.text El texto a mostrar
 */
function PageSection(props: { text: string }): ReactElement {
  const { text } = props;
  return (
    <div tw="text-header2 font-bold text-very_darkgray col-span-3">{text}</div>
  );
}

export default PageSection;
