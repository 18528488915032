import { AppState } from "../../../dispatcher";
import { InstitutionVariableStatus } from "../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

/**
 * Funcion que se encarga de enviar todos los cambios en la ventana de edicion de indicadores al backend.
 *
 * @param setReady setter de ready
 * @param deletedVariables variables eliminadas
 * @param appState estado de app
 * @param institutionVariables variables de evaluacion
 * @param reload reload
 * @param setReload setter de reload
 * @param setChanges setter de cambios
 */
function sendInstitutionChangesToBackend(
  setReady: React.Dispatch<React.SetStateAction<boolean>>,
  deletedVariables: InstitutionVariableStatus[],
  appState: AppState,
  institutionVariables: InstitutionVariableStatus[],
  reload: number,
  setReload: React.Dispatch<React.SetStateAction<number>>,
  setChanges: React.Dispatch<React.SetStateAction<boolean>>
): void {
  setReady(false);
  const deleteList: Promise<unknown>[] = [];
  deletedVariables.forEach((vari) => {
    if (!appState.token) return;
    if (vari.generalStatus !== "new") {
      deleteList.push(
        fetchAPI<APIActions["deleteInstitutionVariable"]>(
          APIRoutes.deleteInstitutionVariable,
          {
            method: "DELETE",
            routeParams: { variable_id: vari.id.toString() },
          },
          appState.token
        )
      );
    }
  });
  Promise.all(deleteList).then(() => {
    Promise.all(
      institutionVariables.map((vari) => {
        if (!appState.token) return Promise.resolve();
        if (vari.generalStatus === "new") {
          return fetchAPI<APIActions["createInstitutionVariable"]>(
            APIRoutes.createInstitutionVariable,
            {
              method: "POST",
              body: {
                indicator_slug: vari.indicator_slug,
                category_id: vari.category_id,
                description: vari.description,
                is_omittable: vari.is_omittable,
                requires_verificator: vari.requires_verificator,
                default_score: vari.default_score,
                json_config: vari.json_config,
                type: vari.type,
              },
            },
            appState.token
          );
        }
        if (vari.generalStatus === "modified") {
          return fetchAPI<APIActions["editInstitutionVariable"]>(
            APIRoutes.editInstitutionVariable,
            {
              method: "PUT",
              body: {
                indicator_slug: vari.indicator_slug,
                category_id: vari.category_id,
                description: vari.description,
                is_omittable: vari.is_omittable,
                requires_verificator: vari.requires_verificator,
                default_score: vari.default_score,
                json_config: vari.json_config,
                type: vari.type,
              },
              routeParams: { variable_id: vari.id.toString() },
            },
            appState.token
          );
        }
        return 0;
      })
    ).then(() => {
      setReload(reload + 1);
      setChanges(false);
    });
  });
}

export default sendInstitutionChangesToBackend;
