import { AppState } from "../../../dispatcher";
import { Subcategory } from "../../../types/backendTypes";
import {
  EvaluationVariablesFromIndicator,
  EvaluationVariableStatus,
  IndicatorStatus,
} from "../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

/**
 * Funcion que envia los cambios en los indicadores al backend
 *
 * @param indicators indicadores
 * @param subCategory subcategoria
 * @param appState estado de app
 * @param evaluationVariables variables de evaluacion
 */
function sendIndicators(
  indicators: IndicatorStatus[],
  subCategory: Subcategory,
  appState: AppState,
  evaluationVariables: EvaluationVariablesFromIndicator[]
) {
  const promisesList: Promise<unknown>[] = [];
  indicators.forEach((indi) => {
    if (!appState.token) return;
    const { token } = appState;
    if (
      indi.generalStatus === "modified" ||
      indi.generalStatus === "no modified"
    ) {
      promisesList.push(
        fetchAPI<APIActions["editIndicator"]>(
          APIRoutes.editIndicator,
          {
            method: "PUT",
            routeParams: { indicator_id: indi.id.toString() },
            body: {
              slug: indi.slug,
              subcategory_id: subCategory.id,
              title: indi.title,
              score_weight: indi.score_weight,
              color: indi.color,
              formula: indi.formula,
              unreplaced_formula: indi.unreplaced_formula,
              auxiliary_formulas: indi.auxiliaryFormula,
            },
          },
          token
        ).then(() =>
          evaluationVariables
            .filter((evalu) => evalu.id === indi.id)[0]
            .evaluationVariables.forEach((evalu) => {
              if (evalu.generalStatus === "modified") {
                fetchAPI<APIActions["editEvaluationVariable"]>(
                  APIRoutes.editEvaluationVariable,
                  {
                    method: "PUT",
                    routeParams: { variable_id: evalu.id.toString() },
                    body: {
                      indicator_id: evalu.indicator_id,
                      display_order: evalu.display_order,
                      type: evalu.type,
                      description: evalu.description,
                      is_omittable: evalu.is_omittable,
                      requires_verificator: evalu.requires_verificator,
                      description_field_helper_text:
                        evalu.description_field_helper_text,
                      verification_field_helper_text:
                        evalu.verification_field_helper_text,
                      default_score: evalu.default_score,
                      json_config: evalu.json_config,
                    },
                  },
                  token
                );
              } else if (evalu.generalStatus === "new") {
                fetchAPI<APIActions["createEvaluationVariable"]>(
                  APIRoutes.createEvaluationVariable,
                  {
                    method: "POST",
                    body: {
                      indicator_id: evalu.indicator_id,
                      display_order: evalu.display_order,
                      type: evalu.type,
                      description: evalu.description,
                      is_omittable: evalu.is_omittable,
                      requires_verificator: evalu.requires_verificator,
                      description_field_helper_text:
                        evalu.description_field_helper_text,
                      verification_field_helper_text:
                        evalu.verification_field_helper_text,
                      default_score: evalu.default_score,
                      json_config: evalu.json_config,
                    },
                  },
                  token
                );
              }
            })
        )
      );
    } else if (indi.generalStatus === "new") {
      promisesList.push(
        fetchAPI<APIActions["createIndicator"]>(
          APIRoutes.createIndicator,
          {
            method: "POST",
            body: {
              slug: indi.slug,
              subcategory_id: subCategory.id,
              title: indi.title,
              score_weight: indi.score_weight,
              formula: indi.formula,
              unreplaced_formula: indi.unreplaced_formula,
              auxiliary_formulas: indi.auxiliaryFormula,
            },
          },
          appState.token
        ).then((data) => {
          evaluationVariables
            .filter((evalu) => evalu.id === indi.id)[0]
            .evaluationVariables.forEach((evalu) => {
              if (evalu.generalStatus === "modified") {
                fetchAPI<APIActions["editEvaluationVariable"]>(
                  APIRoutes.editEvaluationVariable,
                  {
                    method: "PUT",
                    routeParams: { variable_id: evalu.id.toString() },
                    body: {
                      indicator_id: data.id,
                      display_order: evalu.display_order,
                      type: evalu.type,
                      description: evalu.description,
                      is_omittable: evalu.is_omittable,
                      requires_verificator: evalu.requires_verificator,
                      description_field_helper_text:
                        evalu.description_field_helper_text,
                      verification_field_helper_text:
                        evalu.verification_field_helper_text,
                      default_score: evalu.default_score,
                      json_config: evalu.json_config,
                    },
                  },
                  token
                );
              } else if (evalu.generalStatus === "new") {
                fetchAPI<APIActions["createEvaluationVariable"]>(
                  APIRoutes.createEvaluationVariable,
                  {
                    method: "POST",
                    body: {
                      indicator_id: data.id,
                      display_order: evalu.display_order,
                      type: evalu.type,
                      description: evalu.description,
                      is_omittable: evalu.is_omittable,
                      requires_verificator: evalu.requires_verificator,
                      description_field_helper_text:
                        evalu.description_field_helper_text,
                      verification_field_helper_text:
                        evalu.verification_field_helper_text,
                      default_score: evalu.default_score,
                      json_config: evalu.json_config,
                    },
                  },
                  token
                );
              }
            });
        })
      );
    }
  });
  return Promise.all(promisesList);
}
/**
 * Funcion que se encarga de enviar todos los cambios en la ventana de edicion de indicadores al backend.
 *
 * @param setReady setter de ready
 * @param deletedVariables variables eliminadas
 * @param appState estado de app
 * @param deletedIndicators indicadores eliminados
 * @param indicators indicadores
 * @param evaluationVariables variables de evaluacion
 * @param subCategory subcategoria
 * @param reload reload
 * @param setReload setter de reload
 * @param setChanges setter de cambios
 */
function sendChangesToBackend(
  setReady: React.Dispatch<React.SetStateAction<boolean>>,
  deletedVariables: EvaluationVariableStatus[],
  appState: AppState,
  deletedIndicators: IndicatorStatus[],
  indicators: IndicatorStatus[],
  evaluationVariables: EvaluationVariablesFromIndicator[],
  subCategory: Subcategory,
  reload: number,
  setReload: React.Dispatch<React.SetStateAction<number>>,
  setChanges: React.Dispatch<React.SetStateAction<boolean>>
): void {
  setReady(false);
  const deleteList: Promise<unknown>[] = [];
  deletedVariables.forEach((vari) => {
    if (vari.generalStatus !== "new") {
      if (!appState.token) return;
      deleteList.push(
        fetchAPI<APIActions["deleteEvaluationVariable"]>(
          APIRoutes.deleteEvaluationVariable,
          {
            method: "DELETE",
            routeParams: { variable_id: vari.id.toString() },
          },
          appState.token
        )
      );
    }
  });
  Promise.all(deleteList)
    .then(() => {
      deletedIndicators.forEach((indi) => {
        if (!appState.token) return;
        if (indi.generalStatus !== "new") {
          fetchAPI<APIActions["deleteIndicator"]>(
            APIRoutes.deleteIndicator,
            {
              method: "DELETE",
              routeParams: { indicator_id: indi.id.toString() },
            },
            appState.token
          );
        }
      });
    })
    .then(() => {
      sendIndicators(
        indicators,
        subCategory,
        appState,
        evaluationVariables
      ).then(() => {
        setChanges(false);
        setReady(false);
        setReload(reload + 1);
      });
    });
}

export default sendChangesToBackend;
