import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Redirect, useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import EvidenceComments from "../../components/common/EvidenceView/EvidenceComments";
import EvidenceDataRow from "../../components/common/EvidenceView/EvidenceDataRow";
import EvidenceInfo from "../../components/common/EvidenceView/EvidenceInfo";
import EvidenceTitle from "../../components/common/EvidenceView/EvidenceTitle";
import VolverButton from "../../components/common/VolverButton";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import {
  EvaluationVariableDetails,
  InstitutionDetails,
  ResponseVariable,
} from "../../types/backendTypes";
import getSingleResponse from "../../utils/data/AdmData/getSingleResponse";
import getInstitutionDetails from "../../utils/data/AudData/getInstitutionDetails";
import getVariableDetails from "../../utils/data/getVariableDetails";

/**
 * Componente de la vista de evidencia del ADM
 */
function ADMEvidenceView(): ReactElement {
  const { variableId } = useParams<{ variableId: string }>();
  const { responseId } = useParams<{ responseId: string }>();
  const { institutionId } = useParams<{ institutionId: string }>();
  const [uploadFile, setUploadFile] = useState(0);
  const [institution, setInstitution] = useState<InstitutionDetails>();
  const [ready, setReady] = useState(false);
  const [responseVariable, setResponseVariable] = useState<ResponseVariable>();
  const [reload, setReload] = useState(0);
  const [evaluationVariable, setEvaluationVariable] =
    useState<EvaluationVariableDetails>();
  const [error, setError] = useState(false);
  const [appState] = useContext(AppContext);

  useEffect(() => {
    getInstitutionDetails(institutionId, appState.token, setInstitution);
    getVariableDetails(appState, variableId, setEvaluationVariable, setReady);
    getSingleResponse(
      appState,
      institutionId,
      responseId,
      setResponseVariable,
      setError,
      setReady
    );
  }, [appState, institutionId, responseId, variableId, uploadFile, reload]);

  return (
    <div tw="bg-ghostwhite">
      {error ? <Redirect to={Routes.ADMindicators} /> : <></>}
      <CargandoScreen ready={ready}>
        <Container>
          {institution ? (
            <EvidenceTitle
              evaluationVariable={evaluationVariable}
              title={institution.institution.description}
              responseVariable={responseVariable}
            />
          ) : (
            <></>
          )}
          <EvidenceInfo
            info={responseVariable?.description}
            title="Descripción de variable"
          />
          <EvidenceInfo
            info={evaluationVariable?.verification_field_helper_text}
            title="Verificación Necesaria"
          />
          {responseVariable ? (
            <EvidenceDataRow
              response={responseVariable}
              setUploadFile={setUploadFile}
              reload={reload}
              setReload={setReload}
            />
          ) : (
            <></>
          )}
          <EvidenceComments />
          <VolverButton
            to={`${Routes.ADMindicators.replace(
              ":institutionId",
              institutionId
            )}?indic=${evaluationVariable?.indicator.slug}&tab=${
              evaluationVariable?.indicator.subcategory?.category.id
            }`}
          />
          <div tw="bg-ghostwhite h-10" />
        </Container>
      </CargandoScreen>
    </div>
  );
}

export default ADMEvidenceView;
