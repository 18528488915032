import React, { ReactElement, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import { AppContext } from "../../../../dispatcher";
import Routes from "../../../../routers/routes";
import { CategoryStatus, SubCategoryStatus } from "../../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../../../utils/fetchAPI";
import ConfirmationPopup from "../../../common/EvidenceView/ConfirmationPopup";

/**
 * El botón de borrar una categoría
 *
 * @param props react props
 * @param props.subCategory La subcategoría a borrar (posiblemente)
 * @param props.onDeleteSubcategory La función a llamar cuando se borre la categoría
 */
function DeleteSubcategoryButton(props: {
  subCategory: SubCategoryStatus;
  onDeleteSubcategory: (e: number) => Promise<void>;
}) {
  const { subCategory, onDeleteSubcategory } = props;
  const ref = useRef<() => void>(null);
  return (
    <button
      type="button"
      tw="w-1/12 flex flex-wrap focus:outline-none"
      name={subCategory.id.toString()}
      onClick={() => {
        if (ref.current) ref.current();
      }}
    >
      <ConfirmationPopup
        popupText={`Está a punto de borrar la subcategoría ${subCategory.name}. Desea continuar?`}
        errorText="Ha ocurrido un error. Por favor intente de nuevo"
        callback={() => onDeleteSubcategory(subCategory.id)}
        ref={ref}
      />
      <img
        tw="object-contain my-auto mx-auto w-5/12 pt-1"
        src="/trash.png"
        alt="trash"
      />
    </button>
  );
}

/**
 * Componente que muestra las subcategorias de una categoria
 *
 * @param props props
 * @param props.subCategoryStatus estado de las subcategorias
 * @param props.setSubCategoryStatus setter del estado de las subcatgorias
 * @param props.category categoria de la cual se mostraran sus subcategorias
 * @param props.setErrorMessage setter de mensaje de error.
 * @param props.setReady setter para cargar la pagina
 * @param props.reload numero de reloads
 * @param props.setReload setter de numero de reloads
 */
function SubcategoryOption(props: {
  subCategoryStatus: SubCategoryStatus[];
  setSubCategoryStatus: React.Dispatch<
    React.SetStateAction<SubCategoryStatus[]>
  >;
  category: CategoryStatus;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setReady: React.Dispatch<React.SetStateAction<boolean>>;
  reload: number;
  setReload: React.Dispatch<React.SetStateAction<number>>;
}): ReactElement {
  const {
    subCategoryStatus,
    setSubCategoryStatus,
    category,
    setErrorMessage,
    setReady,
    reload,
    setReload,
  } = props;
  const [appState] = useContext(AppContext);
  /**
   * Funcion que maneja el cambio de nombre en las subcategorias que se estan editando.
   *
   * @param e evento de cambio en el input
   */
  function onChangeEditCategory(e: React.ChangeEvent<HTMLInputElement>) {
    const copyStatus = [...subCategoryStatus];
    const element = copyStatus.filter(
      (status) => status.id.toString() === e.target.name
    )[0];
    element.name = e.target.value;
    setSubCategoryStatus([...copyStatus]);
  }

  /**
   * Funcion que maneja el cambio de status en las subcategorias.
   *
   * @param e evento que desencadena el cambio con un click.
   */
  function changeStatus(e: React.MouseEvent<HTMLButtonElement>) {
    const copyStatus = [...subCategoryStatus];
    let errorNameRepeated = false;
    const names = copyStatus
      .filter((sub) => sub.categoryId === category.id)
      .map((cate) => cate.name);
    errorNameRepeated = new Set(names).size !== names.length;
    if (errorNameRepeated) {
      setErrorMessage("No se pueden tener nombres repetidos");
      return;
    }
    const element = copyStatus.filter(
      (status) => status.id.toString() === e.currentTarget.name
    )[0];
    if (element.status === "ready") {
      element.status = "edit";
    } else {
      setReady(false);
      if (!appState.token) return;
      fetchAPI<APIActions["editSubcategory"]>(
        APIRoutes.editSubcategory,
        {
          method: "PUT",
          routeParams: { subcategory_id: element.id.toString() },
          queryParams: { subcategory_name: element.name },
        },
        appState.token
      ).then(() => {
        setReload(reload + 1);
        setErrorMessage("");
      });
    }
    setSubCategoryStatus([...copyStatus]);
  }

  /**
   * Handler para eliminar subcategorias
   *
   * @param id La id de la subcategoría a borrar
   */
  function onDeleteSubCategory(id: number) {
    if (!appState.token) return Promise.resolve();
    const copySubCategories = [...subCategoryStatus];
    const deletedSubCategoryStatus = copySubCategories.filter(
      (subcat) => subcat.id === id
    )[0];
    const indexStatus = copySubCategories.indexOf(deletedSubCategoryStatus);
    setReady(false);
    return fetchAPI<APIActions["deleteSubcategory"]>(
      APIRoutes.deleteSubcategory,
      {
        method: "DELETE",
        routeParams: { subcategory_id: deletedSubCategoryStatus.id.toString() },
      },
      appState.token
    )
      .then(() => {
        copySubCategories.splice(indexStatus, 1);
        setSubCategoryStatus([...copySubCategories]);
      })
      .then(() => setReload(reload + 1));
  }
  return (
    <>
      {subCategoryStatus
        .filter((sub) => sub.categoryId === category.id)
        .map((sub) => {
          if (sub.status === "ready") {
            return (
              <div tw="flex flex-row" key={sub.id}>
                <div tw="text-header2 text-resies_purple font-normal w-10/12">
                  <Link
                    to={Routes.editIndicators.replace(
                      ":subcategoryId",
                      sub.id.toString()
                    )}
                  >
                    {sub.name}
                  </Link>
                </div>
                <button
                  type="button"
                  tw="w-1/12 flex flex-wrap focus:outline-none"
                  name={sub.id.toString()}
                  onClick={(e) => changeStatus(e)}
                >
                  <img
                    tw="object-contain my-auto mx-auto"
                    src="/editCategory.png"
                    alt="edit"
                  />
                </button>
                <DeleteSubcategoryButton
                  onDeleteSubcategory={onDeleteSubCategory}
                  subCategory={sub}
                />
              </div>
            );
          }
          return (
            <div tw="flex flex-row" key={sub.id}>
              <input
                tw="text-header2 text-resies_purple font-normal w-10/12 bg-resies_lightgreen border-2"
                value={sub.name}
                name={sub.id.toString()}
                onChange={(e) => onChangeEditCategory(e)}
              />
              <button
                type="button"
                tw="w-1/12 flex flex-wrap focus:outline-none"
                name={sub.id.toString()}
                onClick={(e) => changeStatus(e)}
              >
                <img
                  tw="object-contain my-auto mx-auto"
                  src="/add.png"
                  alt="add"
                />
              </button>
              <DeleteSubcategoryButton
                onDeleteSubcategory={onDeleteSubCategory}
                subCategory={sub}
              />
            </div>
          );
        })}
    </>
  );
}

export default SubcategoryOption;
