import React, { Fragment, ReactElement, ReactNode } from "react";
import ReactLoading from "react-loading";
import "twin.macro";

/**
 * Una página que muestra "Cargando" cuando está cargando el fetch a la api
 *
 * @param props react props.
 * @param props.children hijos react
 * @param props.ready listo
 */
function CargandoScreen(props: {
  children: ReactNode;
  ready: boolean;
}): ReactElement {
  const { children, ready } = props;
  const visibleLoading = ready === true ? "hidden" : "flex";
  const visibleChildren = ready === true ? "" : "hidden";
  return (
    <>
      <div tw="bg-ghostwhite h-screen" className={visibleLoading}>
        <div tw="mx-auto my-auto">
          <ReactLoading type="spin" color="#724c69" height={50} width={50} />
        </div>
      </div>
      <div className={visibleChildren}>{children}</div>
    </>
  );
}

export default CargandoScreen;
