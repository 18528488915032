import React, { ReactElement } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import InsideNavigationBar from "../../components/common/InsideNavigationBar";
import AuditDeadlinesIndex from "../../components/admin/AuditDeadlinesIndex";

/**
 * La página que muetra los procesos de auditoría y sus plazos
 */
function ADMAuditDeadlinesPage(): ReactElement {
  return (
    <Container>
      <InsideNavigationBar
        items={[
          { name: "Plazos", href: Routes.auditDeadlines },
          { name: "Portal auditores", href: Routes.auditorPortal },
          { name: "Apelaciones", href: Routes.appeals },
        ]}
        title="Gestión de auditorías"
      />
      <AuditDeadlinesIndex />
      <VolverButton to={Routes.landing} />
    </Container>
  );
}

export default ADMAuditDeadlinesPage;
