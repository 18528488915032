import React, { ReactElement } from "react";
import "twin.macro";
import { InstitutionVariableStatus } from "../../../../../types/utilTypes";
import ADMInstitutionVariableRow from "./ADMInstitutionVariableRow";

/**
 * Componente que muestra la lista de variables
 *
 * @param props react props.
 * @param props.setInstitutionVariables setState que cambia las variables de cada indicador.
 * @param props.institutionVariables Objeto que muestra todas las variables de la categoria
 * @param props.maxVariableId Id mas alto de las variables actuales
 * @param props.setMaxVariableId setState de maxVariableId
 * @param props.deletedVariables variables eliminadas
 * @param props.setDeletedVariables eliminar variables
 * @param props.setChanges set changes
 * @param props.categoryId id de la categoría
 */
function ADMInstitutionVariableList(props: {
  setInstitutionVariables: React.Dispatch<
    React.SetStateAction<InstitutionVariableStatus[]>
  >;
  institutionVariables: InstitutionVariableStatus[];
  maxVariableId: number;
  setMaxVariableId: React.Dispatch<React.SetStateAction<number>>;
  deletedVariables: InstitutionVariableStatus[];
  setDeletedVariables: React.Dispatch<
    React.SetStateAction<InstitutionVariableStatus[]>
  >;
  setChanges: React.Dispatch<React.SetStateAction<boolean>>;
  categoryId: number;
}): ReactElement {
  const {
    setInstitutionVariables,
    institutionVariables,
    maxVariableId,
    setMaxVariableId,
    deletedVariables,
    setDeletedVariables,
    setChanges,
    categoryId,
  } = props;
  /**
   * Funcion que crea una nueva variable al hacer click en el boton.
   */
  function onClickHandler() {
    const copyInstitutionVariables = [...institutionVariables];
    copyInstitutionVariables.push({
      indicator_slug: "",
      category_id: categoryId,
      description: "",
      is_omittable: false,
      requires_verificator: true,
      slugStatus: "edit",
      descriptionStatus: "edit",
      id: maxVariableId + 1,
      generalStatus: "new",
      type: "MULTIPLE_CHOICE",
      default_score: 0,
      json_config: [
        {
          option_text: "Sí",
          option_score: 1,
        },
        {
          option_text: "No",
          option_score: 0,
        },
      ],
    });
    setInstitutionVariables(copyInstitutionVariables);
    setMaxVariableId(maxVariableId + 1);
    setChanges(true);
  }
  return (
    <div tw="flex flex-col bg-ghostwhite overflow-y-scroll mb-10 h-screen">
      <div tw="flex justify-start my-3">
        <button
          type="button"
          tw="flex flex-row my-auto"
          onClick={() => onClickHandler()}
        >
          <img src="/add.png" alt="add" tw="my-auto ml-2 mr-2" />
          <p tw="text-resies_purple font-semibold text-body my-auto">
            Agregar Variable
          </p>
        </button>
      </div>
      {institutionVariables.length ? (
        institutionVariables.map((variable) => (
          <ADMInstitutionVariableRow
            variable={variable}
            key={variable.id}
            institutionVariables={institutionVariables}
            setInstitutionVariables={setInstitutionVariables}
            deletedVariables={deletedVariables}
            setDeletedVariables={setDeletedVariables}
            setChanges={setChanges}
          />
        ))
      ) : (
        <></>
      )}
    </div>
  );
}

export default ADMInstitutionVariableList;
