import React, { ReactElement } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import InsideNavigationBar from "../../components/common/InsideNavigationBar";
import ADMAppealsIndex from "../../components/admin/ADMAppealsIndex";

/**
 * Página desdicada al proceso de apelaciones para el administrador
 */
function ADMAppeals(): ReactElement {
  return (
    <Container>
      <InsideNavigationBar
        items={[
          { name: "Plazos", href: Routes.auditDeadlines },
          { name: "Portal auditores", href: Routes.auditorPortal },
          { name: "Apelaciones", href: Routes.appeals },
        ]}
        title="Gestión de auditorías"
      />
      <ADMAppealsIndex />
      <VolverButton to={Routes.landing} />
    </Container>
  );
}

export default ADMAppeals;
