import {
  AdminInstitutionsOverview,
  AuditableResponses,
  CategoriesOverview,
  Category,
  EvaluationVariable,
  EvaluationVariableDetails,
  Evidence,
  Indicator,
  Institution,
  InstitutionCategory,
  InstitutionVariables,
  InstitutionDetails,
  InstitutionWithAuditor,
  NotificationElement,
  PostResponseVariable,
  PutResponseVariable,
  ResponseComment,
  ResponseVariable,
  Subcategory,
  User,
  UserLogin,
  UserRegister,
  UserTypes,
  CategoryQuartiles,
  GeneralQuartiles,
  InstitutionMember,
  ResiesVersion,
  InstitutionResiesVersion,
  InstitutionalData,
  PutInstitutionalDataResponse,
  PostInstitutionalDataResponse,
  RecoveryUser,
  InstitutionScore,
  CategoryScore,
  InstitutionalResponse,
  AllInstitutionResponses,
  InstitutionalResponsesAUD,
  AllInstitutionInstitutionalResponses,
  InstitutionalDataResponse,
  OdsObjective,
  OdsGoal,
} from "../types/backendTypes";
import { OdsScorePattern } from "../types/utilTypes";

/// Para agregar una ruta, añadir aquí la ruta con un nombre en camelCase
export enum APIRoutes {
  exportFile = "/api/overviews/export_file/{resies_version_id}",
  exportHistoricInstitutionFile = "/api/overviews/export_file/by_institution/{institution_id}/all",
  quartiles = "/api/overviews/quartiles",
  quartilesCat = "/api/overviews/quartiles/category/{category_id}",
  fetchInstitutionScores = "/api/scores/institution/{institution_id}",
  fetchHistoricInstitutionScores = "/api/scores/institution/{institution_id}/historic",
  fetchIndicatorScores = "/api/scores/indicator/{indicator_id}",
  fetchCategoryScores = "/api/scores/category/{category_id}",
  fetchInstitutions = "/api/institutions",
  fetchUsers = "/api/users",
  userProfile = "/api/users/profile/{user_id}",
  softdeleteAccount = "/api/users/soft_delete/{user_id}",
  assignRole = "/api/users/assign_role/{userId}",
  assignAuditor = "/api/users/assign_auditor/{userId}",
  unassignAuditor = "/api/users/unassign_auditor/{institution_id}",
  fetchCategories = "/api/categories",
  fetchOdsObjectives = "/api/ods",
  fetchOdsGoalsByIndicator = "/api/ods/goals_by_indicator/{indicator_id}",
  fetchOdsScoresByInstitution = "/api/ods/score_by_institution/{institution_id}",
  fetchIndicators = "/api/indicators",
  fetchEvaluationVariables = "/api/evaluation_variables",
  fetchResponseVariables = "/api/response_variable",
  fetchInstitutionResponseVariables = "/api/response_variable/institution/{institution_id}",
  fetchInstitutionalDataResponses = "/api/institutional_data_response/institution/{institution_id}",
  postVariables = "/api/response_variable/batch_create",
  putVariables = "/api/response_variable/batch_edit",
  fetchInstitutionDetails = "/api/institutions/{institution_id}",
  assignIndicator = "/api/users/assign_indicator/{user_id}/{indicator_id}",
  fetchLogin = "/api/users/login",
  fetchInvite = "/api/users/invite",
  getInviteToken = "/api/users/register/{invite_token}",
  postInviteToken = "/api/users/register/{invite_token}",
  getMdiIndicators = "/api/indicators/assigned_indicators/{user_id}",
  assignIndicatorBatch = "/api/users/assign_indicator_batch/{user_id}",
  indicatorOverviewByUsersInstitution = "/api/overviews/indicator",
  indicatorOverviewByInstitution = "/api/overviews/indicator/institution/{institution_id}",
  adminIndicatorOverview = "/api/overviews/indicator_admin",
  assignInstitution = "/api/users/assign_institution/{user_id}/{institution_id}",
  unassignInstitution = "/api/users/unassign_auditor/{institution_id}",
  fetchCategoryDetails = "/api/categories/{category_id}",
  fetchIndicatorsFromCategory = "/api/categories/{category_id}/indicators",
  createIndicator = "/api/indicators",
  fetchSubCategoriesFromCategory = "/api/categories/{category_id}/subcategories",
  getIndicator = "/api/indicators/{indicator_id}",
  fetchIndicatorVariables = "/api/indicators/{indicator_id}/variables",
  editIndicator = "/api/indicators/{indicator_id}",
  deleteIndicator = "/api/indicators/{indicator_id}",
  createEvaluationVariable = "/api/evaluation_variables",
  editEvaluationVariable = "/api/evaluation_variables/{variable_id}",
  deleteEvaluationVariable = "/api/evaluation_variables/{variable_id}",
  editCategories = "/api/categories/{category_id}",
  editOds = "/api/ods/{ods_id}",
  assignOdsGoal = "/api/ods/{ods_id}/goals/{ods_goal_id}/assign_indicator/{indicator_id}",
  removeOdsGoal = "/api/ods/{ods_id}/goals/{ods_goal_id}/remove_indicator/{indicator_id}",
  variableDetails = "/api/evaluation_variables/{variableId}",
  auditableResponses = "/api/response_variable/auditable_responses",
  auditableResponsesWithId = "/api/response_variable/auditable_responses/{user_id}",
  downloadFile = "/api/evidences/download/{response_verificator_id}",
  downloadInstitutionFile = "/api/institutional_data_evidences/download/{response_verificator_id}",
  createInstitution = "/api/institutions",
  auditVariable = "/api/response_variable/audit/{response_variable_id}",
  auditMissingVariable = "/api/response_variable/institution/{institution_id}/audit_missing/{evaluation_variable_id}",
  fetchResponseVariablesByInstitution = "/api/response_variable/institution/{institution_id}",
  fetchIndicatorDetails = "/api/indicators/{indicator_id}",
  getInstitutionsWithAuditor = "/api/institutions/with_auditors",
  addLink = "/api/evidences/link",
  fetchResponseComments = "/api/response_comment/response_variable/{response_variable_id}",
  fetchInstitutionalResponseComments = "/api/institutional_data_comment/response_variable/{response_variable_id}",
  createResponseComment = "/api/response_comment",
  createInstitutionalResponseComment = "/api/institutional_data_comment",
  editResponseComment = "/api/response_comment/{response_comment_id}",
  editInstitutionalResponseComment = "/api/institutional_data_comment/{response_comment_id}",
  deleteResponseComment = "/api/response_comment/{response_comment_id}",
  deleteInstitutionalResponseComment = "/api/institutional_data_comment/{response_comment_id}",
  deleteEvidence = "/api/evidences/{response_verificator_id}",
  fetchSubCategories = "/api/subcategories",
  createCategory = "/api/categories",
  deleteCategory = "/api/categories/{category_id}",
  fetchSubcategoryDetails = "/api/subcategories",
  fetchNotificacions = "/api/notifications",
  editProfile = "/api/users/edit_profile",
  deleteInstitution = "/api/institutions/{institution_id}",
  fetchInstitutionCategories = "/api/institutional_data_category",
  createInstitutionCategory = "/api/institutional_data_category",
  deleteInstitutionCategory = "/api/institutional_data_category/{institutional_data_category_id}",
  editInstitutionCategory = "/api/institutional_data_category/{institutional_data_category_id}",
  fetchInstitutionVariables = "/api/institutional_data_variable",
  deleteInstitutionVariable = "/api/institutional_data_variable/{variable_id}",
  createInstitutionVariable = "/api/institutional_data_variable",
  editInstitutionVariable = "/api/institutional_data_variable/{variable_id}",
  fetchInstitutionalData = "/api/institutional_data_response/institution_profile",
  createInstitutionalDataResponse = "/api/institutional_data_response/batch_create",
  editInstitutionalDataResponse = "/api/institutional_data_response/batch_edit",
  createSubCategory = "/api/subcategories",
  deleteSubcategory = "/api/subcategories/{subcategory_id}",
  editCategoryColor = "/api/categories/{category_id}/color",
  membersOverview = "/api/overviews/members_my_institution",
  membersOverviewAdmin = "/api/overviews/members_by_institution/{institution_id}",
  getResiesVersions = "/api/resies_version",
  newResiesVersion = "/api/resies_version/", // error de tipeo en backend, es importante el / final o falla la request
  getActiveVersion = "/api/resies_version/active",
  getActiveVersionPerInstitution = "/api/resies_version/active-for-institution/{institution_id}",
  editActiveVersionProcess = "/api/resies_version/active/edit/process",
  editActiveVersionPerInstitutionProcess = "/api/resies_version/active-for-institution/{institution_id}/edit/process",
  editActiveVersion = "/api/resies_version/active/edit",
  editActiveInstitutionVersion = "/api/resies_version/active-for-institution/{institution_id}/edit",
  getMdiInstitutionVariables = "/api/institutional_data_variable/assigned_variables/{mdi_user_id}",
  assignInstitutionVariableBatch = "/api/users/assign_institutional_data_variable_batch/{user_id}",
  unassignMDI = "/api/users/unassign_mdi/{user_id}",
  generalBroadcast = "/api/institutions/general_broadcast",
  institutionBroadcast = "/api/institutions/institution_broadcast",
  responseVariablesByInstitution = "/api/response_variable/institution/{institution_id}",
  sendRecoveryMail = "/api/users/send_recovery_email",
  getRecoveryMetaData = "/api/users/recover/{recovery_token}",
  recoverPassword = "/api/users/recover/{recovery_token}",
  downloadResponseEvidenceZip = "/api/response_variable/zip_evidences/{response_variable_id}",
  changePassword = "/api/users/change_password",
  getInstitutionalVariable = "/api/institutional_data_variable/{variable_id}",
  auditInstitutionalVariable = "/api/institutional_data_response/audit/{institutional_data_response_id}",
  auditInstitutionalMissingVariable = "/api/institutional_data_response/{institution_id}/audit_missing/{institution_variable_id}",
  deleteInstitutionalEvidence = "/api/institutional_data_evidences/{response_verificator_id}",
  downloadInstitutionalEvidenceZip = "/api/institutional_data_response/zip_evidences/{response_variable_id}",
  fetchInstitutionalResponses = "/api/institutional_data_response",
  addInstitutionalLink = "/api/institutional_data_evidences/link",
  editInstitution = "/api/institutions/{institution_id}",
  editSubcategory = "/api/subcategories/{subcategory_id}",
  appealRDI = "/api/response_variable/appeal/{response_variable_id}",
  appealInstitutionalRDI = "/api/institutional_data_response/appeal/{response_variable_id}",
  adminAllResponses = "/api/response_variable/all_responses",
  adminAllInstitutionalResponses = "/api/institutional_data_response/all_institutional_data_respones", // otro typo back xd
  audInstitutionalDataResponses = "/api/institutional_data_response/auditable_institutional_data",
}

// Luego definir los parámetros de la ruta acá. Seguir el esquema de APIActionClass declarado abajo.
export interface APIActions
  extends Record<keyof typeof APIRoutes, APIActionClass> {
  quartilesCat: {
    APIRoute: APIRoutes.quartilesCat;
    ReturnType: CategoryQuartiles;
    options: {
      method: "POST";
      routeParams: { category_id: string };
      queryParams?: {
        strict_mode?: "true" | "false";
      };
    };
    apiKey: true;
  };

  quartiles: {
    APIRoute: APIRoutes.quartiles;
    ReturnType: GeneralQuartiles;
    options: {
      method: "POST";
      queryParams?: {
        strict_mode?: "true" | "false";
      };
    };
    apiKey: true;
  };
  exportFile: {
    ReturnType: { file_link: string };
    APIRoute: APIRoutes.exportFile;
    options: {
      method: "GET";
      routeParams: {
        resies_version_id: string;
      };
    };
    apiKey: true;
  };
  exportHistoricInstitutionFile: {
    ReturnType: { file_link: string };
    APIRoute: APIRoutes.exportHistoricInstitutionFile;
    options: {
      method: "GET";
      routeParams: {
        institution_id: string;
      };
    };
    apiKey: true;
  };
  adminAllResponses: {
    ReturnType: AllInstitutionResponses;
    APIRoute: APIRoutes.adminAllResponses;
    options: {
      method: "GET";
      queryParams?: {
        filter_status?: ResponseVariable["audit_status"];
      };
    };
    apiKey: true;
  };
  adminAllInstitutionalResponses: {
    ReturnType: AllInstitutionInstitutionalResponses;
    APIRoute: APIRoutes.adminAllInstitutionalResponses;
    options: {
      method: "GET";
      queryParams?: {
        filter_status?: InstitutionalDataResponse["audit_status"];
      };
    };
    apiKey: true;
  };
  fetchInstitutionResponseVariables: {
    ReturnType: ResponseVariable[];
    APIRoute: APIRoutes.fetchInstitutionResponseVariables;
    options: {
      method: "GET";
      routeParams: {
        institution_id: string;
      };
    };
    apiKey: true;
  };
  fetchInstitutionalDataResponses: {
    ReturnType: InstitutionalDataResponse[];
    APIRoute: APIRoutes.fetchInstitutionalDataResponses;
    options: {
      method: "GET";
      routeParams: {
        institution_id: string;
      };
    };
    apiKey: true;
  };
  editProfile: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editProfile;
    options: {
      method: "PUT";
      body: {
        password: string;
        first_name?: string;
        last_name?: string;
        description?: string;
        email?: string;
        phone_number?: string;
      };
    };
    apiKey: true;
  };
  getInstitutionsWithAuditor: {
    ReturnType: InstitutionWithAuditor[];
    APIRoute: APIRoutes.getInstitutionsWithAuditor;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  fetchInstitutions: {
    ReturnType: Institution[];
    APIRoute: APIRoutes.fetchInstitutions;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  fetchUsers: {
    ReturnType: User[];
    APIRoute: APIRoutes.fetchUsers;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  userProfile: {
    ReturnType: User;
    APIRoute: APIRoutes.userProfile;
    options: {
      method: "GET";
      routeParams: {
        user_id: string;
      };
    };
    apiKey: true;
  };
  softdeleteAccount: {
    ReturnType: unknown;
    APIRoute: APIRoutes.softdeleteAccount;
    options: {
      method: "DELETE";
      routeParams: {
        user_id: string;
      };
    };
    apiKey: true;
  };
  assignRole: {
    ReturnType: unknown;
    APIRoute: APIRoutes.assignRole;
    options: {
      method: "POST";
      routeParams: {
        userId: string;
      };
      body: {
        role: "AUD" | "ADM" | "RDI" | "MDI" | "UNA";
      };
    };
    apiKey: true;
  };
  assignAuditor: {
    ReturnType: unknown;
    APIRoute: APIRoutes.assignAuditor;
    options: {
      method: "POST";
      routeParams: {
        userId: string;
      };
    };
    apiKey: true;
  };
  unassignAuditor: {
    ReturnType: unknown;
    APIRoute: APIRoutes.unassignAuditor;
    options: {
      method: "POST";
      routeParams: {
        institution_id: string;
      };
    };
    apiKey: true;
  };
  fetchCategories: {
    ReturnType: Category[];
    options: {
      method: "GET";
    };
    APIRoute: APIRoutes.fetchCategories;
    apiKey: true;
  };
  fetchOdsObjectives: {
    ReturnType: OdsObjective[];
    options: {
      method: "GET";
    };
    APIRoute: APIRoutes.fetchOdsObjectives;
    apiKey: true;
  };
  fetchOdsGoalsByIndicator: {
    ReturnType: OdsGoal[];
    options: {
      method: "GET";
      routeParams: {
        indicator_id: string;
      };
    };
    APIRoute: APIRoutes.fetchOdsGoalsByIndicator;
    apiKey: true;
  };
  fetchOdsScoresByInstitution: {
    ReturnType: OdsScorePattern;
    options: {
      method: "GET";
      routeParams: {
        institution_id: string;
      };
    };
    APIRoute: APIRoutes.fetchOdsScoresByInstitution;
    apiKey: true;
  };
  fetchIndicators: {
    ReturnType: Indicator[];
    options: {
      method: "GET";
      queryParams?: {
        category_id?: string;
      };
    };
    APIRoute: APIRoutes.fetchIndicators;
    apiKey: true;
  };
  fetchEvaluationVariables: {
    ReturnType: EvaluationVariable[];
    options: {
      method: "GET";
    };
    APIRoute: APIRoutes.fetchEvaluationVariables;
    apiKey: true;
  };
  fetchResponseVariables: {
    ReturnType: ResponseVariable[];
    options: {
      method: "GET";
    };
    APIRoute: APIRoutes.fetchResponseVariables;
    apiKey: true;
  };
  postVariables: {
    ReturnType: unknown;
    APIRoute: APIRoutes.postVariables;
    options: {
      method: "POST";
      body: PostResponseVariable[];
    };
    apiKey: true;
  };
  putVariables: {
    ReturnType: unknown;
    APIRoute: APIRoutes.putVariables;
    options: {
      method: "PUT";
      body: PutResponseVariable[];
    };
    apiKey: true;
  };
  fetchInstitutionDetails: {
    ReturnType: InstitutionDetails;
    APIRoute: APIRoutes.fetchInstitutionDetails;
    options: {
      method: "GET";
      routeParams: { institution_id: string };
    };
    apiKey: true;
  };
  assignIndicator: {
    ReturnType: unknown;
    APIRoute: APIRoutes.assignIndicator;
    options: {
      method: "POST";
      routeParams: { user_id: string; indicator_id: string };
    };
    apiKey: true;
  };
  fetchLogin: {
    ReturnType: UserLogin;
    APIRoute: APIRoutes.fetchLogin;
    options: {
      method: "POST";
      body: {
        email: string;
        password: string;
      };
    };
    apiKey: false;
  };
  fetchInvite: {
    ReturnType: unknown;
    APIRoute: APIRoutes.fetchInvite;
    options: {
      method: "POST";
      body: {
        first_name: string;
        last_name: string;
        email: string;
        user_type: keyof UserTypes;
        phone_number: string;
        institution_id: number;
      };
    };
    apiKey: true;
  };
  getInviteToken: {
    ReturnType: UserRegister;
    APIRoute: APIRoutes.getInviteToken;
    options: {
      method: "GET";
      routeParams: {
        invite_token: string;
      };
    };
    apiKey: false;
  };
  postInviteToken: {
    ReturnType: UserLogin;
    APIRoute: APIRoutes.postInviteToken;
    options: {
      method: "POST";
      routeParams: {
        invite_token: string;
      };
      body: {
        password: string;
      };
    };
    apiKey: false;
  };
  getMdiIndicators: {
    ReturnType: Indicator[];
    APIRoute: APIRoutes.getMdiIndicators;
    options: {
      method: "GET";
      routeParams: {
        user_id: string;
      };
    };
    apiKey: true;
  };
  assignIndicatorBatch: {
    ReturnType: User;
    APIRoute: APIRoutes.assignIndicatorBatch;
    options: {
      method: "POST";
      routeParams: {
        user_id: string;
      };
      body: {
        indicators: number[];
      };
    };
    apiKey: true;
  };
  indicatorOverviewByInstitution: {
    ReturnType: CategoriesOverview;
    APIRoute: APIRoutes.indicatorOverviewByInstitution;
    options: {
      method: "GET";
      routeParams: {
        institution_id: string;
      };
    };
    apiKey: true;
  };
  indicatorOverviewByUsersInstitution: {
    ReturnType: CategoriesOverview;
    APIRoute: APIRoutes.indicatorOverviewByUsersInstitution;
    options: {
      method: "GET";
    };
    apiKey: true;
  };

  variableDetails: {
    ReturnType: EvaluationVariableDetails;
    APIRoute: APIRoutes.variableDetails;
    options: {
      method: "GET";
      routeParams: {
        variableId: string;
      };
    };
    apiKey: true;
  };
  auditableResponses: {
    ReturnType: AuditableResponses[];
    APIRoute: APIRoutes.auditableResponses;
    options: {
      method: "GET";
      queryParams?: {
        filter_status: ResponseVariable["audit_status"];
      };
    };
    apiKey: true;
  };
  auditableResponsesWithId: {
    ReturnType: AuditableResponses[];
    APIRoute: APIRoutes.auditableResponsesWithId;
    options: {
      method: "GET";
      routeParams: {
        user_id: string;
      };
      queryParams?: {
        filter_status: ResponseVariable["audit_status"];
      };
    };
    apiKey: true;
  };
  downloadFile: {
    ReturnType: string;
    APIRoute: APIRoutes.downloadFile;
    options: {
      method: "GET";
      routeParams: {
        response_verificator_id: string;
      };
    };
    apiKey: true;
  };
  downloadInstitutionFile: {
    ReturnType: string;
    APIRoute: APIRoutes.downloadInstitutionFile;
    options: {
      method: "GET";
      routeParams: {
        response_verificator_id: string;
      };
    };
    apiKey: true;
  };

  assignInstitution: {
    ReturnType: User;
    APIRoute: APIRoutes.assignInstitution;
    options: {
      method: "POST";
      routeParams: {
        user_id: string;
        institution_id: string;
      };
    };
    apiKey: true;
  };
  unassignInstitution: {
    ReturnType: unknown;
    APIRoute: APIRoutes.unassignInstitution;
    options: {
      method: "POST";
      routeParams: {
        institution_id: string;
      };
    };
    apiKey: true;
  };
  fetchCategoryDetails: {
    ReturnType: Category;
    APIRoute: APIRoutes.fetchCategoryDetails;
    options: {
      method: "GET";
      routeParams: {
        category_id: string;
      };
    };
    apiKey: true;
  };
  fetchIndicatorsFromCategory: {
    ReturnType: Indicator[];
    APIRoute: APIRoutes.fetchIndicatorsFromCategory;
    options: {
      method: "GET";
      routeParams: {
        category_id: string;
      };
    };
    apiKey: true;
  };
  fetchSubCategoriesFromCategory: {
    ReturnType: Subcategory[];
    APIRoute: APIRoutes.fetchSubCategoriesFromCategory;
    options: {
      method: "GET";
      routeParams: {
        category_id: string;
      };
    };
    apiKey: true;
  };
  createIndicator: {
    ReturnType: Indicator;
    APIRoute: APIRoutes.createIndicator;
    options: {
      method: "POST";
      body: {
        slug: string;
        subcategory_id: number;
        title: string;
        score_weight: number;
        formula: string;
        unreplaced_formula: string;
        auxiliary_formulas: Array<{ name: string; code: string }>;
      };
    };
    apiKey: true;
  };
  getIndicator: {
    ReturnType: Indicator;
    APIRoute: APIRoutes.getIndicator;
    options: {
      method: "GET";
      routeParams: {
        indicator_id: string;
      };
    };
    apiKey: true;
  };
  fetchCategoryScores: {
    ReturnType: CategoryScore;
    APIRoute: APIRoutes.fetchCategoryScores;
    options: {
      method: "GET";
      routeParams: {
        category_id: string;
      };
      queryParams?: {
        strict_mode?: "true" | "false";
        institution_id?: string;
      };
    };
    apiKey: true;
  };

  fetchIndicatorScores: {
    ReturnType: number;
    APIRoute: APIRoutes.fetchIndicatorScores;
    options: {
      method: "GET";
      routeParams: {
        indicator_id: string;
      };
      queryParams?: {
        strict_mode?: "true" | "false";
      };
    };
    apiKey: true;
  };
  fetchInstitutionScores: {
    ReturnType: InstitutionScore;
    APIRoute: APIRoutes.fetchInstitutionScores;
    options: {
      method: "GET";
      routeParams: {
        institution_id: string;
      };
      queryParams?: {
        strict_mode?: "true" | "false";
      };
    };
    apiKey: true;
  };
  fetchHistoricInstitutionScores: {
    ReturnType: InstitutionScore[];
    APIRoute: APIRoutes.fetchHistoricInstitutionScores;
    options: {
      method: "GET";
      routeParams: {
        institution_id: string;
      };
      queryParams?: {
        strict_mode?: "true" | "false";
      };
    };
    apiKey: true;
  };
  fetchIndicatorVariables: {
    ReturnType: EvaluationVariable[];
    APIRoute: APIRoutes.fetchIndicatorVariables;
    options: {
      method: "GET";
      routeParams: {
        indicator_id: string;
      };
    };
    apiKey: true;
  };
  editIndicator: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editIndicator;
    options: {
      method: "PUT";
      routeParams: {
        indicator_id: string;
      };
      body: {
        slug: string;
        subcategory_id: number;
        title: string;
        score_weight: number;
        color: string;
        formula: string;
        unreplaced_formula: string;
        auxiliary_formulas: Array<{ name: string; code: string }>;
      };
    };
    apiKey: true;
  };
  deleteIndicator: {
    ReturnType: unknown;
    APIRoute: APIRoutes.deleteIndicator;
    options: {
      method: "DELETE";
      routeParams: {
        indicator_id: string;
      };
    };
    apiKey: true;
  };
  createEvaluationVariable: {
    ReturnType: unknown;
    APIRoute: APIRoutes.createEvaluationVariable;
    options: {
      method: "POST";
      body: {
        indicator_id: number;
        display_order: number;
        type: string;
        description: string;
        is_omittable: boolean;
        default_score: number;
        requires_verificator: boolean;
        description_field_helper_text: string;
        verification_field_helper_text: string;
        json_config: Array<{ option_text: string; option_score: number }>;
      };
    };
    apiKey: true;
  };
  editEvaluationVariable: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editEvaluationVariable;
    options: {
      method: "PUT";
      routeParams: {
        variable_id: string;
      };
      body: {
        indicator_id: number;
        display_order: number;
        type: string;
        description: string;
        is_omittable: boolean;
        default_score: number;
        requires_verificator: boolean;
        description_field_helper_text: string;
        verification_field_helper_text: string;
        json_config: Array<{ option_text: string; option_score: number }>;
      };
    };
    apiKey: true;
  };
  deleteEvaluationVariable: {
    ReturnType: unknown;
    APIRoute: APIRoutes.deleteEvaluationVariable;
    options: {
      method: "DELETE";
      routeParams: {
        variable_id: string;
      };
    };
    apiKey: true;
  };
  editCategories: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editCategories;
    options: {
      method: "PUT";
      routeParams: {
        category_id: string;
      };
      body: {
        name: string;
        description: string;
        score_weight: number;
        color: string;
      };
    };
    apiKey: true;
  };
  editOds: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editOds;
    options: {
      method: "PUT";
      routeParams: {
        ods_id: string;
      };
      body: {
        name: string;
        color: string;
      };
    };
    apiKey: true;
  };
  assignOdsGoal: {
    ReturnType: unknown;
    APIRoute: APIRoutes.assignOdsGoal;
    options: {
      method: "POST";
      routeParams: {
        ods_id: string;
        ods_goal_id: string;
        indicator_id: string;
      };
    };
    apiKey: true;
  };
  removeOdsGoal: {
    ReturnType: unknown;
    APIRoute: APIRoutes.removeOdsGoal;
    options: {
      method: "PUT";
      routeParams: {
        ods_id: string;
        ods_goal_id: string;
        indicator_id: string;
      };
    };
    apiKey: true;
  };
  createInstitution: {
    ReturnType: Institution;
    APIRoute: APIRoutes.createInstitution;
    options: {
      method: "POST";
      body: {
        name: string;
        description: string;
        member_since: string;
      };
    };
    apiKey: true;
  };
  adminIndicatorOverview: {
    ReturnType: AdminInstitutionsOverview;
    APIRoute: APIRoutes.adminIndicatorOverview;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  auditVariable: {
    ReturnType: unknown;
    APIRoute: APIRoutes.auditVariable;
    options: {
      method: "PUT";
      routeParams: {
        response_variable_id: string;
      };
      queryParams: { auditor_veredict: string };
    };
    apiKey: true;
  };
  auditMissingVariable: {
    ReturnType: Record<string, any>;
    APIRoute: APIRoutes.auditMissingVariable;
    options: {
      method: "PUT";
      routeParams: {
        institution_id: string;
        evaluation_variable_id: string;
      };
    };
    apiKey: true;
  };
  fetchResponseVariablesByInstitution: {
    ReturnType: ResponseVariable[];
    APIRoute: APIRoutes.fetchResponseVariablesByInstitution;
    options: {
      method: "GET";
      routeParams: {
        institution_id: string;
      };
    };
    apiKey: true;
  };
  fetchIndicatorDetails: {
    ReturnType: Indicator;
    APIRoute: APIRoutes.fetchIndicatorDetails;
    options: {
      method: "GET";
      routeParams: { indicator_id: string };
    };
    apiKey: true;
  };
  fetchSubCategories: {
    ReturnType: Subcategory[];
    APIRoute: APIRoutes.fetchSubCategories;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  createCategory: {
    ReturnType: Category;
    APIRoute: APIRoutes.createCategory;
    options: {
      method: "POST";
      body: {
        name: string;
        description: string;
        score_weight: number;
        color: string;
      };
    };
    apiKey: true;
  };
  deleteCategory: {
    ReturnType: unknown;
    APIRoute: APIRoutes.deleteCategory;
    options: {
      method: "DELETE";
      routeParams: {
        category_id: string;
      };
    };
    apiKey: true;
  };
  fetchSubcategoryDetails: {
    ReturnType: Subcategory[];
    APIRoute: APIRoutes.fetchSubCategories;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  fetchNotifications: {
    ReturnType: NotificationElement[];
    APIRoute: APIRoutes.fetchNotificacions;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  deleteInstitution: {
    ReturnType: unknown;
    APIRoute: APIRoutes.deleteInstitution;
    options: {
      method: "DELETE";
      routeParams: {
        institution_id: string;
      };
    };
    apiKey: true;
  };
  fetchInstitutionCategories: {
    ReturnType: InstitutionCategory[];
    APIRoute: APIRoutes.fetchInstitutionCategories;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  createInstitutionCategory: {
    ReturnType: unknown;
    APIRoute: APIRoutes.createInstitutionCategory;
    options: {
      method: "POST";
      body: { name: string };
    };
    apiKey: true;
  };
  editInstitutionCategory: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editInstitutionCategory;
    options: {
      method: "PUT";
      body: { name: string };
      routeParams: { institutional_data_category_id: string };
    };
    apiKey: true;
  };
  deleteInstitutionCategory: {
    ReturnType: unknown;
    APIRoute: APIRoutes.deleteInstitutionCategory;
    options: {
      method: "DELETE";
      routeParams: { institutional_data_category_id: string };
    };
    apiKey: true;
  };
  fetchInstitutionVariables: {
    ReturnType: InstitutionVariables[];
    APIRoute: APIRoutes.fetchInstitutionVariables;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  deleteInstitutionVariable: {
    ReturnType: unknown;
    APIRoute: APIRoutes.deleteInstitutionVariable;
    options: {
      method: "DELETE";
      routeParams: { variable_id: string };
    };
    apiKey: true;
  };
  createInstitutionVariable: {
    ReturnType: unknown;
    APIRoute: APIRoutes.createInstitutionVariable;
    options: {
      method: "POST";
      body: {
        indicator_slug: string;
        category_id: number;
        description: string;
        is_omittable: boolean;
        requires_verificator: boolean;
        type: string;
        default_score: number;
        json_config: Array<{ option_text: string; option_score: number }>;
      };
    };
    apiKey: true;
  };
  editInstitutionVariable: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editInstitutionVariable;
    options: {
      method: "PUT";
      body: {
        indicator_slug: string;
        category_id: number;
        description: string;
        is_omittable: boolean;
        requires_verificator: boolean;
        type: string;
        default_score: number;
        json_config: Array<{ option_text: string; option_score: number }>;
      };
      routeParams: {
        variable_id: string;
      };
    };
    apiKey: true;
  };
  fetchInstitutionalData: {
    ReturnType: InstitutionalData;
    APIRoute: APIRoutes.fetchInstitutionalData;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  createInstitutionalDataResponse: {
    ReturnType: unknown;
    APIRoute: APIRoutes.createInstitutionalDataResponse;
    options: {
      method: "POST";
      body: PostInstitutionalDataResponse[];
    };
    apiKey: true;
  };
  editInstitutionalDataResponse: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editInstitutionalDataResponse;
    options: {
      method: "PUT";
      body: PutInstitutionalDataResponse[];
    };
    apiKey: true;
  };
  addLink: {
    ReturnType: Evidence;
    APIRoute: APIRoutes.addLink;
    options: {
      method: "POST";
      body: {
        response_variable_id: number;
        link_or_text: string;
      };
    };
    apiKey: true;
  };
  fetchResponseComments: {
    ReturnType: ResponseComment[];
    APIRoute: APIRoutes.fetchResponseComments;
    options: {
      method: "GET";
      routeParams: {
        response_variable_id: string;
      };
    };
    apiKey: true;
  };
  fetchInstitutionalResponseComments: {
    ReturnType: ResponseComment[];
    APIRoute: APIRoutes.fetchInstitutionalResponseComments;
    options: {
      method: "GET";
      routeParams: {
        response_variable_id: string;
      };
    };
    apiKey: true;
  };
  createResponseComment: {
    ReturnType: unknown;
    APIRoute: APIRoutes.createResponseComment;
    options: {
      method: "POST";
      body: {
        response_variable_id: number;
        content: string;
        type: string;
      };
    };
    apiKey: true;
  };
  createInstitutionalResponseComment: {
    ReturnType: unknown;
    APIRoute: APIRoutes.createInstitutionalResponseComment;
    options: {
      method: "POST";
      body: {
        response_variable_id: number;
        content: string;
        type: string;
      };
    };
    apiKey: true;
  };
  editResponseComment: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editResponseComment;
    options: {
      method: "PUT";
      routeParams: {
        response_comment_id: string;
      };
      body: {
        content: string;
      };
    };
    apiKey: true;
  };
  editInstitutionalResponseComment: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editInstitutionalResponseComment;
    options: {
      method: "PUT";
      routeParams: {
        response_comment_id: string;
      };
      body: {
        content: string;
      };
    };
    apiKey: true;
  };
  deleteResponseComment: {
    ReturnType: unknown;
    APIRoute: APIRoutes.deleteResponseComment;
    options: {
      method: "DELETE";
      routeParams: {
        response_comment_id: string;
      };
    };
    apiKey: true;
  };
  deleteInstitutionalResponseComment: {
    ReturnType: unknown;
    APIRoute: APIRoutes.deleteInstitutionalResponseComment;
    options: {
      method: "DELETE";
      routeParams: {
        response_comment_id: string;
      };
    };
    apiKey: true;
  };
  deleteEvidence: {
    ReturnType: unknown;
    APIRoute: APIRoutes.deleteEvidence;
    options: {
      method: "DELETE";
      routeParams: { response_verificator_id: string };
    };
    apiKey: true;
  };
  createSubcategory: {
    ReturnType: unknown;
    APIRoute: APIRoutes.createSubCategory;
    options: {
      method: "POST";
      queryParams: {
        category_id: string;
        subcategory_name: string;
      };
    };
    apiKey: true;
  };
  deleteSubcategory: {
    ReturnType: unknown;
    APIRoute: APIRoutes.deleteSubcategory;
    options: {
      method: "DELETE";
      routeParams: {
        subcategory_id: string;
      };
    };
    apiKey: true;
  };
  editCategoryColor: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editCategoryColor;
    options: {
      method: "PUT";
      routeParams: {
        category_id: string;
      };
      queryParams: {
        new_color: string;
      };
    };
    apiKey: true;
  };
  membersOverview: {
    ReturnType: { institution_members: InstitutionMember[] };
    APIRoute: APIRoutes.membersOverview;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  getResiesVersions: {
    ReturnType: ResiesVersion[];
    APIRoute: APIRoutes.getResiesVersions;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  newResiesVersion: {
    ReturnType: unknown;
    APIRoute: APIRoutes.newResiesVersion;
    options: {
      method: "POST";
    };
    apiKey: true;
  };
  membersOverviewAdmin: {
    ReturnType: { institution_members: InstitutionMember[] };
    APIRoute: APIRoutes.membersOverviewAdmin;
    options: {
      method: "GET";
      routeParams: { institution_id: string };
    };
    apiKey: true;
  };
  getActiveResiesVersion: {
    ReturnType: ResiesVersion;
    APIRoute: APIRoutes.getActiveVersion;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  getActiveResiesVersionPerInstitution: {
    ReturnType: InstitutionResiesVersion;
    APIRoute: APIRoutes.getActiveVersionPerInstitution;
    options: {
      method: "GET";
      routeParams: { institution_id: string };
    };
    apiKey: true;
  };
  editActiveVersionProcess: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editActiveVersionProcess;
    options: {
      method: "PUT";
      body: Array<{ process_string: string; start: string; end: string }>;
    };
    apiKey: true;
  };
  editActiveVersionPerInstitutionProcess: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editActiveVersionPerInstitutionProcess;
    options: {
      method: "PUT";
      routeParams: { institution_id: string };
      body: Array<{ process_string: string; start: string; end: string }>;
    };
    apiKey: true;
  };
  editActiveVersion: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editActiveVersion;
    options: {
      method: "PUT";
      body: {
        version_name: string;
        start: string;
        end: string;
      };
    };
    apiKey: true;
  };
  editActiveInstitutionVersion: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editActiveInstitutionVersion;
    options: {
      method: "PUT";
      routeParams: { institution_id: string };
      body: {
        version_name: string;
        start: string;
        end: string;
      };
    };
    apiKey: true;
  };
  getMdiInstitutionVariables: {
    ReturnType: InstitutionVariables[];
    APIRoute: APIRoutes.getMdiInstitutionVariables;
    options: {
      method: "GET";
      routeParams: {
        mdi_user_id: string;
      };
    };
    apiKey: true;
  };
  assignInstitutionVariableBatch: {
    ReturnType: unknown;
    APIRoute: APIRoutes.assignInstitutionVariableBatch;
    options: {
      method: "POST";
      body: {
        variables: number[];
      };
      routeParams: {
        user_id: string;
      };
    };
    apiKey: true;
  };
  unassignMDI: {
    ReturnType: User;
    APIRoute: APIRoutes.unassignMDI;
    options: {
      method: "POST";
      routeParams: {
        user_id: string;
      };
    };
    apiKey: true;
  };
  generalBroadcast: {
    ReturnType: unknown;
    APIRoute: APIRoutes.generalBroadcast;
    options: {
      method: "POST";
      body: {
        message: string;
        user_type: string[];
      };
    };
    apiKey: true;
  };
  responseVariablesByInstitution: {
    ReturnType: ResponseVariable[];
    APIRoute: APIRoutes.responseVariablesByInstitution;
    options: {
      method: "GET";
      routeParams: { institution_id: string };
    };
    apiKey: true;
  };
  fetchInstitutionalDataForAdmin: {
    ReturnType: InstitutionalData;
    APIRoute: APIRoutes.fetchInstitutionalData;
    options: {
      method: "GET";
      queryParams: { institution_id: string };
    };
    apiKey: true;
  };
  sendRecoveryMail: {
    ReturnType: { detail: string };
    APIRoute: APIRoutes.sendRecoveryMail;
    options: {
      method: "POST";
      body: {
        email: string;
      };
    };
    apiKey: false;
  };
  getRecoveryMetaData: {
    ReturnType: RecoveryUser;
    APIRoute: APIRoutes.getRecoveryMetaData;
    options: {
      method: "GET";
      routeParams: {
        recovery_token: string;
      };
    };
    apiKey: false;
  };
  recoverPassword: {
    ReturnType: UserLogin;
    APIRoute: APIRoutes.recoverPassword;
    options: {
      method: "POST";
      routeParams: {
        recovery_token: string;
      };
      body: {
        password: string;
      };
    };
    apiKey: false;
  };
  downloadResponseEvidenceZip: {
    ReturnType: { file_link: string };
    APIRoute: APIRoutes.downloadResponseEvidenceZip;
    options: {
      method: "GET";
      routeParams: {
        response_variable_id: string;
      };
    };
    apiKey: true;
  };
  changePassword: {
    ReturnType: unknown;
    APIRoute: APIRoutes.changePassword;
    options: {
      method: "PUT";
      body: {
        current_password: string;
        new_password: string;
      };
    };
    apiKey: true;
  };
  getInstitutionalVariable: {
    ReturnType: InstitutionVariables;
    APIRoute: APIRoutes.getInstitutionalVariable;
    options: {
      method: "GET";
      routeParams: {
        variable_id: string;
      };
    };
    apiKey: true;
  };
  auditInstitutionalVariable: {
    ReturnType: unknown;
    APIRoute: APIRoutes.auditInstitutionalVariable;
    options: {
      method: "PUT";
      routeParams: {
        institutional_data_response_id: string;
      };
      queryParams: {
        auditor_veredict: string;
      };
    };
    apiKey: true;
  };
  auditInstitutionalMissingVariable: {
    ReturnType: Record<string, any>;
    APIRoute: APIRoutes.auditInstitutionalMissingVariable;
    options: {
      method: "PUT";
      routeParams: {
        institution_id: string;
        institution_variable_id: string;
      };
    };
    apiKey: true;
  };
  institutionBroadcast: {
    ReturnType: unknown;
    APIRoute: APIRoutes.institutionBroadcast;
    options: {
      method: "POST";
      body: {
        message: string;
        institution_id?: number;
      };
    };
    apiKey: true;
  };
  appealRDI: {
    ReturnType: unknown;
    APIRoute: APIRoutes.appealRDI;
    options: {
      method: "PUT";
      routeParams: {
        response_variable_id: string;
      };
    };
    apiKey: true;
  };
  appealInstitutionalRDI: {
    ReturnType: unknown;
    APIRoute: APIRoutes.appealInstitutionalRDI;
    options: {
      method: "PUT";
      routeParams: {
        response_variable_id: string;
      };
    };
    apiKey: true;
  };
  deleteInstitutionalEvidence: {
    ReturnType: unknown;
    APIRoute: APIRoutes.deleteInstitutionalEvidence;
    options: {
      method: "DELETE";
      routeParams: { response_verificator_id: string };
    };
    apiKey: true;
  };
  downloadInstitutionalEvidenceZip: {
    ReturnType: { file_link: string };
    APIRoute: APIRoutes.downloadInstitutionalEvidenceZip;
    options: {
      method: "GET";
      routeParams: {
        response_variable_id: string;
      };
    };
    apiKey: true;
  };
  fetchInstitutionalResponses: {
    ReturnType: InstitutionalResponse[];
    APIRoute: APIRoutes.fetchInstitutionalResponses;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
  addInstitutionalLink: {
    ReturnType: Evidence;
    APIRoute: APIRoutes.addInstitutionalLink;
    options: {
      method: "POST";
      body: {
        response_variable_id: number;
        link_or_text: string;
      };
    };
    apiKey: true;
  };
  editInstitution: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editInstitution;
    options: {
      method: "PUT";
      routeParams: { institution_id: string };
      body: {
        name: string;
        description: string;
        member_since: string;
      };
    };
    apiKey: true;
  };
  editSubcategory: {
    ReturnType: unknown;
    APIRoute: APIRoutes.editSubcategory;
    options: {
      method: "PUT";
      routeParams: {
        subcategory_id: string;
      };
      queryParams: {
        subcategory_name: string;
      };
    };
    apiKey: true;
  };
  audInstitutionalDataResponses: {
    ReturnType: InstitutionalResponsesAUD[];
    APIRoute: APIRoutes.audInstitutionalDataResponses;
    options: {
      method: "GET";
    };
    apiKey: true;
  };
}

// Resto de declaraciones.

/// El esquema para cualquier declaración de ruta
type APIActionClass = {
  ReturnType: unknown;
  APIRoute: APIRoutes;
  options: {
    method: Method;
    queryParams?: Record<string, string>;
    routeParams?: Record<string, string>;
    body?:
      | Record<
          string,
          | string
          | boolean
          | number
          | number[]
          | Array<{ option_text: string; option_score: number }>
          | string[]
          | Array<{ name: string; code: string }>
        >
      | PostResponseVariable[]
      | PutResponseVariable[]
      | PutInstitutionalDataResponse[]
      | PostInstitutionalDataResponse[]
      | Array<{ process_string: string; start: string; end: string }>;
  };
  apiKey: boolean;
};

// Los métodos soportados por la API
export type Method = "GET" | "PUT" | "POST" | "DELETE";

type APIAction = APIActions[keyof APIActions];

/**
 * La función genérica de fetch a la api. Hace una request y retorna un valor dependiendo de que request haya hecho
 *
 * @template T El tipo de parámetro que retorna
 * @param apiRoute La ruta a la cual se va a ejecutar la request
 * @param options Las opciones con que se llamará a la api
 * @param _apiKey api-key for the API.
 */
async function fetchAPI<Action extends APIAction & APIActionClass = never>(
  apiRoute: Action["APIRoute"],
  options: Action["options"],
  ..._apiKey: Action["apiKey"] extends true ? [string] : [string] | []
): Promise<Action["ReturnType"]> {
  // Seteamos las headers apropiadas
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    accept: "application/json",
  };
  const [apiKey] = _apiKey;
  if (apiKey) headers["api-key"] = apiKey;

  // Construimos la ruta:
  // Cambiamos las variables por las correctas
  let route = String(apiRoute);
  const { routeParams } = options;
  if (routeParams !== undefined) {
    Object.keys(routeParams).forEach((key) => {
      if (routeParams[key] !== undefined) {
        route = route.replace(`{${key}}`, routeParams[key]);
      }
    });
  }

  // Añadimos los parametros de query
  if (options.queryParams !== undefined) {
    route = `${route}?${new URLSearchParams(options.queryParams).toString()}`;
  }

  // Construimos las opciones
  const fetchOptions: RequestInit = {
    method: options.method,
    headers,
  };
  if (options.body !== undefined) {
    fetchOptions.body = JSON.stringify(options.body);
  }
  return await fetch(
    `${process.env.REACT_APP_API_URL}${route}`,
    fetchOptions
  ).then((res) => {
    if (res.ok) {
      return res.json();
    }
    throw Error("The request failed");
  });
}

export default fetchAPI;
