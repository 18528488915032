import React, { useContext, useLayoutEffect, useState } from "react";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import { InstitutionMember, User } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "./CargandoScreen";
import Modal from "./Modal";
import ProgressBar from "./ProgressBar";

const cargos = {
  RDI: "Representante de institución",
  MDI: "Informante",
};
/**
 * tabla de miembros de institucion
 *
 * @param props react props
 * @param props.institutionId id de institucion
 */
export default function MembersWidget({
  institutionId,
}: {
  institutionId: string;
}): React.ReactElement {
  const [state] = useContext(AppContext);
  const [data, setData] = useState<InstitutionMember[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [ready, setReady] = useState(false);
  const [rdi, setRDI] = useState<User>();
  useLayoutEffect(() => {
    if (!state.token) return;
    fetchAPI<APIActions["membersOverviewAdmin"]>(
      APIRoutes.membersOverviewAdmin,
      { method: "GET", routeParams: { institution_id: institutionId } },
      state.token
    ).then((membersData) => setData(membersData.institution_members));
    fetchAPI<APIActions["fetchInstitutionDetails"]>(
      APIRoutes.fetchInstitutionDetails,
      { method: "GET", routeParams: { institution_id: institutionId } },
      state.token
    ).then((ins) => {
      setUsers(ins.members);
      setRDI(ins.members.find((m) => m.user_type === "RDI"));
      setReady(true);
    });
  }, [institutionId, state.token]);
  return (
    <CargandoScreen ready={ready}>
      {ready ? (
        <div>
          <div tw="font-semibold text-header2 text-resies_blue1">
            Integrantes
          </div>
          <div
            style={{ height: "250px" }}
            tw="flex-col flex bg-resies_lightpurple overflow-y-auto"
          >
            <div tw="flex flex-row bg-resies_blue2 text-white font-bold h-5 justify-evenly">
              <div tw="text-center w-2/12">NOMBRE</div>
              <div tw="text-center w-3/12">CARGO</div>
              <div tw="text-center w-3/12">EMAIL</div>
              <div tw="text-center w-3/12">PROGRESO</div>
              <div tw="text-center w-1/12"> </div>
            </div>
            <div className="flex-grow-1">
              {rdi && (
                <div
                  tw="flex flex-row justify-evenly p-3"
                  style={{ height: "35px" }}
                  key={rdi.id}
                >
                  <div tw="text-center w-2/12">
                    {`${rdi.first_name} ${rdi.last_name}`}
                  </div>
                  <div tw="text-center w-3/12">
                    {cargos[rdi.user_type as "RDI" | "MDI"]}
                  </div>
                  <div tw="text-center w-3/12"> {rdi.email} </div>
                  <div tw="text-center w-3/12">
                    <div tw="flex flex-row h-full content-center" />
                  </div>
                  <div tw="text-center w-1/12">
                    <Modal user={rdi} />
                  </div>
                </div>
              )}
              {data.map((d) => {
                return (
                  <div
                    tw="flex flex-row justify-evenly p-3"
                    style={{ height: "35px" }}
                    key={d.id}
                  >
                    <div tw="text-center w-2/12"> {`${d.name}`} </div>
                    <div tw="text-center w-3/12">
                      {cargos[d.role as "RDI" | "MDI"]}
                    </div>
                    <div tw="text-center w-3/12"> {d.email} </div>
                    <div tw="text-center w-3/12">
                      <div tw="flex flex-row h-full content-center">
                        <div tw="w-9/12 h-full">
                          <ProgressBar
                            completed={
                              (d.total_responses /
                                (d.total_assigned_variables !== 0
                                  ? d.total_assigned_variables
                                  : 1)) *
                              100
                            }
                          />
                        </div>
                        <div tw="w-3/12">
                          {d.total_responses} / {d.total_assigned_variables}
                        </div>
                      </div>
                    </div>
                    <div tw="text-center w-1/12">
                      <Modal user={users.filter((us) => us.id === d.id)[0]} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </CargandoScreen>
  );
}
