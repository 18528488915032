import { ReactElement } from "react";
import "twin.macro";
import RDIHistoricReports from "../rdi/RDIHistoricReports";

/**
 * Página perteneciente al Auditor para ver los datos de una institución.
 */
function AUDHistoricInstitutionReport(): ReactElement {
  return (
    <>
      <RDIHistoricReports />
    </>
  );
}

export default AUDHistoricInstitutionReport;
