import React, { ReactElement } from "react";
import "twin.macro";
/**
 * Elemento informativo del footer.
 *
 * @param props react props.
 * @param props.image Imagen que acompaña al texto del elemento.
 * @param props.text Texto informativo.
 */
function FooterElement(props: { image: string; text: string }): ReactElement {
  const { text, image } = props;
  return (
    <div tw="flex flex-row justify-center">
      <div tw="flex flex-wrap content-center">
        <img tw="w-5 h-4" src={image} alt="home" />
      </div>
      <p tw="text-resies_lightpurple flex flex-wrap content-center px-2">
        {text}
      </p>
    </div>
  );
}

export default FooterElement;
