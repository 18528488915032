import React, { ReactElement } from "react";
import "twin.macro";
import { Category } from "../../../types/backendTypes";
import CategorieNavbarElement from "./CategorieNavbarElement";

/**
 * Navbar que contiene las categorias actualmente existentes.
 *
 * @param props react props.
 * @param props.categories Lista que contiene todas las categorias a mostrar en el navbar.
 * @param props.selectedCategory Corresponde al nombre de la categoria actualmente seleccionada.
 */
function CategoriesNavbar(props: {
  categories: Category[];
  selectedCategory: Category;
}): ReactElement {
  const { categories, selectedCategory } = props;
  return (
    <div tw="flex flex-row justify-between bg-resies_lightpurple px-10 lg:pr-20 lg:pl-28 w-full">
      {categories.map((category) => (
        <CategorieNavbarElement
          category={category}
          selectedCategory={selectedCategory}
          key={category.id}
        />
      ))}
    </div>
  );
}

export default CategoriesNavbar;
