import React, { ReactElement, useEffect } from "react";
import "twin.macro";
import { InstitutionVariableStatus } from "../../../../../types/utilTypes";
import ADMEntryInstitutionVariableValue from "./ADMEntryInstitutionVariableValue";

/**
 * Formulario correspondiente a una variable de un indicador.
 *
 * @param props react props.
 * @param props.variable Variable que se muestra en esta fila.
 * @param props.setInstitutionVariables setState que maneja los cambios en las variables.
 * @param props.institutionVariables Objeto que muestra todas las variables de cada indicador.
 * @param props.deletedVariables Variables que han sido eliminadas
 * @param props.setDeletedVariables setState para manejar las variables que han sido eliminadas.
 * @param props.setChanges set changes
 */
function ADMVariableRow(props: {
  variable: InstitutionVariableStatus;
  setInstitutionVariables: React.Dispatch<
    React.SetStateAction<InstitutionVariableStatus[]>
  >;
  institutionVariables: InstitutionVariableStatus[];
  deletedVariables: InstitutionVariableStatus[];
  setDeletedVariables: React.Dispatch<
    React.SetStateAction<InstitutionVariableStatus[]>
  >;
  setChanges: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement {
  const {
    variable,
    setInstitutionVariables,
    institutionVariables,
    deletedVariables,
    setDeletedVariables,
    setChanges,
  } = props;
  const slugRef = React.createRef<HTMLTextAreaElement>();
  const descriptionRef = React.createRef<HTMLTextAreaElement>();

  useEffect(() => {
    slugRef.current?.focus();
  }, [slugRef]);

  useEffect(() => {
    descriptionRef.current?.focus();
  }, [descriptionRef]);

  /**
   * Funcion que cambia al estado de edición un elemento de la variable al hacer click.
   *
   * @param type tipo de objeto, que puede ser description o slug.
   */
  function onClickHandler(type: string) {
    const copyInstitutionVariables = [...institutionVariables];
    const copyVariable = copyInstitutionVariables.filter(
      (vari) => vari.id === variable.id
    )[0];
    if (type === "description") {
      copyVariable.descriptionStatus = "edit";
    } else if (type === "slug") {
      copyVariable.slugStatus = "edit";
    }
    setInstitutionVariables(copyInstitutionVariables);
  }

  /**
   * Función que maneja el cambio de cada elemento de la variable en el modo de edición.
   *
   * @param e event
   * @param type type
   */
  function onChangeHandler(
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: string
  ) {
    const copyInstitutionVariables = [...institutionVariables];
    const copyVariable = copyInstitutionVariables.filter(
      (vari) => vari.id === variable.id
    )[0];
    if (type === "description") {
      copyVariable.description = e.currentTarget.value;
    } else if (type === "slug") {
      copyVariable.indicator_slug = e.currentTarget.value;
    } else if (type === "type") {
      copyVariable.type = e.currentTarget.value;
    } else if (type === "option") {
      copyVariable.json_config[parseInt(e.currentTarget.name, 10)].option_text =
        e.currentTarget.value;
    } else if (type === "score") {
      copyVariable.json_config[
        parseInt(e.currentTarget.name, 10)
      ].option_score = parseFloat(e.currentTarget.value);
    } else if (type === "newOption") {
      copyVariable.json_config.push({
        option_text: "texto",
        option_score: 0,
      });
    } else if (type === "deleteOption") {
      copyVariable.json_config.splice(parseInt(e.currentTarget.name, 10), 1);
    }
    if (copyVariable.generalStatus === "no modified") {
      copyVariable.generalStatus = "modified";
    }
    setInstitutionVariables(copyInstitutionVariables);
    setChanges(true);
  }

  /**
   * Función que cierra el modo de edición al hacer click en ENTER.
   *
   * @param e keyboard event
   * @param type tipo puede ser description, descriptionHelper o verification.
   */
  function onKeyDownHandler(
    e: React.KeyboardEvent<HTMLTextAreaElement>,
    type: string
  ) {
    if (e.key === "Enter") {
      const copyInstitutionVariables = [...institutionVariables];
      const copyVariable = copyInstitutionVariables.filter(
        (vari) => vari.id === variable.id
      )[0];
      if (type === "description") {
        copyVariable.descriptionStatus = "ready";
      } else if (type === "slug") {
        copyVariable.slugStatus = "ready";
      }
      setInstitutionVariables(copyInstitutionVariables);
    }
  }

  /**
   * Funcion que elimina la variable al hacer click.
   */
  function onClickDeleteVariable() {
    const copyDeletedVariables = [...deletedVariables];
    const copyInstitutionVariables = [...institutionVariables];
    const copyVariable = copyInstitutionVariables.filter(
      (vari) => vari.id === variable.id
    )[0];
    copyDeletedVariables.push(copyVariable);
    const index = copyInstitutionVariables.indexOf(copyVariable);
    copyInstitutionVariables.splice(index, 1);
    setInstitutionVariables(copyInstitutionVariables);
    setDeletedVariables(copyDeletedVariables);
    setChanges(true);
  }
  /**
   * Funcion que cierra el modo de edicion al hacer focus en otro elemento
   *
   * @param type type
   */
  function onBlurHandler(type: string) {
    const copyInstitutionVariables = [...institutionVariables];
    const copyVariable = copyInstitutionVariables.filter(
      (vari) => vari.id === variable.id
    )[0];
    if (type === "description") {
      copyVariable.descriptionStatus = "ready";
    } else if (type === "slug") {
      copyVariable.slugStatus = "ready";
    }
    setInstitutionVariables(copyInstitutionVariables);
  }
  return (
    <div
      tw="flex flex-row grid grid-cols-12
          content-center border-b-2 border-darkgray"
    >
      <ADMEntryInstitutionVariableValue
        statusVerificator={variable.slugStatus}
        typeHandler="slug"
        value={variable.indicator_slug}
        onChangeHandler={onChangeHandler}
        onClickHandler={onClickHandler}
        onKeyDownHandler={onKeyDownHandler}
        onBlurHandler={onBlurHandler}
        reference={slugRef}
      />

      <ADMEntryInstitutionVariableValue
        statusVerificator={variable.descriptionStatus}
        typeHandler="description"
        value={variable.description}
        onChangeHandler={onChangeHandler}
        onClickHandler={onClickHandler}
        onKeyDownHandler={onKeyDownHandler}
        onBlurHandler={onBlurHandler}
        reference={descriptionRef}
      />

      <div tw="flex flex-col text-justify min-h-100 mx-auto col-span-3 xl:w-72 lg:w-52">
        <select
          tw="mx-auto h-2/6 rounded-md border-resies_blue1 border"
          value={variable.type}
          onChange={(e) => onChangeHandler(e, "type")}
        >
          <option value="NUMBER">Numérica</option>
          <option value="MULTIPLE_CHOICE">Elección multiple</option>
          <option value="FREE_TEXT">Texto libre</option>
        </select>
        {variable.type === "MULTIPLE_CHOICE" ? (
          <div tw="flex flex-col">
            <div tw="flex flex-row">
              <p tw="w-4/6 font-normal text-black xl:text-sm text-xs">Opción</p>
              <p tw="w-1/6 font-normal text-black xl:text-sm text-xs">
                Puntuación
              </p>
            </div>
            {variable.json_config.map((option) => (
              <div
                tw="flex flex-row justify-between my-1"
                key={variable.json_config.indexOf(option)}
              >
                <input
                  tw="w-3/6"
                  type="text"
                  value={option.option_text}
                  name={variable.json_config.indexOf(option).toString()}
                  onChange={(e) => onChangeHandler(e, "option")}
                />
                <div tw="w-1/6" />
                <input
                  tw="w-1/6"
                  type="number"
                  value={option.option_score}
                  name={variable.json_config.indexOf(option).toString()}
                  onChange={(e) => onChangeHandler(e, "score")}
                />
                <button
                  type="button"
                  tw="w-1/6"
                  name={variable.json_config.indexOf(option).toString()}
                  onClick={(e) => onChangeHandler(e, "deleteOption")}
                >
                  <img
                    src="/cancel.png"
                    alt="delete"
                    tw="object-contain mx-auto"
                  />
                </button>
              </div>
            ))}
            <button
              type="button"
              tw="bg-resies_green font-normal text-black 2xl:text-sm md:text-xs text-xs my-2 py-1 rounded-md"
              onClick={(e) => onChangeHandler(e, "newOption")}
            >
              Agregar opción
            </button>
          </div>
        ) : (
          <></>
        )}
        {variable.type === "NUMBER" ? (
          <p tw="2xl:text-sm md:text-xs text-xs mx-4 text-center">
            La variable tendra valor numérico
          </p>
        ) : (
          <></>
        )}
        {variable.type === "FREE_TEXT" ? (
          <p tw="2xl:text-sm md:text-xs text-xs mx-4 text-center">
            La variable sera de texto libre
          </p>
        ) : (
          <></>
        )}
      </div>

      <div tw="flex text-justify min-h-100 mx-auto col-span-3 xl:w-52 lg:w-40">
        <button
          type="button"
          tw="w-2/12 my-auto mx-auto"
          onClick={onClickDeleteVariable}
        >
          <img
            src="/trash.png"
            alt="trash"
            tw="object-contain my-auto mx-auto"
          />
        </button>
      </div>
    </div>
  );
}

export default ADMVariableRow;
