/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { AbstractParseTreeVisitor } from "antlr4ts/tree/AbstractParseTreeVisitor";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { ExcelLangGrammarLexer } from "../../ANTLR/ExcelLangGrammarLexer";
import {
  AddContext,
  ClipContext,
  DivContext,
  EqContext,
  GeqContext,
  GtContext,
  LeqContext,
  LtContext,
  MulContext,
  NegContext,
  ParensContext,
  SiContext,
  SubContext,
  SumaContext,
} from "../../ANTLR/ExcelLangGrammarParser";
import { ExcelLangGrammarVisitor } from "../../ANTLR/ExcelLangGrammarVisitor";
import { AuxiliarFormula } from "../../types/backendTypes";

class FormulaVisitor
  extends AbstractParseTreeVisitor<number>
  implements ExcelLangGrammarVisitor<number>
{
  // Los valores de prueba de las variables del indicador
  variables: Record<string, number> = {};

  // Los valores de prueba de las variables de la institucion
  di_variables: Record<string, number> = {};

  // Las formulas auxiliares
  // TODO: cambiar por un diccionario para rapidez
  auxFormulas: Array<AuxiliarFormula> = [];

  // Las variables del indicador existentes
  indicatorVariables = new Set<string>();

  // Las variables de la institución existentes
  institutionVariables = new Set<string>();

  // Los errores encontrados al visitar
  errors: Array<{ type: "VAR" | "AUX" | "DI"; name: string }> = [];

  constructor(
    variables?: Record<string, number>,
    auxFormulas?: AuxiliarFormula[],
    indicatorVariables?: Set<string>,
    institutionVariables?: Set<string>,
    di_variables?: Record<string, number>
  ) {
    super();
    if (variables) {
      this.variables = variables;
    }
    if (auxFormulas) {
      this.auxFormulas = auxFormulas;
    }
    if (indicatorVariables) {
      this.indicatorVariables = indicatorVariables;
    }
    if (institutionVariables) {
      this.institutionVariables = institutionVariables;
    }
    if (di_variables) {
      this.di_variables = di_variables;
    }
  }

  defaultResult(): number {
    return 0;
  }

  visitTerminal(ctx: TerminalNode): number {
    const { text } = ctx.payload;
    if (!text) return this.defaultResult();
    switch (ctx.payload.type) {
      case ExcelLangGrammarLexer.DI:
        if (this.di_variables[text]) {
          return this.di_variables[text];
        }
        if (!this.institutionVariables.has(text)) {
          this.errors.push({ type: "DI", name: text });
        }
        return this.defaultResult();
      case ExcelLangGrammarLexer.AUX:
        this.errors.push({ type: "AUX", name: text });
        return this.defaultResult();
      case ExcelLangGrammarLexer.VAR:
        if (this.variables[text]) {
          return this.variables[text];
        }
        if (!this.indicatorVariables.has(text)) {
          this.errors.push({ type: "VAR", name: text });
        }
        return this.defaultResult();
      case ExcelLangGrammarLexer.NUMBER:
        return parseFloat(text);
      default:
        return this.defaultResult();
    }
  }

  visitGt(ctx: GtContext): number {
    return this.visit(ctx._left) > this.visit(ctx._right) ? 1 : 0;
  }

  visitLt(ctx: LtContext): number {
    return this.visit(ctx._left) < this.visit(ctx._right) ? 1 : 0;
  }

  visitGeq(ctx: GeqContext): number {
    return this.visit(ctx._left) >= this.visit(ctx._right) ? 1 : 0;
  }

  visitLeq(ctx: LeqContext): number {
    return this.visit(ctx._left) <= this.visit(ctx._right) ? 1 : 0;
  }

  visitEq(ctx: EqContext): number {
    return this.visit(ctx._left) === this.visit(ctx._right) ? 1 : 0;
  }

  visitAdd(ctx: AddContext): number {
    return this.visit(ctx._left) + this.visit(ctx._right);
  }

  visitSub(ctx: SubContext): number {
    return this.visit(ctx._left) - this.visit(ctx._right);
  }

  visitMul(ctx: MulContext): number {
    return this.visit(ctx._left) * this.visit(ctx._right);
  }

  visitDiv(ctx: DivContext): number {
    const right = this.visit(ctx._right);
    if (right === 0) return 0;
    return this.visit(ctx._left) / right;
  }

  visitNeg(ctx: NegContext): number {
    return -this.visit(ctx._single);
  }

  visitSi(ctx: SiContext): number {
    if (this.visit(ctx._first)) {
      return this.visit(ctx._second);
    }
    return this.visit(ctx._third);
  }

  visitClip(ctx: ClipContext): number {
    const clipped = this.visit(ctx._first);
    const minval = this.visit(ctx._second);
    const maxval = this.visit(ctx._third);
    if (clipped < minval) {
      return minval;
    }
    if (clipped > maxval) {
      return maxval;
    }
    return clipped;
  }

  visitSuma(ctx: SumaContext): number {
    return (
      ctx.children?.reduce((res, next) => res + this.visit(next), 0) ||
      this.defaultResult()
    );
  }

  visitParens(ctx: ParensContext): number {
    return this.visit(ctx._single);
  }
}

export default FormulaVisitor;
