import { AppState } from "../../../dispatcher";
import { InstitutionCategoryStatus } from "../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

/**
 * Funcion que retorna las categorias para el menu de editar categorias.
 *
 * @param appState estado de la aplicacion
 * @param setReady setter de ready
 * @param setMaxId setter de id maxima
 * @param setCategoryStatus setter de estado de categoria
 */
function getInstitutionCategories(
  appState: AppState,
  setReady: React.Dispatch<React.SetStateAction<boolean>>,
  setMaxId: React.Dispatch<React.SetStateAction<number>>,
  setCategoryStatus: React.Dispatch<
    React.SetStateAction<InstitutionCategoryStatus[]>
  >
): void {
  if (!appState.token) return;
  fetchAPI<APIActions["fetchInstitutionCategories"]>(
    APIRoutes.fetchInstitutionCategories,
    {
      method: "GET",
    },
    appState.token
  ).then((categs) => {
    const catStatus: InstitutionCategoryStatus[] = [];
    let id = 0;
    categs.forEach((cate) => {
      catStatus.push({
        id: cate.id,
        status: "ready",
        name: cate.name,
      });
      if (cate.id >= id) {
        id = cate.id;
      }
      catStatus.sort((a, b) => a.id - b.id);
      setMaxId(id);
    });
    setCategoryStatus([...catStatus]);
    setReady(true);
  });
}

export default getInstitutionCategories;
