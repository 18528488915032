import React, { ReactElement } from "react";
import "twin.macro";
/**
 * Titulo del form correspondiente a cada indicador.
 */
function ADMTitleInstitutionVariableForm(): ReactElement {
  return (
    <div tw="flex flex-col">
      <div tw="flex grid grid-cols-12 h-5 bg-resies_blue2 flex-wrap content-center justify-between">
        <p tw="font-bold text-base text-ghostwhite col-span-3 text-center">
          Nombre
        </p>
        <p tw="font-bold text-base text-ghostwhite col-span-3 text-center">
          Descripción
        </p>
        <p tw="font-bold text-base text-ghostwhite col-span-3 text-center">
          Tipo de respuesta
        </p>
      </div>
    </div>
  );
}

export default ADMTitleInstitutionVariableForm;
