import { AppState } from "../../../dispatcher";
import { OdsScorePattern } from "../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

function getOdsScoreByInstitution(
  institution_id: number,
  appState: AppState,
  setScore: React.Dispatch<React.SetStateAction<OdsScorePattern | undefined>>,
  setReady: React.Dispatch<React.SetStateAction<boolean>>
): void {
  if (!appState.token) return;
  fetchAPI<APIActions["fetchOdsScoresByInstitution"]>(
    APIRoutes.fetchOdsScoresByInstitution,
    {
      method: "GET",
      routeParams: {
        institution_id: institution_id.toString(),
      },
    },
    appState.token
  ).then((goals) => {
    setScore(goals);
    setReady(true);
  });
}

export default getOdsScoreByInstitution;
