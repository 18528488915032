import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import "twin.macro";
import { AppContext } from "../../../dispatcher";
import useRESIESDates from "../../../hooks/useRESIESDates";
import { ResponseComment } from "../../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../../utils/fetchAPI";
import NormalComments from "./NormalComments";

/**
 * Componente donde se muestran los comentarios sobre una variable
 */
function EvidenceComments(): ReactElement {
  const [appState] = useContext(AppContext);
  const { responseId } = useParams<{ responseId: string }>();
  const [allComments, setAllComments] = useState<ResponseComment[]>([]);
  const [reload, setReload] = useState(0);
  const [, currentProcess, currentInstitutionProcess] = useRESIESDates();

  const getCurrentProcessName = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess.current_process?.name;
    }

    return currentProcess?.name;
  };

  const reloadComments = useCallback(() => {
    if (!appState.token) return;

    if (responseId !== "missing") {
      fetchAPI<APIActions["fetchResponseComments"]>(
        APIRoutes.fetchResponseComments,
        {
          method: "GET",
          routeParams: {
            response_variable_id: responseId,
          },
        },
        appState.token
      ).then((comments) => {
        setAllComments(comments);
      });
    }
  }, [appState.token, responseId]);

  useEffect(() => reloadComments(), [reloadComments, reload]);

  /**
   * Funcion que maneja el envio del comentario al backend
   *
   * @param comment comentario que se quiere enviar
   * @param setComment Setter del comentario
   */
  function onSubmit(
    comment: string,
    setComment: React.Dispatch<React.SetStateAction<string>>
  ) {
    if (!appState.token) return;
    fetchAPI<APIActions["createResponseComment"]>(
      APIRoutes.createResponseComment,
      {
        method: "POST",
        body: {
          response_variable_id: parseInt(responseId, 10),
          content: comment,
          type: getCurrentProcessName() || "",
        },
      },
      appState.token
    ).then(() => {
      setComment("");
      setReload(reload + 1);
    });
  }

  return (
    <>
      <NormalComments
        onSubmit={onSubmit}
        allComments={allComments}
        reloadComments={reloadComments}
      />
    </>
  );
}

export default EvidenceComments;
