import { Evidence } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../fetchAPI";
import setForms from "./setForms";

/**
 * Función que retorna un diccionario con el titulo de cada indicador.
 *
 * @param setIndicatorsNames setter de nombres de indicadores
 * @param setIndicatorForm setter de formulario de indicador
 * @param token token
 * @param setReady setter de ready
 */
function indicatorsTitle(
  setIndicatorForm: React.Dispatch<
    React.SetStateAction<
      Record<string, string | boolean | number | null | Evidence[]>[]
    >
  >,
  token: string | null,
  setReady: React.Dispatch<React.SetStateAction<boolean>>
): void {
  let apiKey = "";
  if (token !== null) {
    apiKey = token;
  }
  const titles: Record<string, string> = {};
  if (apiKey) {
    fetchAPI<APIActions["fetchIndicators"]>(
      APIRoutes.fetchIndicators,
      {
        method: "GET",
      },
      apiKey
    )
      .then((indicators) => {
        indicators.forEach((indicator) => {
          titles[indicator.slug] = indicator.title;
        });
        setForms(indicators, setIndicatorForm, apiKey, setReady);
      })
      .catch(() => {}); // TODO
  }
}
export default indicatorsTitle;
