import React, { ReactElement } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import PageTitle from "../../components/common/PageTitle";
import ResultsBox from "../../components/common/ResultsBox";
import CategoryProgressRow from "../../components/common/CategoryProgressRow";
import InstitutionViewButton from "../../components/common/InstitutionViewButton";
import Routes from "../../routers/routes";

/**
 * La página de información de institución del MDI
 */
function MDIMyInstitution(): ReactElement {
  return (
    <div tw="bg-ghostwhite">
      <Container>
        <PageTitle title="Tu institución" />
        <InstitutionViewButton
          label="Responder datos institucionales"
          href={Routes.institutionData}
        />
        <div tw="mb-4" />
        <CategoryProgressRow wantProgressbar />
        <div tw="my-28 w-full">
          <ResultsBox />
        </div>
      </Container>
    </div>
  );
}

export default MDIMyInstitution;
