import React, { ReactElement } from "react";
import "twin.macro";

/**
 * Una institución a mostrar en la vista índice de instituciones.
 *
 * @param props react props.
 * @param props.name El nombre de la institución a mostrar
 * @param props.imageUrl La url de la imagen grande
 * @param props.logoUrl La url del logos
 * @param props.id La id de la institución
 */
function InstitutionIndexEntry(props: {
  name: string;
  imageUrl: string;
  logoUrl: string;
  id: number;
}): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, imageUrl, logoUrl, id } = props;
  return (
    <div tw="flex col-span-2 bg-resies_lightpurple rounded-md px-5 inline-block shadow-md h-full">
      <div>
        <div tw="flex h-10 mt-2 mb-2">
          <img tw="object-contain" src={logoUrl} alt="uc" />
          <div tw="flex flex-wrap content-center ml-2 mt-2.5 hover:text-resies_purple text-black font-bold text-header2 text-very_darkgray">
            {name}
          </div>
        </div>
        <div tw="flex flex-wrap">
          <img
            src={imageUrl}
            alt="institution_image"
            tw="object-cover h-48 w-full"
          />
        </div>
      </div>
      <div tw="flex justify-end mt-2">
        {/* {appState.userType === "ADM" && (
          <>
            <Link
              to={Routes.assignInstitutionRDI.replace(
                ":institutionId",
                id.toString()
              )}
            >
              <button type="button">Asignar RDI</button>
            </Link>
            <Link
              to={Routes.assignInstitutionAUD.replace(
                ":institutionId",
                id.toString()
              )}
            >
              <button type="button">Asignar AUD</button>
            </Link>
          </>
        )} */}
      </div>
    </div>
  );
}

export default InstitutionIndexEntry;
