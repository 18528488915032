import { Dispatch, SetStateAction } from "react";
import { UserLogin } from "../types/backendTypes";
import { ActionTypes, Dispatcher } from "../dispatcher";

/**
 * setter para la informacion del login
 *
 * @param data datos de login
 * @param setError setter de error
 * @param appDispatch dispatcher
 * @param history historia de router
 */
function setLoginInfo(
  data: UserLogin,
  setError: Dispatch<SetStateAction<boolean>>,
  appDispatch: Dispatcher,
  history: History
): void {
  let institutionId = 0;
  let institutionName: string | null = null;
  if (data.user_type === "RDI" && data.rdiuser) {
    institutionId = data.rdiuser.institution?.id || 0;
    institutionName = data.rdiuser.institution?.name || null;
  } else if (data.user_type === "MDI" && data.mdiuser?.institution) {
    institutionId = data.mdiuser.institution.id;
    institutionName = data.mdiuser.institution.name;
  }
  if (!data.api_key) {
    setError(true);
  } else {
    appDispatch({
      type: ActionTypes.setLoginInfo,
      payload: {
        userId: data.id.toString(),
        token: data.api_key,
        userType: data.user_type,
        name: data.email,
        institutionId: institutionId.toString(),
        institutionName,
        history,
      },
    });
  }
}
export default setLoginInfo;
