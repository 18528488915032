import React, { ReactElement } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import EvidenceObjectedIndex from "../../components/rdi/EvidenceObjectedIndex";
import RDIEvidenceNavigationBar from "./RDIEvidenceNavigationBar";

/**
 * Página para el RDI con la evidencia objetada de su institución.
 */
function RDIObjectedEvidence(): ReactElement {
  return (
    <Container>
      <RDIEvidenceNavigationBar />
      <EvidenceObjectedIndex />
    </Container>
  );
}

export default RDIObjectedEvidence;
