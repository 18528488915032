/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { MutableRefObject, useState } from "react";

/**
 * PopupContainer. There must be a Popup component inside for it to work correctly.
 *
 * @param props0 props
 * @param props0.children the children
 * @param props0.containerStyle stylesheet for component
 * @param props0.className class for tailwindCSS
 * @param props0.onClick on click handler if necessary
 * @param props0.id id
 */
function PopupContainer({
  children,
  containerStyle,
  className,
  onClick,
  id,
}: {
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  id?: string;
}) {
  return (
    <div
      onClick={onClick}
      id={id}
      style={{
        position: "relative",
        display: "inline-block",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        userSelect: "none",
        ...containerStyle,
      }}
      {...(className ? { className } : null)}
    >
      {children}
    </div>
  );
}

/**
 * The Popup. The visibility must be toggled using the useRef hook.
 *
 * @param props0 props
 * @param props0.children the children
 * @param props0.popupStyle stylesheet for component
 * @param props0.className class for tailwindCSS
 * @param props0.ref objeto ref
 */
const Popup = React.forwardRef(
  (
    props: {
      children?: React.ReactNode;
      popupStyle?: React.CSSProperties;
      className?: string;
      closeEvent?: string;
      onClick?: () => void;
    },
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        style={{
          ...props.popupStyle,
          visibility: "hidden",
          position: "absolute",
          zIndex: 1,
        }}
        {...(props.className ? { className: props.className } : null)}
        onClick={(e) => {
          if (e.type === "click") e.stopPropagation();
          if (props.onClick) props.onClick();
        }}
      >
        {props.children}
      </div>
    );
  }
);

/**
 * A popup that is always centered and does not require a container.
 *
 * @param props0 react props
 * @param props0.width width of popup
 * @param props0.height height of popup
 * @param props0.children react children
 */
const CenteredPopup = React.forwardRef(
  (
    {
      width,
      height,
      children,
    }: {
      width: number;
      height: number;
      children: React.ReactNode;
    },
    ref: React.ForwardedRef<() => void>
  ) => {
    const [hidden, setHidden] = useState(true);
    (ref as MutableRefObject<() => void>).current = () => setHidden(!hidden);
    return hidden ? null : (
      <div id="popup">
        <div
          onClick={() => {
            setHidden(true);
          }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            opacity: 0.5,
            backgroundColor: "black",
            zIndex: 1,
            cursor: "auto",
          }}
        />
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            width,
            height,
            top: "50%",
            left: "50%",
            marginTop: -height / 2,
            marginLeft: -width / 2,
            position: "fixed",
            zIndex: 2,
            cursor: "auto",
            boxShadow: "0px 0px 1000000px black",
            overflowY: "auto",
          }}
        >
          {children}
        </div>
      </div>
    );
  }
);
export { Popup, PopupContainer, CenteredPopup };
