import { useCallback, useEffect, useState } from "react";
import { User } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * Un hook para buscar los usuarios en la api.
 *
 * @param pageSize tamano de pagina
 * @param apiKey token
 * @param userFilter filtro de usuario
 * @param institutionFilter filtro de institucion
 * @param roleFilter filtro de rol
 */
function useUsers(
  pageSize: number,
  apiKey: string | null,
  userFilter: string,
  institutionFilter: string | undefined,
  roleFilter: string | undefined
): [User[], number, number, (arg0: number) => void, boolean, () => void] {
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [ready, setReady] = useState(false);
  const [visibleUsers, setVisibleUsers] = useState<Array<User>>([]);
  const [filteredUsers, setFilteredUsers] = useState<Array<User>>([]);
  const [users, setUsers] = useState<Array<User>>([]);

  const reloadUsers = useCallback(() => {
    if (apiKey) {
      fetchAPI<APIActions["fetchUsers"]>(
        APIRoutes.fetchUsers,
        {
          method: "GET",
        },
        apiKey
      )
        .then((userss) => {
          setUsers(
            userss.sort((s1, s2) =>
              `${s1.first_name} ${s1.last_name}`.localeCompare(
                `${s2.first_name} ${s2.last_name}`
              )
            )
          );
          setReady(true);
        })
        .catch(() => {}); // TODO
    }
  }, [apiKey]);

  useEffect(() => {
    reloadUsers();
  }, [reloadUsers]);

  useEffect(() => {
    const fus = users
      .filter((user) =>
        `${user.first_name} ${user.last_name}`
          .toLowerCase()
          .includes(userFilter.toLowerCase())
      )
      .filter((user) => (roleFilter ? user.user_type === roleFilter : true))
      // eslint-disable-next-line no-unneeded-ternary
      .filter((user) => {
        if (institutionFilter) {
          if (user.user_type === "ADM") {
            return true;
          }
          if (
            user.user_type === "MDI" &&
            user.mdiuser?.institution?.id.toString() === institutionFilter
          ) {
            return true;
          }
          if (
            user.user_type === "RDI" &&
            user.rdiuser?.institution?.id.toString() === institutionFilter
          ) {
            return true;
          }
          if (
            user.user_type === "AUD" &&
            user.auduser?.institution_set
              .map((ins) => ins.id.toString())
              .includes(institutionFilter)
          ) {
            return true;
          }
          return false;
        }
        return true;
      });
    setFilteredUsers(fus);
    setMaxPages(Math.ceil(fus.length / pageSize));
  }, [pageNumber, users, pageSize, userFilter, institutionFilter, roleFilter]);

  useEffect(() => {
    setVisibleUsers(
      filteredUsers.slice(
        pageSize * (pageNumber - 1),
        Math.min(pageSize * pageNumber, filteredUsers.length)
      )
    );
  }, [filteredUsers, pageNumber, pageSize]);

  useEffect(() => {
    if (pageNumber > maxPages && ready) {
      setPageNumber(maxPages);
    }
    if (pageNumber < 1 && maxPages !== 0 && ready) {
      setPageNumber(1);
    }
    if (maxPages === 0 && ready) {
      setPageNumber(0);
    }
  }, [pageNumber, maxPages, ready]);

  useEffect(() => {
    setPageNumber(1);
  }, [userFilter, institutionFilter, roleFilter]);

  return [
    visibleUsers,
    maxPages,
    pageNumber,
    setPageNumber,
    ready,
    reloadUsers,
  ];
}

export default useUsers;
