import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import "twin.macro";

/**
 * Barra de navegación para usar dentro de las vistas
 *
 * @param props react props
 * @param props.items items
 * @param props.title titulo
 */
function InsideNavigationBar(props: {
  items: Array<{ name: string; href: string }>;
  title: string;
}): ReactElement {
  const { items, title } = props;
  return (
    <>
      <div tw="bg-resies_lightgreen font-sans text-header1 font-bold text-resies_blue1 py-2 px-6">
        {title}
      </div>
      <div tw="bg-resies_lightpurple h-14 flex mb-12">
        <div tw="flex flex-row justify-between w-full">
          {items.map((item) => (
            <NavLink
              to={item.href}
              tw="w-full text-xs md:text-xs xl:text-lg lg:px-3 px-0 py-3 
       font-semibold box-border text-center"
              className="text-gray border-0 text-header2"
              activeClassName={
                item.href === "#"
                  ? "text-gray border-0 text-header2"
                  : "text-resies_blue1 border-b-8 border-resies_blue1 bg-resies_lightgreen"
              }
              key={item.name}
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
}

export default InsideNavigationBar;
