import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import NavbarElement from "./NavbarElement";
import "twin.macro";
import Routes from "../../routers/routes";

/**
 * Navbar principal de la aplicación.
 *
 * @param props react props.
 * @param props.items Nombre y vínculo de los elementos que iran en la navbar.
 */
function Navbar(props: {
  items: Array<{ name: string; href: string }>;
}): ReactElement {
  const { items } = props;
  return (
    <div tw="bg-resies_purple pr-20 pl-28 h-14 flex print:pr-0 print:pl-0">
      <div tw="h-full w-auto inline-block">
        <Link to={Routes.landing}>
          <img
            tw="object-contain h-full w-auto pr-4 print:ml-5"
            src="/resiesLogo.png"
            alt="logo"
          />
        </Link>
      </div>
      <div tw="flex flex-row justify-between w-full">
        {items.map((item) => (
          <NavbarElement name={item.name} href={item.href} key={item.name} />
        ))}
      </div>
    </div>
  );
}

export default Navbar;
