import React, { ReactElement } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import PageTitle from "../../components/common/PageTitle";
import TableInstitutions from "../../components/aud/TableInstitutions";

/**
 * La página que muestra otras instituciones al RDI
 */
function AUDMyInstitutions(): ReactElement {
  return (
    <Container>
      <PageTitle title="Instituciones a Cargo" />
      <TableInstitutions />
    </Container>
  );
}

export default AUDMyInstitutions;
