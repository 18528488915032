import { Category, Indicator } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../fetchAPI";

/**
 * dropdown de categorias e indicadores.
 *
 * @param setCategories setter de categorias
 * @param setIndicators setter de indicadores
 * @param token token
 * @param setReady setter de listo
 * @param setCategorySelected setter de categoria seleccionada
 */
function dropDownData(
  setCategories: React.Dispatch<React.SetStateAction<Category[]>>,
  setIndicators: React.Dispatch<
    React.SetStateAction<Record<string, Indicator[]>>
  >,
  token: string | null,
  setReady: React.Dispatch<React.SetStateAction<boolean>>,
  setCategorySelected: React.Dispatch<React.SetStateAction<Category>>
): void {
  const inds: Record<string, Indicator[]> = {};
  const categories: Array<Category> = [];
  let apiKey = "";
  if (token !== null) {
    apiKey = token;
  }
  if (apiKey) {
    fetchAPI<APIActions["fetchCategories"]>(
      APIRoutes.fetchCategories,
      {
        method: "GET",
      },
      apiKey
    )
      .then((categs) => {
        categs.sort((a, b) => a.id - b.id);
        categs.forEach((categ) => {
          categories.push(categ);
          inds[categ.name] = [];
        });
        setCategories([...categories]);
        setCategorySelected(categories[0]);
      })
      .then(() => {
        categories.forEach((cate) => {
          fetchAPI<APIActions["fetchIndicators"]>(
            APIRoutes.fetchIndicators,
            {
              method: "GET",
              queryParams: {
                category_id: cate.id.toString(),
              },
            },
            apiKey
          )
            .then((indica) => {
              indica.sort((a, b) => a.id - b.id);
              indica.forEach((ind) => {
                inds[cate.name].push(ind);
              });
              setIndicators({ ...inds });
            })
            .then(() => setReady(true))
            .catch(() => {}); // TODO
        });
      })
      .catch(() => {}); // TODO
  }
}
export default dropDownData;
