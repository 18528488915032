import {
  ReactElement,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
} from "react";
import { Prompt, useParams } from "react-router";
import "twin.macro";
import ADMIndicatorList from "../../components/adm/EvalInstruments/EditEvalInstruments/EditIndicatorsView/ADMIndicatorList";
import ADMTitleIndicatorForm from "../../components/adm/EvalInstruments/EditEvalInstruments/EditIndicatorsView/ADMTitleIndicatorForm";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import { AppContext } from "../../dispatcher";
import {
  CategoryStatus,
  EvaluationVariablesFromIndicator,
  EvaluationVariableStatus,
  IndicatorStatus,
} from "../../types/utilTypes";
import singleCategorieData from "../../utils/data/AdmData/singleSubCategorieData";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import ADMVariableList from "../../components/adm/EvalInstruments/EditEvalInstruments/EditIndicatorsView/ADMVariableList";
import ADMEditIndicatorsTitle from "../../components/adm/EvalInstruments/EditEvalInstruments/EditIndicatorsView/ADMEditIndicatorsTitle";
import { OdsGoal, OdsObjective, Subcategory } from "../../types/backendTypes";
import sendChangesToBackend from "../../utils/data/AdmData/sendChangesToBackend";
import getOdsObjectives from "../../utils/data/AdmData/getOdsObjectives";
import StyledSelectElement from "../../components/common/StyledSelectElement";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import getOdsGoalsByIndicator from "../../utils/data/AdmData/getOdsGoalsByIndicator";

type OptionType = {
  text: string;
  value: string | number;
};

/**
 * Componente que muestra la vista para editar indicadores
 */
function ADMEditIndicators(): ReactElement {
  const { subcategoryId } = useParams<{ subcategoryId: string }>();
  const [indicators, setIndicators] = useState<IndicatorStatus[]>([]);
  const [deletedIndicators, setDeletedIndicators] = useState<IndicatorStatus[]>(
    []
  );
  const [deletedVariables, setDeletedVariables] = useState<
    EvaluationVariableStatus[]
  >([]);
  const [evaluationVariables, setEvaluationVariables] = useState<
    EvaluationVariablesFromIndicator[]
  >([]);
  const [indicatorSelected, setIndicatorSelected] = useState<IndicatorStatus>();
  const [ready, setReady] = useState(false);
  const [category, setCategory] = useState<CategoryStatus>();
  const [appState] = useContext(AppContext);
  const [maxVariableId, setMaxVariableId] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [subCategory, setSubCategory] = useState<Subcategory>();
  const [changes, setChanges] = useState(false);
  const [reload, setReload] = useState(0);
  const [odsObjectives, setOdsObjectives] = useState<OdsObjective[]>([]);
  const [refreshOds, setRefreshOds] = useState(false);
  const [selectedOds, setSelectedOds] = useState<OdsObjective | null>(null);
  const [selectedGoal, setSelectedGoal] = useState<number | null>(null);
  const [indicatorGoals, setIndicatorGoals] = useState<OdsGoal[]>([]);

  useEffect(() => {
    singleCategorieData(
      appState,
      subcategoryId,
      setCategory,
      setIndicators,
      setEvaluationVariables,
      setReady,
      setIndicatorSelected,
      setMaxVariableId,
      setSubCategory
    );
    getOdsObjectives(appState, setReady, setOdsObjectives);
    setDeletedVariables([]);
    setDeletedIndicators([]);
  }, [appState, subcategoryId, reload]);

  useEffect(() => {
    if (
      indicatorSelected !== undefined ||
      (refreshOds === true && indicatorSelected !== undefined)
    ) {
      getOdsGoalsByIndicator(indicatorSelected.id, appState, setIndicatorGoals);
      setRefreshOds(false);
      setReady(true);
    }
  }, [appState, indicatorSelected, refreshOds]);

  /**
   * Funcion que se activa al apretar el boton de guardar cambios y envia datos al backend.
   */
  function onSave() {
    let error = false;
    let errorIndicator = false;
    let errorNameRepeated = false;
    let errorSlugRepeated = false;
    const repeatedNames: string[] = [];
    const repeatedSlugs: string[] = [];
    indicators.forEach((ind) => {
      if (ind.statusSlug === "edit" || ind.statusTitle === "edit") {
        errorIndicator = true;
      }
      repeatedNames.push(ind.title);
      repeatedSlugs.push(ind.slug);

      evaluationVariables
        .filter((indi) => indi.id === ind.id)[0]
        .evaluationVariables.forEach((ind_variable) => {
          if (
            ind_variable.statusDescription === "edit" ||
            ind_variable.statusDescriptionFieldHelper === "edit" ||
            ind_variable.statusVerification === "edit"
          ) {
            error = true;
          }
        });
    });
    errorNameRepeated = new Set(repeatedNames).size !== repeatedNames.length;
    errorSlugRepeated = new Set(repeatedSlugs).size !== repeatedSlugs.length;
    if (error && !errorIndicator) {
      setErrorMessage("Hay variables en modo de edición");
    } else if (!error && errorIndicator) {
      setErrorMessage("Hay indicadores en modo de edición");
    } else if (error && errorIndicator) {
      setErrorMessage("Hay indicadores y variables en modo de edición");
    } else if (errorNameRepeated || errorSlugRepeated) {
      setErrorMessage("y no pueden existir nombres repetidos");
    } else if (subCategory) {
      setErrorMessage("");
      sendChangesToBackend(
        setReady,
        deletedVariables,
        appState,
        deletedIndicators,
        indicators,
        evaluationVariables,
        subCategory,
        reload,
        setReload,
        setChanges
      );
    }
  }

  const selectOds = (e: ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    const ods = odsObjectives.filter((ods) => ods.id === parseInt(id))[0];

    if (ods) {
      setSelectedOds(ods);
    }
  };

  const assignOdsGoal = () => {
    if (!appState.token) return;

    if (
      selectedOds !== null &&
      selectedGoal !== null &&
      indicatorSelected !== undefined
    ) {
      setReady(false);
      fetchAPI<APIActions["assignOdsGoal"]>(
        APIRoutes.assignOdsGoal,
        {
          method: "POST",
          routeParams: {
            ods_id: selectedOds.id.toString(),
            ods_goal_id: selectedGoal.toString(),
            indicator_id: indicatorSelected.id.toString(),
          },
        },
        appState.token
      ).then(() => {
        setSelectedOds(null);
        setSelectedGoal(null);
        setRefreshOds(true);
        setReady(false);
        setErrorMessage("");
      });
    }
  };

  const removeOdsGoal = (odsGoal: OdsGoal) => {
    if (!appState.token) return;

    if (
      odsGoal !== undefined &&
      odsGoal.ods_objective !== undefined &&
      indicatorSelected !== undefined
    ) {
      setReady(false);
      fetchAPI<APIActions["removeOdsGoal"]>(
        APIRoutes.removeOdsGoal,
        {
          method: "PUT",
          routeParams: {
            ods_id: odsGoal.ods_objective.id.toString(),
            ods_goal_id: odsGoal.id.toString(),
            indicator_id: indicatorSelected.id.toString(),
          },
        },
        appState.token
      ).then(() => {
        setSelectedOds(null);
        setSelectedGoal(null);
        setRefreshOds(true);
        setReady(false);
        setErrorMessage("");
      });
    }
  };

  return (
    <CargandoScreen ready={ready}>
      {ready ? (
        <>
          <Prompt
            when={changes}
            message="Tienes cambios sin guardar, ¿seguro que quieres abandonar la pagina?"
          />
          <Container>
            {category ? (
              <ADMEditIndicatorsTitle
                category={category}
                errorMessage={errorMessage}
                onSave={onSave}
                changes={changes}
                subCategory={subCategory}
              />
            ) : (
              <></>
            )}
          </Container>
          <div tw="text-darkgray font-semibold flex h-full">
            <ADMIndicatorList
              indicators={indicators}
              indicatorSelected={indicatorSelected}
              setIndicatorSelected={setIndicatorSelected}
              setIndicators={setIndicators}
              setEvaluationVariables={setEvaluationVariables}
              evaluationVariables={evaluationVariables}
              deletedIndicators={deletedIndicators}
              setDeletedIndicators={setDeletedIndicators}
              deletedVariables={deletedVariables}
              setDeletedVariables={setDeletedVariables}
              setChanges={setChanges}
            />
            <div tw="pr-20 flex flex-col flex-grow bg-ghostwhite relative">
              {indicatorSelected ? (
                <>
                  <ADMTitleIndicatorForm
                    subCategoryId={subcategoryId}
                    indicatorSelected={indicatorSelected}
                    indicators={indicators}
                    setIndicators={setIndicators}
                    setIndicatorSelected={setIndicatorSelected}
                    setChanges={setChanges}
                  />
                  <ADMVariableList
                    indicatorSelected={indicatorSelected}
                    setEvaluationVariables={setEvaluationVariables}
                    evaluationVariables={evaluationVariables}
                    maxVariableId={maxVariableId}
                    setMaxVariableId={setMaxVariableId}
                    deletedVariables={deletedVariables}
                    setDeletedVariables={setDeletedVariables}
                    setChanges={setChanges}
                  />
                  {odsObjectives && odsObjectives.length > 0 && (
                    <div>
                      <h2 tw="bg-resies_lightgreen font-sans text-header2 font-bold text-resies_blue1 py-2 px-6">
                        Metas ODS asociadas
                      </h2>

                      {indicatorGoals.length > 0 && (
                        <div tw="flex flex-col m-6">
                          {indicatorGoals.map((goal) => (
                            <p>
                              - {goal.name}{" "}
                              {goal.ods_objective &&
                                `[${goal.ods_objective.name}]`}{" "}
                              <button onClick={() => removeOdsGoal(goal)}>
                                (Desasignar)
                              </button>
                            </p>
                          ))}
                        </div>
                      )}

                      <div tw="flex flex-row items-center m-6">
                        <div tw="flex flex-col flex-grow mr-10">
                          <p>Selecciona un ODS</p>
                          <StyledSelectElement
                            options={(
                              [
                                { text: "Selecciona un ODS", value: "" },
                              ] as OptionType[]
                            ).concat(
                              odsObjectives.map<{
                                text: string;
                                value: string | number;
                              }>((ins) => ({
                                text: ins.name,
                                value: ins.id,
                              }))
                            )}
                            selectedValue={selectedOds?.id.toString() || ""}
                            selectedValueChangeHandler={selectOds}
                          />
                        </div>

                        <div tw="flex flex-row flex-grow">
                          {selectedOds !== null && (
                            <>
                              <div tw="flex flex-col flex-grow mr-10">
                                <p>Selecciona una meta</p>
                                <StyledSelectElement
                                  options={(
                                    [
                                      {
                                        text: "Selecciona una meta",
                                        value: "",
                                      },
                                    ] as OptionType[]
                                  ).concat(
                                    selectedOds.goals.map<{
                                      text: string;
                                      value: string | number;
                                    }>((ins) => ({
                                      text: ins.name,
                                      value: ins.id,
                                    }))
                                  )}
                                  selectedValue={selectedGoal?.toString() || ""}
                                  selectedValueChangeHandler={(e) =>
                                    setSelectedGoal(parseInt(e.target.value))
                                  }
                                />
                              </div>
                              <button
                                type="button"
                                tw="flex flex-row flex-wrap content-center bg-resies_purple py-1 my-2 rounded-sm max-h-24 mb-0"
                                onClick={() => {
                                  assignOdsGoal();
                                }}
                              >
                                <p tw="font-bold text-base text-ghostwhite mr-4 ml-4">
                                  Asignar
                                </p>
                              </button>
                            </>
                          )}
                        </div>

                        <div tw="flex-grow-0"></div>
                      </div>
                    </div>
                  )}
                  <VolverButton to={Routes.evaluation_instruments} />
                </>
              ) : (
                <>
                  <div tw="h-96" />
                  <VolverButton to={Routes.evaluation_instruments} />
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </CargandoScreen>
  );
}

export default ADMEditIndicators;
