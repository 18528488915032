import React, { ReactElement } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import PageTitle from "../../components/common/PageTitle";
import VolverButton from "../../components/common/VolverButton";
import InstitutionIndex from "../../components/common/InstitutionIndex";
import Routes from "../../routers/routes";

/**
 * La página que muestra otras instituciones al RDI
 */
function RDIInstitutionIndexPage(): ReactElement {
  return (
    <Container>
      <PageTitle title="Instituciones" />
      <InstitutionIndex />
      <VolverButton to={Routes.landing} />
    </Container>
  );
}

export default RDIInstitutionIndexPage;
