/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useContext, useState } from "react";
import "twin.macro";
import { Prompt } from "react-router";
import TitleIndicatorForm from "./TitleIndicatorForm";
import IndicatorForm from "./IndicatorForm";
import IndicatorList from "./IndicatorList";
import { AppContext } from "../../../dispatcher";
import VolverButton from "../VolverButton";
import fetchAPI, { APIActions, APIRoutes } from "../../../utils/fetchAPI";
import Routes from "../../../routers/routes";
import {
  PostResponseVariable,
  PutResponseVariable,
  Category,
  Indicator,
} from "../../../types/backendTypes";
import useRESIESDates from "../../../hooks/useRESIESDates";

/**
 * Menu principal para modificar datos de los indicadores.
 *
 * @param props react props.
 * @param props.indicators Diccionario que contiene los nombres de los indicadores de cada categoria.
 * @param props.selected Corresponde al nombre de la categoria actualmente seleccionada.
 * @param props.indicatorSelected State del indicador actualmente seleccionado.
 * @param props.setIndicatorSelected Corresponde al setState de el indicador seleccionado.
 * @param props.indicatorForm Diccionario que muestra los datos del formulario de cada variable.
 * @param props.indicatorsName Diccionario que contiene los nombres de cada indicador.
 * @param props.setReady setter de ready
 * @param props.setIndicatorForm setter de formulario de indicador
 * @param props.reload state que cambia cada vez que se presiona el boton guardar para recargar la pagina
 * @param props.setReload setter para realizar un reload de la pagina.
 */
function MainIndicatorsMenu(props: {
  category: Category;
  indicatorSelected: Indicator;
  indicatorForm: Record<string, string>[];
  setReady: React.Dispatch<React.SetStateAction<boolean>>;
  setIndicatorForm: React.Dispatch<
    React.SetStateAction<Record<string, string>[]>
  >;
}): ReactElement {
  const {
    category,
    indicatorSelected,
    indicatorForm,
    setIndicatorForm,
    setReady,
  } = props;
  const [appState] = useContext(AppContext);
  const [changes, setChanges] = useState(false);
  const [activeVersion, currentProcess, currentInstitutionProcess] =
    useRESIESDates();

  category.indicators?.forEach((indicator) => {
    indicator.category_id = category.id;
  });

  const getActualCurrentProcess = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess.current_process;
    }

    return currentProcess;
  };

  /**
   * Función que maneja los cambios de estado cuando se producen cambios en los formularios.
   *
   * @param key llave
   * @param e evento
   */
  function changeHandler(
    key: number,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) {
    const copy = [...indicatorForm];
    copy[key][e.target.name] = e.target.value;
    copy[key].modified = "yes";
    setChanges(true);
    setIndicatorForm(copy);
  }

  /**
   * Función que envía los de los formularios al backend.
   */
  function onSaveHandler(indicator: Indicator) {
    if (!appState.token || !activeVersion) return;
    setReady(false);
    const newResponses: PostResponseVariable[] = indicatorForm
      .filter((form) => !form.completed && form.modified)
      .map((form) => {
        return {
          variable_id: parseInt(form.variable_id, 10),
          response: form.value,
          omitted: !!form.value,
          resies_version: activeVersion.version_name,
          description: form.description,
        };
      });
    const updatedResponses: PutResponseVariable[] = indicatorForm
      .filter((form) => form.completed && form.modified)
      .map((form) => {
        return {
          response: form.value,
          omitted: !!form.value,
          id: parseInt(form.response_id, 10),
          resies_version: activeVersion.version_name,
          description: form.description,
        };
      });
    fetchAPI<APIActions["postVariables"]>(
      APIRoutes.postVariables,
      {
        method: "POST",
        body: newResponses,
      },
      appState.token
    ).then(() => {
      if (!appState.token) return;
      fetchAPI<APIActions["putVariables"]>(
        APIRoutes.putVariables,
        {
          method: "PUT",
          body: updatedResponses,
        },
        appState.token
      )
        .then(() => {
          window.location.href = `/my-institution/indicators?category=${category.id}&indicator=${indicator.id}`;
        })
        .catch(() => {});
    }); // TODO
  }

  return (
    <div>
      <div tw="text-darkgray font-semibold flex h-full bg-ghostwhite">
        {category.indicators === undefined ||
        !Object.keys(category.indicators).length ? (
          <div tw="bg-ghostwhite flex flex-col justify-center w-full">
            <div tw="flex flex-wrap h-screen justify-center content-center">
              <p tw="text-header2 font-semibold">
                No tienes indicadores asignados
              </p>
              <div>
                <VolverButton to={Routes.landing} />
              </div>
            </div>
          </div>
        ) : (
          <div tw="flex flex-row w-full">
            <Prompt
              when={changes}
              message="Tienes cambios sin guardar, ¿seguro que quieres abandonar la pagina?"
            />
            <IndicatorList
              indicators={category.indicators || []}
              indicatorSelected={indicatorSelected}
              formState={indicatorForm}
            />
            <div tw="flex flex-col flex-grow bg-ghostwhite relative w-full">
              <TitleIndicatorForm
                indicatorSelected={indicatorSelected}
                onSaveHandler={onSaveHandler}
                formState={indicatorForm}
                changes={changes}
              />
              <div tw="flex flex-col bg-ghostwhite overflow-y-scroll mb-10 h-screen">
                {indicatorForm
                  .filter((elem) => elem.name === indicatorSelected.slug)
                  .map((elem) => (
                    <IndicatorForm
                      formState={indicatorForm}
                      elem={elem}
                      changeHandler={changeHandler}
                      indicatorForm={indicatorForm}
                      key={elem.slug}
                      disabled={
                        !(
                          getActualCurrentProcess()?.name ===
                            "Llenado de Indicadores" ||
                          getActualCurrentProcess()?.name ===
                            "Rectificación de Objetados"
                        )
                      }
                    />
                  ))}
              </div>
              <VolverButton
                to={
                  appState.userType === "RDI"
                    ? Routes.myInstitution
                    : Routes.landing
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MainIndicatorsMenu;
