import React, { ReactElement } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Footer from "../components/common/Footer";
import LoginNavbar from "../components/common/LoginNavbar";
import Navbar from "../components/common/Navbar";
import AUDEvidenceView from "../pages/aud/AUDEvidenceView";
import AUDIndicatorsView from "../pages/aud/AUDIndicatorsView";
import AUDInstitutionData from "../pages/aud/AUDInstitutionalData";
import AUDInstitutionalEvidenceView from "../pages/aud/AUDInstitutionEvidenceView";
import AUDMyInstitutions from "../pages/aud/AUDMyInstitutionsView";
import MyProfileAUD from "../pages/aud/MyProfileAUD";
import ChangePasswordPage from "../pages/common/ChangePasswordPage";
import Routes from "./routes";
import AUDInstitutionReport from "../pages/aud/AUDInstitutionReport";
import AUDHistoricInstitutionReport from "../pages/aud/AUDHistoricInstitutionReport";
import AUDODSInstitutionReport from "../pages/aud/AUDODSInstitutionReport";

/**
 * El router del usuario auditor
 *
 * Al haber iniciado sesión como auditor, este es el router que se muestra
 */
function AUDRouter(): ReactElement {
  return (
    <Router>
      <LoginNavbar />
      <Navbar
        items={[{ name: "Tus instituciones", href: Routes.myInstitutions }]}
      />
      <div tw="bg-ghostwhite">
        <Switch>
          <Route exact path={Routes.landing}>
            {/* TODO: landing page */}
            <Redirect to={Routes.myInstitutions} />
          </Route>
          <Route exact path={Routes.AUDindicators}>
            <AUDIndicatorsView />
          </Route>
          <Route exact path={Routes.AUDevidence}>
            <AUDEvidenceView />
          </Route>
          <Route exact path={Routes.myInstitutions}>
            <AUDMyInstitutions />
          </Route>
          <Route exact path={Routes.myProfile}>
            <MyProfileAUD />
          </Route>
          <Route exact path={Routes.changePassword}>
            <ChangePasswordPage />
          </Route>
          <Route exact path={Routes.audInstitutionData}>
            <AUDInstitutionData />
          </Route>
          <Route exact path={Routes.audInstitutionReport}>
            <AUDInstitutionReport />
          </Route>
          <Route exact path={Routes.audInstitutionHistoricReport}>
            <AUDHistoricInstitutionReport />
          </Route>
          <Route exact path={Routes.audInstitutionODSReport}>
            <AUDODSInstitutionReport />
          </Route>
          <Route exact path={Routes.audInstitutionalEvidence}>
            <AUDInstitutionalEvidenceView />
          </Route>
          <Redirect to={Routes.landing} />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default AUDRouter;
