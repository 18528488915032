import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import { useHistory, useParams } from "react-router-dom";
import Container from "../../components/common/Container";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import { AppContext, AppState, Dispatcher } from "../../dispatcher";
import PageTitleSubtitle from "../../components/common/PageTitleSubtitle";
import CargandoScreen from "../../components/common/CargandoScreen";

/**
 * Página para eliminar una institución
 */
function ADMDeleteInstitution(): ReactElement {
  const { institutionId } = useParams<{ institutionId: string }>();
  const history = useHistory();
  const [appState]: [AppState, Dispatcher] = useContext(AppContext);
  const [institutionName, setInstitutionName] = useState("");
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["fetchInstitutionDetails"]>(
      APIRoutes.fetchInstitutionDetails,
      { method: "GET", routeParams: { institution_id: institutionId } },
      appState.token
    )
      .then((details) => {
        setInstitutionName(details.institution.description);
        setReady(true);
      })
      .catch(() => {}); // TODO
  }, [appState, institutionId]);
  /**
   * Borrar institución
   */
  function deleteInstitution() {
    if (appState.token) {
      fetchAPI<APIActions["deleteInstitution"]>(
        APIRoutes.deleteInstitution,
        { method: "DELETE", routeParams: { institution_id: institutionId } },
        appState.token
      ).then(() => {
        history.push(Routes.admPanelInstitutions);
      });
    }
  }
  return (
    <CargandoScreen ready={ready}>
      <Container>
        <PageTitleSubtitle
          title={institutionName}
          subtitle="ELIMINAR INSTITUCIÓN"
        />
        <div tw="w-full">
          <div tw="h-96 flex flex-wrap flex-col content-center justify-center">
            <div tw="text-header2 font-bold py-5">
              Estás seguro que deseas borrar {institutionName}?
            </div>
            <div tw="flex flex-row justify-center">
              <button
                tw="py-2 px-4 bg-darkred text-white w-auto rounded-md"
                onClick={deleteInstitution}
                type="button"
              >
                Borrar
              </button>
            </div>
          </div>
        </div>
        <VolverButton
          to={Routes.admInstitutionsView.replace(
            ":institutionId",
            institutionId.toString()
          )}
        />
      </Container>
    </CargandoScreen>
  );
}

export default ADMDeleteInstitution;
