import React, { ReactElement } from "react";
import "twin.macro";
import { Subcategory } from "../../../../../types/backendTypes";
import { CategoryStatus } from "../../../../../types/utilTypes";

/**
 * Componente que muestra el titulo de la vista de edición de indicadores.
 *
 * @param props react props
 * @param props.category Categoria que esta actualmente en la vista
 * @param props.errorMessage Tipo de error que se debe mostrar si hay algo en modo de edición.
 * @param props.onSave Función que manda los datos al backend si es que no hay nada en modo de edición.
 * @param props.changes changes
 * @param props.subCategory subcategoria
 */
function ADMEditIndicatorsTitle(props: {
  category: CategoryStatus;
  errorMessage: string;
  onSave: () => void;
  changes: boolean;
  subCategory: Subcategory | undefined;
}): ReactElement {
  const { category, errorMessage, onSave, changes, subCategory } = props;
  return (
    <>
      <div tw="bg-resies_lightgreen font-sans text-header1 font-bold text-resies_blue1 py-2 px-6 flex flex-row">
        {category?.name} {">"} {subCategory?.name}
        {/*
        <button
          type="button"
          tw="w-2/12 my-auto flex flex-row"
          onClick={() => onDeleteHandler()}
        >
           
          <img
            src="/trash.png"
            alt="trash"
            tw="object-contain my-auto ml-2 mr-2"
          />
          <p tw="text-body font-normal text-resies_purple">
            Eliminar categoría
          </p>
        </button>
        */}
      </div>
      <div tw="flex justify-end flex-row">
        <p tw="flex flex-wrap content-center text-darkred mr-2 text-header2 font-bold">
          {changes ? `Hay cambios sin guardar ${errorMessage}` : ""}
        </p>
        <button
          type="button"
          tw="flex flex-row flex-wrap content-center bg-resies_purple py-1 my-2 rounded-sm max-h-12"
          onClick={onSave}
        >
          <img src="/save.png" alt="save" tw="mr-4 ml-2" />
          <p tw="font-bold text-base text-ghostwhite mr-4">Guardar Cambios</p>
        </button>
      </div>
    </>
  );
}

export default ADMEditIndicatorsTitle;
