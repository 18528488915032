import React, { ReactElement, useContext, useState } from "react";
import { useForm } from "react-hook-form";
// eslint-disable-next-line
import "twin.macro";
import { Link, Redirect, useHistory } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppContext, AppState, Dispatcher } from "../../dispatcher";
import VolverButton from "../common/VolverButton";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import Routes from "../../routers/routes";
import LoadingFormButton from "../common/LoadingFormButton";
import setLoginInfo from "../../utils/setLoginInfo";
// eslint-disable-next-line import/no-cycle
import usePasswordToggle from "../../hooks/common/usePasswordToggle";

type FormInputs = {
  email: string;
  password: string;
};

/**
 * Formulario para realizar el login.
 */
function AnonLoginForm(): ReactElement {
  const [appState, appDispatch]: [AppState, Dispatcher] =
    useContext(AppContext);
  const history = useHistory();
  const { register, handleSubmit, formState } = useForm<FormInputs>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inputType, icon] = usePasswordToggle();
  /**
   * Funcion que envía los datos del form al backend
   *
   * @param creds credenciales
   */
  function onSubmit(creds: FormInputs) {
    setLoading(true);
    fetchAPI<APIActions["fetchLogin"]>(APIRoutes.fetchLogin, {
      method: "POST",
      body: { email: creds.email, password: creds.password },
    })
      .then((data) => {
        setLoginInfo(
          data,
          setError,
          appDispatch,
          history as unknown as History
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }

  // eslint-disable-next-line
  return (
    <div tw="flex justify-center mb-10 bg-ghostwhite">
      {appState.token !== null ? <Redirect to={Routes.landing} /> : null}
      <div tw="w-5/12 pt-20 pb-10">
        <form onSubmit={handleSubmit(onSubmit)} tw="mb-5">
          <div tw="mb-2">
            <label htmlFor="email" tw="block text-2xl">
              Usuario
            </label>
            <input
              tw="border border-black rounded text-2xl p-2 w-full"
              {...register("email", { required: true })}
            />
            {formState.errors.email && <span>Este campo es obligatorio</span>}
          </div>
          <div tw="mb-4">
            <label htmlFor="password" tw="block text-2xl">
              Contraseña
            </label>
            <div tw="relative">
              <input
                tw="border border-black rounded text-2xl p-2 w-full"
                style={{ paddingRight: "40px" }}
                {...register("password", { required: true })}
                type={inputType}
              />
              <div tw="absolute top-0 right-0 h-full">
                <div tw="flex h-full justify-center content-center p-3 text-xs">
                  {icon}
                </div>
              </div>
            </div>
            {formState.errors.password && (
              <span>Este campo es obligatorio</span>
            )}
            {error ? <p>Credenciales inválidas</p> : <p />}
          </div>
          <Link to={Routes.forgotPassword}>
            <p tw="text-black hover:text-blue-400">¿Olvidaste tu contraseña?</p>
          </Link>
          <LoadingFormButton
            loading={loading}
            label="Ingresar"
            className="rounded text-white bg-resies_blue1 py-1 px-4 text-2xl"
          />
        </form>
        <VolverButton to={Routes.landing} />
      </div>
    </div>
  );
}

export default AnonLoginForm;
