import React, { ReactElement } from "react";
import "twin.macro";
import { useHistory } from "react-router-dom";

/**
 * Elemento de la navbar de la vista de indicadores.
 *
 * @param props react props.
 * @param props.name Nombre de la categoria que se mostrara en este elemento del navbar.
 * @param props.selected Corresponde al nombre de la categoria actualmente seleccionada.
 * @param props.setSelected Corresponde al setState de la categoria seleccionada.
 * @param props.setIndicatorSelected Corresponde al setState de el indicador seleccionado.
 * @param props.indicators Diccionario que contiene los nombres de los indicadores de cada categoria.
 */
function AUDCategorieNavbarElement(props: {
  name: string;
  selected: string;
  position: number;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  setIndicatorSelected: React.Dispatch<React.SetStateAction<string>>;
  indicators: Record<string, string[]>;
}): ReactElement {
  const {
    name,
    selected,
    position,
    setSelected,
    setIndicatorSelected,
    indicators,
  } = props;
  const history = useHistory();

  /**
   * Función que maneja el cambio de categoria seleccionada e indicador seleccionado al cambiar de categoria.
   */
  function clickHandler() {
    setSelected(name);
    setIndicatorSelected(indicators[name][0]);
    history.push(`${history.location.pathname}?tab=${position + 1}`);
  }

  return (
    <button
      type="button"
      className={
        selected === name
          ? "text-resies_blue2 w-full text-xs md:text-xs xl:text-xl lg:px-3 px-0 py-3 font-semibold bg-resies_lightgreen "
          : "text-black w-full text-xs md:text-xs xl:text-xl lg:px-3 px-0 py-3 font-semibold opacity-50"
      }
      onClick={clickHandler}
    >
      {name}
    </button>
  );
}

export default AUDCategorieNavbarElement;
