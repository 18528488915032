import React, { ReactElement, useState } from "react";
import "twin.macro";
import EditCategories from "../../components/adm/EvalInstruments/EditEvalInstruments/EditCategories";
import EditInstitutionCategories from "../../components/adm/EvalInstruments/EditInstitutionInstruments/EditInstitutionCategories";
import NavButton from "../../components/adm/EvalInstruments/NavButton";
import Container from "../../components/common/Container";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import EditOds from "../../components/adm/EvalInstruments/EditEvalInstruments/EditOds";

/**
 * Vista de instrumentos de evaluacion del admin
 */
function ADMEvalInstruments(): ReactElement {
  const [navSelected, setNavSelected] = useState(1);
  /**
   * Funcion que maneja el cambio de nav seleccionada
   *
   * @param e Evento que desencadena la función.
   */
  function onClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (e.currentTarget.name === "nav1") {
      setNavSelected(1);
    } else if (e.currentTarget.name === "nav2") {
      setNavSelected(2);
    }
  }
  return (
    <Container>
      <div tw="bg-resies_lightgreen font-sans text-header1 font-bold text-resies_blue1 py-2 px-6">
        Instrumento
      </div>
      <div tw="flex flex-row h-10">
        <NavButton
          navSelected={navSelected}
          onClick={onClick}
          buttonName="nav1"
          buttonNumber={1}
          label="Editar Items"
        />
        <NavButton
          navSelected={navSelected}
          onClick={onClick}
          buttonName="nav2"
          buttonNumber={2}
          label="Editar Items Institucionales"
        />
      </div>
      {navSelected === 1 ? <EditCategories /> : <EditInstitutionCategories />}

      {navSelected === 1 && <EditOds />}
      <VolverButton to={Routes.landing} />
    </Container>
  );
}

export default ADMEvalInstruments;
