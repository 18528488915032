import { faEdit, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useContext, useRef, useState } from "react";
import "twin.macro";
import { AppContext } from "../../../dispatcher";
import { ResponseComment } from "../../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../../utils/fetchAPI";

/**
 * Un comentario de las lista de comentarios de una respuesta
 *
 * @param props react props
 * @param props.comm El comentario a mostrar
 * @param props.reloadComments Recarga los comentarios
 */
export default function SingleComment(props: {
  comm: ResponseComment;
  reloadComments: () => void;
}): ReactElement {
  const { comm, reloadComments } = props;
  const [state] = useContext(AppContext);
  const [editing, setEditing] = useState(false);
  const ref = useRef<HTMLTextAreaElement>(null);

  /**
   * Activa la edición de un comentario o guarda los cambios
   */
  function changeComment() {
    if (!state.token) return;
    if (editing) {
      if (!ref.current) return;
      fetchAPI<APIActions["editInstitutionalResponseComment"]>(
        APIRoutes.editInstitutionalResponseComment,
        {
          method: "PUT",
          routeParams: { response_comment_id: comm.id.toString() },
          body: {
            content: ref.current.value,
          },
        },
        state.token
      ).then(() => {
        setEditing(!editing);
        reloadComments();
      });
    } else {
      setEditing(!editing);
    }
  }
  /**
   * Borra un comentario
   */
  function deleteComment() {
    if (!state.token) return;
    fetchAPI<APIActions["deleteInstitutionalResponseComment"]>(
      APIRoutes.editInstitutionalResponseComment,
      {
        method: "DELETE",
        routeParams: { response_comment_id: comm.id.toString() },
      },
      state.token
    ).then(() => {
      reloadComments();
    });
  }
  return (
    <div tw="flex flex-row my-4 w-full" key={comm.id}>
      <div tw="h-12 w-12 mr-3 flex">
        <img
          src={comm.user_photo_url || "/blank_avatar.png"}
          tw="object-cover"
          alt="profile"
        />
      </div>
      <div tw="flex flex-col w-full">
        <div tw="text-barra font-bold w-full flex flex-row">
          <div tw="flex flex-row justify-between w-full">
            <div tw="">
              <div tw="w-auto inline-block">{comm.user_name}</div>
              {comm.user_user_type && (
                <div tw="border border-warning_yellow rounded-md px-2 ml-4 text-black flex-grow w-auto inline-block">
                  {comm.user_user_type}
                </div>
              )}
              {comm.type && (
                <div tw="border border-resies_purple rounded-md px-2 ml-4 flex-grow w-auto inline-block">
                  {comm.type}
                </div>
              )}
            </div>
          </div>
          <div tw="flex flex-row justify-end w-full pr-10">
            {comm.user_id.toString() === state.userId && (
              <>
                <button
                  type="button"
                  tw="px-4 bg-resies_blue2 text-ghostwhite rounded-md"
                  onClick={changeComment}
                >
                  {editing ? (
                    <FontAwesomeIcon icon={faSave} />
                  ) : (
                    <FontAwesomeIcon icon={faEdit} />
                  )}
                </button>
                <button
                  type="button"
                  tw="px-4 bg-darkred text-ghostwhite rounded-md ml-4"
                  onClick={deleteComment}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </>
            )}
          </div>
        </div>
        {!editing && <p tw="text-body font-normal">{comm.content}</p>}
        {editing && <textarea tw="" defaultValue={comm.content} ref={ref} />}
      </div>
    </div>
  );
}
