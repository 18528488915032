import fetchAPI, { APIActions, APIRoutes } from "../fetchAPI";

/**
 * Busca todas las categorías y luego todos los indicadores de cada categoría
 *
 * @param setData setter de datos
 * @param setIndicators setter de indicadores
 * @param token token
 * @param setSelected setter de seleccion
 * @param setIndicatorSelected setter de seleccion de indicador
 * @param totalReload parametro para ver si se debe hacer un reload completo
 */
function getData(
  setData: React.Dispatch<React.SetStateAction<string[]>>,
  setIndicators: React.Dispatch<React.SetStateAction<Record<string, string[]>>>,
  token: string | null,
  setSelected: React.Dispatch<React.SetStateAction<string>>,
  setIndicatorSelected: React.Dispatch<React.SetStateAction<string>>,
  totalReload: boolean
): void {
  let apiKey = "";
  if (token !== null) {
    apiKey = token;
  }
  const categories: string[] = [];
  if (apiKey) {
    fetchAPI<APIActions["fetchCategories"]>(
      APIRoutes.fetchCategories,
      {
        method: "GET",
      },
      apiKey
    )
      .then((data) => {
        data.sort((a, b) => a.id - b.id);
        const inds: Record<string, any[]> = {};

        data.forEach((element) => {
          categories.push(element.name);
          inds[element.name] =
            element.indicators?.map((indicator) => indicator.slug) || [];
        });

        setData(categories);
        setIndicators(inds);

        if (totalReload) {
          setSelected(categories[0]);
          const key = Object.keys(inds)[0];
          setIndicatorSelected(inds[key][0]);
        }
        /*getIndicators(
          data,
          setIndicators,
          apiKey,
          setIndicatorSelected,
          totalReload
        );*/
      })
      .catch(() => {}); // TODO
  }
}

export function getCategories(token: string, callback: Function): void {
  if (token) {
    fetchAPI<APIActions["fetchCategories"]>(
      APIRoutes.fetchCategories,
      {
        method: "GET",
      },
      token
    )
      .then((data) => {
        callback(data);
      })
      .catch(() => {}); // TODO
  }
}

export default getData;
