import React, { ReactElement } from "react";
import Editor from "react-simple-code-editor";
import { highlightFormula } from "../../utils/parser/parser";
import { AuxiliarFormula } from "../../types/backendTypes";
import "./editorThemes/theme.css";

/**
 * Editor de formulas basico
 *
 * @param props react props
 * @param props.code El código del editor
 * @param props.setCode El handler de cambio de código
 * @param props.auxiliarFormulas Las fórmulas auxiliares usadas hasta ahora
 * @param props.variablesSet Una set con strings representando variables del indicador
 * @param props.institutionalVariablesSet Un set con string representando los datos de la institución
 */
function FormulaEditor(props: {
  code: string;
  setCode: (s: string) => void;
  auxiliarFormulas?: AuxiliarFormula[];
  variablesSet?: Set<string>;
  institutionalVariablesSet?: Set<string>;
}): ReactElement {
  const {
    code,
    setCode,
    auxiliarFormulas,
    variablesSet,
    institutionalVariablesSet,
  } = props;
  /**
   * El handler de cambio del código
   *
   * @param new_code El código actualizado después de cambiarlo
   */
  function onValueChange(new_code: string) {
    setCode(new_code);
  }

  return (
    <div tw="border border-gray">
      <Editor
        value={code}
        onValueChange={onValueChange}
        // highlight={(new_code) => `<span>${new_code}</span>`}
        highlight={(new_code) => {
          return highlightFormula(
            new_code,
            auxiliarFormulas,
            variablesSet,
            institutionalVariablesSet
          );
        }}
        className="w-auto border border-gray bg-ghostwhite rounded-md"
        padding={10}
        style={{
          minHeight: "100px",
        }}
        placeholder="Ingrese una fórmula..."
      />
    </div>
  );
}

export default FormulaEditor;
