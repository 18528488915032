/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-plusplus */
import { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import { AppContext } from "../../dispatcher";
import { Institution } from "../../types/backendTypes";
import { OdsScorePattern } from "../../types/utilTypes";
import useRESIESDates from "../../hooks/useRESIESDates";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  Pie,
  PieChart,
  LabelList,
} from "recharts";
import getOdsScoreByInstitution from "../../utils/data/AdmData/getOdsScoreByInstitution";
import { useParams } from "react-router-dom";

const RDIOdsReport = (): ReactElement => {
  const [ready, setReady] = useState(false);
  const [state] = useContext(AppContext);
  const [institution, setInstitution] = useState<Institution>();
  const [institutionScores, setInstitutionScores] = useState<OdsScorePattern>();
  const [, , currentInstitutionProcess] = useRESIESDates();
  const { institutionId } = useParams<{ institutionId: string }>();

  useEffect(() => {
    if (!state.token) return;

    if (currentInstitutionProcess) {
      setInstitution(currentInstitutionProcess.institution);
      getOdsScoreByInstitution(
        currentInstitutionProcess.institution.id,
        state,
        setInstitutionScores,
        setReady
      );
    }
  }, [currentInstitutionProcess, state]);

  const getDataForBarChart = () => {
    const data: Array<Record<string, any>> = [];

    if (institutionScores !== undefined) {
      for (let i = 1; i < 18; i++) {
        const ods_key = `ODS-${i}`;
        const ods_score = institutionScores.ods[ods_key];

        if (ods_score) {
          data.push({
            name: ods_key,
            puntaje: (ods_score.score / ods_score.max_score) * 100,
          });
        } else {
          data.push({ name: ods_key, puntaje: 0 });
        }
      }
    }

    return data;
  };

  const getDataForRadialChart = () => {
    if (institutionScores !== undefined) {
      let weightedSum: number = 0;
      const constructor = Object.entries(institutionScores.ods).map(
        ([ods, score]) => {
          weightedSum += score.score * 100;
          if (score.score === 0) {
            return null;
          }
          return {
            name: ods,
            value: score.score * 100,
            color: score.color,
          };
        }
      );

      if (100 - weightedSum > 0) {
        constructor.push({
          name: "Sin avance",
          value: 100 - weightedSum,
          color: "#a2a2a2",
        });
      }

      return constructor.filter((elem) => elem !== null);
    }

    return [];
  };

  const getDataForRelativeRadialChart = () => {
    if (institutionScores !== undefined) {
      const constructor = [];
      for (let i = 1; i < 18; i++) {
        const ods_key = `ODS-${i}`;
        const ods_score = institutionScores.ods[ods_key];

        if (ods_score) {
          constructor.push({
            name: ods_key,
            value: ods_score.max_score * 100,
            color: ods_score.color,
          });
        }
      }

      return constructor;
    }

    return [];
  };

  const formatNum = (value: number) => {
    return `${value.toFixed(2)}%`;
  };

  const allODS = () => {
    const ods = [];
    for (let i = 1; i < 18; i++) {
      ods.push(`ODS-${i}`);
    }
    return ods;
  };

  const calculateTotalODSScore = () => {
    if (institutionScores !== undefined) {
      const scores: number[] = Object.entries(institutionScores.ods).map(
        ([ods, score]) => score.score
      );
      return scores.reduce((sum, val) => sum + val);
    }

    return 0;
  };

  return (
    <Container>
      <CargandoScreen ready={ready}>
        {institution && institutionScores !== undefined && (
          <div id="vista-principal" tw="flex flex-col justify-around">
            <div
              id="caja-superior"
              tw="bg-resies_lightgreen flex flex-row text-resies_blue1 justify-between"
            >
              <p tw="font-bold text-xl m-10 ml-5 print:ml-5">Agenda 2030</p>
            </div>

            <div tw="overflow-x-scroll ">
              <div tw="flex mb-4 print:flex-col">
                <div tw="flex-1 print:flex-1 print:w-full m-5">
                  <div tw="flex print:overflow-x-visible print:pl-5">
                    <div>
                      <h3 tw="mb-4">
                        <strong>Puntaje ponderado ODS</strong>
                      </h3>

                      <PieChart
                        width={400}
                        height={420}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 70,
                          bottom: 0,
                        }}
                      >
                        <Pie
                          isAnimationActive={false}
                          data={getDataForRadialChart()}
                          cx="50%"
                          cy="50%"
                          innerRadius={80}
                          dataKey="value"
                          fill="#8884d8"
                          nameKey="name"
                          label={false}
                          labelLine={true}
                        >
                          {getDataForRadialChart().map((entry, index) => (
                            <Cell fill={entry?.color} />
                          ))}
                        </Pie>
                        <Tooltip
                          formatter={(value: number) => `${value.toFixed(2)}%`}
                        />
                      </PieChart>

                      <p>
                        Este gráfico muestra el puntaje RESIES, pero distribuido
                        en los Objetivos del Desarrollo Sostenible (ODS) de la
                        Agenda 2030. Para calcularlo, se transforman los
                        puntajes brutos de cada indicador RESIES al equivalente
                        en su aporte a las metas de los ODS. Luego estos aportes
                        se suman para obtener los resultados por cada Objetivo.
                      </p>
                    </div>
                  </div>
                </div>

                <div tw="flex-1 print:flex-1 print:w-full m-5">
                  <div tw="flex print:overflow-x-visible print:pl-5">
                    <div>
                      <h3 tw="mb-4">
                        <strong>Puntaje relativo para cada ODS</strong>
                      </h3>

                      <BarChart
                        width={500}
                        height={420}
                        data={getDataForBarChart()}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 0,
                          bottom: 5,
                        }}
                        layout="vertical"
                      >
                        <CartesianGrid strokeDasharray="4 4" />
                        <XAxis type="number" domain={[0, 100]} />
                        <YAxis type="category" dataKey="name" interval={0} />
                        <Tooltip
                          formatter={(value: number) => `${value.toFixed(2)}%`}
                        />
                        <Bar dataKey="puntaje" isAnimationActive={false}>
                          {/* @ts-ignore The typings are incorrect */}
                          <LabelList formatter={formatNum} position="right" />
                          {Object.entries(institutionScores.ods).map(
                            ([ods, score], index) => (
                              <Cell key={`cell-${index}`} fill={score.color} />
                            )
                          )}
                        </Bar>
                      </BarChart>

                      <p>
                        Este gráfico muestra cómo se ha avanzado en cada ODS en
                        el contexto de RESIES. Es decir, cada barra indica el
                        avance relativo (como porcentaje) que se ha logrado del
                        total de aportes que puede dar RESIES al ODS específico.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div tw="flex mb-4 print:flex-col">
                <div tw="flex-1 print:flex-1 print:w-full m-5">
                  <table tw="my-2">
                    <tbody tw="flex flex-col items-center justify-between w-full">
                      <tr tw="flex border-b">
                        <td tw="w-64">
                          <strong>
                            Objetivos del desarrollo sostenible (ODS)
                          </strong>
                        </td>
                        <td tw="w-20 text-center">
                          <strong>Aporte</strong>
                        </td>
                        <td tw="w-20 text-center">
                          <strong>Aporte máximo</strong>
                        </td>
                        <td tw="w-20 text-center">
                          <strong>Avance relativo de cada ODS</strong>
                        </td>
                      </tr>
                      {allODS().map((ods) => (
                        <tr tw="flex border-b">
                          <td
                            tw="w-64"
                            style={{
                              backgroundColor: `${
                                institutionScores.ods[ods]
                                  ? institutionScores.ods[ods].color
                                  : "#e5243b"
                              }`,
                            }}
                          >
                            {`${
                              institutionScores.ods[ods]
                                ? institutionScores.ods[ods].name
                                : "ODS-1 Fin de la pobreza"
                            }`}
                          </td>
                          <td tw="w-20 text-center">
                            {institutionScores.ods[ods]
                              ? (
                                  institutionScores.ods[ods].score * 100
                                ).toFixed(2)
                              : "0.00"}
                          </td>
                          <td tw="w-20 text-center">
                            {institutionScores.ods[ods]
                              ? (
                                  institutionScores.ods[ods].max_score * 100
                                ).toFixed(2)
                              : "0.00"}
                          </td>
                          <td tw="w-20 text-center">
                            {institutionScores.ods[ods]
                              ? (
                                  (institutionScores.ods[ods].score /
                                    institutionScores.ods[ods].max_score) *
                                  100
                                ).toFixed(2)
                              : "0.00"}
                          </td>
                        </tr>
                      ))}
                      <tr tw="flex border-b">
                        <td tw="w-64">
                          <strong>Total</strong>
                        </td>
                        <td tw="w-20 text-center">
                          <strong>
                            {(calculateTotalODSScore() * 100).toFixed(2)}
                          </strong>
                        </td>
                        <td tw="w-20 text-center">
                          <strong>100</strong>
                        </td>
                        <td tw="w-20 text-center">
                          <strong>
                            {(calculateTotalODSScore() * 100).toFixed(2)}%
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    <strong>Aporte</strong>: puntaje RESIES aportado a cada ODS.
                    <br />
                    <strong>Aporte máximo</strong>: puntaje máximo que podría
                    aportar RESIES a cada ODS.
                    <br />
                    <strong>Avance relativo de cada ODS</strong>: avance con
                    respecto al aporte máximo posible para cada ODS
                  </p>
                </div>

                <div tw="flex-1 print:flex-1 print:w-full m-5">
                  <div tw="flex print:overflow-x-visible print:pl-5">
                    <div>
                      <p>
                        Una institución que logra 100 puntos en RESIES
                        alcanzaría el aporte máximo en cada ODS. Para
                        referencia, esta situación equivale al siguiente gráfico
                        circular:
                      </p>

                      <PieChart
                        width={400}
                        height={420}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 70,
                          bottom: 0,
                        }}
                      >
                        <Pie
                          isAnimationActive={false}
                          data={getDataForRelativeRadialChart()}
                          cx="50%"
                          cy="50%"
                          innerRadius={80}
                          dataKey="value"
                          fill="#8884d8"
                          nameKey="name"
                          label={false}
                          labelLine={true}
                        >
                          {getDataForRelativeRadialChart().map(
                            (entry, index) => (
                              <Cell fill={entry?.color} />
                            )
                          )}
                        </Pie>
                        <Tooltip
                          formatter={(value: number) => `${value.toFixed(2)}%`}
                        />
                      </PieChart>

                      <p>
                        Este gráfico se compone de los aportes máximos de la
                        tabla izquierda.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CargandoScreen>

      {institutionId !== undefined && (
        <>
          {institutionId === null || institutionId === "0" ? (
            <VolverButton to={Routes.myInstitutions} />
          ) : (
            <VolverButton
              to={Routes.audInstitutionReport.replace(
                ":institutionId",
                institutionId
              )}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default RDIOdsReport;
