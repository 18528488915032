import React, { ReactElement } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import PageTitleSubtitle from "../../components/common/PageTitleSubtitle";
import ChangePasswordForm from "../../components/common/ChangePasswordForm";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";

/**
 * La página para cambiar contraseña
 */
function ChangePasswordPage(): ReactElement {
  return (
    <Container>
      <PageTitleSubtitle title="Mi Perfil" subtitle="CAMBIAR CONTRASEÑA" />
      <div tw="ml-auto mr-auto w-1/2 mb-8">
        <ChangePasswordForm />
      </div>
      <VolverButton to={Routes.myProfile} />
    </Container>
  );
}

export default ChangePasswordPage;
