import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import Routes from "../../routers/routes";
/**
 * El título formateado de las páginas.
 *
 * @param props react props.
 * @param props.title El texto que deberá mostrarse como título
 * @param props.institutionId id de institución
 */
function PageTitle(props: {
  title: string;
  institutionId?: string;
}): ReactElement {
  const { title, institutionId } = props;
  return (
    <div tw="bg-resies_lightgreen font-sans text-header1 font-bold text-resies_blue1 py-2 px-6 mb-12 flex">
      {title}
      {institutionId ? (
        <Link to={Routes.rdieditInstitution}>
          <div tw="cursor-pointer font-normal text-body text-resies_purple">
            <FontAwesomeIcon icon={faEdit} color="#5b5b5b" tw="ml-4" /> Editar
            institución{" "}
          </div>
        </Link>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PageTitle;
