import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router";
import "twin.macro";
import NavButton from "../../components/adm/EvalInstruments/NavButton";
import AuxiliarFormulaEditor from "../../components/admin/AuxiliarFormulaEditor";
import FormulaEditor from "../../components/admin/FormulaEditor";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import PageTitle from "../../components/common/PageTitle";
import VolverButton from "../../components/common/VolverButton";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import {
  EvaluationVariable,
  Indicator,
  InstitutionVariables,
  AuxiliarFormula,
} from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import FormulaVisitor from "../../utils/parser/FormulaVisitor";
import parseFormula from "../../utils/parser/parser";

/**
 * Retorna el componente de input correcto dependiendo del tipo de input
 *
 * @param variable La variable
 * @param addVariableValue La función para cambiar el valor de prueba de una variable
 */
function getTestValueInput<T extends EvaluationVariable | InstitutionVariables>(
  variable: T,
  addVariableValue: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    variable: T
  ) => void
): ReactElement {
  if (variable.type === "MULTIPLE_CHOICE") {
    return (
      <select
        onChange={(e) => addVariableValue(e, variable)}
        tw="px-1 py-1 rounded-md border border-gray w-full"
      >
        <option value="">--</option>
        {variable.json_config.map((val) => (
          <option
            key={val.option_text}
            value={val.option_text}
            data-score={val.option_score}
          >
            {val.option_text}
          </option>
        ))}
      </select>
    );
  }
  return (
    <input
      tw="px-1 py-1 rounded-md border border-gray w-full"
      onChange={(e) => addVariableValue(e, variable)}
    />
  );
}

/**
 * La página para editar la fórmula de un indicador
 */
function ADMIndicatorFormula(): ReactElement {
  const [code, setCode] = useState<string>("");
  const [ready, setReady] = useState(false);
  const [indicator, setIndicator] = useState<Indicator>();
  const [variables, setVariables] = useState<EvaluationVariable[]>([]);
  const [institutionalData, setInstitutionalData] = useState<
    InstitutionVariables[]
  >([]);
  const [appState] = useContext(AppContext);
  const { subcategoryId } = useParams<{ subcategoryId: string }>();
  const { indicatorId } = useParams<{ indicatorId: string }>();
  const [auxiliarFormulas, setAuxiliarFormulas] = useState<
    Array<AuxiliarFormula>
  >([]);
  const [variableTestValues, setVariableTestValues] = useState<
    Record<string, number>
  >({});
  const [institutionalVariableTestValues, setInstitutionalVariableTestValues] =
    useState<Record<string, number>>({});
  const [testScore, setTestScore] = useState(0);
  const [finalFormula, setFinalFormula] = useState("");

  const [variablesSet, setVariablesSet] = useState<Set<string>>(
    new Set<string>()
  );
  const [institutionalDataSet, setInstitutionalDataSet] = useState<Set<string>>(
    new Set<string>()
  );

  // Errores
  const [syntaxErrors, setSyntaxErrors] = useState(false);
  const [noDefinedErrors, setNoDefinedErrors] = useState(false);
  const [auxVarsSyntaxErrors, setAuxVarsSyntaxErrors] = useState<string[]>([]);
  const [noVariablesInAuxError, setNoVariablesInAuxError] = useState(false);
  const [repeatedNamesError, setRepeatedNamesError] = useState(false);
  const [noBlankNamesError, setNoBlankNamesError] = useState(false);

  // Selector del lado derecho
  const [navSelected, setNavSelected] = useState(1);

  const fetchIndicator = useCallback(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["getIndicator"]>(
      APIRoutes.getIndicator,
      {
        method: "GET",
        routeParams: { indicator_id: indicatorId },
      },
      appState.token
    ).then((ind) => {
      if (!appState.token) return;
      setIndicator(ind);
      setCode(ind.unreplaced_formula);
      setAuxiliarFormulas(ind.auxiliary_formulas || []);
      fetchAPI<APIActions["fetchEvaluationVariables"]>(
        APIRoutes.fetchEvaluationVariables,
        { method: "GET" },
        appState.token
      ).then((vars) => {
        setVariables(
          vars.sort((a, b) => {
            if (a.indicator_id === b.indicator_id) {
              return a.id - b.id;
            }
            return a.indicator_slug.localeCompare(b.indicator_slug, undefined, {
              numeric: true,
              sensitivity: "base",
            });
          })
        );
        if (!appState.token) return;
        fetchAPI<APIActions["fetchInstitutionVariables"]>(
          APIRoutes.fetchInstitutionVariables,
          { method: "GET" },
          appState.token
        ).then((ins_data) => {
          setInstitutionalData(
            ins_data
              .filter((ins) => ins.type !== "FREE_TEXT")
              .sort((a, b) => a.id - b.id)
          );
          setReady(true);
        });
      });
    });
  }, [indicatorId, appState.token]);

  useEffect(() => {
    fetchIndicator();
  }, [fetchIndicator]);

  useEffect(() => {
    setInstitutionalDataSet(
      new Set(institutionalData.map((i) => `__di__${i.id}`))
    );
  }, [institutionalData]);

  useEffect(() => {
    setVariablesSet(new Set(variables.map((i) => `__var__${i.id}`)));
  }, [variables]);

  // Cambia el valor de prueba de una variable
  const addVariableValue = useCallback(
    (
      e: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
      variable: EvaluationVariable
    ) => {
      const newVariableTestValues: Record<string, number> = {
        ...variableTestValues,
      };
      if (variable.type === "MULTIPLE_CHOICE") {
        const option = variable.json_config.find(
          (val) => val.option_text === e.target.value
        );
        if (option) {
          newVariableTestValues[`__var__${variable.id}`] = option.option_score;
        } else if (`__var__${variable.id}` in variableTestValues) {
          delete newVariableTestValues[`__var__${variable.id}`];
        }
      } else if (e.target.value.match(/^([1-9][0-9]*|0)(\.[0-9]+)?$/)) {
        newVariableTestValues[`__var__${variable.id}`] = parseFloat(
          e.target.value
        );
      } else if (`__var__${variable.id}` in variableTestValues) {
        delete newVariableTestValues[`__var__${variable.id}`];
      }
      setVariableTestValues(newVariableTestValues);
    },
    [variableTestValues]
  );
  // Cambia el valor de prueba de una variable institucional
  const addInstitutionalVariableValue = useCallback(
    (
      e: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
      variable: InstitutionVariables
    ) => {
      const newVariableTestValues: Record<string, number> = {
        ...institutionalVariableTestValues,
      };
      if (variable.type === "MULTIPLE_CHOICE") {
        const option = variable.json_config.find(
          (val) => val.option_text === e.target.value
        );
        if (option) {
          newVariableTestValues[`__di__${variable.id}`] = option.option_score;
        } else if (`__di__${variable.id}` in institutionalVariableTestValues) {
          delete newVariableTestValues[`__di__${variable.id}`];
        }
      } else if (e.target.value.match(/^([1-9][0-9]*|0)(\.[0-9]+)?$/)) {
        newVariableTestValues[`__di__${variable.id}`] = parseFloat(
          e.target.value
        );
      } else if (`__di__${variable.id}` in variableTestValues) {
        delete newVariableTestValues[`__di__${variable.id}`];
      }
      setInstitutionalVariableTestValues(newVariableTestValues);
    },
    [institutionalVariableTestValues, variableTestValues]
  );

  // Encuentra los errores y produce el puntaje de prueba
  useEffect(() => {
    setNoBlankNamesError(false);
    setSyntaxErrors(false);
    setNoDefinedErrors(false);
    setNoVariablesInAuxError(false);
    setRepeatedNamesError(false);
    const auxSE: string[] = [];
    let variableNames = new Set<string>();
    for (let i = 0; i < auxiliarFormulas.length; i += 1) {
      if (auxiliarFormulas[i].name.match(/^\s*$/)) {
        setNoBlankNamesError(true);
      }
      if (variableNames.has(auxiliarFormulas[i].name)) {
        setRepeatedNamesError(true);
      }
      variableNames = variableNames.add(auxiliarFormulas[i].name);
      // eslint-disable-next-line no-continue
      const { start: auxStart, errors } = parseFormula(
        auxiliarFormulas[i].code
      );
      if (errors.length || !auxStart) {
        auxSE.push(auxiliarFormulas[i].name);
      } else {
        const auxVisitor = new FormulaVisitor(
          variableTestValues,
          auxiliarFormulas,
          variablesSet,
          institutionalDataSet,
          institutionalVariableTestValues
        );
        auxVisitor.visit(auxStart);
        if (auxVisitor.errors.length) {
          setNoVariablesInAuxError(true);
        }
      }
    }
    setAuxVarsSyntaxErrors(auxSE);
    let newCode = code;
    auxiliarFormulas.forEach((formula) => {
      if (formula.name.match(/^\s$/)) return;
      newCode = newCode.replaceAll(
        RegExp(`\\b${formula.name}\\b`, "g"),
        `(${formula.code})`
      );
    });
    setFinalFormula(newCode);
    const { start, errors } = parseFormula(newCode);
    if (errors.length || !start) {
      setSyntaxErrors(true);
    } else {
      const visitor = new FormulaVisitor(
        variableTestValues,
        undefined,
        variablesSet,
        institutionalDataSet,
        institutionalVariableTestValues
      );
      const result = visitor.visit(start);
      if (visitor.errors.length) {
        setNoDefinedErrors(true);
        return;
      }
      setTestScore(Math.round(result * 1000) / 1000);
    }
  }, [
    code,
    variableTestValues,
    addVariableValue,
    auxiliarFormulas,
    variablesSet,
    institutionalDataSet,
    institutionalVariableTestValues,
  ]);
  /**
   * Guarda la fórmula actual
   */
  const saveFormula = useCallback(() => {
    // Si hay errores no enviar
    if (
      syntaxErrors ||
      noDefinedErrors ||
      noVariablesInAuxError ||
      auxVarsSyntaxErrors.length ||
      repeatedNamesError ||
      noBlankNamesError
    ) {
      return;
    }
    if (!indicator || !appState.token) return;
    // Si no hay errores, modificar el indicador
    const newIndicator: Indicator = { ...indicator };
    newIndicator.unreplaced_formula = code;
    newIndicator.formula = finalFormula;
    newIndicator.auxiliary_formulas = auxiliarFormulas;
    fetchAPI<APIActions["editIndicator"]>(
      APIRoutes.editIndicator,
      {
        method: "PUT",
        routeParams: {
          indicator_id: indicatorId,
        },
        body: {
          slug: newIndicator.slug,
          subcategory_id: newIndicator.subcategory_id,
          title: newIndicator.title,
          score_weight: newIndicator.score_weight,
          color: newIndicator.color,
          formula: newIndicator.formula,
          unreplaced_formula: newIndicator.unreplaced_formula,
          auxiliary_formulas: newIndicator.auxiliary_formulas,
        },
      },
      appState.token
    ).then(() => fetchIndicator());
  }, [
    syntaxErrors,
    noDefinedErrors,
    noVariablesInAuxError,
    auxVarsSyntaxErrors.length,
    repeatedNamesError,
    noBlankNamesError,
    indicator,
    appState.token,
    code,
    finalFormula,
    auxiliarFormulas,
    indicatorId,
    fetchIndicator,
  ]);

  /**
   * Función para añadir un campo de fórmulas auxiliares
   */
  function addFormulaComponent() {
    const newAuxiliarFormulas: Array<AuxiliarFormula> = [...auxiliarFormulas];
    newAuxiliarFormulas.push({ name: "", code: "" });
    setAuxiliarFormulas(newAuxiliarFormulas);
  }

  /**
   * Función para quitar un campo de fórmulas auxiliares
   *
   * @param index El índice de la fórmula auxiliar a borrar
   */
  function removeFormulaComponent(index: number) {
    const newAuxiliarFormulas: Array<AuxiliarFormula> = [...auxiliarFormulas];
    newAuxiliarFormulas.splice(index, 1);
    setAuxiliarFormulas(newAuxiliarFormulas);
  }

  // Cambia el código de una formula auxiliar
  const changeCode = useCallback(
    (s: string, i: number) => {
      const newAuxiliarFormulas: Array<AuxiliarFormula> = [...auxiliarFormulas];
      newAuxiliarFormulas[i].code = s;
      setAuxiliarFormulas(newAuxiliarFormulas);
    },
    [auxiliarFormulas]
  );

  // Cambia el nombre de una formula auxiliar
  const changeName = useCallback(
    (e: ChangeEvent<HTMLInputElement>, i: number) => {
      const oldName = auxiliarFormulas[i].name;
      const newName = e.target.value;
      // ! El nuevo nombre debe seguir este patrón
      if (!newName.match(/^([a-zA-Z][a-zA-Z0-9_]*|)$/)) return; // TODO: error de nombre
      if (oldName !== newName) {
        const newAuxiliarFormulas: Array<AuxiliarFormula> = [
          ...auxiliarFormulas,
        ];

        newAuxiliarFormulas[i].name = newName;

        setAuxiliarFormulas(newAuxiliarFormulas);
      }
    },
    [auxiliarFormulas]
  );

  const MemoizedIndicatorsTable = useMemo(() => {
    return (
      <table tw="table-auto text-center w-full">
        <thead>
          <tr tw="bg-resies_blue1 text-white text-barra font-bold">
            <td tw="px-2 py-1">Nombre</td>
            <td tw="px-2 py-1">Indicador</td>
            <td tw="px-2 py-1">Descripción</td>
            <td tw="px-2 py-1">Valor de prueba</td>
          </tr>
        </thead>
        <tbody>
          {variables
            .filter(
              (variable) => variable.indicator_id.toString() === indicatorId
            )
            .map((variable) => (
              <tr key={variable.id} tw="border-b border-gray">
                <td tw="px-2 py-1">__var__{variable.id}</td>
                <td tw="px-2 py-1">{variable.indicator_slug}</td>
                <td tw="px-2 py-1">{variable.description}</td>
                <td tw="px-2 py-1">
                  {getTestValueInput(variable, addVariableValue)}
                </td>
              </tr>
            ))}
          {variables
            .filter(
              (variable) => variable.indicator_id.toString() !== indicatorId
            )
            .map((variable) => (
              <tr key={variable.id} tw="border-b border-gray">
                <td tw="px-2 py-1">__var__{variable.id}</td>
                <td tw="px-2 py-1">{variable.indicator_slug}</td>
                <td tw="px-2 py-1">{variable.description}</td>
                <td tw="px-2 py-1">
                  {getTestValueInput(variable, addVariableValue)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  }, [addVariableValue, indicatorId, variables]);

  const MemoizedInstitutionalDataTable = useMemo(() => {
    return (
      <table tw="table-auto text-center w-full">
        <thead>
          <tr tw="bg-resies_blue1 text-white text-barra font-bold">
            <td tw="px-2 py-1">Nombre</td>
            <td tw="px-2 py-1">Descripción</td>
            <td tw="px-2 py-1">Valor de prueba</td>
          </tr>
        </thead>
        <tbody>
          {institutionalData.map((variable) => (
            <tr key={variable.id} tw="border-b border-gray">
              <td tw="px-2 py-1">__di__{variable.id}</td>
              <td tw="px-2 py-1">{variable.description}</td>
              <td>
                {getTestValueInput(variable, addInstitutionalVariableValue)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [addInstitutionalVariableValue, institutionalData]);

  return (
    <CargandoScreen ready={ready}>
      <Container>
        <PageTitle
          title={`Edición de fórmulas: ${indicator?.slug} ${indicator?.title}`}
        />
        <div tw="flex flex-row flex-grow h-screen">
          <div tw="w-1/2 px-4">
            <div tw="flex flex-col h-full mb-auto">
              <div tw="h-full overflow-y-scroll">
                <div tw="text-resies_blue1 text-header2 font-bold">
                  Editar Fórmula
                </div>
                <FormulaEditor
                  code={code}
                  setCode={setCode}
                  auxiliarFormulas={auxiliarFormulas}
                  variablesSet={variablesSet}
                  institutionalVariablesSet={institutionalDataSet}
                />
                <div tw="text-resies_blue1 text-header2 font-bold mt-4">
                  Fórmulas auxiliares
                </div>
                {auxiliarFormulas.map((formula, i) => (
                  <AuxiliarFormulaEditor
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    index={i}
                    code={formula.code}
                    name={formula.name}
                    changeCode={changeCode}
                    changeName={changeName}
                    removeFormula={removeFormulaComponent}
                    variablesSet={variablesSet}
                    institutionalVariablesSet={institutionalDataSet}
                  />
                ))}
                <button
                  type="button"
                  tw="w-full bg-resies_lightpurple text-black rounded-md py-2 border border-very_darkgray mb-2"
                  onClick={() => addFormulaComponent()}
                >
                  Agregar fórmula auxiliar
                </button>
              </div>
            </div>
          </div>
          <div tw="w-1/2 flex flex-col px-4">
            <div tw="flex flex-row h-10">
              <NavButton
                navSelected={navSelected}
                onClick={() => setNavSelected(1)}
                buttonName="nav1"
                buttonNumber={1}
                label="Variables de la institución"
              />
              <NavButton
                navSelected={navSelected}
                onClick={() => setNavSelected(2)}
                buttonName="nav2"
                buttonNumber={2}
                label="Datos institucionales"
              />
            </div>
            <div tw="overflow-y-scroll">
              {navSelected === 1 && MemoizedIndicatorsTable}
              {navSelected === 2 && MemoizedInstitutionalDataTable}
            </div>
          </div>
        </div>
        <div tw="h-auto w-full bg-ghostwhite border-t border-gray pt-2 mt-auto">
          <div tw="flex flex-row justify-between mb-4">
            <div tw="text-resies_blue1 text-header2 font-bold">
              Vista Previa
            </div>
            <div tw="flex flex-row justify-end">
              <button
                type="button"
                tw="bg-resies_purple text-ghostwhite text-barra px-2 py-1 rounded-md"
                onClick={() => saveFormula()}
              >
                Guardar Cambios
              </button>
            </div>
          </div>
          <div tw="flex flex-row flex-grow">
            <div tw="w-full pr-2">
              <div tw="text-barra text-black font-bold">Fórmula</div>
              <div tw="flex flex-row justify-center text-barra text-black font-bold bg-resies_lightpurple min-h-12">
                <div tw="flex flex-col justify-center">
                  <div tw="text-center px-2">{finalFormula}</div>
                </div>
              </div>
              <div>
                {syntaxErrors && (
                  <span tw="text-darkred">
                    Se han detectado errores de sintaxis
                  </span>
                )}
              </div>
              <div>
                {noDefinedErrors && (
                  <span tw="text-darkred">Hay variables sin definir</span>
                )}
              </div>
              <div>
                {repeatedNamesError && (
                  <span tw="text-darkred">
                    Hay formulas auxiliares con nombre repetido
                  </span>
                )}
              </div>
              <div tw="text-darkred">
                {!!auxVarsSyntaxErrors.length &&
                  `Las fórmulas auxiliares ${auxVarsSyntaxErrors.reduce(
                    (acc, err, i) => {
                      if (i === 0) return `'${err}'`;
                      return `${acc}, '${err}'`;
                    },
                    ""
                  )} tienen errores de sintaxis`}
              </div>
              <div>
                {noVariablesInAuxError && (
                  <span tw="text-darkred">
                    No se pueden utilizar fórmulas auxiliares dentro de otras
                    formulas auxiliares
                  </span>
                )}
              </div>
              <div>
                {noBlankNamesError && (
                  <span tw="text-darkred">
                    Una o más fórmulas auxiliares tienen nombre vacío
                  </span>
                )}
              </div>
            </div>
            <div tw="w-48 pl-2 flex flex-col">
              <div tw="text-barra text-black font-bold">Puntaje de prueba</div>
              <div tw="flex flex-row justify-center text-header2 text-resies_blue1 font-bold bg-resies_lightpurple h-full">
                <div tw="flex flex-col justify-center">
                  <div>{testScore}</div>
                </div>
              </div>
            </div>
          </div>
          <VolverButton
            to={Routes.editIndicators.replace(":subcategoryId", subcategoryId)}
          />
        </div>
      </Container>
    </CargandoScreen>
  );
}

export default ADMIndicatorFormula;
