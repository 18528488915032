import React, { ReactElement } from "react";
import "twin.macro";
import Navbar from "../common/Navbar";

/**
 * Barra de navegación principal del usuario anonimo.
 */
function AnonNavBar(): ReactElement {
  return <Navbar items={[]} />;
}

export default AnonNavBar;
