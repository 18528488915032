import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import CategoriesNavbar from "../../components/common/IndicatorsView/CategoriesNavbar";
import MainInstitutionDataMenu from "../../components/common/InstitutionalVariableView/MainInstitutionDataMenu";
import PageTitle from "../../components/common/PageTitle";
import VolverButton from "../../components/common/VolverButton";
import { AppContext } from "../../dispatcher";
import useRESIESDates from "../../hooks/useRESIESDates";
import Routes from "../../routers/routes";
import {
  InstitutionalDataVariable,
  PostInstitutionalDataResponse,
  PutInstitutionalDataResponse,
  Category,
} from "../../types/backendTypes";
import { InstitutionalVariableForm } from "../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import { SearchOnUrl } from "../../utils/commonFunction";

/**
 * Página perteneciente al MDI para ver y modificar los datos de la institución
 */
function MDIInstitutionData(): ReactElement {
  const [changes, setChanges] = useState(false);
  const [appState] = useContext(AppContext);
  const [ready, setReady] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [indicators, setIndicators] = useState<Record<string, string[]>>({});
  const [variables, setVariables] = useState<
    Record<string, InstitutionalDataVariable>
  >({});
  const [variablesForm, setVariablesForm] = useState<
    InstitutionalVariableForm[]
  >([]);
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [reload, setReload] = useState(0);
  const [activeVersion] = useRESIESDates();
  const query = SearchOnUrl();

  useEffect(() => {
    if (!appState.token) return;

    const selectedCategoryFromUrl =
      query.get("category") !== null ? Number(query.get("category")) : -1;

    fetchAPI<APIActions["fetchInstitutionalData"]>(
      APIRoutes.fetchInstitutionalData,
      {
        method: "GET",
      },
      appState.token
    ).then((data) => {
      setCategories(
        data.categories
          .sort((a, b) => a.category_id - b.category_id)
          .map((cat) => ({
            id: cat.category_id,
            name: cat.category_name,
            description: cat.category_description,
            score_weight: cat.category_score_weight,
            color: cat.category_color,
          }))
      );

      if (data.categories) {
        let categoryFromUrl = data.categories[0];
        if (selectedCategoryFromUrl >= 0) {
          categoryFromUrl = data.categories.filter(
            (category) => category.category_id === selectedCategoryFromUrl
          )[0];
        }

        const category: Category = {
          id: categoryFromUrl.category_id,
          name: categoryFromUrl.category_name,
          description: categoryFromUrl.category_description,
          score_weight: categoryFromUrl.category_score_weight,
          color: categoryFromUrl.category_color,
        };

        setSelectedCategory(category);

        const inds: Record<string, string[]> = {};
        const vars: Record<string, InstitutionalDataVariable> = {};
        const form: InstitutionalVariableForm[] = [];
        data.categories.forEach((cat) => {
          inds[cat.category_name] = cat.variables
            .sort((a, b) => a.variable.id - b.variable.id)
            .map((v) => v.variable.indicator_slug);
          cat.variables.forEach((v) => {
            vars[v.variable.indicator_slug] = v.variable;
            form.push({
              variable_id: v.variable.id,
              response: v.response ? v.response.response : "",
              response_id: v.response ? v.response.id : 0,
              completed: !!v.response,
              audit_status: v.response?.audit_status || "pending",
              modified: false,
              evidences: v.response?.evidences || [],
              json_config: v.variable.json_config,
              type: v.variable.type,
              description: v.response?.description
                ? v.response.description
                : "",
            });
          });
        });
        setIndicators(inds);
        setVariables(vars);
        setVariablesForm(form);
      }
      setReady(true);
    });
  }, [appState.token, reload, query]);
  /**
   * función para guardar datos
   */
  function onSave() {
    setReady(false);
    if (!activeVersion) return;
    const newResponses: PostInstitutionalDataResponse[] = variablesForm
      .filter((v) => v.modified && !v.completed)
      .map((vnew) => {
        return {
          variable_id: vnew.variable_id,
          response:
            !vnew.response && vnew.type === "NUMBER" ? "0" : vnew.response,
          description: vnew.description,
          resies_version: activeVersion.version_name,
        };
      });
    const editResponses: PutInstitutionalDataResponse[] = variablesForm
      .filter((v) => v.modified && v.completed && v.response_id)
      .map((vedit) => {
        return {
          id: vedit.response_id,
          response:
            !vedit.response && vedit.type === "NUMBER" ? "0" : vedit.response,
          description: vedit.description,
          resies_version: activeVersion.version_name,
        };
      });
    if (!appState.token) return;
    fetchAPI<APIActions["createInstitutionalDataResponse"]>(
      APIRoutes.createInstitutionalDataResponse,
      {
        method: "POST",
        body: newResponses,
      },
      appState.token
    ).then(() => {
      if (!appState.token) return;
      fetchAPI<APIActions["editInstitutionalDataResponse"]>(
        APIRoutes.editInstitutionalDataResponse,
        {
          method: "PUT",
          body: editResponses,
        },
        appState.token
      ).then(() => {
        setChanges(false);
        setReload(reload + 1);
      });
    });
  }
  return (
    <>
      <Container>
        <PageTitle title="Datos de mi institución" />
      </Container>
      <CargandoScreen ready={ready}>
        <Container>
          <div tw="w-full flex flex-row justify-end">
            <p tw="flex flex-wrap content-center text-darkred mr-2 text-header2 font-bold">
              {changes ? `Hay cambios sin guardar` : ""}
            </p>
            <button
              type="button"
              tw="flex flex-row flex-wrap mr-0 mb-2 content-center bg-resies_purple py-1 rounded-sm max-h-12"
              onClick={() => onSave()}
            >
              <img src="/save.png" alt="save" tw="mr-4 ml-2" />
              <p tw="font-bold text-base text-ghostwhite mr-4">
                Guardar Cambios
              </p>
            </button>
          </div>
        </Container>
        {categories ? (
          <>
            {selectedCategory && (
              <>
                <CategoriesNavbar
                  categories={categories}
                  selectedCategory={selectedCategory}
                />
                <MainInstitutionDataMenu
                  indicators={indicators}
                  selectedCategory={selectedCategory}
                  variables={variables}
                  variablesForm={variablesForm}
                  setVariablesForm={setVariablesForm}
                  setChanges={setChanges}
                  changes={changes}
                />
              </>
            )}
          </>
        ) : (
          " "
        )}
        <VolverButton to={Routes.myInstitution} />
      </CargandoScreen>
    </>
  );
}

export default MDIInstitutionData;
