import React, { ReactElement, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import usePasswordToggle from "../../hooks/common/usePasswordToggle";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import LoadingFormButton from "./LoadingFormButton";

type PasswordInput = {
  new_password: string;
  confirm_new_password: string;
  current_password: string;
};

/**
 * Formulario para el cambio de contraseña
 */
function ChangePasswordForm(): ReactElement {
  const [appState] = useContext(AppContext);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordInput>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [inputType1, icon1] = usePasswordToggle();
  const [inputType2, icon2] = usePasswordToggle();
  const [inputType3, icon3] = usePasswordToggle();

  /**
   * handler de cambio de contrasena
   *
   * @param data input de contrasena
   */
  function onSubmit(data: PasswordInput) {
    setSuccess(false);
    setError(false);
    setLoading(true);
    if (!appState.token) return;
    fetchAPI<APIActions["changePassword"]>(
      APIRoutes.changePassword,
      {
        method: "PUT",
        body: {
          current_password: data.current_password,
          new_password: data.new_password,
        },
      },
      appState.token
    )
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }
  return (
    <form tw="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <div tw="relative">
        <input
          type={inputType1}
          placeholder="Tu contraseña actual"
          tw="block my-1 rounded px-2 py-1 w-full border-darkgray border"
          {...register("current_password", {
            required: true,
          })}
        />
        <div tw="absolute top-0 right-0 h-full">
          <div tw="flex h-full justify-center content-center p-2 text-xs text-very_darkgray">
            {icon1}
          </div>
        </div>
      </div>
      {error ? <p>Contraseña actual inválida</p> : <p />}
      <br />
      <div tw="relative">
        <input
          type={inputType2}
          placeholder="Ingresa nueva una contraseña"
          tw="my-1 rounded px-2 py-1 w-full border-darkgray border"
          {...register("new_password", { required: true })}
        />
        <div tw="absolute top-0 right-0 h-full">
          <div tw="flex h-full justify-center content-center p-2 text-xs text-very_darkgray">
            {icon2}
          </div>
        </div>
      </div>
      <div tw="relative">
        <input
          type={inputType3}
          placeholder="Repite la nueva contraseña"
          tw="block my-1 rounded px-2 py-1 w-full border-darkgray border"
          {...register("confirm_new_password", {
            required: true,
            validate: (value) => value === watch("new_password"),
          })}
        />
        <div tw="absolute top-0 right-0 h-full">
          <div tw="flex h-full justify-center content-center p-2 text-xs text-very_darkgray">
            {icon3}
          </div>
        </div>
      </div>
      {errors.confirm_new_password && <div>Las contraseñas no coinciden</div>}
      <div tw="flex flex-row w-full justify-center mt-4">
        <LoadingFormButton
          loading={loading}
          label="Guardar cambios"
          className="bg-resies_purple text-ghostwhite text-barra font-bold px-6 py-2 rounded-2xl"
        />
      </div>
      {success ? <p>Has cambiado tu contraseña</p> : <p />}
    </form>
  );
}

export default ChangePasswordForm;
