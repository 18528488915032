/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ReactElement,
  useRef,
  useState,
  useEffect,
  useContext,
  RefObject,
} from "react";
import "twin.macro";
import { ResiesVersion } from "../../types/backendTypes";
import { AppContext } from "../../dispatcher";
import CargandoScreen from "./CargandoScreen";
import { CenteredPopup } from "./utils/Popup";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * Boton para mostrar las fechas de los procesos de la plataforma.
 *
 * @param props react props
 * @param props.currentProcess proceso actual en la plataforma.
 */
function ExcelPopUp(): ReactElement {
  const ref = useRef<() => void>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [ready, setReady] = useState(false);
  const [downloadingText, setDownloadingText] = useState<{
    [key: string]: string;
  }>({});
  const [appState] = useContext(AppContext);
  const [versions, setVersions] = useState<ResiesVersion[]>([]);

  type Export = {
    file_link: string;
  };

  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["getResiesVersions"]>(
      APIRoutes.getResiesVersions,
      {
        method: "GET",
      },
      appState.token
    ).then((versions) => {
      setVersions(versions);
      const downloadingTexts: { [key: string]: string } = {};
      versions.forEach((version) => {
        downloadingTexts[version.id] = "Descargar";
      });
      setReady(true);
      setDownloadingText(downloadingTexts);
    });
  }, [appState.token]);

  const downloadExport = (ref: RefObject<HTMLDivElement>, url: string) => {
    const a = document.createElement("a");
    ref.current?.appendChild(a);
    a.download = "export_RESIES.xls";
    a.href = url;
    a.click();
    ref.current?.removeChild(a);
  };

  const getExportPromise = (resies_version_id: string, token: string) =>
    fetchAPI<APIActions["exportFile"]>(
      APIRoutes.exportFile,
      { method: "GET", routeParams: { resies_version_id: resies_version_id } },
      token
    );

  const exportClickFunction = (
    ref: RefObject<HTMLDivElement>,
    resies_version_id: string,
    token: string
  ) => {
    setDownloadingText({
      ...downloadingText,
      [resies_version_id]: "Descargando...",
    });
    getExportPromise(resies_version_id, token).then((thing: Export) => {
      downloadExport(ref, thing.file_link);
      setDownloadingText({
        ...downloadingText,
        [resies_version_id]: "Descargar",
      });
    });
  };

  return (
    <>
      <button
        type="button"
        tw="font-semibold bg-resies_purple text-ghostwhite rounded-md px-2"
        onClick={() => {
          if (ref.current !== null) ref.current();
        }}
      >
        Exportar Excel
      </button>
      <CenteredPopup width={700} height={500} ref={ref}>
        <div className="bg-resies_lightpurple font-sans text-black rounded-lg popup flex flex-col pb-10 h-full py-10">
          <div tw="flex flex-row justify-start mb-2">
            <p tw="text-resies_blue1 text-header2 font-semibold ml-5">
              Procesos RESIES con información
            </p>
          </div>
          <CargandoScreen ready={ready}>
            <div tw="" ref={containerRef}>
              <table tw="w-full h-full">
                <thead tw="bg-resies_blue2 flex text-white w-full">
                  <tr tw="flex w-full h-5">
                    <th tw="text-barra font-bold text-ghostwhite text-center w-8/12 h-5">
                      PROCESO
                    </th>
                    <th tw="text-barra font-bold text-ghostwhite text-center w-4/12 h-5">
                      Descargar Excel
                    </th>
                  </tr>
                </thead>
                <tbody tw="bg-resies_lightpurple">
                  {versions &&
                    versions.map((version, idx) => (
                      <tr
                        tw="flex w-full bg-resies_lightgreen my-3 justify-between"
                        key={idx}
                      >
                        <td tw="text-header2 font-semibold text-left pl-10 py-2 w-8/12">
                          {version.version_name}
                        </td>
                        <td tw="text-center p-2 w-4/12">
                          <a
                            href="#"
                            onClick={() =>
                              exportClickFunction(
                                containerRef,
                                version.id.toString(),
                                appState.token as string
                              )
                            }
                          >
                            {downloadingText[version.id.toString()]}
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </CargandoScreen>
        </div>
      </CenteredPopup>
    </>
  );
}

export default ExcelPopUp;
