/* eslint-disable import/prefer-default-export */
import React from "react";
import { useLocation } from "react-router-dom";

const SearchOnUrl = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export { SearchOnUrl };
