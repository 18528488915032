import React, { ReactElement, useContext, useState } from "react";
import "twin.macro";
import AUDAssignedInstitutions from "../../components/aud/AUDAssignedInstitutions";
import ProfileData from "../../components/admin/ProfileData";
import AuditedPercentage from "../../components/admin/AuditedPercentage";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import PageSection from "../../components/common/PageSection";
import PageTitle from "../../components/common/PageTitle";
import useAuditor from "../../hooks/useAuditor";
import { AppContext } from "../../dispatcher";

/**
 * La página de perfil del auditor
 */
function MyProfileAUD(): ReactElement {
  const [ready, setReady] = useState(false);
  const [appState] = useContext(AppContext);
  const audId = appState.userId;
  const [auditor, auditableResponses, reloadResponses] = useAuditor(
    setReady,
    audId
  );

  // TODO: datos reales como en audProfile

  return (
    <CargandoScreen ready={ready}>
      <Container>
        <PageTitle title="Mi Perfil" />
        <div tw="grid grid-cols-2 mb-8">
          <div>
            <PageSection text="Datos Personales" />
            <div tw="w-full">
              <ProfileData user={auditor} reload={reloadResponses} />
            </div>
          </div>
          <div tw="w-full">
            <div tw="h-full">
              <AuditedPercentage
                percentage={(() => {
                  const completed = auditableResponses.reduce(
                    (acc, response) =>
                      acc +
                      response.responses.reduce(
                        (acc2, res) =>
                          acc2 +
                          (res.audit_status === "accepted" ||
                          res.audit_status === "rejected"
                            ? 1
                            : 0),
                        0
                      ),
                    0
                  );
                  const total = auditableResponses.reduce(
                    (acc, response) => acc + response.responses.length,
                    0
                  );
                  return completed / (total !== 0 ? total : 1);
                })()}
              />
            </div>
          </div>
        </div>
        <AUDAssignedInstitutions
          responses={auditableResponses}
          reloadResponses={reloadResponses}
          inProfile
        />
      </Container>
    </CargandoScreen>
  );
}

export default MyProfileAUD;
