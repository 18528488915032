import React, { ReactElement } from "react";
import "twin.macro";

/**
 * Componente que muestra la información de la variable que se esta observando.
 *
 * @param props react props.
 * @param props.info Texto que se quiere mostrar como información.
 * @param props.title Texto que se quiere mostrar como titulo.
 */
function EvidenceInfo(props: {
  info: string | undefined;
  title: string;
}): ReactElement {
  const { info, title } = props;
  return (
    <div>
      <div tw="bg-resies_blue2 font-sans text-barra font-bold text-ghostwhite px-6 mt-7">
        {title}
      </div>
      <div tw="bg-resies_lightpurple font-sans text-body font-normal text-black px-6 py-2">
        {info}
      </div>
    </div>
  );
}

export default EvidenceInfo;
