import React, { ReactElement } from "react";
import "twin.macro";
import ADMCategorieNavbarElement from "./ADMCategorieNavbarElement";

/**
 * Navbar que contiene las categorias actualmente existentes.
 *
 * @param props react props.
 * @param props.labels Lista que contiene todos los nombres de las categorias a mostrar en el navbar.
 * @param props.selected Corresponde al nombre de la categoria actualmente seleccionada.
 * @param props.setSelected Corresponde al setState de la categoria seleccionada.
 * @param props.setIndicatorSelected Corresponde al setState de el indicador seleccionado.
 * @param props.indicators Diccionario que contiene los nombres de los indicadores de cada categoria.
 */
function ADMCategoriesNavbar(props: {
  labels: string[];
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  setIndicatorSelected: React.Dispatch<React.SetStateAction<string>>;
  indicators: Record<string, string[]>;
}): ReactElement {
  const { labels, selected, setSelected, setIndicatorSelected, indicators } =
    props;
  return (
    <div tw="flex flex-row justify-between bg-resies_lightpurple px-10 lg:pr-20 lg:pl-28">
      {labels.map((label, index) => (
        <ADMCategorieNavbarElement
          name={label}
          position={index}
          selected={selected}
          setSelected={setSelected}
          setIndicatorSelected={setIndicatorSelected}
          indicators={indicators}
          key={label}
        />
      ))}
    </div>
  );
}

export default ADMCategoriesNavbar;
