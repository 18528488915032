/* eslint-disable import/no-cycle */
import React, { ReactElement, useReducer, useEffect } from "react";
import "./App.css";
import "twin.macro";
import ADMRouter from "./routers/ADMRouter";
import AnonRouter from "./routers/AnonRouter";
import MDIRouter from "./routers/MDIRouter";
import RDIRouter from "./routers/RDIRouter";
import {
  Action,
  ActionTypes,
  AppContext,
  appReducer,
  AppState,
  initialAppState,
  Reducer,
} from "./dispatcher";
import AUDRouter from "./routers/AUDRouter";
/**
 * Función que retorna un router distinto dependiendo del tipo de usuario.
 *
 * @param userType tipo de usuario.
 */
export function landingSwitch(userType: string | null): ReactElement {
  return (() => {
    switch (userType || localStorage.getItem("userType")) {
      case "ADM": {
        return <ADMRouter />;
      }
      case "MDI": {
        return <MDIRouter />;
      }
      case "RDI": {
        return <RDIRouter />;
      }
      case "AUD": {
        return <AUDRouter />;
      }
      default: {
        return <AnonRouter />;
      }
    }
  })();
}
/**
 * Componente principal de la aplicación.
 */
function App(): ReactElement {
  const [appState, appDispatch]: [
    AppState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    React.Dispatch<Action>
  ] = useReducer<Reducer>(appReducer, initialAppState);
  useEffect(() => {
    appDispatch({
      type: ActionTypes.reloadStorage,
      payload: {},
    });
  }, []);
  return (
    <AppContext.Provider value={[appState, appDispatch]}>
      {landingSwitch(appState.userType)}
    </AppContext.Provider>
  );
}

export default App;
