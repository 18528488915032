import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import CargandoScreen from "../common/CargandoScreen";
import Container from "../common/Container";
import TableInstitutionRow from "./TableInstitutionRow";
import { AppContext } from "../../dispatcher";
import { AuditableResponses } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * Instituciones del que tiene a cargo el auditor
 */
function TableInstitutions(): ReactElement {
  const [state] = useContext(AppContext);
  const apiKey = state.token;
  const [ready, setReady] = useState(false);
  const [institutions, setInstitutions] = useState<Array<AuditableResponses>>(
    []
  );
  useEffect(() => {
    if (apiKey) {
      fetchAPI<APIActions["auditableResponses"]>(
        APIRoutes.auditableResponses,
        {
          method: "GET",
        },
        apiKey
      )
        .then((responses) => {
          setInstitutions(responses);
          setReady(true);
        })
        .catch(() => {}); // TODO
    }
  }, [apiKey]);
  return (
    <CargandoScreen ready={ready}>
      <Container>
        <table tw="table w-full mb-5 min-h-80 bg-resies_lightpurple">
          <thead tw=" bg-resies_blue2 flex text-white w-full">
            <tr tw="flex w-full h-5">
              <th tw="text-barra font-bold text-ghostwhite text-center w-1/3 h-5">
                Institución
              </th>
              <th tw="text-barra font-bold text-ghostwhite text-center w-1/3 h-5">
                Porcentaje de avance
              </th>
              <th tw="text-barra font-bold text-ghostwhite text-center w-1/3 h-5">
                Auditar
              </th>
            </tr>
          </thead>
          <tbody tw="bg-resies_lightpurple flex flex-col items-center justify-between overflow-y-scroll w-full max-h-72">
            {!institutions.length ? (
              <div tw="text-header2 my-28 text-darkgray">
                No tienes instituciones asignadas
              </div>
            ) : (
              institutions.map((i) => (
                <TableInstitutionRow
                  institutionName={i.institution.name}
                  institutionId={i.institution.id}
                  responses={i.responses}
                />
              ))
            )}
          </tbody>
        </table>
      </Container>
    </CargandoScreen>
  );
}

export default TableInstitutions;
