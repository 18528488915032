import { AppState } from "../../../dispatcher";
import { CategoryStatus, DropdownStatus } from "../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

/**
 * Funcion que retorna las categorias para el menu de editar categorias.
 *
 * @param appState estado de la aplicacion
 * @param setReady setter de ready
 * @param setMaxId setter de id maxima
 * @param setCategoryStatus setter de estado de categoria
 * @param setDropdownStatus setter de el estado de las dropdown
 * @param optionReload booleano que indica si se deben setear los status de dropdown en up o no.
 */
function getCategories(
  appState: AppState,
  setReady: React.Dispatch<React.SetStateAction<boolean>>,
  setMaxId: React.Dispatch<React.SetStateAction<number>>,
  setCategoryStatus: React.Dispatch<React.SetStateAction<CategoryStatus[]>>,
  setDropdownStatus: React.Dispatch<React.SetStateAction<DropdownStatus[]>>,
  optionReload: boolean
): void {
  if (!appState.token) return;
  fetchAPI<APIActions["fetchCategories"]>(
    APIRoutes.fetchCategories,
    {
      method: "GET",
    },
    appState.token
  ).then((categs) => {
    const catStatus: CategoryStatus[] = [];
    const dropStatus: DropdownStatus[] = [];
    let id = 0;
    categs.forEach((cate) => {
      dropStatus.push({ status: "up", id: cate.id });
      catStatus.push({
        id: cate.id,
        status: "ready",
        name: cate.name,
        description: cate.description,
        score_weight: cate.score_weight,
        color: cate.color,
      });
      if (cate.id >= id) {
        id = cate.id;
      }
      catStatus.sort((a, b) => a.id - b.id);
      setMaxId(id);
    });
    setCategoryStatus([...catStatus]);
    if (!optionReload) {
      setDropdownStatus([...dropStatus]);
    }
    setReady(true);
  });
}

export default getCategories;
