import React, { ReactElement } from "react";
import "twin.macro";
import { AdminInstitutionsOverview } from "../../types/backendTypes";
import ProgressBar from "../common/ProgressBar";

/**
 * progreso total de institucion
 *
 * @param props react props
 * @param props.overview overview
 */
function TotalInstitutionProgress(props: {
  overview: AdminInstitutionsOverview | null;
}): ReactElement {
  const { overview } = props;

  const totalIndicators =
    overview?.reduce(
      (prev2, institution) =>
        prev2 +
        institution.categories.reduce(
          (prev, category) => prev + category.total_indicators,
          0
        ),
      0
    ) || 0;
  const totalResponses =
    overview?.reduce(
      (prev2, institution) =>
        prev2 +
        institution.categories.reduce(
          (prev, category) => prev + category.complete_indicators,
          0
        ),
      0
    ) || 1;

  const percentage = Math.round((totalResponses / totalIndicators) * 100);

  return (
    <div tw="">
      <div tw="text-header2 text-resies_blue1 font-bold mb-2">
        Avance Instituciones
      </div>
      <div tw="h-10">
        <ProgressBar completed={percentage} />
      </div>
      <div tw="text-body text-very_darkgray">
        {totalResponses} de {totalIndicators} indicadores completados
      </div>
    </div>
  );
}

export default TotalInstitutionProgress;
