import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import { Category, InstitutionScore } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import ResultCategory from "./ResultCategory";

/**
 * Box que muestra los resultados preliminares
 */
function ResultsBox(): ReactElement {
  const [appState] = useContext(AppContext);
  const [categories, setCategories] = useState<Category[]>([]);
  const { institutionId } = useParams<{ institutionId: string }>();
  const [scores, setScores] = useState<InstitutionScore>();
  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["fetchCategories"]>(
      APIRoutes.fetchCategories,
      {
        method: "GET",
      },
      appState.token
    )
      .then((categs) => {
        const sorted = categs.sort((a, b) => a.id - b.id);
        setCategories(sorted);
      })
      .then(() => {
        if (!appState.token) return;
        fetchAPI<APIActions["fetchInstitutionScores"]>(
          APIRoutes.fetchInstitutionScores,
          {
            method: "GET",
            routeParams: {
              institution_id: institutionId || "0",
            },
            queryParams: { strict_mode: "false" },
          },
          appState.token
        ).then((data) => setScores(data));
      });
  }, [appState, institutionId]);
  return (
    <div tw="my-16 h-56">
      <div tw="font-semibold text-header2 text-resies_blue1">
        Resultados preliminares
      </div>
      <table tw="w-full">
        <tbody tw="flex flex-col items-center justify-between w-full">
          <tr tw="flex w-full mb-4">
            <td tw="p-4 w-2/6 my-2 bg-resies_lightpurple font-semibold text-header2 text-very_darkgray">
              <table tw="h-full w-full">
                <tbody tw="h-full w-full">
                  <tr tw="w-full h-2/5 font-semibold text-header2 text-left text-very_darkgray">
                    <td>
                      RESULTADO
                      <br />
                      GLOBAL
                    </td>
                  </tr>
                  <tr tw="font-semibold text-header1 text-black text-center">
                    <td colSpan={2}>{scores?.score.toFixed(2)}/100</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td tw="pl-4 w-full">
              {appState.userType !== "MDI" && scores ? (
                categories.map((categ) => (
                  <ResultCategory
                    key={categ.id}
                    categoryName={categ.name}
                    score={scores.categories
                      .filter((cate) => cate.category_id === categ.id)[0]
                      ?.score.toFixed(2)
                      .toString()}
                    color={categ.color}
                  />
                ))
              ) : (
                <></>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ResultsBox;
