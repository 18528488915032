import {
  InstitutionCategory,
  InstitutionVariables,
} from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../fetchAPI";

/**
 * dropdown de categorias y variables institucionales.
 *
 * @param setCategories setter de categorias institucionales
 * @param setVariables setter de variables institucionales
 * @param token token
 * @param setReady setter de listo
 * @param setCategorySelected setter de categoria seleccionada
 */
function dropDownInstitutionalData(
  setCategories: React.Dispatch<React.SetStateAction<InstitutionCategory[]>>,
  setVariables: React.Dispatch<
    React.SetStateAction<Record<string, InstitutionVariables[]>>
  >,
  token: string | null,
  setReady: React.Dispatch<React.SetStateAction<boolean>>,
  setCategorySelected: React.Dispatch<React.SetStateAction<InstitutionCategory>>
): void {
  const variables: Record<string, InstitutionVariables[]> = {};
  const categories: Array<InstitutionCategory> = [];
  let apiKey = "";
  if (token !== null) {
    apiKey = token;
  }
  if (apiKey) {
    fetchAPI<APIActions["fetchInstitutionCategories"]>(
      APIRoutes.fetchInstitutionCategories,
      {
        method: "GET",
      },
      apiKey
    )
      .then((categs) => {
        categs.sort((a, b) => a.id - b.id);
        categs.forEach((categ) => {
          categories.push(categ);
          variables[categ.name] = [];
        });
        setCategories([...categories]);
        setCategorySelected(categories[0]);
      })
      .then(() => {
        fetchAPI<APIActions["fetchInstitutionVariables"]>(
          APIRoutes.fetchInstitutionVariables,
          {
            method: "GET",
          },
          apiKey
        )
          .then((varias) => {
            varias.sort((a, b) => a.id - b.id);
            varias.forEach((vari) => {
              variables[vari.category.name].push(vari);
            });
            setVariables({ ...variables });
          })
          .then(() => setReady(true))
          .catch(() => {}); // TODO
      })
      .catch(() => {}); // TODO
  }
}
export default dropDownInstitutionalData;
