import React, { ReactElement } from "react";
import "twin.macro";
import FooterElement from "./FooterElement";
/**
 * Footer de la aplicación.
 */
function Footer(): ReactElement {
  return (
    <div tw="print:hidden">
      <div tw="lg:h-32 h-64 bg-ghostwhite" />
      <div
        tw="flex bg-resies_purple lg:flex-row md:flex-col flex-col justify-start w-full lg:h-32 h-64 px-2 pl-28"
        style={{ position: "absolute", bottom: 0 }}
      >
        <img tw="lg:w-2/12 lg:pr-5 py-6 w-4/12" src="/Logo.png" alt="logo" />
        <div tw="grid justify-items-start m-5">
          <FooterElement
            text="Nueva Providencia #1363 - Oficina 1102 - Providencia"
            image="/home.png"
          />

          <FooterElement
            text="contacto@redcampussustentable.cl"
            image="/mail.png"
          />

          <FooterElement text="+56 2 22352981" image="/phone-call.png" />
        </div>
      </div>
    </div>
  );
}

export default Footer;
