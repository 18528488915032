import React, { ReactElement } from "react";
import { Cell, Pie, PieChart } from "recharts";
import "twin.macro";
import PageSection from "../common/PageSection";

const COLORS = ["#7cc199", "#d91111"];

/**
 * porcentaje auditado
 *
 * @param props react props
 * @param props.percentage porcentaje
 */
function AuditedPercentage(props: { percentage: number }): ReactElement {
  const { percentage } = props;
  return (
    <div tw="h-full w-auto flex justify-center">
      <div>
        <PageSection text="Porcentaje auditado" />
        <PieChart width={200} height={100}>
          <Pie
            dataKey="value"
            data={[
              { name: "Completado", value: Math.round(percentage * 100) },
              { name: "Incompleto", value: 100 - Math.round(percentage * 100) },
            ]}
            cx="50%"
            cy="50%"
            innerRadius={30}
            outerRadius={50}
          >
            {[
              { name: "Completado", value: Math.round(percentage * 100) },
              { name: "Incompleto", value: 100 },
            ].map((item, index) => (
              <Cell fill={COLORS[index % COLORS.length]} key={item.name} />
            ))}
          </Pie>
          <text
            x={100}
            y={50}
            textAnchor="middle"
            dominantBaseline="middle"
            fill="#3b9a9b"
          >
            {Math.round(percentage * 100)}%
          </text>
        </PieChart>
      </div>
    </div>
  );
}

export default AuditedPercentage;
