import React, { ReactElement } from "react";
import "twin.macro";
import { Institution } from "../../types/backendTypes";

/**
 * Form para crear una nueva institución
 *
 * @param props react props.
 * @param props.name Nombre de la institucion
 * @param props.setName setState para cambiar nombre
 * @param props.setError setState para indicar que el nombre está vacio.
 * @param props.setPhoto Setter de la foto de la institución
 * @param props.setLogo setter del logo de la institución
 * @param props.institution institución en el caso de que se esté editando.
 */
function InstitutionForm(props: {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  setPhoto: React.Dispatch<React.SetStateAction<File | undefined>>;
  setLogo: React.Dispatch<React.SetStateAction<File | undefined>>;
  institution?: Institution | undefined;
}): ReactElement {
  const { name, setName, setError, setPhoto, setLogo, institution } = props;

  /**
   * Función que maneja el cambio en los inputs
   *
   * @param e change event
   */
  function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.name === "name") {
      if (!e.target.value.trim()) {
        setError(true);
      } else {
        setError(false);
      }
      setName(e.target.value);
    } else if (e.target.files && e.currentTarget.name === "photo") {
      setPhoto(e.target.files[0]);
    } else if (e.target.files && e.currentTarget.name === "logo") {
      setLogo(e.target.files[0]);
    }
  }
  return (
    <>
      <div tw="flex flex-row flex-wrap h-16 ml-4 mb-8">
        <p tw="w-3/12 text-header2 font-semibold text-very_darkgray my-auto">
          Nombre
        </p>
        <input
          tw="bg-resies_lightpurple px-2 h-3/6 my-auto ml-8 w-4/12 rounded-md"
          placeholder="Ingresa nombre de la institución"
          name="name"
          value={name}
          onChange={(e) => onChangeHandler(e)}
        />
      </div>
      <div tw="flex flex-row flex-wrap ml-4 mb-8">
        <p tw="w-3/12 text-header2 font-semibold text-very_darkgray my-auto">
          Foto de institución
        </p>
        <input
          type="file"
          tw="px-1 h-3/6 my-auto ml-8 w-4/12 bg-resies_lightpurple rounded-md text-very_darkgray font-normal"
          name="photo"
          accept="image/*"
          onChange={(e) => onChangeHandler(e)}
        />
        <div tw="w-1/12" />
        {institution ? (
          <div tw="w-2/12 flex flex-col h-40">
            <p tw="text-center font-bold h-1/6">Foto actual</p>
            <img
              src={
                institution.photo_url
                  ? institution.photo_url
                  : "/university_generic.jpg"
              }
              alt="institution img"
              tw="object-contain h-5/6"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div tw="flex flex-row flex-wrap ml-4 mb-8">
        <p tw="w-3/12 text-header2 font-semibold text-very_darkgray my-auto">
          Logo de institución
        </p>
        <input
          type="file"
          tw="px-1 h-3/6 my-auto ml-8 w-4/12 bg-resies_lightpurple rounded-md text-very_darkgray font-normal"
          name="logo"
          accept="image/*"
          onChange={(e) => onChangeHandler(e)}
        />
        <div tw="w-1/12" />
        {institution ? (
          <div tw="w-2/12 flex flex-col h-40">
            <p tw="text-center font-bold h-1/6">Logo actual</p>
            <img
              src={
                institution.logo_url
                  ? institution.logo_url
                  : "/standard-logo.png"
              }
              alt="institution img"
              tw="object-contain h-5/6"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default InstitutionForm;
