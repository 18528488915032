/* eslint-disable no-nested-ternary */
import React, { ChangeEvent, ReactElement, useState } from "react";
import "twin.macro";
import { useParams } from "react-router-dom";
import { InstitutionWithAuditor, User } from "../../types/backendTypes";
import PageSection from "../common/PageSection";
import SearchBar from "../common/SearchBar";
import ADMAUDInstitutionAssignerRow from "./ADMAUDInstitutionAssignerRow";

/**
 * Asignador de instituciones a auditor
 *
 * @param props react props
 * @param props.institutions instituciones
 * @param props.auditor auditor
 * @param props.setAuditor setter del auditor
 * @param props.setInstitutions setter de las instituciones
 */
function ADMAUDInstitutionAssigner(props: {
  institutions: InstitutionWithAuditor[];
  auditor: User;
  setAuditor: (auditor: User) => void;
  setInstitutions: React.Dispatch<
    React.SetStateAction<InstitutionWithAuditor[]>
  >;
}): ReactElement {
  const { audId } = useParams<{ audId: string }>();
  const { institutions, auditor, setAuditor, setInstitutions } = props;
  const [searchText, setSearchText] = useState("");
  /**
   * handler de cambio de texto
   *
   * @param e change event
   */
  function searchTextChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
  }
  return (
    <>
      <div tw="grid grid-cols-2">
        <div tw="">
          <PageSection text="Instituciones" />
        </div>
        <div tw="">
          <div tw="float-right">
            <SearchBar
              searchText={searchText}
              onChangeHandler={searchTextChangeHandler}
            />
          </div>
        </div>
      </div>
      <div tw=" bg-resies_lightpurple">
        <table tw="w-full">
          <thead tw="text-ghostwhite bg-resies_blue1 text-center">
            <tr>
              <td>Nombre</td>
              <td>Auditor</td>
              <td>Acciones</td>
            </tr>
          </thead>
          <tbody tw="h-60 overflow-y-scroll">
            {institutions
              .filter((institution) =>
                institution.institution.name.includes(searchText)
              )
              .map((institution) => (
                <ADMAUDInstitutionAssignerRow
                  institution={institution}
                  audId={audId}
                  auditor={auditor}
                  setAuditor={setAuditor}
                  setInstitutions={setInstitutions}
                />
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ADMAUDInstitutionAssigner;
