import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import EvidenceInfo from "../../components/common/EvidenceView/EvidenceInfo";
import InstitutionalEvidenceComments from "../../components/common/EvidenceView/InstitutionalEvidenceComments";
import InstitutionalEvidenceDataRow from "../../components/common/InstitutionalEvidenceView/InstitutionalEvidenceDataRow";
import PageTitle from "../../components/common/PageTitle";
import VolverButton from "../../components/common/VolverButton";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import {
  InstitutionalResponse,
  InstitutionVariables,
} from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * Página para subir la evidencia de las variables institucionales
 */
function MDIInstitutionalEvidenceView(): ReactElement {
  const { variableId } = useParams<{ variableId: string }>();
  const { responseId } = useParams<{ responseId: string }>();
  const [institutionalVariable, setInstitutionalVariable] =
    useState<InstitutionVariables>();
  const [ready, setReady] = useState(false);
  const [appState] = useContext(AppContext);
  const [uploadFile, setUploadFile] = useState(0);
  const [reload, setReload] = useState(0);
  const [response, setResponse] = useState<InstitutionalResponse>();

  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["getInstitutionalVariable"]>(
      APIRoutes.getInstitutionalVariable,
      {
        method: "GET",
        routeParams: { variable_id: variableId },
      },
      appState.token
    ).then((data) => {
      setInstitutionalVariable(data);
      if (!appState.token) return;
      fetchAPI<APIActions["fetchInstitutionalResponses"]>(
        APIRoutes.fetchInstitutionalResponses,
        {
          method: "GET",
        },
        appState.token
      ).then((resps) => {
        setResponse(resps.filter((re) => re.id.toString() === responseId)[0]);
        setReady(true);
      });
    });
  }, [variableId, appState.token, responseId, uploadFile, reload]);
  return (
    <Container>
      <PageTitle title="Evidencia" />
      <CargandoScreen ready={ready}>
        {institutionalVariable ? (
          <>
            <EvidenceInfo
              info={institutionalVariable.description}
              title={institutionalVariable.indicator_slug}
            />
            <EvidenceInfo info={response?.description} title="Descripción" />
          </>
        ) : (
          ""
        )}
        {response ? (
          <InstitutionalEvidenceDataRow
            response={response}
            setUploadFile={setUploadFile}
            reload={reload}
            setReload={setReload}
          />
        ) : (
          ""
        )}
        <InstitutionalEvidenceComments />
      </CargandoScreen>
      {institutionalVariable && (
        <VolverButton
          to={`${Routes.institutionData}?category=${institutionalVariable.category.id}`}
        />
      )}
    </Container>
  );
}

export default MDIInstitutionalEvidenceView;
