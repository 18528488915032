import React, { ReactElement, useContext } from "react";
import { AppContext } from "../../dispatcher";
import useInstitutions from "../../hooks/common/useInstitutions";
import Routes from "../../routers/routes";
import AddButton from "./AddButton";
import CargandoScreen from "./CargandoScreen";
import InstitutionIndexEntry from "./InstitutionIndexEntry";
import InstitutionIndexWrapper from "./InstitutionIndexWrapper";
import PaginatedListNavigator from "./PaginatedListNavigator";

/**
 * La lista paginada de instituciones a mostrar cuando se abre el menu de todas las instituciones
 */
function InstitutionIndex(): ReactElement {
  const [state] = useContext(AppContext);
  const [institutions, maxPages, pageNumber, setPageNumber, ready] =
    useInstitutions(6, state.token);
  /**
   * La función para setear la página actual
   *
   * @param n El número por el cual cambiar la página actual
   */
  function setCurrentPage(n: number): void {
    setPageNumber(n);
  }

  return (
    <CargandoScreen ready={ready}>
      {state.userType === "ADM" && (
        <AddButton to={Routes.addInstitution} text="Agregar Institución" />
      )}
      <div>
        <InstitutionIndexWrapper>
          {institutions.map((i) => (
            <InstitutionIndexEntry
              key={i.id}
              name={i.name}
              imageUrl={i.photo_url ? i.photo_url : "/university_generic.jpg"}
              logoUrl={i.logo_url ? i.logo_url : "/standard-logo.png"}
              id={i.id}
            />
          ))}
        </InstitutionIndexWrapper>
        <PaginatedListNavigator
          currentPage={pageNumber}
          setCurrentPage={setCurrentPage}
          maxPages={maxPages}
        />
      </div>
    </CargandoScreen>
  );
}

export default InstitutionIndex;
