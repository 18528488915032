import React, { ReactElement } from "react";
import "twin.macro";
import { InstitutionCategory } from "../../../types/backendTypes";

/**
 * Navbar en la cual se presentan las categorias institucionales
 *
 * @param props react props.
 * @param props.categories Lista con las categorias existentes.
 * @param props.selectedCategory La categoria seleccionada.
 * @param props.setSelectedCategory El setState para asignar la categoria seleccionada.
 */
function InstitutionalCategoriesNavbar(props: {
  categories: InstitutionCategory[];
  selectedCategory: InstitutionCategory;
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<InstitutionCategory>
  >;
}): ReactElement {
  const { categories, selectedCategory, setSelectedCategory } = props;

  /**
   * Funcion que maneja el cambio de categoria al seleccionar una.
   *
   * @param category Categoria clickeada.
   */
  function clickHandler(category: InstitutionCategory) {
    setSelectedCategory(category);
  }
  return (
    <div>
      <div
        tw="flex flex-row justify-between bg-resies_lightpurple font-sans 
            text-header2 font-semibold text-resies_blue1"
      >
        {categories.map((category) => (
          <button
            type="button"
            onClick={() => clickHandler(category)}
            className={
              selectedCategory.name === category.name
                ? "text-black text-opacity-50 px-10 py-2 bg-resies_lightgreen text-header2 font-semibold"
                : "text-black opacity-50 px-10 py-2 text-header2 font-semibold"
            }
            key={category.name}
          >
            {category.name}
          </button>
        ))}
      </div>
    </div>
  );
}

export default InstitutionalCategoriesNavbar;
