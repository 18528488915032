import { useContext, useEffect, useState } from "react";
import { AppContext } from "../dispatcher";
import { AdminInstitutionsOverview } from "../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../utils/fetchAPI";

/**
 * hook de overview de categorias
 */
function useCategoriesOverview(): [
  AdminInstitutionsOverview | undefined,
  boolean
] {
  const [overview, setOverview] = useState<AdminInstitutionsOverview>();
  const [ready, setReady] = useState(false);
  const [appState] = useContext(AppContext);

  useEffect(() => {
    if (appState.token) {
      fetchAPI<APIActions["adminIndicatorOverview"]>(
        APIRoutes.adminIndicatorOverview,
        { method: "GET" },
        appState.token
      ).then((ov) => {
        setOverview(ov);
        setReady(true);
      });
    }
  }, [appState.token]);

  return [overview, ready];
}

export default useCategoriesOverview;
