import React, { ReactElement, useState } from "react";
import "twin.macro";
import { useParams } from "react-router";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import AUDContactData from "../../components/admin/AUDContactData";
import PageTitle from "../../components/common/PageTitle";
import PageSection from "../../components/common/PageSection";
import ADMAUDAssignedInstitutions from "../../components/admin/ADMAUDAssignedInstitutions";
import VolverButton from "../../components/common/VolverButton";
import useAuditor from "../../hooks/useAuditor";
import AuditedPercentage from "../../components/admin/AuditedPercentage";
import Routes from "../../routers/routes";

/**
 * Perfil de auditor para administrador.
 */
function ADMCheckAUDProfile(): ReactElement {
  const [ready, setReady] = useState(false);
  const { audId } = useParams<{ audId: string }>();
  const [auditor, auditableResponses, reloadResponses] = useAuditor(
    setReady,
    audId
  );

  return (
    <CargandoScreen ready={ready}>
      <Container>
        <PageTitle
          title={`Auditor: ${auditor.first_name} ${auditor.last_name}`}
        />
        <div tw="grid grid-cols-2 mb-8">
          <div>
            <PageSection text="Datos Personales" />
            <div tw="w-full">
              <AUDContactData auditor={auditor} />
            </div>
          </div>
          <div tw="w-full">
            <div tw="h-full">
              <AuditedPercentage
                percentage={(() => {
                  const completed = auditableResponses.reduce(
                    (acc, response) =>
                      acc +
                      response.responses.reduce(
                        (acc2, res) =>
                          acc2 +
                          (res.audit_status === "accepted" ||
                          res.audit_status === "rejected"
                            ? 1
                            : 0),
                        0
                      ),
                    0
                  );
                  const total = auditableResponses.reduce(
                    (acc, response) => acc + response.responses.length,
                    0
                  );
                  return completed / (total !== 0 ? total : 1);
                })()}
              />
            </div>
          </div>
        </div>
        <ADMAUDAssignedInstitutions
          responses={auditableResponses}
          reloadResponses={reloadResponses}
        />
        <VolverButton to={Routes.auditorPortal} />
      </Container>
    </CargandoScreen>
  );
}

export default ADMCheckAUDProfile;
