/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-plusplus */
import { ReactElement, useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import { AppContext } from "../../dispatcher";
import { Institution } from "../../types/backendTypes";
import { ItemScore, ScorePattern } from "../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import useRESIESDates from "../../hooks/useRESIESDates";
import {
  BarChart,
  Bar,
  LabelList,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import { Link, useParams } from "react-router-dom";

const printCategoriesMargin: Record<string, any> = {
  "0": tw`print:mb-[350px]`,
  "1": tw`print:mb-[280px]`,
  "2": tw`print:mb-[310px]`,
  "3": tw`print:mb-[200px]`,
};

const RDIReports = (): ReactElement => {
  const [ready, setReady] = useState(false);
  const [state] = useContext(AppContext);
  const [institution, setInstitution] = useState<Institution>();
  const [institutionScore, setInstitutionScore] = useState<ScorePattern>();
  const [, , currentInstitutionProcess] = useRESIESDates();
  const { institutionId } = useParams<{ institutionId: string }>();

  useEffect(() => {
    if (!state.token) return;

    if (currentInstitutionProcess) {
      setInstitution(currentInstitutionProcess.institution);
      fetchAPI<APIActions["fetchInstitutionScores"]>(
        APIRoutes.fetchInstitutionScores,
        {
          method: "GET",
          routeParams: {
            institution_id: currentInstitutionProcess.institution.id.toString(),
          },
          queryParams: { strict_mode: "false" },
        },
        state.token
      ).then((score) => {
        setInstitutionScore({
          elements: [
            ...score.categories.map((c) => ({
              name: c.category_name,
              slug: c.category_slug,
              score: c.score,
              weighted_score: c.weighted_score,
              weight: c.weight,
              id: c.category_id,
              color: c.color,
              raw_subelements: c.indicators,
              subelements: c.indicators.reduce((rv: any, x: ItemScore) => {
                (rv[x["subcategory"]] = rv[x["subcategory"]] || []).push(x);
                return rv;
              }, {}),
            })),
          ],
          score: score.score,
        });
        setReady(true);
      });
    }
  }, [currentInstitutionProcess, state.token]);

  const getDataForBarChart = (
    elementCategory: string,
    elements: any[] | undefined
  ) => {
    if (elements) {
      const constructor: Record<string, string | number> = {
        name: elementCategory,
      };
      elements.forEach((element, index) => {
        constructor[`element_${index}`] = element.score;
        const name = element.name || element.slug;
        constructor[`element_${index}_name`] = name.split(" ")[0];
      });

      return [constructor];
    }
    return [];
  };

  const getDataForRadialChart = (
    elementCategory: string,
    elements: any[] | undefined
  ) => {
    if (elements) {
      let weightedSum: number = 0;
      const constructor = elements.map((element, index) => {
        weightedSum += element.weighted_score;
        if (element.weighted_score === 0) {
          return null;
        }
        return {
          name: element.name || element.slug,
          value: element.weighted_score,
          color: element.color,
        };
      });

      if (100 - weightedSum > 0) {
        constructor.push({
          name: "Sin avance",
          value: 100 - weightedSum,
          color: "#a2a2a2",
        });
      }

      return constructor.filter((elem) => elem !== null);
    }
    return [];
  };

  const CategoryReportContainer = (props: {
    color: string;
    categoryIndex: number;
    children: any;
  }) => {
    const { color, categoryIndex, children } = props;
    return (
      <div
        tw="p-5 border-t-2"
        css={printCategoriesMargin[categoryIndex.toString()]}
        style={{ backgroundColor: color }}
      >
        {children}
      </div>
    );
  };

  return (
    <Container>
      <CargandoScreen ready={ready}>
        {institution && institutionScore && (
          <div id="vista-principal" tw="flex flex-col justify-around">
            <div
              id="caja-superior"
              tw="bg-resies_lightgreen flex flex-row text-resies_blue1 justify-between"
            >
              <p tw="font-bold text-xl m-10 ml-5 print:ml-5">
                Reporte y evaluación de la sustentabilidad en Instituciones de
                Educación Superior
                {state.institutionId === "0" && (
                  <span>: {institution.name}</span>
                )}
              </p>

              <div tw="m-10 mr-5 print:hidden">
                <button onClick={() => window.print()}>Imprimir</button>
              </div>

              <div tw="m-10 mr-5 print:hidden">
                {institutionId === undefined ||
                institutionId === null ||
                institutionId === "0" ? (
                  <Link to={Routes.historicReports}>
                    Ver información histórica
                  </Link>
                ) : (
                  <Link
                    to={Routes.audInstitutionHistoricReport.replace(
                      ":institutionId",
                      institutionId.toString()
                    )}
                  >
                    Ver información histórica
                  </Link>
                )}
              </div>

              <div tw="m-10 mr-5 print:hidden">
                {institutionId === undefined ||
                institutionId === null ||
                institutionId === "0" ? (
                  <Link to={Routes.odsReports}>Agenda 2030</Link>
                ) : (
                  <Link
                    to={Routes.audInstitutionODSReport.replace(
                      ":institutionId",
                      institutionId.toString()
                    )}
                  >
                    Agenda 2030
                  </Link>
                )}
              </div>
            </div>

            <div>
              <div tw="flex mb-4 print:flex-col">
                <div tw="w-3/5 print:flex-1 print:w-full">
                  <table tw="w-full">
                    <tbody tw="flex flex-col items-center justify-between w-full">
                      <tr tw="flex w-full mb-4">
                        <td tw="p-4 w-2/6 my-2 bg-resies_lightpurple font-semibold text-header2 text-very_darkgray">
                          <table tw="h-full w-full">
                            <tbody tw="h-full w-full">
                              <tr tw="w-full font-semibold text-header2 text-left text-very_darkgray">
                                <td>RESULTADO GLOBAL</td>
                              </tr>
                              <tr tw="text-sm">
                                <td colSpan={2}>
                                  Sobre un total de 100 puntos la institución
                                  obtiene:
                                </td>
                              </tr>
                              <tr tw="font-semibold text-numberBig text-black text-center">
                                <td colSpan={2} tw="pt-5">
                                  {institutionScore.score.toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td tw="pl-4 w-full">
                          <table tw="my-2">
                            <tbody tw="flex flex-col items-center justify-between w-full">
                              <tr tw="flex w-full border-b">
                                <td tw="w-64"></td>
                                <td tw="w-20 text-center">Peso</td>
                                <td tw="w-20 text-center">Puntaje bruto</td>
                                <td tw="w-20 text-center">Puntaje ponderado</td>
                              </tr>
                              {institutionScore.elements.map(
                                (category, index) => (
                                  <tr
                                    tw="flex w-full border-b"
                                    style={{
                                      borderStyle:
                                        index !==
                                        institutionScore.elements.length - 1
                                          ? "dashed"
                                          : "solid",
                                    }}
                                  >
                                    <td
                                      tw="font-bold w-64 pt-1 pb-1"
                                      style={{
                                        backgroundColor: category.color,
                                      }}
                                    >
                                      {category.name}
                                    </td>
                                    <td tw="w-20 text-center">
                                      {category.weight.toFixed(2)}
                                    </td>
                                    <td tw="w-20 text-center">
                                      {category.score.toFixed(2)}
                                    </td>
                                    <td tw="w-20 text-center">
                                      {category.weighted_score.toFixed(2)}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div tw="w-2/5 print:flex-1 print:w-full">
                  <div tw="pl-10 flex overflow-x-scroll print:overflow-x-visible print:pl-5">
                    <div>
                      <h3 tw="mb-4">
                        <strong>Puntaje bruto</strong>
                      </h3>
                      <BarChart
                        width={350}
                        height={300}
                        data={getDataForBarChart(
                          "Categorías",
                          institutionScore.elements
                        )}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid vertical={false} strokeDasharray="4" />
                        <XAxis dataKey={"name"} />
                        <YAxis domain={[0, 100]} />
                        <Tooltip
                          shared={false}
                          formatter={(
                            value: string,
                            name: string,
                            props: any
                          ) => [value, props.payload[`${name}_name`]]}
                        />
                        {institutionScore.elements.map((element, index) => (
                          <Bar
                            isAnimationActive={false}
                            dataKey={`element_${index}`}
                            fill={element.color}
                          >
                            <LabelList
                              dataKey={`element_${index}_name`}
                              position="insideBottom"
                              offset={50}
                              angle={270}
                            />
                          </Bar>
                        ))}
                      </BarChart>
                    </div>

                    <div>
                      <h3 tw="mb-4">
                        <strong>Puntaje ponderado</strong>
                      </h3>
                      <div tw="ml-10">
                        <PieChart
                          width={440}
                          height={300}
                          margin={{
                            top: 0,
                            right: 0,
                            left: 70,
                            bottom: 0,
                          }}
                        >
                          <Pie
                            isAnimationActive={false}
                            data={getDataForRadialChart(
                              "Categorías",
                              institutionScore.elements
                            )}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            dataKey="value"
                            fill="#8884d8"
                            nameKey="name"
                            label={(props) =>
                              `${
                                props.name === "Sin avance"
                                  ? props.name
                                  : props.name.substr(0, 3)
                              }: ${props.value.toFixed(2)}%`
                            }
                            labelLine={true}
                          >
                            {getDataForRadialChart(
                              "Categorías",
                              institutionScore.elements
                            ).map((entry, index) => (
                              <Cell fill={entry?.color} />
                            ))}
                          </Pie>
                        </PieChart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h2 tw="text-header2 font-bold mb-4 ml-5 print:text-2xl print:mt-[330px] print:mb-5">
                Resultados por categoría
              </h2>

              {institutionScore.elements.map((category, index) => (
                <CategoryReportContainer
                  color={category.color}
                  categoryIndex={index}
                >
                  <h3 tw="text-header3 font-bold">{category.name}</h3>

                  <div tw="flex mb-4 print:flex-col">
                    <div tw="w-3/5 print:flex-1 print:w-full">
                      <table>
                        <thead>
                          <tr tw="border-b">
                            <th tw="w-56">Subcategoría</th>
                            <th tw="w-16 text-center">ID</th>
                            <th tw="w-80">Indicador</th>
                            <th tw="w-20 text-center">Peso</th>
                            <th tw="w-20 text-center">Puntaje bruto</th>
                            <th tw="w-20 text-center">Puntaje ponderado</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(category.subelements).map(
                            (subcategory: string, position) => (
                              <>
                                {category.subelements[subcategory].map(
                                  (
                                    indicator: ItemScore,
                                    indicatorIndex: number
                                  ) => (
                                    <tr
                                      style={{
                                        borderBottom:
                                          indicatorIndex ===
                                          category.subelements[subcategory]
                                            .length -
                                            1
                                            ? "1px dashed"
                                            : "",
                                      }}
                                    >
                                      {indicatorIndex === 0 && (
                                        <td
                                          tw="w-56"
                                          rowSpan={
                                            category.subelements[subcategory]
                                              .length
                                          }
                                        >
                                          {subcategory}
                                        </td>
                                      )}
                                      <td tw="w-16 text-center">
                                        {indicator.slug}
                                      </td>
                                      <td tw="w-80">{indicator.title}</td>
                                      <td tw="w-20 text-center">
                                        {indicator.weight.toFixed(2)}
                                      </td>
                                      <td tw="w-20 text-center">
                                        {indicator.score.toFixed(2)}
                                      </td>
                                      <td tw="w-20 text-center">
                                        {indicator.weighted_score.toFixed(2)}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div tw="w-2/5 print:flex-1 print:w-full print:mt-5">
                      <div tw="pl-10 flex overflow-x-scroll print:overflow-x-visible print:pl-0">
                        <div>
                          <h3 tw="mb-4">
                            <strong>Puntaje bruto</strong>
                          </h3>
                          <BarChart
                            width={390}
                            height={315}
                            data={getDataForBarChart(
                              category.name,
                              category.raw_subelements
                            )}
                            margin={{
                              top: 0,
                              right: 0,
                              left: 0,
                              bottom: 15,
                            }}
                          >
                            <CartesianGrid
                              vertical={false}
                              strokeDasharray="4"
                            />
                            <XAxis dataKey={"name"} />
                            <YAxis domain={[0, 100]} />
                            <Tooltip
                              shared={false}
                              formatter={(
                                value: string,
                                name: string,
                                props: any
                              ) => [value, props.payload[`${name}_name`]]}
                            />
                            {category.raw_subelements &&
                              category.raw_subelements.map((element, index) => (
                                <Bar
                                  isAnimationActive={false}
                                  dataKey={`element_${index}`}
                                  fill={element.color}
                                >
                                  <LabelList
                                    dataKey={`element_${index}_name`}
                                    position="insideBottom"
                                    offset={50}
                                    angle={270}
                                  />
                                </Bar>
                              ))}
                          </BarChart>
                        </div>

                        <div>
                          <h3 tw="mb-4">
                            <strong>Puntaje ponderado</strong>
                          </h3>
                          <div tw="ml-10">
                            <PieChart
                              width={400}
                              height={300}
                              margin={{
                                top: 0,
                                right: 0,
                                left: 70,
                                bottom: 0,
                              }}
                            >
                              <Pie
                                isAnimationActive={false}
                                data={getDataForRadialChart(
                                  category.name,
                                  category.raw_subelements
                                )}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={80}
                                dataKey="value"
                                fill="#8884d8"
                                nameKey="name"
                                label={(props) =>
                                  `${props.name}: ${props.value.toFixed(2)}%`
                                }
                                labelLine={true}
                              >
                                {getDataForRadialChart(
                                  category.name,
                                  category.raw_subelements
                                ).map((entry, index) => (
                                  <Cell fill={entry?.color} />
                                ))}
                              </Pie>
                            </PieChart>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CategoryReportContainer>
              ))}
            </div>
          </div>
        )}
      </CargandoScreen>

      {institution && state.institutionId === "0" && (
        <VolverButton to={Routes.myInstitutions} />
      )}
    </Container>
  );
};

export default RDIReports;
