import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import InstitutionViewButton from "../../components/common/InstitutionViewButton";
import PageTitle from "../../components/common/PageTitle";
import Routes from "../../routers/routes";
import MembersMyInstitution from "../../components/rdi/MembersMyInstitution";
import ResultsBox from "../../components/common/ResultsBox";
import CategoryProgressRow from "../../components/common/CategoryProgressRow";
import { AppContext } from "../../dispatcher";
import { Institution } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "../../components/common/CargandoScreen";

/**
 * La página de información de institución del RDI
 */
function RDIMyInstitution(): ReactElement {
  const [appState] = useContext(AppContext);
  const [institution, setInstitution] = useState<Institution>({
    name: "",
    description: "",
    id: 0,
    photo_url: "",
    logo_url: "",
    member_since: "",
  });
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (!appState.token || !appState.institutionId) return;
    fetchAPI<APIActions["fetchInstitutionDetails"]>(
      APIRoutes.fetchInstitutionDetails,
      {
        method: "GET",
        routeParams: { institution_id: appState.institutionId },
      },
      appState.token
    )
      .then((details) => {
        setInstitution(details.institution);
        setReady(true);
      })
      .catch(() => {}); // TODO
  }, [appState]);
  return (
    <div tw="bg-ghostwhite">
      <CargandoScreen ready={ready}>
        <Container>
          {appState.institutionId ? (
            <PageTitle
              title={`Administración de ${institution.name}`}
              institutionId={institution.id.toString()}
            />
          ) : (
            <></>
          )}
          <div tw="flex flex-row justify-between space-x-10 mb-10">
            <InstitutionViewButton
              label="Asignar Indicadores"
              href={Routes.assignMDI}
            />
            <InstitutionViewButton
              label="Asignar Datos Institucionales"
              href={Routes.assignInstitutionalMDI}
            />
            <InstitutionViewButton
              label="Indicadores"
              href={Routes.indicators}
            />
            <InstitutionViewButton
              label="Datos de la institución"
              href={Routes.institutionData}
            />
            <InstitutionViewButton
              label="Invitar Informante"
              href={Routes.inviteMDI}
            />
          </div>
          <MembersMyInstitution />
          <CategoryProgressRow wantProgressbar />
          <div tw="my-28 w-full">
            <ResultsBox />
          </div>
        </Container>
      </CargandoScreen>
    </div>
  );
}

export default RDIMyInstitution;
