import React, { ReactElement } from "react";
import "twin.macro";
/**
 * Función de títutlo formateado de las páginas para cuando se requiere de un subtítulo
 *
 * @param props react props.
 * @param props.title El texto que deberá mostrarse como título
 * @param props.subtitle El texto que deberá mostrarse como subtítulo
 */
function PageTitleSubtitle(props: {
  title: string;
  subtitle: string;
}): ReactElement {
  const { title, subtitle } = props;
  return (
    <div>
      <div tw="bg-resies_lightgreen font-sans text-header1 font-bold text-resies_blue1 py-2 px-6">
        {title}
      </div>
      <div tw="bg-resies_blue2 font-sans font-bold text-barra text-ghostwhite px-6 mb-12">
        {subtitle}
      </div>
    </div>
  );
}

export default PageTitleSubtitle;
