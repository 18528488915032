import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import { Institution, User } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import Modal from "../common/Modal";

/**
 * Función que devuelve una fila de la tabla de usuarios
 *
 * @param props react props
 * @param props.user El usuario a mostrar en la tabla
 * @param props.institutionList la lista de instituciones existentes
 * @param props.reloadUsers recarga los usuarios
 */
function UsersListRow(props: {
  user: User;
  institutionList: Institution[];
  reloadUsers: () => void;
}): ReactElement {
  const [appState] = useContext(AppContext);
  const { user, institutionList, reloadUsers } = props;

  const [editingInstitution, setEditingInstitution] = useState(false);
  const [editingRole, setEditingRole] = useState(false);

  const institutionRef = useRef<HTMLSelectElement>(null);
  const roleRef = useRef<HTMLSelectElement>(null);
  /**
   * obtener institucion de usuario de forma legible
   */
  function getInstitution(): string {
    if (user.user_type === "ADM") {
      return "N/A";
    }
    if (user.user_type === "RDI") {
      return user.rdiuser?.institution?.name || "N/A";
    }
    if (user.user_type === "MDI") {
      return user.mdiuser?.institution?.name || "N/A";
    }
    if (user.user_type === "AUD") {
      if (user.auduser?.institution_set.length) {
        return `${user.auduser?.institution_set.length} instituciones`;
      }
      return "Sin instituciones";
    }
    return "N/A";
  }

  /**
   * obtener id de institución de usuario
   */
  function getInstitutionId(): number {
    if (user.user_type === "ADM") {
      return -1;
    }
    if (user.user_type === "RDI") {
      return user.rdiuser?.institution?.id || -1;
    }
    if (user.user_type === "MDI") {
      return user.mdiuser?.institution?.id || -1;
    }
    return -1;
  }

  /**
   * obtener rol de usuario de forma legible
   */
  function getRole(): string {
    if (user.user_type === "ADM") {
      return "Administrador(a)";
    }
    if (user.user_type === "AUD") {
      return "Auditor(a)";
    }
    if (user.user_type === "MDI") {
      return "Informante";
    }
    if (user.user_type === "RDI") {
      return "Responsable de institución";
    }
    return "N/A";
  }

  /**
   * Guarda la nueva institución del usuario
   */
  function saveEditingInstitution() {
    const institutionId = institutionRef.current?.value;
    if (!institutionId || !appState.token) return;

    fetchAPI<APIActions["assignInstitution"]>(
      APIRoutes.assignInstitution,
      {
        method: "POST",
        routeParams: {
          user_id: user.id.toString(),
          institution_id: institutionId,
        },
      },
      appState.token
    ).then(() => {
      reloadUsers();
      setEditingInstitution(false);
    });
  }

  /**
   * Guarda la nueva institución del usuario
   */
  function saveEditingRole() {
    const newRole = roleRef.current?.value;
    if (
      !(
        newRole === "ADM" ||
        newRole === "AUD" ||
        newRole === "RDI" ||
        newRole === "MDI" ||
        newRole === "UNA"
      ) ||
      !appState.token
    )
      return;

    fetchAPI<APIActions["assignRole"]>(
      APIRoutes.assignRole,
      {
        method: "POST",
        routeParams: {
          userId: user.id.toString(),
        },
        body: {
          role: newRole,
        },
      },
      appState.token
    ).then(() => {
      if (newRole === "ADM" || newRole === "AUD") {
        setEditingInstitution(false);
      }
      reloadUsers();
      setEditingRole(false);
    });
  }
  return (
    <tr key={user.id} tw="border-b border-gray h-10">
      <td>{`${user.first_name} ${user.last_name}`}</td>
      <td>{user.email}</td>
      <td>
        <div tw="flex flex-row">
          {editingInstitution &&
          !(user.user_type === "ADM" || user.user_type === "AUD") ? (
            <>
              <select tw="w-full" ref={institutionRef}>
                {institutionList.map((ins) => (
                  <option
                    value={ins.id}
                    selected={ins.id === getInstitutionId()}
                  >
                    {ins.name}
                  </option>
                ))}
              </select>
              <div>
                <button type="button" onClick={() => saveEditingInstitution()}>
                  <FontAwesomeIcon icon={faSave} />
                </button>
              </div>
            </>
          ) : (
            <>
              <div tw="w-full">{getInstitution()}</div>
              {(user.user_type === "MDI" || user.user_type === "RDI") && (
                <div>
                  <button
                    type="button"
                    onClick={() => setEditingInstitution(true)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </td>
      <td>
        {editingRole ? (
          <>
            <select ref={roleRef}>
              <option value="ADM" selected={user.user_type === "ADM"}>
                Administrador(a)
              </option>
              <option value="AUD" selected={user.user_type === "AUD"}>
                Auditor(a)
              </option>
              <option value="RDI" selected={user.user_type === "RDI"}>
                Responsable de Institución
              </option>
              <option value="MDI" selected={user.user_type === "MDI"}>
                Informante
              </option>
              <option
                value="UNA"
                selected={user.user_type === "UNA" || !user.user_type}
              >
                N/A
              </option>
            </select>
            <div>
              <button type="button" onClick={() => saveEditingRole()}>
                <FontAwesomeIcon icon={faSave} />
              </button>
            </div>
          </>
        ) : (
          <>
            <div tw="w-full">{getRole()}</div>

            <div>
              <button type="button" onClick={() => setEditingRole(true)}>
                <FontAwesomeIcon icon={faEdit} />
              </button>
            </div>
          </>
        )}
      </td>
      <td>
        <Modal user={user} />
      </td>
      <td>
        <Link to={Routes.deleteUser.replace(":userId", user.id.toString())}>
          <button
            type="button"
            tw="bg-darkred text-ghostwhite px-4 rounded-md disabled:cursor-default disabled:bg-gray"
            disabled={user.id.toString() === appState.userId}
          >
            Eliminar
          </button>
        </Link>
      </td>
    </tr>
  );
}
export default UsersListRow;
