import React, { ReactElement } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import EvidenceRejectedIndex from "../../components/rdi/EvidenceRejectedIndex";
import RDIEvidenceNavigationBar from "./RDIEvidenceNavigationBar";

/**
 * Página para el RDI con la evidencia rechazada de su institución.
 */
function RDIRejectedEvidence(): ReactElement {
  return (
    <Container>
      <RDIEvidenceNavigationBar />
      <EvidenceRejectedIndex />
    </Container>
  );
}

export default RDIRejectedEvidence;
