import React, { ReactElement } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import AppealsIndex from "../../components/rdi/AppealsIndex";
import RDIEvidenceNavigationBar from "./RDIEvidenceNavigationBar";

/**
 * Página para el RDI con sus apelaciones.
 */
function RDIAppealedEvidence(): ReactElement {
  return (
    <Container>
      <RDIEvidenceNavigationBar />
      <AppealsIndex />
    </Container>
  );
}

export default RDIAppealedEvidence;
