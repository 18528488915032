import React, { ChangeEvent, ReactElement } from "react";
import "twin.macro";
import { Institution } from "../../types/backendTypes";
import SearchBar from "../common/SearchBar";
import StyledSelectElement from "../common/StyledSelectElement";

type OptionType = {
  text: string;
  value: string | number;
};

/**
 * Caja para filtrar usuarios.
 *
 * @param props react props
 * @param props.userSearch busqueda del usuario
 * @param props.userSearchChangeHandler handler de busqueda
 * @param props.institutionSearch busqueda de institucion
 * @param props.institutionSearchChangeHandler handler de busqueda de institucion
 * @param props.roleSearch busqueda de rol
 * @param props.roleSearchChangeHandler handler de busqueda de institucion
 * @param props.institutions instituciones
 */
function ADMUserFilterBox(props: {
  userSearch: string;
  userSearchChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  institutionSearch: string;
  institutionSearchChangeHandler: (e: ChangeEvent<HTMLSelectElement>) => void;
  roleSearch: string;
  roleSearchChangeHandler: (e: ChangeEvent<HTMLSelectElement>) => void;
  institutions: Array<Institution>;
}): ReactElement {
  const {
    userSearch,
    userSearchChangeHandler,
    institutionSearch,
    institutionSearchChangeHandler,
    roleSearch,
    roleSearchChangeHandler,
    institutions,
  } = props;
  return (
    <div tw="bg-resies_lightgreen py-2 px-4 pb-4 h-full">
      <div tw="text-resies_blue1 font-bold text-header2">
        Buscar usuarios de RESIES
      </div>
      <div tw="text-body text-very_darkgray mt-2 mb-2">
        Acá puedes filtrar según varias opciones para facilitar la búsqueda
      </div>
      <div tw="text-resies_blue1 font-bold text-header2 mt-6">
        Filtrar por Nombre
      </div>
      <SearchBar
        searchText={userSearch}
        onChangeHandler={userSearchChangeHandler}
      />
      <div tw="text-resies_blue1 font-bold text-header2 mt-6">
        Filtrar por Institución
      </div>
      <StyledSelectElement
        options={(
          [{ text: "Selecciona una institución", value: "" }] as OptionType[]
        ).concat(
          institutions.map<{
            text: string;
            value: string | number;
          }>((ins) => ({
            text: ins.name,
            value: ins.id,
          }))
        )}
        selectedValue={institutionSearch}
        selectedValueChangeHandler={institutionSearchChangeHandler}
      />
      <div tw="text-resies_blue1 font-bold text-header2 mt-6">
        Filtrar por Rol
      </div>
      <StyledSelectElement
        options={[
          { text: "Selecciona un rol", value: "" },
          { text: "Informante de institución", value: "MDI" },
          { text: "Responsable de institución", value: "RDI" },
          { text: "Auditor", value: "AUD" },
          { text: "Administrador", value: "ADM" },
        ]}
        selectedValue={roleSearch}
        selectedValueChangeHandler={roleSearchChangeHandler}
      />
    </div>
  );
}

export default ADMUserFilterBox;
