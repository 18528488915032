import { AppState } from "../../dispatcher";
import { CategoryOverview } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../fetchAPI";

/**
 * Tipo para ver el progreso por categoria
 */
type CategoryProgress = {
  categoryName: string;
  progress: { completed_indicators: number; total_indicators: number };
};

/**
 * Funcion que obtiene el progreso de cada categoria
 *
 * @param appState Estado general de la aplicacion
 * @param setCategoriesProgress setState que cambia el nivel de progreso de las categorias
 * @param setPercentageCompleted setState que cambia el nivel general de completitud de indicadores
 * @param institutionId id de institucion
 * @param setCategoriesData setter de los datos de cada categoria.
 */
function obtainCategoriesProgress(
  appState: AppState,
  setCategoriesProgress: React.Dispatch<
    React.SetStateAction<CategoryProgress[]>
  >,
  setPercentageCompleted: React.Dispatch<React.SetStateAction<number>>,
  institutionId: string,
  setCategoriesData: React.Dispatch<React.SetStateAction<CategoryOverview[]>>
): Promise<void> {
  let totalCompleted = 0;
  let totalIndicators = 0;
  const categoriesArray: CategoryProgress[] = [];
  let apiKey = "";
  if (appState.token !== null) {
    apiKey = appState.token;
  }
  if (apiKey && appState.userType === "ADM") {
    return (
      fetchAPI<APIActions["indicatorOverviewByInstitution"]>(
        APIRoutes.indicatorOverviewByInstitution,
        {
          method: "GET",
          routeParams: {
            institution_id: institutionId,
          },
        },
        apiKey
      )
        .then((data) => {
          setCategoriesData([...data.categories]);
          data.categories.sort((a, b) => a.id - b.id);
          data.categories.forEach((categorie) => {
            const completed = categorie.indicators.filter(
              (ind) => ind.status === "complete"
            ).length;
            const total = categorie.indicators.length;
            totalCompleted += completed;
            totalIndicators += total;
            categoriesArray.push({
              categoryName: categorie.name,
              progress: {
                completed_indicators: completed,
                total_indicators: total,
              },
            });
          });
        })
        .then(() => {
          setCategoriesProgress(categoriesArray);
          setPercentageCompleted(
            parseFloat(
              (
                (totalCompleted / totalIndicators + Number.EPSILON) *
                100
              ).toFixed(1)
            )
          );
        })
        // eslint-disable-next-line no-console
        .catch(() => console.log("LOL, error..."))
    );
  }
  if (apiKey && (appState.userType === "RDI" || appState.userType === "MDI")) {
    return (
      fetchAPI<APIActions["indicatorOverviewByUsersInstitution"]>(
        APIRoutes.indicatorOverviewByUsersInstitution,
        {
          method: "GET",
        },
        apiKey
      )
        .then((data) => {
          setCategoriesData([...data.categories]);
          data.categories.sort((a, b) => a.id - b.id);
          data.categories.forEach((categorie) => {
            const completed = categorie.indicators.filter(
              (ind) => ind.status === "complete"
            ).length;
            const total = categorie.indicators.length;
            totalCompleted += completed;
            totalIndicators += total;
            categoriesArray.push({
              categoryName: categorie.name,
              progress: {
                completed_indicators: completed,
                total_indicators: total,
              },
            });
          });
        })
        .then(() => {
          setCategoriesProgress(categoriesArray);
          setPercentageCompleted(
            parseFloat(
              (
                (totalCompleted / totalIndicators + Number.EPSILON) *
                100
              ).toFixed(1)
            )
          );
        })
        // eslint-disable-next-line no-console
        .catch(() => console.log("LOL, error..."))
    );
  }
  return Promise.resolve();
}

export default obtainCategoriesProgress;
