import { AppState } from "../../dispatcher";
import { InstitutionalDataVariable } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../fetchAPI";

/**
 * Funcion que obtiene los datos de una variable de un indicador
 *
 * @param appState Estado general de la aplicación
 * @param variableId Id de la variable
 * @param setEvaluationVariable setState de la variable
 * @param setError setState que indica si es que ocurrio un error
 */
function getInstitutionalVariableDetails(
  appState: AppState,
  variableId: string,
  setEvaluationVariable: React.Dispatch<
    React.SetStateAction<InstitutionalDataVariable | undefined>
  >,
  setError: React.Dispatch<React.SetStateAction<boolean>>
): void {
  if (!appState.token) {
    return;
  }
  const { token } = appState;
  fetchAPI<APIActions["getInstitutionalVariable"]>(
    APIRoutes.getInstitutionalVariable,
    {
      method: "GET",
      routeParams: { variable_id: variableId },
    },
    token
  )
    .then((vari) => setEvaluationVariable(vari))
    .catch(() => {
      setError(true);
    });
}
export default getInstitutionalVariableDetails;
