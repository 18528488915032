import React, { ReactElement } from "react";
import "twin.macro";
import Routes from "../../routers/routes";
import InsideNavigationBar from "../common/InsideNavigationBar";

/**
 * navbar de administrador
 */
function ADMPanelNavbar(): ReactElement {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <InsideNavigationBar
      items={[
        { name: "Instituciones", href: Routes.admPanelInstitutions },
        { name: "Usuarios", href: Routes.admPanelUsers },
      ]}
      title="Panel de Administración"
    />
  );
}

export default ADMPanelNavbar;
