// Generated from ./ExcelLangGrammar.g4 by ANTLR 4.9.0-SNAPSHOT

import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

export class ExcelLangGrammarLexer extends Lexer {
  public static readonly SI = 1;
  public static readonly SUMA = 2;
  public static readonly CLIP = 3;
  public static readonly LPAREN = 4;
  public static readonly RPAREN = 5;
  public static readonly COMMA = 6;
  public static readonly PLUS = 7;
  public static readonly MINUS = 8;
  public static readonly DIVSIGN = 9;
  public static readonly MULSIGN = 10;
  public static readonly GTSIGN = 11;
  public static readonly LTSIGN = 12;
  public static readonly GEQSIGN = 13;
  public static readonly LEQSIGN = 14;
  public static readonly EQSIGN = 15;
  public static readonly NUMBER = 16;
  public static readonly VAR = 17;
  public static readonly DI = 18;
  public static readonly AUX = 19;
  public static readonly WS = 20;

  // tslint:disable:no-trailing-whitespace
  public static readonly channelNames: string[] = [
    "DEFAULT_TOKEN_CHANNEL",
    "HIDDEN",
  ];

  // tslint:disable:no-trailing-whitespace
  public static readonly modeNames: string[] = ["DEFAULT_MODE"];

  public static readonly ruleNames: string[] = [
    "SI",
    "SUMA",
    "CLIP",
    "LPAREN",
    "RPAREN",
    "COMMA",
    "PLUS",
    "MINUS",
    "DIVSIGN",
    "MULSIGN",
    "GTSIGN",
    "LTSIGN",
    "GEQSIGN",
    "LEQSIGN",
    "EQSIGN",
    "NUMBER",
    "VAR",
    "DI",
    "AUX",
    "WS",
  ];

  private static readonly _LITERAL_NAMES: Array<string | undefined> = [
    undefined,
    "'SI'",
    "'SUMA'",
    "'CLIP'",
    "'('",
    "')'",
    "','",
    "'+'",
    "'-'",
    "'/'",
    "'*'",
    "'>'",
    "'<'",
    "'>='",
    "'<='",
    "'='",
  ];
  private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
    undefined,
    "SI",
    "SUMA",
    "CLIP",
    "LPAREN",
    "RPAREN",
    "COMMA",
    "PLUS",
    "MINUS",
    "DIVSIGN",
    "MULSIGN",
    "GTSIGN",
    "LTSIGN",
    "GEQSIGN",
    "LEQSIGN",
    "EQSIGN",
    "NUMBER",
    "VAR",
    "DI",
    "AUX",
    "WS",
  ];
  public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(
    ExcelLangGrammarLexer._LITERAL_NAMES,
    ExcelLangGrammarLexer._SYMBOLIC_NAMES,
    []
  );

  // @Override
  // @NotNull
  public get vocabulary(): Vocabulary {
    return ExcelLangGrammarLexer.VOCABULARY;
  }
  // tslint:enable:no-trailing-whitespace

  constructor(input: CharStream) {
    super(input);
    this._interp = new LexerATNSimulator(ExcelLangGrammarLexer._ATN, this);
  }

  // @Override
  public get grammarFileName(): string {
    return "ExcelLangGrammar.g4";
  }

  // @Override
  public get ruleNames(): string[] {
    return ExcelLangGrammarLexer.ruleNames;
  }

  // @Override
  public get serializedATN(): string {
    return ExcelLangGrammarLexer._serializedATN;
  }

  // @Override
  public get channelNames(): string[] {
    return ExcelLangGrammarLexer.channelNames;
  }

  // @Override
  public get modeNames(): string[] {
    return ExcelLangGrammarLexer.modeNames;
  }

  public static readonly _serializedATN: string =
    "\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\x16\x8C\b\x01" +
    "\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06" +
    "\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r" +
    "\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t" +
    "\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x03\x02\x03\x02\x03\x02" +
    "\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x04\x03\x04\x03\x04\x03\x04" +
    "\x03\x04\x03\x05\x03\x05\x03\x06\x03\x06\x03\x07\x03\x07\x03\b\x03\b\x03" +
    "\t\x03\t\x03\n\x03\n\x03\v\x03\v\x03\f\x03\f\x03\r\x03\r\x03\x0E\x03\x0E" +
    "\x03\x0E\x03\x0F\x03\x0F\x03\x0F\x03\x10\x03\x10\x03\x11\x03\x11\x07\x11" +
    "U\n\x11\f\x11\x0E\x11X\v\x11\x03\x11\x05\x11[\n\x11\x03\x11\x03\x11\x06" +
    "\x11_\n\x11\r\x11\x0E\x11`\x05\x11c\n\x11\x03\x12\x03\x12\x03\x12\x03" +
    "\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x07\x12o\n\x12\f" +
    "\x12\x0E\x12r\v\x12\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03" +
    "\x13\x03\x13\x03\x13\x07\x13}\n\x13\f\x13\x0E\x13\x80\v\x13\x03\x14\x03" +
    "\x14\x07\x14\x84\n\x14\f\x14\x0E\x14\x87\v\x14\x03\x15\x03\x15\x03\x15" +
    "\x03\x15\x02\x02\x02\x16\x03\x02\x03\x05\x02\x04\x07\x02\x05\t\x02\x06" +
    "\v\x02\x07\r\x02\b\x0F\x02\t\x11\x02\n\x13\x02\v\x15\x02\f\x17\x02\r\x19" +
    "\x02\x0E\x1B\x02\x0F\x1D\x02\x10\x1F\x02\x11!\x02\x12#\x02\x13%\x02\x14" +
    "'\x02\x15)\x02\x16\x03\x02\x05\x04\x02C\\c|\x06\x022;C\\aac|\x05\x02" +
    '\v\f\x0F\x0F""\x02\x92\x02\x03\x03\x02\x02\x02\x02\x05\x03\x02\x02\x02' +
    "\x02\x07\x03\x02\x02\x02\x02\t\x03\x02\x02\x02\x02\v\x03\x02\x02\x02\x02" +
    "\r\x03\x02\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02\x02\x02\x02" +
    "\x13\x03\x02\x02\x02\x02\x15\x03\x02\x02\x02\x02\x17\x03\x02\x02\x02\x02" +
    "\x19\x03\x02\x02\x02\x02\x1B\x03\x02\x02\x02\x02\x1D\x03\x02\x02\x02\x02" +
    "\x1F\x03\x02\x02\x02\x02!\x03\x02\x02\x02\x02#\x03\x02\x02\x02\x02%\x03" +
    "\x02\x02\x02\x02'\x03\x02\x02\x02\x02)\x03\x02\x02\x02\x03+\x03\x02\x02" +
    "\x02\x05.\x03\x02\x02\x02\x073\x03\x02\x02\x02\t8\x03\x02\x02\x02\v:\x03" +
    "\x02\x02\x02\r<\x03\x02\x02\x02\x0F>\x03\x02\x02\x02\x11@\x03\x02\x02" +
    "\x02\x13B\x03\x02\x02\x02\x15D\x03\x02\x02\x02\x17F\x03\x02\x02\x02\x19" +
    "H\x03\x02\x02\x02\x1BJ\x03\x02\x02\x02\x1DM\x03\x02\x02\x02\x1FP\x03\x02" +
    "\x02\x02!Z\x03\x02\x02\x02#d\x03\x02\x02\x02%s\x03\x02\x02\x02'\x81\x03" +
    "\x02\x02\x02)\x88\x03\x02\x02\x02+,\x07U\x02\x02,-\x07K\x02\x02-\x04\x03" +
    "\x02\x02\x02./\x07U\x02\x02/0\x07W\x02\x0201\x07O\x02\x0212\x07C\x02\x02" +
    "2\x06\x03\x02\x02\x0234\x07E\x02\x0245\x07N\x02\x0256\x07K\x02\x0267\x07" +
    "R\x02\x027\b\x03\x02\x02\x0289\x07*\x02\x029\n\x03\x02\x02\x02:;\x07+" +
    "\x02\x02;\f\x03\x02\x02\x02<=\x07.\x02\x02=\x0E\x03\x02\x02\x02>?\x07" +
    "-\x02\x02?\x10\x03\x02\x02\x02@A\x07/\x02\x02A\x12\x03\x02\x02\x02BC\x07" +
    "1\x02\x02C\x14\x03\x02\x02\x02DE\x07,\x02\x02E\x16\x03\x02\x02\x02FG\x07" +
    "@\x02\x02G\x18\x03\x02\x02\x02HI\x07>\x02\x02I\x1A\x03\x02\x02\x02JK\x07" +
    "@\x02\x02KL\x07?\x02\x02L\x1C\x03\x02\x02\x02MN\x07>\x02\x02NO\x07?\x02" +
    "\x02O\x1E\x03\x02\x02\x02PQ\x07?\x02\x02Q \x03\x02\x02\x02RV\x043;\x02" +
    "SU\x042;\x02TS\x03\x02\x02\x02UX\x03\x02\x02\x02VT\x03\x02\x02\x02VW\x03" +
    "\x02\x02\x02W[\x03\x02\x02\x02XV\x03\x02\x02\x02Y[\x072\x02\x02ZR\x03" +
    "\x02\x02\x02ZY\x03\x02\x02\x02[b\x03\x02\x02\x02\\^\x070\x02\x02]_\x04" +
    "2;\x02^]\x03\x02\x02\x02_`\x03\x02\x02\x02`^\x03\x02\x02\x02`a\x03\x02" +
    '\x02\x02ac\x03\x02\x02\x02b\\\x03\x02\x02\x02bc\x03\x02\x02\x02c"\x03' +
    "\x02\x02\x02de\x07a\x02\x02ef\x07a\x02\x02fg\x07x\x02\x02gh\x07c\x02\x02" +
    "hi\x07t\x02\x02ij\x07a\x02\x02jk\x07a\x02\x02kl\x03\x02\x02\x02lp\x04" +
    "3;\x02mo\x042;\x02nm\x03\x02\x02\x02or\x03\x02\x02\x02pn\x03\x02\x02\x02" +
    "pq\x03\x02\x02\x02q$\x03\x02\x02\x02rp\x03\x02\x02\x02st\x07a\x02\x02" +
    "tu\x07a\x02\x02uv\x07f\x02\x02vw\x07k\x02\x02wx\x07a\x02\x02xy\x07a\x02" +
    "\x02yz\x03\x02\x02\x02z~\x043;\x02{}\x042;\x02|{\x03\x02\x02\x02}\x80" +
    "\x03\x02\x02\x02~|\x03\x02\x02\x02~\x7F\x03\x02\x02\x02\x7F&\x03\x02\x02" +
    "\x02\x80~\x03\x02\x02\x02\x81\x85\t\x02\x02\x02\x82\x84\t\x03\x02\x02" +
    "\x83\x82\x03\x02\x02\x02\x84\x87\x03\x02\x02\x02\x85\x83\x03\x02\x02\x02" +
    "\x85\x86\x03\x02\x02\x02\x86(\x03\x02\x02\x02\x87\x85\x03\x02\x02\x02" +
    "\x88\x89\t\x04\x02\x02\x89\x8A\x03\x02\x02\x02\x8A\x8B\b\x15\x02\x02\x8B" +
    "*\x03\x02\x02\x02\n\x02VZ`bp~\x85\x03\b\x02\x02";
  public static __ATN: ATN;
  public static get _ATN(): ATN {
    if (!ExcelLangGrammarLexer.__ATN) {
      ExcelLangGrammarLexer.__ATN = new ATNDeserializer().deserialize(
        Utils.toCharArray(ExcelLangGrammarLexer._serializedATN)
      );
    }

    return ExcelLangGrammarLexer.__ATN;
  }
}
