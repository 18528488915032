/* eslint-disable no-nested-ternary */
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import Routes from "../../../routers/routes";
import {
  InstitutionalDataVariable,
  Process,
} from "../../../types/backendTypes";
import { InstitutionalVariableForm } from "../../../types/utilTypes";

/**
 * Formulario para modificar un indicador de los datos de la institución.
 *
 * @param props react props
 * @param props.slug nombre del indicador
 * @param props.variables todas las variables en un diccionario
 * @param props.variablesForm Diccionario de la forma { id : descripción } que guarda el estado del formulario
 * @param props.changeHandler función que maneja el estado del formulario
 * @param props.descriptionChangeHandler funcion que maneja el cambio en las descripciones.
 * @param props.currentProcess proceso actual de resies
 * */
function DataForm(props: {
  slug: string;
  variables: Record<string, InstitutionalDataVariable>;
  variablesForm: InstitutionalVariableForm[];
  changeHandler: (
    key: number,
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => void;
  descriptionChangeHandler: (
    key: number,
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => void;
  currentProcess: Process | undefined;
}): ReactElement {
  const {
    slug,
    variables,
    variablesForm,
    changeHandler,
    descriptionChangeHandler,
    currentProcess,
  } = props;
  const variable = variables[slug];
  const elem = variablesForm.filter((va) => va.variable_id === variable.id)[0];
  const key = variablesForm.indexOf(elem);
  let status = "N/A";
  if (elem) {
    if (
      elem.audit_status === "accepted" ||
      elem.audit_status === "final_accepted"
    ) {
      status = "Auditado";
    } else if (elem.audit_status === "pending") {
      status = "Por auditar";
    } else {
      status = "Rechazado";
    }
  }
  return (
    <>
      {variable ? (
        <tr tw="flex flex-col w-full text-body text-black">
          <div tw="flex min-h-40 py-3 flex-wrap content-center justify-between">
            <th tw="w-1/12 text-center">{slug}</th>
            <th tw="w-2/12 text-left">{variable.description}</th>
            <th tw="w-2/12 text-left px-10">
              {variablesForm[key]?.type === "MULTIPLE_CHOICE" ? (
                <select
                  data-testid={`s${elem.variable_id}`}
                  value={variablesForm[key] ? variablesForm[key].response : ""}
                  tw="mx-auto w-full"
                  name="value"
                  onChange={(e) => changeHandler(key, e)}
                  disabled={currentProcess?.name !== "Llenado de Indicadores"}
                >
                  {!elem.response ? <option value="">Nada</option> : <></>}
                  {elem.json_config.map(
                    (opt: { option_text: string; option_score: number }) => (
                      <option value={opt.option_text} key={opt.option_text}>
                        {opt.option_text}
                      </option>
                    )
                  )}
                </select>
              ) : (
                <></>
              )}
              {variablesForm[key]?.type === "NUMBER" ? (
                <input
                  value={variablesForm[key] ? variablesForm[key].response : 0}
                  type="number"
                  tw="mx-auto w-full"
                  name="value"
                  onChange={(e) => changeHandler(key, e)}
                  disabled={
                    currentProcess?.name !== "Llenado de Indicadores" &&
                    currentProcess?.name !== "Rectificación de Objetados"
                  }
                />
              ) : (
                <></>
              )}
              {variablesForm[key]?.type === "FREE_TEXT" ? (
                <textarea
                  value={variablesForm[key] ? variablesForm[key].response : ""}
                  tw="mx-auto w-full"
                  name="value"
                  onChange={(e) => changeHandler(key, e)}
                  disabled={
                    currentProcess?.name !== "Llenado de Indicadores" &&
                    currentProcess?.name !== "Rectificación de Objetados"
                  }
                />
              ) : (
                <></>
              )}
            </th>
            <th tw="w-3/12 text-center">
              <textarea
                value={variablesForm[key] ? variablesForm[key].description : ""}
                tw="mx-auto w-full"
                name="value"
                onChange={(e) => descriptionChangeHandler(key, e)}
                disabled={
                  currentProcess?.name !== "Llenado de Indicadores" &&
                  currentProcess?.name !== "Rectificación de Objetados"
                }
              />
            </th>
            <th tw="w-2/12 text-center">
              {variable && variable.requires_verificator ? (
                elem && elem.response && elem.response_id !== 0 ? (
                  <>
                    <Link
                      to={Routes.institutionalEvidence
                        .replace(":variableId", elem.variable_id.toString())
                        .replace(":responseId", elem.response_id.toString())}
                    >
                      <button
                        type="button"
                        tw="bg-resies_green py-1 mb-4 w-1/2 h-5/6 mx-auto rounded-md 
                    flex flex-row flex-wrap content-center max-h-12"
                      >
                        <p tw="text-ghostwhite mr-2 font-normal text-xs lg:text-sm mx-auto">
                          Ir a la evidencia
                        </p>
                      </button>
                    </Link>
                    <p tw="text-xs lg:text-sm mx-auto text-darkgray">
                      {elem.evidences.length ? "Evidencia subida" : "Faltante"}
                    </p>
                  </>
                ) : (
                  <button
                    type="button"
                    tw="bg-resies_green  py-1 mb-2 w-3/4 h-5/6 mx-auto rounded-md 
                    flex flex-row flex-wrap content-center max-h-12"
                    disabled
                  >
                    <p tw="text-ghostwhite font-normal text-xs lg:text-sm mx-auto cursor-not-allowed">
                      Debe responder antes de subir evidencia
                    </p>
                  </button>
                )
              ) : (
                "No requiere verificación"
              )}
            </th>
            <th tw="w-2/12 text-center">{status}</th>
          </div>
        </tr>
      ) : (
        ""
      )}
    </>
  );
}

export default DataForm;
