import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useEffect } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import Routes from "../../../../../routers/routes";
import { IndicatorStatus } from "../../../../../types/utilTypes";
/**
 * Titulo del form correspondiente a cada indicador.
 *
 * @param props react props.
 * @param props.indicatorSelected Indicador actualmente seleccionado
 * @param props.indicators Estado de todos los indicadores
 * @param props.setIndicatorSelected setState que cambio el indicador seleccionado
 * @param props.setIndicators setState que cambia el status de los indicadores
 * @param props.setChanges setState de cambios.
 * @param props.subCategoryId La id de la subcategoría actual
 */
function ADMTitleIndicatorForm(props: {
  indicatorSelected: IndicatorStatus;
  indicators: IndicatorStatus[];
  setIndicators: React.Dispatch<React.SetStateAction<IndicatorStatus[]>>;
  setIndicatorSelected: React.Dispatch<
    React.SetStateAction<IndicatorStatus | undefined>
  >;
  setChanges: React.Dispatch<React.SetStateAction<boolean>>;
  subCategoryId: string;
}): ReactElement {
  const {
    indicatorSelected,
    indicators,
    setIndicators,
    setIndicatorSelected,
    setChanges,
    subCategoryId,
  } = props;

  const titleRef = React.createRef<HTMLInputElement>();
  const weightRef = React.createRef<HTMLInputElement>();
  const colorRef = React.createRef<HTMLInputElement>();
  useEffect(() => {
    titleRef.current?.focus();
  }, [titleRef]);
  useEffect(() => {
    weightRef.current?.focus();
  }, [weightRef]);
  useEffect(() => {
    colorRef.current?.focus();
  }, [colorRef]);
  /**
   * Función que cambia el titulo del indicador al estado de edición al hacer click en el.
   */
  function onClickHandler() {
    const copyIndicators = [...indicators];
    const ind = copyIndicators.filter(
      (indi) => indi.id === indicatorSelected.id
    )[0];
    ind.statusTitle = "edit";
    setIndicators(copyIndicators);
    setIndicatorSelected(ind);
  }
  /**
   * Función que cambia el peso del indicador al estado de edición al hacer click en el.
   */
  function onClickHandlerWeight() {
    const copyIndicators = [...indicators];
    const ind = copyIndicators.filter(
      (indi) => indi.id === indicatorSelected.id
    )[0];
    ind.statusWeight = "edit";
    setIndicators(copyIndicators);
    setIndicatorSelected(ind);
  }
  /**
   * Función que cambia el color del indicador al estado de edición al hacer click en el.
   */
  function onClickHandlerColor() {
    const copyIndicators = [...indicators];
    const ind = copyIndicators.filter(
      (indi) => indi.id === indicatorSelected.id
    )[0];
    ind.statusColor = "edit";
    setIndicators(copyIndicators);
    setIndicatorSelected(ind);
  }
  /**
   * Funcion que maneja el cambio de value del titulo cuando se esta en el modo de edición.
   *
   * @param e event
   */
  function onChangeTitle(e: React.ChangeEvent<HTMLInputElement>) {
    const copyIndicators = [...indicators];
    const ind = copyIndicators.filter(
      (indi) => indi.id === indicatorSelected.id
    )[0];
    if (ind.generalStatus === "no modified") {
      ind.generalStatus = "modified";
    }
    ind.title = e.target.value;
    setIndicators(copyIndicators);
    setIndicatorSelected(ind);
    setChanges(true);
  }
  /**
   * Funcion que maneja el cambio de value del weight cuando se esta en el modo de edición.
   *
   * @param e event
   */
  function onChangeWeight(e: React.ChangeEvent<HTMLInputElement>) {
    const copyIndicators = [...indicators];
    const ind = copyIndicators.filter(
      (indi) => indi.id === indicatorSelected.id
    )[0];
    if (ind.generalStatus === "no modified") {
      ind.generalStatus = "modified";
    }
    ind.score_weight = parseFloat(e.target.value);
    setIndicators(copyIndicators);
    setIndicatorSelected(ind);
    setChanges(true);
  }
  /**
   * Funcion que maneja el cambio de value del color cuando se esta en el modo de edición.
   *
   * @param e event
   */
  function onChangeColor(e: React.ChangeEvent<HTMLInputElement>) {
    const copyIndicators = [...indicators];
    const ind = copyIndicators.filter(
      (indi) => indi.id === indicatorSelected.id
    )[0];
    if (ind.generalStatus === "no modified") {
      ind.generalStatus = "modified";
    }
    ind.color = e.target.value;
    setIndicators(copyIndicators);
    setIndicatorSelected(ind);
    setChanges(true);
  }
  /**
   * Función que cierra el estado de edición al hacer click en la tecla ENTER.
   *
   * @param e event
   */
  function onKeyDownHandler(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      const copyIndicators = [...indicators];
      const ind = copyIndicators.filter(
        (indi) => indi.id === indicatorSelected.id
      )[0];
      ind.statusTitle = "ready";
      setIndicators([...copyIndicators]);
      setIndicatorSelected(ind);
    }
  }
  /**
   * Función que cierra el estado de edición al hacer click en la tecla ENTER.
   *
   * @param e event
   */
  function onKeyDownHandlerWeight(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      const copyIndicators = [...indicators];
      const ind = copyIndicators.filter(
        (indi) => indi.id === indicatorSelected.id
      )[0];
      ind.statusWeight = "ready";
      setIndicators([...copyIndicators]);
      setIndicatorSelected(ind);
    }
  }
  /**
   * Funcion que cierra el modo de edicion al hacer focus en otro elemento
   */
  function onBlurHandler() {
    const copyIndicators = [...indicators];
    const ind = copyIndicators.filter(
      (indi) => indi.id === indicatorSelected.id
    )[0];
    ind.statusTitle = "ready";
    setIndicators([...copyIndicators]);
    setIndicatorSelected(ind);
  }
  /**
   * Funcion que cierra el modo de edicion al hacer focus en otro elemento
   */
  function onBlurHandlerWeight() {
    const copyIndicators = [...indicators];
    const ind = copyIndicators.filter(
      (indi) => indi.id === indicatorSelected.id
    )[0];
    ind.statusWeight = "ready";
    setIndicators([...copyIndicators]);
    setIndicatorSelected(ind);
  }
  /**
   * Funcion que cierra el modo de edicion al hacer focus en otro elemento
   */
  function onBlurHandlerColor() {
    const copyIndicators = [...indicators];
    const ind = copyIndicators.filter(
      (indi) => indi.id === indicatorSelected.id
    )[0];
    ind.statusColor = "ready";
    setIndicators([...copyIndicators]);
    setIndicatorSelected(ind);
  }
  return (
    <div tw="flex flex-col">
      <div tw="flex flex-row h-16 bg-resies_lightgreen flex-wrap content-center justify-between min-h-100">
        <div tw="flex flex-row flex-wrap content-center">
          {indicatorSelected.statusTitle === "ready" ? (
            <>
              <button
                type="button"
                tw="text-resies_blue1 font-bold text-4xl ml-4 mr-4 max-w-3xl"
                onClick={() => onClickHandler()}
              >
                {indicatorSelected.title}
              </button>
            </>
          ) : (
            <input
              tw="text-resies_blue1 font-bold text-4xl ml-4 w-10/12 bg-resies_lightgreen"
              value={indicatorSelected.title}
              onChange={(e) => onChangeTitle(e)}
              onKeyDown={(e) => onKeyDownHandler(e)}
              onBlur={() => onBlurHandler()}
              ref={titleRef}
            />
          )}
          {indicatorSelected.statusWeight === "ready" ? (
            <>
              <button
                type="button"
                tw="text-resies_blue1 font-bold text-header2 ml-4 mr-4 max-w-3xl"
                onClick={() => onClickHandlerWeight()}
              >
                <p>Peso</p>
                <p>{indicatorSelected.score_weight}</p>
              </button>
            </>
          ) : (
            <div tw="flex flex-col text-resies_blue1 font-bold text-header2 ml-4 mr-4 max-w-3xl">
              <p>Peso</p>
              <input
                type="number"
                tw="text-resies_blue1 font-bold text-header2 bg-resies_lightgreen w-3/12 text-center"
                value={indicatorSelected.score_weight}
                onChange={(e) => onChangeWeight(e)}
                onKeyDown={(e) => onKeyDownHandlerWeight(e)}
                onBlur={() => onBlurHandlerWeight()}
                ref={weightRef}
              />
            </div>
          )}
          {indicatorSelected.statusColor === "ready" ? (
            <>
              <button
                type="button"
                tw="text-resies_blue1 font-bold text-header2 ml-4 mr-4 max-w-3xl"
                onClick={() => onClickHandlerColor()}
              >
                <p>Color</p>
                <p
                  tw="inline-block text-center"
                  style={{
                    backgroundColor: indicatorSelected.color,
                    height: "20px",
                    width: "40px",
                  }}
                ></p>
              </button>
            </>
          ) : (
            <div tw="flex flex-col text-resies_blue1 font-bold text-header2 ml-4 mr-4 max-w-3xl">
              <p>Color</p>
              <input
                type="color"
                tw="text-barra text-resies_purple font-semibold text-center bg-resies_lightpurple border-2"
                value={indicatorSelected.color}
                onChange={(e) => onChangeColor(e)}
                onBlur={() => onBlurHandlerColor()}
                ref={colorRef}
              />
            </div>
          )}
        </div>
        <div tw="w-2/12 text-4xl flex justify-end px-4">
          <Link
            to={Routes.changeIndicatorFormula
              .replace(":subcategoryId", subCategoryId)
              .replace(":indicatorId", indicatorSelected.id.toString())}
            tw="flex"
          >
            <FontAwesomeIcon icon={faCog} />
            <span tw="text-barra ml-2">Editar Fórmula</span>
          </Link>
        </div>
      </div>
      <div tw="flex grid grid-cols-12 h-5 bg-resies_blue2 flex-wrap content-center justify-between">
        <p tw="font-bold text-base text-ghostwhite col-span-4 text-center">
          Variable
        </p>
        <p tw="font-bold text-base text-ghostwhite col-span-2 text-center">
          Descripción
        </p>
        <p tw="font-bold text-base text-ghostwhite col-span-2 text-center">
          Evidencia Necesaria
        </p>
        <p tw="font-bold text-base text-ghostwhite col-span-3 text-center">
          Tipo de respuesta
        </p>
      </div>
    </div>
  );
}

export default ADMTitleIndicatorForm;
