import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import Routes from "../../routers/routes";
import { ResponseVariable } from "../../types/backendTypes";
import ProgressBar from "../common/ProgressBar";
import useRESIESDates from "../../hooks/useRESIESDates";

/**
 * Fila de la tabla de instituciones del auditor.
 *
 * @param props react props.
 * @param props.institutionName Nombre de la institución.
 * @param props.institutionId ID de la institución.
 * @param props.responses Lista de respuestas disponibles para auditar.
 */
function TableInstitutionRow(props: {
  institutionName: string;
  institutionId: number;
  responses: ResponseVariable[];
}): ReactElement {
  const { institutionName, institutionId, responses } = props;
  const responsesTotal = responses.length;
  const responsesAudited = responses.filter(
    (res) => res.audit_status !== "pending"
  ).length;
  const progress = responsesTotal
    ? Math.round((responsesAudited / responsesTotal) * 100)
    : 0;
  const [, currentProcess] = useRESIESDates();

  return (
    <tr tw="flex w-full mb-3">
      <td tw="text-center p-2 w-1/3">{institutionName}</td>
      <td tw="flex text-body text-center p-3 w-1/3">
        <ProgressBar completed={progress} /> {progress}%
      </td>
      <td tw="flex justify-center p-2 w-1/3">
        <Link
          to={Routes.AUDindicators.replace(
            ":institutionId",
            institutionId.toString()
          )}
          tw="w-4/12 bg-resies_blue1 rounded-md flex mr-2"
        >
          <button
            type="button"
            tw="text-xs text-ghostwhite bg-resies_blue1 font-semibold rounded-md mx-auto"
          >
            Indicadores
          </button>
        </Link>
        <Link
          to={Routes.audInstitutionData.replace(
            ":institutionId",
            institutionId.toString()
          )}
          tw="w-4/12 bg-resies_blue1 rounded-md flex mr-2"
        >
          <button
            type="button"
            tw="text-xs text-ghostwhite bg-resies_blue1 font-semibold rounded-md mx-auto"
          >
            Datos institucionales
          </button>
        </Link>

        <Link
          to={Routes.audInstitutionReport.replace(
            ":institutionId",
            institutionId.toString()
          )}
          tw="w-4/12 bg-resies_blue1 rounded-md flex mr-2"
        >
          <button
            type="button"
            tw="text-xs text-ghostwhite bg-resies_blue1 font-semibold rounded-md mx-auto"
          >
            {currentProcess &&
            ["Resultados de Apelación", "Fin proceso RESIES"].includes(
              currentProcess.name
            )
              ? "Resultados finales"
              : "Resultados preliminares"}
          </button>
        </Link>
      </td>
    </tr>
  );
}

export default TableInstitutionRow;
