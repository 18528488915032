import React, { ReactElement } from "react";
import "twin.macro";
import {
  EvaluationVariable,
  InstitutionalDataResponse,
  InstitutionalDataVariable,
  ResponseVariable,
} from "../../types/backendTypes";
import AppealsByStateRow from "./AppealsByStateRow";
import InstitutionalAppealsByStateRow from "./InstitutionalAppealsByStateRow";

/**
 * Display de las apelaciones por estado
 *
 * @param props react props
 * @param props.title El título de la categoría
 * @param props.appeals Las apelaciones existentes
 * @param props.institutionId id de institucion
 * @param props.evaluationVariables Las variables de evaluacion
 * @param props.institutionalAppeals Las variables institucionales
 * @param props.institutionalDataVariables Las variables de evaluación institucionales
 */
function AppealsByState(props: {
  title: string;
  appeals: ResponseVariable[];
  institutionalAppeals: InstitutionalDataResponse[];
  evaluationVariables: EvaluationVariable[];
  institutionalDataVariables: InstitutionalDataVariable[];
  institutionId: string;
}): ReactElement {
  const {
    title,
    appeals,
    institutionId,
    evaluationVariables,
    institutionalDataVariables,
    institutionalAppeals,
  } = props;
  return (
    <div>
      <table tw="w-full">
        <thead tw="bg-resies_blue2 flex text-white w-full">
          <tr tw="flex w-full h-5">
            <th tw="text-barra font-bold text-ghostwhite text-left h-5 pl-10">
              {title.toLocaleUpperCase()}
            </th>
          </tr>
        </thead>
        <tbody tw="bg-resies_lightpurple flex flex-col justify-between overflow-y-scroll max-h-72">
          {!appeals.length && !institutionalAppeals.length && (
            <div tw="text-header2 my-5 pl-10 text-darkgray">
              No hay apelaciones {title.toLocaleLowerCase()}
            </div>
          )}
          {!!appeals.length &&
            appeals.map((a) => (
              <AppealsByStateRow
                indicatorSlug={
                  evaluationVariables.find((ev) => ev.id === a.variable_id)
                    ?.indicator_slug || ""
                }
                responseId={a.id}
                variableId={a.variable_id}
                institutionId={institutionId}
              />
            ))}
          {!!institutionalAppeals.length &&
            institutionalAppeals.map((a) => (
              <InstitutionalAppealsByStateRow
                indicatorSlug={
                  institutionalDataVariables.find(
                    (ev) => ev.id === a.variable_id
                  )?.indicator_slug || ""
                }
                responseId={a.id}
                variableId={a.variable_id}
                institutionId={institutionId}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default AppealsByState;
