import React, { ReactElement, useContext, useState } from "react";
import "twin.macro";
import ProfileData from "../../components/admin/ProfileData";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import PageSection from "../../components/common/PageSection";
import PageTitleSubtitle from "../../components/common/PageTitleSubtitle";
import { AppContext } from "../../dispatcher";
import useUser from "../../hooks/useUser";

/**
 * La página de perfil del administrador
 */
function ADMProfile(): ReactElement {
  const [ready, setReady] = useState(false);
  const [appState] = useContext(AppContext);
  const adminId = appState.userId;
  const [admin, reloadResponses] = useUser(setReady, adminId);
  return (
    <Container>
      <PageTitleSubtitle title="Mi Perfil" subtitle="ADMINISTRADOR" />
      <CargandoScreen ready={ready}>
        <div tw="grid grid-cols-2 mb-8">
          <div>
            <PageSection text="Datos Personales" />
            <div tw="w-full">
              {admin ? (
                <ProfileData user={admin} reload={reloadResponses} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </CargandoScreen>
    </Container>
  );
}

export default ADMProfile;
