import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import CategoriesNavbar from "../../components/common/IndicatorsView/CategoriesNavbar";
import MainIndicatorsMenu from "../../components/common/IndicatorsView/MainIndicatorsMenu";
import { AppContext } from "../../dispatcher";
import { getCategories } from "../../utils/data/getData";
import indicatorsTitle from "../../utils/data/indicatorsTitle";
import { SearchOnUrl } from "../../utils/commonFunction";
import { Category, Indicator } from "../../types/backendTypes";

/**
 * Vista de indicadores del MDI.
 */
function MDIIndicatorsMenu(): ReactElement {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [indicatorForm, setIndicatorForm] = useState([{}]);
  const [indicatorSelected, setIndicatorSelected] = useState<Indicator | null>(
    null
  );
  const [appState] = useContext(AppContext);
  const query = SearchOnUrl();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (appState.token) {
      getCategories(appState.token, (response: Category[]) => {
        setCategories(response);

        const parsedIndicators: Record<string, string[]> = {};
        response.forEach((category) => {
          if (category.indicators) {
            parsedIndicators[category.name] = category.indicators.map(
              (indicator) => indicator.slug
            );
          }
        });
        indicatorsTitle(setIndicatorForm, appState.token, setReady);
      });
    }
  }, [appState.token]);

  useEffect(() => {
    if (categories.length > 0) {
      const position =
        query.get("category") !== null
          ? Number(query.get("category"))
          : categories[0].id;
      let categoryFromQuery = categories.filter(
        (category) => category.id === position
      )[0];

      if (categoryFromQuery === null) {
        categoryFromQuery = categories[0];
      }

      if (categoryFromQuery.indicators === undefined) {
        throw new Error("Se ha detectado una categoría sin indicadores");
      } else {
        setSelectedCategory(categoryFromQuery);

        const queryIndicator =
          query.get("indicator") !== null ? Number(query.get("indicator")) : 0;
        let indicatorFromUrl = categoryFromQuery.indicators?.filter(
          (indicator) => indicator.id === queryIndicator
        )[0];

        if (!indicatorFromUrl) {
          indicatorFromUrl = categoryFromQuery.indicators![0];
        }

        setIndicatorSelected(indicatorFromUrl);
      }
    }
  }, [categories, query]);

  return (
    <CargandoScreen ready={ready}>
      {ready ? (
        <div tw="flex flex-col relative">
          <div>
            {selectedCategory && indicatorSelected && (
              <>
                <CategoriesNavbar
                  categories={categories}
                  selectedCategory={selectedCategory}
                />
                <MainIndicatorsMenu
                  category={selectedCategory}
                  indicatorSelected={indicatorSelected}
                  indicatorForm={indicatorForm}
                  setIndicatorForm={setIndicatorForm}
                  setReady={setReady}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </CargandoScreen>
  );
}

export default MDIIndicatorsMenu;
