/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from "react";
import "twin.macro";
import AUDTitleIndicatorForm from "./AUDTitleIndicatorForm";
import AUDIndicatorForm from "./AUDIndicatorForm";
import AUDIndicatorList from "./AUDIndicatorList";
import VolverButton from "../../common/VolverButton";
import Routes from "../../../routers/routes";
import { ResponseForm } from "../../../types/utilTypes";
import { SearchOnUrl } from "../../../utils/commonFunction";

/**
 * Menu principal para modificar datos de los indicadores.
 *
 * @param props react props.
 * @param props.indicators Diccionario que contiene los nombres de los indicadores de cada categoria.
 * @param props.selected Corresponde al nombre de la categoria actualmente seleccionada.
 * @param props.indicatorSelected State del indicador actualmente seleccionado.
 * @param props.setIndicatorSelected Corresponde al setState de el indicador seleccionado.
 * @param props.indicatorForm Diccionario que muestra los datos del formulario de cada variable.
 * @param props.indicatorsName diccionario de nombres de indicadores
 */
function AUDMainIndicatorsMenu(props: {
  indicators: Record<string, string[]>;
  selected: string;
  indicatorsName: Record<string, string>;
  indicatorSelected: string;
  setIndicatorSelected: React.Dispatch<React.SetStateAction<string>>;
  indicatorForm: ResponseForm[];
}): ReactElement {
  const {
    indicators,
    selected,
    indicatorsName,
    indicatorSelected,
    setIndicatorSelected,
    indicatorForm,
  } = props;
  const query = SearchOnUrl().get("indic");
  const [click, setClick] = useState(false);

  /**
   * Función que maneja los cambios de estado del indicador seleccionado.
   *
   * @param ind indicador
   */
  function clickHandler(ind: string) {
    setIndicatorSelected(ind);
    setClick(true);
  }

  useEffect(() => {
    if (!click) {
      const indicatorSel = query === null ? indicatorSelected : query;
      setIndicatorSelected(indicatorSel);
    }
  }, [query, indicatorSelected]);

  return (
    <div>
      <div tw="text-darkgray font-semibold flex h-full bg-ghostwhite">
        {!Object.keys(indicators).length ? (
          <div tw="bg-ghostwhite flex flex-col justify-center w-full">
            <div tw="flex flex-wrap h-screen justify-center content-center">
              <p tw="text-header2 font-semibold">
                No tienes indicadores asignados
              </p>
              <div>
                <VolverButton to={Routes.landing} />
              </div>
            </div>
          </div>
        ) : (
          <>
            <AUDIndicatorList
              indicators={indicators}
              selected={selected}
              indicatorSelected={indicatorSelected}
              clickHandler={clickHandler}
              formState={indicatorForm}
            />
            <div tw="flex flex-col flex-grow bg-ghostwhite relative">
              <AUDTitleIndicatorForm
                indicatorNames={indicatorsName}
                indicatorSelected={indicatorSelected}
                formState={indicatorForm}
              />
              <div tw="flex flex-col bg-ghostwhite overflow-y-scroll mb-10 h-screen">
                {indicatorForm
                  .filter((elem) => elem.name === indicatorSelected)
                  .map((elem) => (
                    <AUDIndicatorForm
                      formState={indicatorForm}
                      elem={elem}
                      key={elem.variable_id}
                    />
                  ))}
              </div>
              <VolverButton to={Routes.myInstitutions} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AUDMainIndicatorsMenu;
