import React, { ReactElement, useEffect } from "react";
import "twin.macro";
import { IndicatorStatus } from "../../../../../types/utilTypes";

/**
 * Este componente muestra los indicadores en modo de edición.
 *
 * @param props react props
 * @param props.ind Indicador que se mostrara en el componente
 * @param props.indicatorSelected Indicador actualmente seleccionado.
 * @param props.indicators Status de todos los indicadores
 * @param props.setIndicatorSelected setState que cambio el indicador seleccionado
 * @param props.setIndicators setState que cambia el status de los indicadores
 * @param props.onClickDeleteIndicator Funcion que elimina el indicador clickeado.
 * @param props.setChanges sets changes
 */
function ADMEditIndicator(props: {
  ind: IndicatorStatus;
  indicatorSelected: IndicatorStatus | undefined;
  indicators: IndicatorStatus[];
  setIndicatorSelected: React.Dispatch<
    React.SetStateAction<IndicatorStatus | undefined>
  >;
  setIndicators: React.Dispatch<React.SetStateAction<IndicatorStatus[]>>;
  onClickDeleteIndicator: (e: React.MouseEvent<HTMLButtonElement>) => void;
  setChanges: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement {
  const {
    ind,
    indicatorSelected,
    indicators,
    setIndicatorSelected,
    setIndicators,
    onClickDeleteIndicator,
    setChanges,
  } = props;

  const slugRef = React.createRef<HTMLInputElement>();
  useEffect(() => {
    slugRef.current?.focus();
  }, [slugRef]);
  /**
   * Funcion que cambia el indicador seleccionado.
   *
   * @param e event
   */
  function onClickIndicator(e: React.MouseEvent<HTMLInputElement>) {
    setIndicatorSelected(
      indicators.filter(
        (indi) => indi.id.toString() === e.currentTarget.name
      )[0]
    );
  }

  /**
   * Funcion que cierra el modo de edicion de un indicador al presionar ENTER.
   *
   * @param e keyboard event
   */
  function onKeyDownHandler(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      const copyIndicators = [...indicators];
      const clickedIndicator = [
        ...copyIndicators.filter(
          (indi) => indi.id.toString() === e.currentTarget.name
        ),
      ][0];
      clickedIndicator.statusSlug = "ready";
      setIndicators([...copyIndicators]);
    }
  }

  /**
   * Funcion que maneja el cambio del value del indicador en modo de edición.
   *
   * @param e keyboard event
   */
  function onChangeCurrentIndicator(e: React.ChangeEvent<HTMLInputElement>) {
    const copyIndicators = [...indicators];
    const clickedIndicator = [
      ...copyIndicators.filter(
        (indi) => indi.id.toString() === e.currentTarget.name
      ),
    ][0];
    if (clickedIndicator.generalStatus === "no modified") {
      clickedIndicator.generalStatus = "modified";
    }
    clickedIndicator.slug = e.target.value;
    setIndicators([...copyIndicators]);
    setChanges(true);
  }

  /**
   * Funcion que cierra el modo de edición al hacer focus en otro elemento
   *
   * @param e focus event
   */
  function onBlurHandler(e: React.FocusEvent<HTMLInputElement>) {
    const copyIndicators = [...indicators];
    const clickedIndicator = [
      ...copyIndicators.filter(
        (indi) => indi.id.toString() === e.currentTarget.name
      ),
    ][0];
    clickedIndicator.statusSlug = "ready";
    setIndicators([...copyIndicators]);
  }
  return (
    <div
      tw="flex flex-row"
      className={
        indicatorSelected === ind
          ? "border-l-4 border-resies_blue2 bg-resies_lightgreen"
          : ""
      }
      key={ind.id}
    >
      <div
        tw="font-semibold flex flex-row justify-between w-full px-2 focus:outline-none"
        className={
          indicatorSelected === ind
            ? "text-resies_blue2"
            : "text-black opacity-50"
        }
      >
        <input
          tw="w-6/12 bg-resies_lightpurple font-bold"
          value={ind.slug}
          name={ind.id.toString()}
          onKeyDown={(e) => onKeyDownHandler(e)}
          onChange={(e) => onChangeCurrentIndicator(e)}
          onClick={(e) => onClickIndicator(e)}
          onBlur={(e) => onBlurHandler(e)}
          ref={slugRef}
        />
        <button
          type="button"
          tw="w-2/12 my-auto"
          onClick={(e) => onClickDeleteIndicator(e)}
          name={ind.id.toString()}
        >
          <img src="/trash.png" alt="trash" tw="object-contain my-auto mr-2" />
        </button>
      </div>
    </div>
  );
}

export default ADMEditIndicator;
