import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import { AppContext } from "../../../../dispatcher";
import { ActionsForModel, OdsStatus } from "../../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../../../utils/fetchAPI";
import CargandoScreen from "../../../common/CargandoScreen";
import getOdsObjectives from "../../../../utils/data/AdmData/getOdsObjectives";
import { OdsObjective } from "../../../../types/backendTypes";

/**
 * Componente que muestra las categorias que existen, permite editar sus nombres y crear nuevas categorias.
 */
function EditOds(): ReactElement {
  const [ready, setReady] = useState(false);
  const [odsStatus, setOdsStatus] = useState<OdsStatus[]>([]);
  const [appState] = useContext(AppContext);
  const [reload, setReload] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [dropdownStatus, setDropdownStatus] = useState<ActionsForModel[]>([]);
  const [odsObjectives, setOdsObjectives] = useState<OdsObjective[]>([]);

  useEffect(() => {
    getOdsObjectives(appState, setReady, setOdsObjectives);
  }, [appState, reload]);

  useEffect(() => {
    if (odsObjectives.length > 0) {
      const dropdownStatuses: ActionsForModel[] = [];
      const odsStatuses: OdsStatus[] = [];
      odsObjectives.forEach((ods) => {
        dropdownStatuses.push({
          id: ods.id,
          edit: false,
          more_info_dropdown: false,
        });
        odsStatuses.push({
          id: ods.id,
          name: ods.name,
          color: ods.color,
          status: "ready",
          goals: ods.goals,
        });
      });
      setDropdownStatus(dropdownStatuses);
      setOdsStatus(odsStatuses);
    }
  }, [odsObjectives]);

  /**
   * Funcion que cambia el estado de la categoria para ser editable.
   *
   * @param e evento que desencadena la funcion.
   */
  function changeStatus(e: React.MouseEvent<HTMLButtonElement>) {
    if (!appState.token) return;
    const copyStatus = [...odsStatus];
    let errorNameRepeated = false;
    const names = copyStatus.map((cate) => cate.name);
    errorNameRepeated = new Set(names).size !== names.length;
    if (errorNameRepeated) {
      setErrorMessage("No se pueden tener nombres repetidos");
      return;
    }
    const element = copyStatus.filter(
      (status) => status.id.toString() === e.currentTarget.name
    )[0];

    setReady(false);
    fetchAPI<APIActions["editOds"]>(
      APIRoutes.editOds,
      {
        method: "PUT",
        routeParams: { ods_id: element.id.toString() },
        body: {
          name: element.name,
          color: element.color,
        },
      },
      appState.token
    ).then(() => {
      setReload(reload + 1);
      setErrorMessage("");
    });
    element.status = "ready";
    setOdsStatus([...copyStatus]);
  }

  /**
   * Funcion que maneja el cambio de nombre de las categorias existentes.
   *
   * @param e evento que desencadena la funcion.
   */
  function onChangeEditOds(e: React.ChangeEvent<HTMLInputElement>) {
    const copyStatus = [...odsStatus];
    const element = copyStatus.filter(
      (status) => status.id.toString() === e.target.name
    )[0];
    element.name = e.target.value;
    setOdsStatus([...copyStatus]);
  }

  /**
   * Funcion que maneja el cambio de color de las categorias existentes.
   *
   * @param e evento que desencadena la funcion.
   */
  function onChangeEditOdsColor(e: React.ChangeEvent<HTMLInputElement>) {
    const copyStatus = [...odsStatus];
    const element = copyStatus.filter(
      (status) => status.id.toString() === e.target.name
    )[0];
    element.color = e.target.value;
    setOdsStatus([...copyStatus]);
  }

  function onClickHandlerDropdown(ods: OdsStatus) {
    const dropdownStatusCopy = [...dropdownStatus];
    const odsClicked = dropdownStatusCopy.filter((o) => o.id === ods.id)[0];
    if (odsClicked.more_info_dropdown === false) {
      odsClicked.more_info_dropdown = true;
    } else {
      odsClicked.more_info_dropdown = false;
    }
    setDropdownStatus(dropdownStatusCopy);
  }

  function onClickHandlerEdit(ods: OdsStatus) {
    const dropdownStatusCopy = [...dropdownStatus];
    const odsClicked = dropdownStatusCopy.filter((o) => o.id === ods.id)[0];
    if (odsClicked.edit === false) {
      odsClicked.edit = true;
    } else {
      odsClicked.edit = false;
    }
    setDropdownStatus(dropdownStatusCopy);
  }

  return (
    <CargandoScreen ready={ready}>
      <div tw="">
        <p tw="ml-4 text-header1 font-bold text-resies_blue1 mb-2">ODS</p>
        <p tw="text-darkred font-bold text-header2">{errorMessage}</p>
        {odsStatus.map((ods) => (
          <div tw="mb-10" key={ods.id}>
            <div
              className="h-16 w-5/12 mx-auto flex flex-wrap content-center"
              style={{ backgroundColor: ods.color }}
            >
              {dropdownStatus.length > 0 &&
              dropdownStatus.filter((d) => d.id === ods.id)[0].edit === true ? (
                <>
                  <button
                    type="button"
                    tw="w-1/12 flex flex-wrap focus:outline-none"
                    onClick={() => onClickHandlerDropdown(ods)}
                  >
                    <img
                      tw="object-contain my-auto mx-auto w-5/12"
                      src="/dropdown2.png"
                      alt="dropdown"
                    />
                  </button>
                  <input
                    tw="text-header2 text-resies_purple font-semibold w-6/12 text-center bg-resies_lightpurple border-2"
                    placeholder={ods.name}
                    value={ods.name}
                    name={ods.id.toString()}
                    onChange={(e) => onChangeEditOds(e)}
                  />
                  <div tw="w-2/12 my-auto flex flex-col items-center">
                    <p tw="text-barra text-resies_purple font-semibold text-center">
                      Color
                    </p>
                    <input
                      type="color"
                      tw="text-barra text-resies_purple font-semibold text-center bg-resies_lightpurple border-2"
                      value={ods.color}
                      name={ods.id.toString()}
                      onChange={(e) => onChangeEditOdsColor(e)}
                    />
                  </div>
                  <button
                    type="button"
                    tw="w-1/12 flex flex-wrap focus:outline-none"
                    name={ods.id.toString()}
                    onClick={(e) => changeStatus(e)}
                  >
                    <img
                      tw="object-contain my-auto mx-auto"
                      src="/add.png"
                      alt="add"
                    />
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    tw="w-1/12 flex flex-wrap focus:outline-none"
                    onClick={() => onClickHandlerDropdown(ods)}
                  >
                    <img
                      tw="object-contain my-auto mx-auto w-5/12"
                      src="/dropdown2.png"
                      alt="dropdown"
                    />
                  </button>
                  <div tw="w-7/12 my-auto">
                    <p tw="text-header2 text-resies_purple font-semibold text-center">
                      {ods.name}
                    </p>
                  </div>
                  <button
                    type="button"
                    tw="w-1/12 flex flex-wrap focus:outline-none"
                    name={ods.id.toString()}
                    onClick={(e) => onClickHandlerEdit(ods)}
                  >
                    <img
                      tw="object-contain my-auto mx-auto"
                      src="/editCategory.png"
                      alt="edit"
                    />
                  </button>
                </>
              )}
            </div>

            {dropdownStatus.length &&
            dropdownStatus.filter((o) => o.id === ods.id)[0]
              .more_info_dropdown === true ? (
              <div tw="bg-resies_lightgreen h-24 w-5/12 mx-auto flex flex-col content-center overflow-y-auto px-4">
                {ods.goals.map((goal) => (
                  <div tw="flex flex-row" key={goal.id}>
                    <p>Meta {goal.name}</p>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </CargandoScreen>
  );
}

export default EditOds;
