import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Redirect, useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import EvidenceComments from "../../components/common/EvidenceView/EvidenceComments";
import EvidenceDataRow from "../../components/common/EvidenceView/EvidenceDataRow";
import EvidenceInfo from "../../components/common/EvidenceView/EvidenceInfo";
import EvidenceTitle from "../../components/common/EvidenceView/EvidenceTitle";
import VolverButton from "../../components/common/VolverButton";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import {
  EvaluationVariableDetails,
  ResponseVariable,
} from "../../types/backendTypes";
import getResponses from "../../utils/data/getResponses";
import getVariableDetails from "../../utils/data/getVariableDetails";

/**
 * Componente de la vista para resubir evidencia del RDI y MDI
 */
function RDIExecuteObjected(): ReactElement {
  const { variableId } = useParams<{ variableId: string }>();
  const { responseId } = useParams<{ responseId: string }>();
  const [uploadFile, setUploadFile] = useState(0);
  const [ready, setReady] = useState(false);
  const [reload, setReload] = useState(0);
  const [evaluationVariable, setEvaluationVariable] =
    useState<EvaluationVariableDetails>();
  const [error, setError] = useState(false);
  const [appState] = useContext(AppContext);
  const [responseVariable, setResponseVariable] = useState<ResponseVariable>();

  useEffect(() => {
    getVariableDetails(appState, variableId, setEvaluationVariable, setError);
    getResponses(appState, responseId, setResponseVariable, setError, setReady);
  }, [appState, responseId, variableId, uploadFile, reload]);

  return (
    <div tw="bg-ghostwhite">
      {error ? <Redirect to={Routes.indicators} /> : <></>}
      <CargandoScreen ready={ready}>
        <Container>
          <EvidenceTitle
            evaluationVariable={evaluationVariable}
            title="EVIDENCIA"
            responseVariable={responseVariable}
          />
          <EvidenceInfo
            info={responseVariable?.description}
            title="Descripción de variable"
          />
          <EvidenceInfo
            info={evaluationVariable?.verification_field_helper_text}
            title="Verificación Necesaria"
          />
          {responseVariable ? (
            <EvidenceDataRow
              response={responseVariable}
              setUploadFile={setUploadFile}
              reload={reload}
              setReload={setReload}
            />
          ) : (
            <></>
          )}
          <EvidenceComments />

          {appState.userType === "MDI" ? (
            <VolverButton to={Routes.auditProcesses} />
          ) : (
            <VolverButton to={Routes.objected} />
          )}
          <div tw="bg-ghostwhite h-10" />
        </Container>
      </CargandoScreen>
    </div>
  );
}

export default RDIExecuteObjected;
