import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import Routes from "../../../../routers/routes";
import { InstitutionCategoryStatus } from "../../../../types/utilTypes";

/**
 * Componente que muestra las categorias institucionales que ya estan creadas
 *
 * @param props react props.
 * @param props.categoryStatus Lista con el estado de todas las categorias actuales
 * @param props.onChangeEditCategory Funcion que maneja el cambio de nombre de la categorias que estan siendo editadas.
 * @param props.changeStatus Funcion que cambia el estado de las categorias actuales para ser editadas.
 * @param props.onDeleteCategory función para manejar eliminación de categorias
 * @param props.ready estado que indica si la pagina está lista
 */
function InstitutionCategoryOption(props: {
  categoryStatus: InstitutionCategoryStatus[];
  onChangeEditCategory: (e: React.ChangeEvent<HTMLInputElement>) => void;
  changeStatus: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDeleteCategory: (e: React.MouseEvent<HTMLButtonElement>) => void;
  ready: boolean;
}): ReactElement {
  const {
    categoryStatus,
    onChangeEditCategory,
    changeStatus,
    onDeleteCategory,
    ready,
  } = props;

  return (
    <div>
      {ready ? (
        categoryStatus.map((category) => (
          <div tw="mb-10" key={category.id}>
            <div tw="bg-resies_lightpurple h-16 w-4/12 mx-auto flex flex-wrap content-center">
              {category.status === "edit" ? (
                <>
                  <input
                    tw="text-header2 text-resies_purple font-semibold w-10/12 text-center bg-resies_lightpurple border-2"
                    placeholder={category.name}
                    value={category.name}
                    name={category.id.toString()}
                    onChange={(e) => onChangeEditCategory(e)}
                  />
                  <button
                    type="button"
                    tw="w-1/12 flex flex-wrap focus:outline-none"
                    name={category.id.toString()}
                    onClick={(e) => changeStatus(e)}
                  >
                    <img
                      tw="object-contain my-auto mx-auto"
                      src="/add.png"
                      alt="add"
                    />
                  </button>
                  <button
                    type="button"
                    tw="w-1/12 flex flex-wrap focus:outline-none"
                    name={category.id.toString()}
                    onClick={(e) => onDeleteCategory(e)}
                  >
                    <img
                      tw="object-contain my-auto mx-auto w-5/12 pt-1"
                      src="/trash.png"
                      alt="trash"
                    />
                  </button>
                </>
              ) : (
                <>
                  <Link
                    to={Routes.editInstitutionIndicators.replace(
                      ":categoryId",
                      category.id.toString()
                    )}
                    tw="w-10/12"
                  >
                    <p tw="text-header2 text-resies_purple font-semibold text-center">
                      {category.name}
                    </p>
                  </Link>
                  <button
                    type="button"
                    tw="w-1/12 flex flex-wrap focus:outline-none"
                    name={category.id.toString()}
                    onClick={(e) => changeStatus(e)}
                  >
                    <img
                      tw="object-contain my-auto mx-auto"
                      src="/editCategory.png"
                      alt="edit"
                    />
                  </button>
                  <button
                    type="button"
                    tw="w-1/12 flex flex-wrap focus:outline-none"
                    name={category.id.toString()}
                    onClick={(e) => onDeleteCategory(e)}
                  >
                    <img
                      tw="object-contain my-auto mx-auto w-5/12 pt-1"
                      src="/trash.png"
                      alt="trash"
                    />
                  </button>
                </>
              )}
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}

export default InstitutionCategoryOption;
