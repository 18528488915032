import { AppState } from "../../../dispatcher";
import { Subcategory } from "../../../types/backendTypes";
import {
  CategoryStatus,
  EvaluationVariablesFromIndicator,
  EvaluationVariableStatus,
  IndicatorStatus,
} from "../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

/**
 * Función que obtiene todos los datos de una categoria, desde la categoria misma hasta
 * las variables de sus indicadores
 *
 * @param appState estado de app
 * @param subcategoryId id de subcategoria
 * @param setCategory setter de categoria
 * @param setIndicators setter de indicadores
 * @param setEvaluationVariables setter de variables de evaluacion
 * @param setReady setter de listo
 * @param setIndicatorSelected setter de indicador seleccionado
 * @param setMaxVariableId maxima id de variable
 * @param setSubCategory setter de subcategoria
 */
function singleSubCategorieData(
  appState: AppState,
  subcategoryId: string,
  setCategory: React.Dispatch<React.SetStateAction<CategoryStatus | undefined>>,
  setIndicators: React.Dispatch<React.SetStateAction<IndicatorStatus[]>>,
  setEvaluationVariables: React.Dispatch<
    React.SetStateAction<EvaluationVariablesFromIndicator[]>
  >,
  setReady: React.Dispatch<React.SetStateAction<boolean>>,
  setIndicatorSelected: React.Dispatch<
    React.SetStateAction<IndicatorStatus | undefined>
  >,
  setMaxVariableId: React.Dispatch<React.SetStateAction<number>>,
  setSubCategory: React.Dispatch<React.SetStateAction<Subcategory | undefined>>
): void {
  if (!appState.token) return;
  const { token } = appState;
  const indicatorsHelper: IndicatorStatus[] = [];
  const evalVariables: EvaluationVariablesFromIndicator[] = [];
  fetchAPI<APIActions["fetchSubcategoryDetails"]>(
    APIRoutes.fetchSubcategoryDetails,
    {
      method: "GET",
    },
    appState.token
  ).then((subcates) => {
    const subcate = subcates.filter(
      (sub) => sub.id.toString() === subcategoryId
    )[0];
    setSubCategory(subcate);
    setCategory({
      id: subcate.category.id,
      status: "ready",
      name: subcate.category.name,
      description: subcate.category.description,
      score_weight: subcate.category.score_weight,
      color: "#eeeaed",
    });
    fetchAPI<APIActions["fetchIndicatorsFromCategory"]>(
      APIRoutes.fetchIndicatorsFromCategory,
      {
        method: "GET",
        routeParams: { category_id: subcate.category.id.toString() },
      },
      token
    ).then((indics) => {
      indics.forEach((indi) =>
        indicatorsHelper.push({
          slug: indi.slug,
          title: indi.title,
          score_weight: indi.score_weight,
          color: indi.color,
          id: indi.id,
          statusSlug: "ready",
          statusTitle: "ready",
          statusWeight: "ready",
          statusColor: "ready",
          generalStatus:
            indi.subcategory_id === subcate.id ? "no modified" : "no-click",
          formula: indi.formula,
          unreplaced_formula: indi.unreplaced_formula,
          auxiliaryFormula: indi.auxiliary_formulas
            ? indi.auxiliary_formulas
            : [],
        })
      );
      indicatorsHelper.sort((a, b) => a.id - b.id);
      setIndicators([...indicatorsHelper]);
      setIndicatorSelected(
        [...indicatorsHelper].filter(
          (indi) => indi.generalStatus === "no modified"
        )[0]
      );
      fetchAPI<APIActions["fetchEvaluationVariables"]>(
        APIRoutes.fetchEvaluationVariables,
        {
          method: "GET",
        },
        token
      )
        .then((variables) => {
          let id = 0;
          variables.forEach((varia) => {
            if (varia.id >= id) {
              id = varia.id;
            }
          });
          setMaxVariableId(id + 1);
          indicatorsHelper.forEach((indicator) => {
            const variablesFromIndicator: EvaluationVariableStatus[] = variables
              .filter((vari) => vari.indicator_id === indicator.id)
              .map((vari) => {
                return {
                  indicator_id: vari.indicator_id,
                  type: vari.type,
                  description: vari.description,
                  is_omittable: vari.is_omittable,
                  requires_verificator: vari.requires_verificator,
                  description_field_helper_text:
                    vari.description_field_helper_text,
                  verification_field_helper_text:
                    vari.verification_field_helper_text,
                  id: vari.id,
                  statusDescription: "ready",
                  statusDescriptionFieldHelper: "ready",
                  statusVerification: "ready",
                  generalStatus: "no modified",
                  display_order: vari.display_order,
                  default_score: vari.default_score,
                  json_config: vari.json_config,
                  statusWeight: "ready",
                };
              });
            evalVariables.push({
              id: indicator.id,
              evaluationVariables: variablesFromIndicator.sort(
                (a, b) => a.id - b.id
              ),
            });
          });
        })
        .then(() => {
          setEvaluationVariables([...evalVariables]);
          setReady(true);
        });
    });
  });
}

export default singleSubCategorieData;
