import React, { ReactElement } from "react";
import "twin.macro";
import { PieChart, Pie, Tooltip, Cell } from "recharts";

const COLORS = ["#7cc199", "#d91111"];
/**
 * Tarjeta para mostrar progreso de categorias.
 *
 * @param props react props.
 * @param props.label Nombre de la categoria
 * @param props.completed Cantidad de variables de los indicadores que han sido contestadas
 * @param props.total Cantidad total de variables
 * @param props.optionalText Texto opcional que se puede añadir en la partede abajo de la tarjeta
 * @param props.setSelectedCategory setter de categoria seleccionada
 * @param props.selectedCategory categoria seleccionada
 */
function CategorieOption(props: {
  label: string;
  completed: number;
  total: number;
  optionalText: string;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
  selectedCategory: string;
}): ReactElement {
  const {
    label,
    completed,
    total,
    optionalText,
    setSelectedCategory,
    selectedCategory,
  } = props;

  const data = [
    { name: "Completado", value: completed, id: "1" },
    { name: "Incompleto", value: total - completed, id: "2" },
  ];
  /**
   * Funcion para manejar el cambio de elemento seleccionado
   *
   * @param e evento de click
   */
  function onClickHandler(e: React.MouseEvent<HTMLButtonElement>) {
    setSelectedCategory(e.currentTarget.name);
  }

  // <div tw="flex flex-row justify-center bg-white py-1 text-xl w-full w-auto h-12">
  return (
    <button
      tw="flex relative block rounded-md w-full h-72 focus:outline-none"
      className={
        selectedCategory === label
          ? "bg-resies_lightgreen mt-6"
          : "bg-resies_lightpurple"
      }
      type="button"
      name={label}
      onClick={(e) => onClickHandler(e)}
    >
      <div tw="pt-10 flex justify-center">
        <PieChart width={200} height={100}>
          <Pie
            dataKey="value"
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={30}
            outerRadius={50}
          >
            {data.map((entry, index) => (
              <Cell fill={COLORS[index % COLORS.length]} key={entry.id} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>
      <div tw="flex flex-col justify-center">
        <p tw="mt-2.5 text-black text-xs lg:text-base font-semibold flex justify-center text-center text-header2">
          {label}
        </p>
        <p tw="text-black text-xs lg:text-base font-bold flex justify-center text-center text-resies_blue1">
          {parseFloat(((completed / total + Number.EPSILON) * 100).toFixed(1))}%
        </p>
      </div>
      {optionalText ? (
        <div tw="flex justify-center">
          <p tw="text-black text-xs lg:text-base font-normal flex justify-center text-center opacity-50">
            {optionalText}
          </p>
        </div>
      ) : (
        <></>
      )}
    </button>
  );
}

export default CategorieOption;
