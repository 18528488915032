import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Redirect, useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import EvidenceInfo from "../../components/common/EvidenceView/EvidenceInfo";
import InstitutionalEvidenceComments from "../../components/common/EvidenceView/InstitutionalEvidenceComments";
import InstitutionalEvidenceTitle from "../../components/common/EvidenceView/InstitutionalEvidenceTitle";
import InsideNavigationBar from "../../components/common/InsideNavigationBar";
import InstitutionalEvidenceDataRow from "../../components/common/InstitutionalEvidenceView/InstitutionalEvidenceDataRow";
import VolverButton from "../../components/common/VolverButton";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import {
  InstitutionalDataResponse,
  InstitutionalDataVariable,
  InstitutionDetails,
} from "../../types/backendTypes";
import getInstitutionalResponseVariable from "../../utils/data/AdmData/getInstitutionalResponseVariable";
import getInstitutionDetails from "../../utils/data/AudData/getInstitutionDetails";
import getInstitutionalVariableDetails from "../../utils/data/getInstitutionalVariableDetails";

/**
 * Componente de la vista de apelacion del ADM
 */
function ADMSingleInstitutionalAppealView(): ReactElement {
  const { variableId } = useParams<{ variableId: string }>();
  const { institutionId } = useParams<{ institutionId: string }>();
  const [uploadFile, setUploadFile] = useState(0);
  // eslint-disable-next-line
  const [institution, setInstitution] = useState<InstitutionDetails>();
  const [ready, setReady] = useState(false);
  const [responseVariable, setResponseVariable] =
    useState<InstitutionalDataResponse>();
  const [reload, setReload] = useState(0);
  const [evaluationVariable, setEvaluationVariable] =
    useState<InstitutionalDataVariable>();
  const [error, setError] = useState(false);
  const [appState] = useContext(AppContext);

  useEffect(() => {
    getInstitutionDetails(institutionId, appState.token, setInstitution);
    getInstitutionalVariableDetails(
      appState,
      variableId,
      setEvaluationVariable,
      setReady
    );
    getInstitutionalResponseVariable(
      appState,
      institutionId,
      variableId,
      setResponseVariable,
      setError,
      setReady
    );
  }, [appState, institutionId, variableId, uploadFile, reload]);

  return (
    <div tw="bg-ghostwhite">
      {error ? <Redirect to={Routes.indicators} /> : <></>}
      <CargandoScreen ready={ready}>
        <Container>
          <InsideNavigationBar
            items={[
              { name: "Plazos", href: Routes.auditDeadlines },
              { name: "Portal auditores", href: Routes.auditorPortal },
              { name: "Apelaciones", href: Routes.appeals },
            ]}
            title="Gestión de auditorías"
          />
          <InstitutionalEvidenceTitle
            evaluationVariable={evaluationVariable}
            title="EVIDENCIA"
            responseVariable={responseVariable}
          />
          <EvidenceInfo
            info={responseVariable?.description}
            title="Descripción de variable"
          />
          {responseVariable ? (
            <InstitutionalEvidenceDataRow
              response={responseVariable}
              setUploadFile={setUploadFile}
              reload={reload}
              setReload={setReload}
            />
          ) : (
            <></>
          )}
          <InstitutionalEvidenceComments />
          <VolverButton
            to={Routes.appealsInstitution.replace(
              ":institutionId",
              institutionId
            )}
          />
          <div tw="bg-ghostwhite h-10" />
        </Container>
      </CargandoScreen>
    </div>
  );
}

export default ADMSingleInstitutionalAppealView;
