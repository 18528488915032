import { Tooltip } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import Routes from "../../../routers/routes";

/**
 * Formulario correspondiente a una variable de un indicador.
 *
 * @param props react props.
 * @param props.elem Datos del formulario de la variable.
 * @param props.indicatorForm Diccionario que muestra los datos del formulario de cada variable.
 * @param props.formState Estado que maneja los datos actualmente ingresados en los formularios de cada variable.
 * @param props.changeHandler Funcion que maneja el cambio de estado cuando se ingresa un dato en el formulario.
 * @param props.disabled Si el input está disabled o no
 */
function IndicatorForm(props: {
  elem: Record<string, string>;
  indicatorForm: Record<string, string>[];
  formState: Record<string, string>[];
  changeHandler: (
    key: number,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  disabled: boolean;
}): ReactElement {
  const { elem, formState, changeHandler, indicatorForm, disabled } = props;
  const key = indicatorForm.indexOf(elem);
  let auditText = "";
  let evidenceText = "";
  let auditColor = "";
  if (elem.audit_status === "pending") {
    auditText = "Pendiente";
    auditColor = "warning_yellow";
  } else if (elem.audit_status === "accepted") {
    auditText = "Aceptado";
    auditColor = "resies_green";
  } else {
    auditText = "Rechazado";
    auditColor = "darkred";
  }
  if (elem.evidences.length) {
    evidenceText = "Evidencia Subida";
  } else {
    evidenceText = "Faltante";
  }
  return (
    <div
      tw="flex flex-row grid grid-cols-11
          content-center border-b-2 border-darkgray w-full"
    >
      <div tw="flex col-span-3 text-justify min-h-100 xl:w-80 lg:w-52 mx-auto">
        <p tw="xl:text-sm text-xs font-normal text-black">{elem.slug}</p>
      </div>
      {elem.type !== "MULTIPLE_CHOICE" ? (
        <input
          value={formState[key].value}
          type="number"
          tw="border-2 border-darkgray flex-wrap align-top h-3/6 rounded-md max-h-14"
          disabled={disabled}
          name="value"
          onChange={(e) => changeHandler(key, e)}
        />
      ) : (
        <select
          data-testid={`s${elem.variable_id}`}
          value={formState[key].value}
          tw="border-2 border-darkgray flex-wrap align-top h-3/6 rounded-md max-h-14"
          disabled={disabled}
          name="value"
          onChange={(e) => changeHandler(key, e)}
        >
          {!elem.value ? <option value="">Nada</option> : <></>}
          {JSON.parse(elem.json_config).map(
            (opt: { option_text: string; option_score: number }) => (
              <option value={opt.option_text} key={opt.option_text}>
                {opt.option_text}
              </option>
            )
          )}
        </select>
      )}

      <div tw="flex col-span-3 text-center h-full flex-wrap max-h-28">
        {elem.value ? (
          <Tooltip
            title={formState[key].descriptionHelper}
            placement="right-start"
          >
            <textarea
              tw="mx-auto w-full mx-10"
              placeholder={formState[key].descriptionHelper}
              value={formState[key].description}
              name="description"
              disabled={disabled}
              onChange={(e) => changeHandler(key, e)}
            />
          </Tooltip>
        ) : (
          <p tw="mx-auto w-full mx-10">
            Necesitas responder para ingresar una descripción
          </p>
        )}
      </div>
      <div tw="flex flex-col col-span-2 text-center h-full flex-wrap mr-8 justify-center">
        {elem.response_id && elem.variable_id && elem.value ? (
          <Link
            to={Routes.evidence
              .replace(":variableId", elem.variable_id.toString())
              .replace(":responseId", elem.response_id.toString())}
          >
            <button
              type="button"
              tw="bg-resies_green  py-1 mb-4 xl:w-8/12 w-full h-5/6 mx-auto rounded-md 
                    flex flex-row flex-wrap content-center max-h-12"
            >
              <p tw="text-ghostwhite mr-2 font-normal text-xs lg:text-sm mx-auto">
                Ir a la evidencia
              </p>
            </button>
          </Link>
        ) : (
          <button
            type="button"
            tw="bg-resies_green  py-1 mb-2 xl:w-8/12 w-full h-5/6 mx-auto rounded-md 
                    flex flex-row flex-wrap content-center max-h-12 cursor-not-allowed"
            disabled
          >
            <p tw="text-ghostwhite font-normal text-xs lg:text-sm mx-auto">
              No ha ingresado dato
            </p>
          </button>
        )}
        <div tw="xl:w-10/12 w-full  h-1/6 mx-auto text-xs lg:text-sm max-h-8">
          <p tw="text-darkgray font-normal text-body">{evidenceText}</p>
        </div>
      </div>
      <div tw="flex flex-row col-span-2 text-center h-full flex-wrap mr-8 content-center justify-center">
        <div className={`rounded-full h-3 w-3 my-auto mr-2 bg-${auditColor}`} />
        <p className={`text-${auditColor}`}>{auditText}</p>
      </div>
    </div>
  );
}

export default IndicatorForm;
