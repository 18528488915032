/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { loadGraph, QData } from "./d3Graph";

const BoxPlot = React.forwardRef(
  (
    { data }: { data: QData },
    ref: React.ForwardedRef<(data: QData) => void>
  ) => {
    const d3Ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (d3Ref.current !== null) {
        const div = d3Ref.current;
        // if there is a graph, destroy it.
        if (div.getElementsByTagName("svg").length !== 0)
          div.getElementsByTagName("svg")[0].remove();
        d3.select(div).append("svg").attr("width", 1000).attr("height", 550);
        loadGraph(div, data);
        (ref as React.MutableRefObject<(data: QData) => void | null>).current =
          (d: QData) => loadGraph(div, d);
      }
    });
    return <div id="d3-node" style={{ display: "inline-block" }} ref={d3Ref} />;
  }
);

export default BoxPlot;
