import React, { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import { ActionTypes, AppContext } from "../../dispatcher";
import useRESIESDates from "../../hooks/useRESIESDates";
import Routes from "../../routers/routes";
import DatesPopUp from "./DatesPopUp";
/**
 * Navbar de login y logout de la aplicación.
 */
function LoginNavbar(): ReactElement {
  const [appState, dispatch] = useContext(AppContext);
  const [activeVersion, currentProcess, currentInstitutionProcess] =
    useRESIESDates();

  const getActualVersion = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess;
    }

    return activeVersion;
  };

  const getActualCurrentProcess = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess.current_process;
    }

    return currentProcess;
  };

  return (
    <div tw="flex flex-row justify-between bg-resies_blue1 py-5 pr-20 pl-28 print:hidden">
      <div tw="flex flex-row">
        <Link to={Routes.myProfile}>
          <button type="button" tw="flex flex-row">
            <div tw="flex w-7">
              <img tw="object-contain" src="/userLogo.png" alt="user logo" />
            </div>
            <div tw="pl-1 pr-10 text-resies_lightpurple font-semibold">
              {appState.name}
            </div>
          </button>
        </Link>
        <div tw="flex w-auto">
          <a
            href="https://docs.google.com/document/d/1-xA1YRudEmqZITlnErg01fB71TedHbKjXnSbxcKp0ec/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              type="button"
              tw=" pl-1 pr-10 text-resies_lightpurple font-semibold flex flex-row"
            >
              <img tw="object-contain mr-2" src="/manual.png" alt="manual" />
              <div>Manual</div>
            </button>
          </a>
        </div>
        <DatesPopUp
          resiesVersion={getActualVersion()}
          currentProcess={getActualCurrentProcess()}
        />
      </div>

      <div tw="flex flex-row">
        <div tw="flex w-7">
          <button type="button">
            <img tw="object-contain" src="/logout.png" alt="logout" />
          </button>
        </div>

        <button
          type="button"
          tw="px-4 text-resies_lightpurple font-semibold"
          onClick={() => dispatch({ type: ActionTypes.logout, payload: {} })}
        >
          Cerrar Sesión
        </button>
      </div>
    </div>
  );
}

export default LoginNavbar;
