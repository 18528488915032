import React, { ReactElement, ReactNode } from "react";
import "twin.macro";

/**
 * Un contenedor generico que tiene el mismo padding que el navbar
 *
 * @param props react props.
 * @param props.children Los nodos hijo
 */
function Container(props: { children: ReactNode }): ReactElement {
  const { children } = props;
  return (
    <div tw="w-full px-10  pr-20 pl-28 flex-grow print:pr-0 print:pl-0">
      {children}
    </div>
  );
}

export default Container;
