/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable spaced-comment */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useRef } from "react";
import "twin.macro";
import _ from "lodash";
import { User } from "../../types/backendTypes";
import { CenteredPopup } from "./utils/Popup";
/** EditorPopUp. It handles user profile edition. Neat, right?
 *
 * @param props0 react props
 * @param props0.user the user data
 */
function Modal({ user }: { user: User }) {
  const ref = useRef<() => void>(null);
  return (
    <button
      type="button"
      onClick={() => {
        if (ref.current !== null) ref.current();
      }}
      className="px-2 bg-resies_blue1 text-ghostwhite rounded-md w-full text-center cursor-pointer"
    >
      Ver Perfil
      <CenteredPopup width={400} height={500} ref={ref}>
        <div className="bg-white font-sans text-black rounded-lg popup flex flex-col justify-between pb-10 h-full">
          <div tw="flex">
            <span
              onClick={() => {
                if (ref.current !== null) ref.current();
              }}
              tw="ml-auto mr-2 text-gray"
            >
              X
            </span>
          </div>
          <h1 tw="font-bold">
            {_.capitalize(user.first_name)} {_.capitalize(user.last_name)}.
          </h1>
          <div tw="h-full flex justify-center">
            <div tw="flex flex-col justify-center">
              <img
                alt="foto"
                tw="h-40 object-cover"
                src={user.photo_url || "/blank_avatar.png"}
              />
            </div>
          </div>
          <div tw="flex flex-col items-center justify-evenly">
            {(
              [
                ["first_name", "nombre"],
                ["last_name", "apellido"],
                ["description", "Cargo"],
                ["email", "correo"],
                ["phone_number", "teléfono"],
              ] as Array<
                [
                  (
                    | "first_name"
                    | "last_name"
                    | "description"
                    | "email"
                    | "phone_number"
                  ),
                  string
                ]
              >
            ).map(
              (
                obj: [
                  (
                    | "first_name"
                    | "last_name"
                    | "description"
                    | "email"
                    | "phone_number"
                  ),
                  string
                ]
              ) => {
                const [attrName, title] = obj;
                return (
                  <div key={title} tw="w-4/5">
                    <h2 tw="font-semibold">{_.capitalize(title)}</h2>
                    <div tw="bg-resies_lightpurple w-full py-1 px-3">
                      {user[attrName] ? user[attrName] : "Sin info"}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </CenteredPopup>
    </button>
  );
}

export default Modal;
