import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import "twin.macro";

/**
 * Un hook para manejar hide/show password
 * Retorna el type del input (text o password) y el boton
 */
function usePasswordToggle(): [string, JSX.Element] {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const Icon = (
    <FontAwesomeIcon
      icon={passwordShown ? faEyeSlash : faEye}
      onClick={togglePasswordVisiblity}
      tw="cursor-pointer"
      size="2x"
    />
  );
  const inputType = passwordShown ? "text" : "password";
  return [inputType, Icon];
}

export default usePasswordToggle;
