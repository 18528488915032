import React, { ReactElement, useContext } from "react";
import "twin.macro";
import { AppContext } from "../../../dispatcher";
import useRESIESDates from "../../../hooks/useRESIESDates";
import { InstitutionalResponse } from "../../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../../utils/fetchAPI";
import InstitutionalFilesColumn from "./InstitutionalFilesColum";
import InstitutionalLinksColumn from "./InstitutionalLinksColum";

/**
 * Componente que contiene la fila donde se muestran las evidencias de la variable institucional.
 *
 * @param props react props.
 * @param props.response Es la response variable de la cual se esta viendo evidencia
 * @param props.setUploadFile settear archivo a subir
 * @param props.reload numero a recargar
 * @param props.setReload setear reload
 */
function EvidenceDataRow(props: {
  response: InstitutionalResponse;
  setUploadFile: React.Dispatch<React.SetStateAction<number>>;
  reload: number;
  setReload: React.Dispatch<React.SetStateAction<number>>;
}): ReactElement {
  const [appState] = useContext(AppContext);
  const { response, setUploadFile, reload, setReload } = props;
  const [, currentProcess, currentInstitutionProcess] = useRESIESDates();

  const getActualCurrentProcess = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess.current_process;
    }

    return currentProcess;
  };

  /**
   * cambiar estado al hacer click
   *
   * @param e evento
   */
  function onClickChangeStatus(e: React.MouseEvent<HTMLButtonElement>) {
    if (!appState.token) return;
    fetchAPI<APIActions["auditInstitutionalVariable"]>(
      APIRoutes.auditInstitutionalVariable,
      {
        method: "PUT",
        routeParams: { institutional_data_response_id: response.id.toString() },
        queryParams: {
          auditor_veredict: e.currentTarget.name,
        },
      },
      appState.token
    ).then(() => {
      setReload(reload + 1);
    });
  }
  /**
   * Función que elimina evidencia
   *
   * @param e evento que desencadena la eliminación
   */
  function deleteEvidence(e: React.MouseEvent<HTMLButtonElement>) {
    if (!appState.token) return;
    fetchAPI<APIActions["deleteInstitutionalEvidence"]>(
      APIRoutes.deleteInstitutionalEvidence,
      {
        method: "DELETE",
        routeParams: { response_verificator_id: e.currentTarget.name },
      },
      appState.token
    ).then(() => setReload(reload + 1));
  }

  /**
   * descargar zip con evidencia.
   */
  function downloadZip() {
    if (!appState.token) return;
    fetchAPI<APIActions["downloadInstitutionalEvidenceZip"]>(
      APIRoutes.downloadInstitutionalEvidenceZip,
      {
        method: "GET",
        routeParams: { response_variable_id: response.id.toString() },
      },
      appState.token
    ).then((link) => {
      const newWindow = window.open(
        link.file_link,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    });
  }
  /**
   * La función para que el administrador acepte una evidencia apelada
   */
  function adminAprove() {
    const key = appState.token;
    if (!key) return;
    fetchAPI<APIActions["auditInstitutionalVariable"]>(
      APIRoutes.auditInstitutionalVariable,
      {
        method: "PUT",
        routeParams: { institutional_data_response_id: response.id.toString() },
        queryParams: {
          auditor_veredict: "final_accepted",
        },
      },
      key
    ).then(() => {
      setReload(reload + 1);
    });
  }
  /**
   * La función para que el administrador rechace una evidencia apelada
   */
  function adminReject() {
    const key = appState.token;
    if (!key) return;
    fetchAPI<APIActions["auditInstitutionalVariable"]>(
      APIRoutes.auditInstitutionalVariable,
      {
        method: "PUT",
        routeParams: { institutional_data_response_id: response.id.toString() },
        queryParams: {
          auditor_veredict: "final_rejected",
        },
      },
      key
    ).then(() => {
      setReload(reload + 1);
    });
  }

  /**
   * Función que permite al RDI apelar
   */
  function appealEvidence() {
    const key = appState.token;
    if (!key) return;
    fetchAPI<APIActions["appealInstitutionalRDI"]>(
      APIRoutes.appealInstitutionalRDI,
      {
        method: "PUT",
        routeParams: {
          response_variable_id: response.id.toString(),
        },
      },
      key
    ).then(() => {
      setReload(reload + 1);
    });
  }

  return (
    <div>
      <div tw="flex flex-row justify-between mt-10">
        <InstitutionalFilesColumn
          response={response}
          setUploadFile={setUploadFile}
          deleteEvidence={deleteEvidence}
        />
        <InstitutionalLinksColumn
          response={response}
          setUploadFile={setUploadFile}
          deleteEvidence={deleteEvidence}
        />
      </div>
      <div tw="flex justify-end">
        {appState.userType === "AUD" ? (
          <>
            {(getActualCurrentProcess()?.name === "Auditoría" ||
              getActualCurrentProcess()?.name === "Revisión de Objetados") && (
              <>
                {(response.audit_status === "accepted" ||
                  response.audit_status === "pending" ||
                  response.audit_status === "objected") &&
                getActualCurrentProcess()?.name === "Revisión de Objetados" ? (
                  <button
                    type="button"
                    tw="bg-resies_purple mt-2 text-ghostwhite font-bold flex flex-row px-3 py-2 mr-3"
                    name="rejected"
                    onClick={(e) => onClickChangeStatus(e)}
                  >
                    <img
                      tw="object-contain mr-2"
                      src="/reject.png"
                      alt="reject"
                    />
                    Rechazar
                  </button>
                ) : (
                  <></>
                )}

                {(response.audit_status === "rejected" ||
                  response.audit_status === "pending" ||
                  response.audit_status === "accepted") &&
                getActualCurrentProcess()?.name === "Auditoría" ? (
                  <button
                    type="button"
                    tw="bg-resies_purple mt-2 text-ghostwhite font-bold flex flex-row px-3 py-2 mr-3"
                    name="objected"
                    onClick={(e) => onClickChangeStatus(e)}
                  >
                    <img
                      tw="object-contain mr-2"
                      src="/object.png"
                      alt="object"
                    />
                    Objetar
                  </button>
                ) : (
                  <></>
                )}

                {response.audit_status === "rejected" ||
                response.audit_status === "pending" ||
                response.audit_status === "objected" ? (
                  <button
                    type="button"
                    tw="bg-resies_purple mt-2 text-ghostwhite font-bold flex flex-row px-3 py-2 mr-3"
                    name="accepted"
                    onClick={(e) => onClickChangeStatus(e)}
                  >
                    <img
                      tw="object-contain mr-2"
                      src="/accept.png"
                      alt="accept"
                    />
                    Aprobar
                  </button>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <></>
        )}
        {appState.userType === "RDI" &&
          response.audit_status === "rejected" &&
          getActualCurrentProcess()?.name === "Proceso de Apelación" && (
            <button
              type="button"
              tw="bg-resies_purple mt-2 text-ghostwhite font-bold flex flex-row px-3 py-2 mr-3"
              onClick={appealEvidence}
            >
              Apelar
            </button>
          )}
        {appState.userType === "ADM" &&
          getActualCurrentProcess()?.name === "Resultados de Apelación" && (
            <>
              {(response.audit_status === "appealed" ||
                response.audit_status === "final_rejected") && (
                <button
                  type="button"
                  tw="bg-resies_purple mt-2 text-ghostwhite font-bold flex flex-row px-3 py-2 mr-3"
                  onClick={adminAprove}
                >
                  <img
                    tw="object-contain mr-2"
                    src="/accept.png"
                    alt="accept"
                  />
                  Aprobar
                </button>
              )}
              {(response.audit_status === "appealed" ||
                response.audit_status === "final_accepted") && (
                <button
                  type="button"
                  tw="bg-resies_purple mt-2 text-ghostwhite font-bold flex flex-row px-3 py-2 mr-3"
                  name="rejected"
                  onClick={adminReject}
                >
                  <img
                    tw="object-contain mr-2"
                    src="/reject.png"
                    alt="reject"
                  />
                  Rechazar
                </button>
              )}
            </>
          )}
        {response.evidences?.filter(
          (evidence) => evidence.evidence_type === "S3"
        ).length ? (
          <button
            type="button"
            tw="bg-resies_purple mt-2 text-ghostwhite font-bold flex flex-row px-3 py-2"
            onClick={() => downloadZip()}
          >
            <img tw="object-contain mr-2" src="/download.png" alt="download" />
            Descargar
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default EvidenceDataRow;
