/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as d3 from "d3";
import _ from "lodash";

export type Quants = {
  name: string;
  stats: { q0: number; q1: number; q2: number; q3: number; q4: number };
  index: number;
};
export type QData = {
  general: Quants;
  elements: Quants[];
};

const HEIGHT = 400;
const CENTER = 200;
const WIDTH = 100;
const UPPERMARGIN = 30;
const LEFTMARGIN = 30;
const HSEPARATION = 30;
const LEGENDMARGIN = 30;
const SCALE = { YScale: d3.scaleLinear() };

/** gets the max and min for the axis and scaling.
 *
 * @param data the QData of the quartiles
 */
function getMinAndMaxQData(data: QData) {
  const newList = _.cloneDeep(data.elements);
  newList.push(_.cloneDeep(data.general));
  const maxs = newList.map((i) => i.stats.q4);
  const mins = newList.map((i) => i.stats.q0);
  return { min: d3.min(mins), max: d3.max(maxs) };
}

/** update function for every boxplot
 *
 * @param sel selector
 */
function updateBoxPlot(sel: any) {
  const y = SCALE.YScale;
  sel
    .select(".v-line")
    .transition()
    .duration(200)
    // @ts-ignore
    .attr("y1", (d) => {
      // console.log("JJOJOOJJ", d);
      // @ts-ignore
      return y(d.stats.q0);
    })
    // @ts-ignore
    .attr("y2", (d) => y(d.stats.q4));

  sel
    .select("rect")
    .transition()
    .duration(200)
    // @ts-ignore
    .attr("y", (d: any) => y(d.stats.q3))
    .attr(
      "height",
      // @ts-ignore
      (d) => d3.max([y(d.stats.q1) - y(d.stats.q3), 1])
    );
  sel.each(function (d: any) {
    // console.log("the data inside:", d);
    // console.log(
    //   "the q-data scaled:",
    //   // @ts-ignore
    //   y(d.stats.q0),
    //   // @ts-ignore
    //   y(d.stats.q1),
    //   // @ts-ignore
    //   y(d.stats.q2),
    //   // @ts-ignore
    //   y(d.stats.q3),
    //   // @ts-ignore
    //   y(d.stats.q4)
    // );
    // @ts-ignore
    // console.log(d3.select(this));
    // @ts-ignore
    d3.select(this)
      .selectAll(".h-line")
      .data([d.stats.q0, d.stats.q2, d.stats.q4])
      .transition()
      .duration(200)
      // @ts-ignore
      .attr("y1", (datum: any) => y(datum))
      // @ts-ignore
      .attr("y2", (datum: any) => y(datum));
  });
  sel
    .select("text.name")
    .attr("x", 0)
    .attr("y", 0)
    .text((d: any) => d.name)
    .call(wrap, 200)
    .attr("transform", `translate(${CENTER - 10}, ${HEIGHT + 20})rotate(40)`);
  return sel;
}
/** the enter function for each boxplot
 *
 * @param enter the enter selector
 */
function enterBoxPlot(enter: any) {
  return enter
    .attr(
      "transform",
      (d: any) =>
        `translate(${
          LEFTMARGIN +
          (HSEPARATION + WIDTH) * d.index -
          (CENTER - WIDTH / 2) +
          10
        }, ${UPPERMARGIN})`
    )
    .call((sel: any) => {
      sel
        .append("line")
        .attr("class", "v-line")
        .attr("x1", CENTER)
        .attr("x2", CENTER)
        .attr("stroke", "black");

      sel
        .append("rect")
        .attr("x", CENTER - WIDTH / 2)
        .attr("width", WIDTH)
        .attr("stroke", "black")

        .style("fill", "#7cc199");

      sel
        .selectAll(".h-line")
        .data([0, 0, 0])
        .enter()
        .append("line")
        .attr("class", "h-line")
        .attr("x1", CENTER - WIDTH / 2)
        .attr("x2", CENTER + WIDTH / 2)
        .attr("stroke", "black");

      // sel
      //   .append("line")
      //   .attr("class", "name-line")
      //   .attr("x1", CENTER - WIDTH / 2)
      //   .attr("x2", CENTER + WIDTH / 2)
      //   .attr("y1", 0)
      //   .attr("y2", 0)
      //   .attr("stroke", "black");

      sel
        .append("text")
        .attr("class", "name")
        .attr("font-weight", "lighter")
        .attr("font-family", "arial")
        .attr("font-size", 12)
        // .attr("visibility", "hidden")
        .attr("stroke", "black");
      // sel.on("mouseover", function () {
      //   // @ts-ignore
      //   d3.select(this).select("text").attr("visibility", "visible");
      // });
      // sel.on("mouseout", function () {
      //   // @ts-ignore
      //   d3.select(this).select("text").attr("visibility", "hidden");
      // });
      sel.call(updateBoxPlot);
    });
}

/**
 * The function that receives the data and updates the svg really.
 *
 * @param div that contains the svg
 * @param data array of data
 */
function loadGraph(div: HTMLDivElement, data: QData) {
  const svg = d3.select(div).selectAll("svg");
  // console.log("loading graph with data", data);
  // tenemos que cargar:
  // - ejeY (usando data)
  // - boxplot principal
  // - los otros boxplots
  const h = 1;

  // 1. THE AXIS
  const { min, max } = getMinAndMaxQData(data);
  // @ts-ignore
  const YScale = d3.scaleLinear().domain([min, max]).range([HEIGHT, 0]);

  const l = YScale(h);
  svg
    .selectAll("g.axis")
    .data([0])
    .enter()
    .append("g")
    .attr("class", "axis")
    .attr("transform", (d: any) => `translate(${LEFTMARGIN}, ${UPPERMARGIN})`)
    .transition()
    // @ts-ignore
    .call(d3.axisLeft(YScale).ticks(5));
  svg
    .selectAll("g.axis")
    .data([0])
    .attr("class", "axis")
    .attr("transform", (d: any) => `translate(${LEFTMARGIN}, ${UPPERMARGIN})`)
    .transition()
    // @ts-ignore
    .call(d3.axisLeft(YScale).ticks(5));
  // @ts-ignore
  SCALE.YScale = YScale;
  // @ts-ignore
  // 2. THE MAIN BOXPLOT
  data.general.index = 0;
  // @ts-ignore
  svg
    .selectAll("g.main-boxplot")
    .data([data.general])
    .join(
      (enter) =>
        enter.append("g").attr("class", "main-boxplot").call(enterBoxPlot),
      updateBoxPlot,
      (remove) => remove.transition().duration(200).remove()
    );
  // 3. THE OTHER BOXPLOTS
  // @ts-ignore
  data.elements.forEach((i, j) => {
    // @ts-ignore
    i.index = j + 1;
  });
  // @ts-ignore
  svg
    .attr("width", (data.elements.length + 1) * (HSEPARATION + WIDTH) + 100)
    .selectAll("g.boxplot")
    .data(data.elements)
    .join(
      (enter) => enter.append("g").attr("class", "boxplot").call(enterBoxPlot),
      updateBoxPlot,
      (remove) =>
        remove.call((sel) =>
          sel.transition().attr("transform", `translate(${0}, ${500})`).remove()
        )
    );
}
export { loadGraph };

/**
 * Parte en partes un texto de largo width
 *
 * @param textt El texto
 * @param width El ancho en px
 */
function wrap(textt: any, width: number) {
  textt.each(function () {
    // @ts-ignore
    const text = d3.select(this);
    const words = text.text().split(/\s+/).reverse();
    let word;
    let line: string[] = [];
    let lineNumber = 0;
    const lineHeight = 1.1; // ems
    const x = text.attr("x");
    const y = text.attr("y");
    const dy = 0; // parseFloat(text.attr("dy")),
    let tspan = text
      .text(null)
      .append("tspan")
      .attr("x", x)
      .attr("y", y)
      .attr("dy", `${dy}em`);
    // eslint-disable-next-line no-cond-assign
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      // @ts-ignore
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text
          .append("tspan")
          .attr("x", x)
          .attr("y", y)
          // eslint-disable-next-line no-plusplus
          .attr("dy", `${++lineNumber * lineHeight + dy}em`)
          .text(word);
      }
    }
  });
}
