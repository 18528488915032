import React, { ReactElement } from "react";
import "twin.macro";
import CompleteIndicator from "./CompleteIndicator";
import { Indicator } from "../../../types/backendTypes";

/**
 * Lista lateral de indicadores.
 *
 * @param props react props.
 * @param props.indicators Diccionario que contiene los nombres de los indicadores de cada categoria.
 * @param props.selected Corresponde al nombre de la categoria actualmente seleccionada.
 * @param props.clickHandler Funcion que maneja el cambio de indicador seleccionado.
 * @param props.indicatorSelected State del indicador actualmente seleccionado.
 * @param props.formState Estado que maneja los datos actualmente ingresados en los formularios de cada variable.
 */
function IndicatorList(props: {
  indicators: Indicator[];
  indicatorSelected: Indicator;
  formState: Record<string, string>[];
}): ReactElement {
  const { indicators, formState, indicatorSelected } = props;

  return (
    <div tw="flex flex-col bg-resies_lightpurple w-28 overflow-y-scroll">
      {indicators.map((indicator: Indicator) => (
        <CompleteIndicator
          indicator={indicator}
          formState={formState}
          indicatorSelected={indicatorSelected}
          key={indicator.id}
        />
      ))}
    </div>
  );
}

export default IndicatorList;
