import React, { ReactElement } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "twin.macro";
import MDIIndicatorsView from "../pages/mdi/MDIIndicatorsView";
import LoginNavbar from "../components/common/LoginNavbar";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import Routes from "./routes";
import MDIInstitutionIndexPage from "../pages/mdi/MDIInstitutionIndexPage";
import MDIEvidenceView from "../pages/mdi/MDIEvidenceView";
import MyProfileMDI from "../pages/mdi/MyProfileMDI";
import MDIMyInstitution from "../pages/mdi/MDIMyInstitution";
import MDIInstitutionData from "../pages/mdi/MDIInstitutionData";
import ChangePasswordPage from "../pages/common/ChangePasswordPage";
import MDIAudit from "../pages/mdi/MDIAudit";
import RDIExecuteObjected from "../pages/rdi/RDIExecuteObjected";
import MDIInstitutionalEvidenceView from "../pages/mdi/MDIInstitutionalEvidenceView";
import RDIExecuteInstitutionalObjected from "../pages/rdi/RDIExecuteInstitutionalObjected";

/**
 * El router del usuario miembro de institución
 *
 * Al estar registrado como un usuario miembro de institución, este es el router que se muestra.
 */
function MDIRouter(): ReactElement {
  return (
    <>
      <Router>
        <LoginNavbar />
        <Navbar
          items={[
            { name: "Tu institución", href: Routes.myInstitutionMDI },
            { name: "Tus indicadores", href: Routes.indicators },
            { name: "Otras instituciones", href: Routes.institutionsIndex },
            { name: "Auditoría", href: Routes.auditProcesses },
          ]}
        />
        <div tw="bg-ghostwhite">
          <Switch>
            <Route exact path={Routes.myInstitutionMDI}>
              <MDIMyInstitution />
            </Route>
            <Route exact path={Routes.indicators}>
              <MDIIndicatorsView />
            </Route>
            <Route exact path={Routes.evidence}>
              <MDIEvidenceView />
            </Route>
            <Route exact path={Routes.institutionsIndex}>
              <MDIInstitutionIndexPage />
            </Route>
            <Route exact path={Routes.myProfile}>
              <MyProfileMDI />
            </Route>
            <Route exact path={Routes.institutionData}>
              <MDIInstitutionData />
            </Route>
            <Route exact path={Routes.changePassword}>
              <ChangePasswordPage />
            </Route>
            <Route exact path={Routes.auditProcesses}>
              <MDIAudit />
            </Route>
            <Route exact path={Routes.executeObjected}>
              <RDIExecuteObjected />
            </Route>
            <Route exact path={Routes.institutionalEvidence}>
              <MDIInstitutionalEvidenceView />
            </Route>
            <Route exact path={Routes.executeInstitutionalObjected}>
              <RDIExecuteInstitutionalObjected />
            </Route>
            <Redirect to={Routes.myInstitutionMDI} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default MDIRouter;
