import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, ReactElement } from "react";
import "twin.macro";
import FormulaEditor from "./FormulaEditor";

/**
 * El componente de una fórmula auxiliar
 *
 * @param props React props
 * @param props.name El nombre de la fórmula
 * @param props.code El código de la fórmula
 * @param props.index El índice de la fórmula en el array de fórmulas
 * @param props.changeCode El handler de change del código de la fórmula
 * @param props.changeName El handler de change del nombre de la fórmula
 * @param props.removeFormula La función para borrar la fórmula auxiliar
 * @param props.variablesSet Una set con strings representando variables del indicador
 * @param props.institutionalVariablesSet Un set con string representando los datos de la institución
 */
function AuxiliarFormulaEditor(props: {
  code: string;
  name: string;
  index: number;
  changeCode: (s: string, i: number) => void;
  changeName: (e: ChangeEvent<HTMLInputElement>, i: number) => void;
  removeFormula: (i: number) => void;
  variablesSet?: Set<string>;
  institutionalVariablesSet?: Set<string>;
}): ReactElement {
  const {
    code,
    name,
    changeCode,
    changeName,
    index,
    removeFormula,
    variablesSet,
    institutionalVariablesSet,
  } = props;

  return (
    <div tw="w-full mb-4 border border-gray rounded-md bg-resies_lightpurple">
      <div tw="flex flex-row">
        <input
          tw="w-full m-2 rounded-md bg-ghostwhite border border-gray px-2 py-2"
          value={name}
          placeholder="Ingrese un nombre..."
          onChange={(e) => changeName(e, index)}
        />
        <button
          type="button"
          tw="text-resies_purple mr-2 px-2 py-2"
          onClick={() => removeFormula(index)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
      <div tw="m-2">
        <FormulaEditor
          code={code}
          setCode={(s) => changeCode(s, index)}
          variablesSet={variablesSet}
          institutionalVariablesSet={institutionalVariablesSet}
        />
      </div>
    </div>
  );
}

export default AuxiliarFormulaEditor;
