import { ReactElement } from "react";
import "twin.macro";
import RDIOdsReport from "../rdi/RDIOdsReport";

/**
 * Página perteneciente al Auditor para ver los datos de una institución.
 */
function AUDODSInstitutionReport(): ReactElement {
  return (
    <>
      <RDIOdsReport />
    </>
  );
}

export default AUDODSInstitutionReport;
