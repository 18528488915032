import React, { ReactElement } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "twin.macro";
import ADMAUDProfileAssignInstitutions from "../pages/admin/ADMAUDProfileAssignInstitutions";
import Footer from "../components/common/Footer";
import LoginNavbar from "../components/common/LoginNavbar";
import Navbar from "../components/common/Navbar";
import ADMEditIndicators from "../pages/admin/ADMEditIndicators";
import ADMEvalInstruments from "../pages/admin/ADMEvalInstruments";
import ADMInstitutionAUDAssignment from "../pages/admin/ADMInstitutionAUDAssignment";
import ADMCheckAUDProfile from "../pages/admin/ADMCheckAUDProfile";
import ADMInstitutionRDIAssignment from "../pages/admin/ADMInstitutionRDIAssignment";
import ADMInstitutionView from "../pages/admin/ADMInstitutionView";
import ADMInviteAUD from "../pages/admin/ADMInviteAUD";
import ADMInviteRDI from "../pages/admin/ADMInviteRDI";
import ADMLandingPage from "../pages/admin/ADMLandingPage";
import ADMDeleteAUDProfile from "../pages/admin/ADMDeleteAUDProfile";
import Routes from "./routes";
import ADMDeleteUser from "../pages/admin/ADMDeleteUser";
import ADMAddInstitution from "../pages/admin/ADMAddInstitution";
import ADMAuditDeadlinesPage from "../pages/admin/ADMAuditDeadlinesPage";
import ADMPanelUsers from "../pages/admin/ADMPanelUsers";
import ADMPanelInstitutions from "../pages/admin/ADMPanelInstitutions";
import ADMAuditorPortalPage from "../pages/admin/ADMAuditorPortal";
import ADMAuditProcessesPage from "../pages/admin/ADMAuditProcessesPage";
import ADMSingleAppealView from "../pages/admin/ADMSingleAppealView";
import ADMAppeals from "../pages/admin/ADMAppeals";
import ADMAppealsByinstitutionPage from "../pages/admin/ADMAppealsByInstitution";
import ADMReports from "../pages/admin/ADMReports";
import ADMIndicatorFormula from "../pages/admin/AdmIndicatorFormula";
import ADMDeleteInstitution from "../pages/admin/ADMDeleteIntitution";
import ADMEditInstitutionVariables from "../pages/admin/ADMEditInstitutionVariables";
import ADMProfile from "../pages/admin/ADMProfile";
import ADMIndicatorsView from "../pages/admin/ADMIndicatorsView";
import ADMEvidenceView from "../pages/admin/ADMEvidenceView";
import ADMInstitutionData from "../pages/admin/ADMInstitutionData";
import ChangePasswordPage from "../pages/common/ChangePasswordPage";
import ADMEditInstitution from "../pages/admin/ADMEditInstitution";
import ADMInviteADM from "../pages/admin/ADMInviteADM";
import ADMInstitutionalEvidenceView from "../pages/admin/ADMInstitutionEvidenceView";
import ADMSingleInstitutionalAppealView from "../pages/admin/ADMSingleInstitutionalAppealView";
import ADMInstitutionResiesVersion from "../pages/admin/ADMInstitutionResiesVersion";
/**
 * El router del usuario administrador
 *
 * Al haber iniciado sesión como usuario administrador, este es el router que se muestra.
 */
function ADMRouter(): ReactElement {
  return (
    <Router>
      <LoginNavbar />
      <Navbar
        items={[
          { name: "Administración", href: Routes.administration },
          { name: "Generar Reportes", href: Routes.reports },
          {
            name: "Instrumento Evaluación",
            href: Routes.evaluation_instruments,
          },
          { name: "Procesos Auditoría", href: Routes.auditProcesses },
        ]}
      />
      <div tw="bg-ghostwhite">
        <Switch>
          <Route exact path={Routes.landing}>
            <Redirect to={Routes.administration} />
            <ADMLandingPage />
          </Route>
          <Route exact path={Routes.addInstitution}>
            <ADMAddInstitution />
          </Route>
          <Route exact path={Routes.assignInstitutionRDI}>
            <ADMInstitutionRDIAssignment />
          </Route>
          <Route exact path={Routes.assignInstitutionAUD}>
            <ADMInstitutionAUDAssignment />
          </Route>
          <Route exact path={Routes.inviteRDI}>
            <ADMInviteRDI />
          </Route>
          <Route exact path={Routes.admInstitutionsView}>
            <ADMInstitutionView />
          </Route>
          <Route exact path={Routes.admDeleteInstitution}>
            <ADMDeleteInstitution />
          </Route>
          <Route exact path={Routes.inviteAUD}>
            <ADMInviteAUD />
          </Route>
          <Route exact path={Routes.evaluation_instruments}>
            <ADMEvalInstruments />
          </Route>
          <Route exact path={Routes.editIndicators}>
            <ADMEditIndicators />
          </Route>
          <Route exact path={Routes.changeIndicatorFormula}>
            <ADMIndicatorFormula />
          </Route>
          <Route exact path={Routes.audProfile}>
            <ADMCheckAUDProfile />
          </Route>
          <Route exact path={Routes.audDeleteProfile}>
            <ADMDeleteAUDProfile />
          </Route>
          <Route exact path={Routes.audProfileAssignInstitutions}>
            <ADMAUDProfileAssignInstitutions />
          </Route>
          <Route exact path={Routes.admPanelUsers}>
            <ADMPanelUsers />
          </Route>
          <Route exact path={Routes.deleteUser}>
            <ADMDeleteUser />
          </Route>
          <Route exact path={Routes.auditProcesses}>
            <ADMAuditProcessesPage />
          </Route>
          <Route exact path={Routes.auditDeadlines}>
            <ADMAuditDeadlinesPage />
          </Route>
          <Route exact path={Routes.admPanelInstitutions}>
            <ADMPanelInstitutions />
          </Route>
          <Route exact path={Routes.auditorPortal}>
            <ADMAuditorPortalPage />
          </Route>
          <Route exact path={Routes.singleAppealView}>
            <ADMSingleAppealView />
          </Route>
          <Route exact path={Routes.singleInstitutionalAppealView}>
            <ADMSingleInstitutionalAppealView />
          </Route>
          <Route exact path={Routes.appeals}>
            <ADMAppeals />
          </Route>
          <Route exact path={Routes.appealsInstitution}>
            <ADMAppealsByinstitutionPage />
          </Route>
          <Route exact path={Routes.administration}>
            <Redirect to={Routes.admPanelInstitutions} />
          </Route>
          <Route exact path={Routes.editInstitutionIndicators}>
            <ADMEditInstitutionVariables />
          </Route>
          <Route exact path={Routes.reports}>
            <ADMReports />
          </Route>
          <Route exact path={Routes.myProfile}>
            <ADMProfile />
          </Route>
          <Route exact path={Routes.ADMindicators}>
            <ADMIndicatorsView />
          </Route>
          <Route exact path={Routes.ADMinstitutionData}>
            <ADMInstitutionData />
          </Route>
          <Route exact path={Routes.ADMinstitutionResiesVersion}>
            <ADMInstitutionResiesVersion />
          </Route>
          <Route exact path={Routes.ADMevidence}>
            <ADMEvidenceView />
          </Route>
          <Route exact path={Routes.changePassword}>
            <ChangePasswordPage />
          </Route>
          <Route exact path={Routes.admeditInstitution}>
            <ADMEditInstitution />
          </Route>
          <Route exact path={Routes.inviteADM}>
            <ADMInviteADM />
          </Route>
          <Route exact path={Routes.admInstitutionalEvidence}>
            <ADMInstitutionalEvidenceView />
          </Route>
          <Redirect to={Routes.landing} />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default ADMRouter;
