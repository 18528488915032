import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Redirect, useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import EvidenceComments from "../../components/common/EvidenceView/EvidenceComments";
import EvidenceDataRow from "../../components/common/EvidenceView/EvidenceDataRow";
import EvidenceInfo from "../../components/common/EvidenceView/EvidenceInfo";
import EvidenceTitle from "../../components/common/EvidenceView/EvidenceTitle";
import VolverButton from "../../components/common/VolverButton";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import {
  EvaluationVariableDetails,
  InstitutionDetails,
  ResponseVariable,
} from "../../types/backendTypes";
import getAuditableResponses from "../../utils/data/AudData/getAuditableReponses";
import getInstitutionDetails from "../../utils/data/AudData/getInstitutionDetails";
import getVariableDetails from "../../utils/data/getVariableDetails";
import useRESIESDates from "../../hooks/useRESIESDates";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * Componente de la vista de evidencia del AUD
 */
function AUDEvidenceView(): ReactElement {
  const { variableId } = useParams<{ variableId: string }>();
  const { responseId } = useParams<{ responseId: string }>();
  const { institutionId } = useParams<{ institutionId: string }>();
  const [uploadFile, setUploadFile] = useState(0);
  const [institution, setInstitution] = useState<InstitutionDetails>();
  const [ready, setReady] = useState(false);
  const [responseVariable, setResponseVariable] = useState<ResponseVariable>();
  const [reload, setReload] = useState(0);
  const [evaluationVariable, setEvaluationVariable] =
    useState<EvaluationVariableDetails>();
  const [error, setError] = useState(false);
  const [appState] = useContext(AppContext);
  const [, currentProcess, currentInstitutionProcess] = useRESIESDates();

  const getActualCurrentProcess = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess.current_process;
    }

    return currentProcess;
  };

  function onAuditMissingVariable(e: React.MouseEvent<HTMLButtonElement>) {
    if (!appState.token) return;
    if (!evaluationVariable) return;
    setReady(false);
    fetchAPI<APIActions["auditMissingVariable"]>(
      APIRoutes.auditMissingVariable,
      {
        method: "PUT",
        routeParams: {
          institution_id: institutionId.toString(),
          evaluation_variable_id: evaluationVariable.id.toString(),
        },
      },
      appState.token
    ).then((response: Record<string, any>) => {
      window.location.href = `/institutions/${institutionId.toString()}/indicators/${evaluationVariable.id.toString()}/${
        response["id"]
      }/evidence`;
    });
  }

  useEffect(() => {
    getInstitutionDetails(institutionId, appState.token, setInstitution);
    getVariableDetails(appState, variableId, setEvaluationVariable, setReady);
    if (responseId !== "missing") {
      getAuditableResponses(
        appState,
        institutionId,
        responseId,
        setResponseVariable,
        setError,
        setReady
      );
    } else {
      setTimeout(function () {
        setReady(true);
      }, 2000);
    }
  }, [appState, institutionId, responseId, variableId, uploadFile, reload]);

  return (
    <div tw="bg-ghostwhite">
      {error ? <Redirect to={Routes.indicators} /> : <></>}
      <CargandoScreen ready={ready}>
        <Container>
          {institution ? (
            <EvidenceTitle
              evaluationVariable={evaluationVariable}
              title={institution.institution.description}
              responseVariable={responseVariable}
            />
          ) : (
            <></>
          )}
          <EvidenceInfo
            info={responseVariable?.description}
            title="Descripción de variable"
          />
          <EvidenceInfo
            info={evaluationVariable?.verification_field_helper_text}
            title="Verificación Necesaria"
          />
          {responseVariable ? (
            <EvidenceDataRow
              response={responseVariable}
              setUploadFile={setUploadFile}
              reload={reload}
              setReload={setReload}
            />
          ) : (
            <>
              {appState.userType === "AUD" &&
                getActualCurrentProcess()?.name === "Auditoría" && (
                  <div tw="flex justify-end">
                    <button
                      type="button"
                      tw="bg-resies_purple mt-2 text-ghostwhite font-bold flex flex-row px-3 py-2 mr-3"
                      name="objected"
                      onClick={(e) => onAuditMissingVariable(e)}
                    >
                      <img
                        tw="object-contain mr-2"
                        src="/object.png"
                        alt="object"
                      />
                      Objetar
                    </button>
                  </div>
                )}
            </>
          )}

          {responseVariable && <EvidenceComments />}

          <VolverButton
            to={`${Routes.AUDindicators.replace(
              ":institutionId",
              institutionId
            )}?tab=${
              evaluationVariable?.indicator.subcategory?.category.id
            }&indic=${evaluationVariable?.indicator.slug}`}
          />
          <div tw="bg-ghostwhite h-10" />
        </Container>
      </CargandoScreen>
    </div>
  );
}

export default AUDEvidenceView;
