import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import Routes from "../../routers/routes";
import {
  InstitutionalDataResponse,
  ResponseVariable,
} from "../../types/backendTypes";

/**
 * Fila en la tabla de plazos de los procesos de auditoría.
 *
 * @param props react props
 * @param props.institutionId ID de la institución que apela.
 * @param props.institutionName nombre de institucion.
 * @param props.responses Las respuestas de la institución que apela
 * @param props.institutionalResponses Las respuestas de variables institucionales de la institución que apela
 */
function AppealsRow(props: {
  institutionId: number;
  institutionName: string;
  responses: ResponseVariable[];
  institutionalResponses: InstitutionalDataResponse[];
}): ReactElement {
  const { institutionId, institutionName, responses, institutionalResponses } =
    props;
  const [total, setTotal] = useState(0);
  const [pending, setPending] = useState(0);
  useEffect(() => {
    setPending(
      responses.reduce(
        (acc, res) => acc + (res.audit_status === "appealed" ? 1 : 0),
        0
      ) +
        institutionalResponses.reduce(
          (acc, res) => acc + (res.audit_status === "appealed" ? 1 : 0),
          0
        )
    );
    setTotal(
      responses.reduce(
        (acc, res) =>
          acc +
          (res.audit_status === "appealed" ||
          res.audit_status === "final_accepted" ||
          res.audit_status === "final_rejected"
            ? 1
            : 0),
        0
      ) +
        institutionalResponses.reduce(
          (acc, res) =>
            acc +
            (res.audit_status === "appealed" ||
            res.audit_status === "final_accepted" ||
            res.audit_status === "final_rejected"
              ? 1
              : 0),
          0
        )
    );
  }, [responses, institutionalResponses]);
  return (
    <tr tw="flex w-full bg-resies_lightgreen my-3">
      <td tw="text-header2 font-semibold text-resies_blue1 text-left pl-10 py-2 w-4/12">
        {institutionName}
      </td>
      <td tw="text-center p-2 w-3/12">{total} apelaciones recibidas</td>
      <td tw="text-center p-2 w-3/12">{pending} apelaciones pendientes</td>
      <td tw="text-center p-2 w-2/12">
        <Link
          to={Routes.appealsInstitution.replace(
            ":institutionId",
            institutionId.toString()
          )}
        >
          <button
            type="button"
            tw="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md"
          >
            <p tw="font-bold text-base text-ghostwhite mx-3">Ver</p>
          </button>
        </Link>
      </td>
    </tr>
  );
}

export default AppealsRow;
