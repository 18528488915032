import React, { ReactElement, useContext } from "react";
import "twin.macro";
import { AppContext } from "../../../dispatcher";
import {
  EvaluationVariableDetails,
  ResponseVariable,
} from "../../../types/backendTypes";

/**
 * Titulo donde se muestra la variable que a la cual se quiere mostrar su evidencia.
 *
 * @param props react props.
 * @param props.evaluationVariable Objeto con la información de la variable que se esta observando.
 * @param props.title titulo que se le quiere agregar al componente
 * @param props.responseVariable La respuesta de la variable sobre la cual se esta viendo evidencia
 */
function EvidenceTitle(props: {
  evaluationVariable: EvaluationVariableDetails | undefined;
  title: string;
  responseVariable: ResponseVariable | undefined;
}): ReactElement {
  const { evaluationVariable, title, responseVariable } = props;
  const [appState] = useContext(AppContext);

  /**
   * Obtener estado de auditacion
   *
   * @param status estado
   */
  function statusData(status: string | undefined) {
    if (status === "accepted") {
      return ["resies_green", "AUDITADO"];
    }
    if (status === "pending") {
      return ["warning_yellow", "POR AUDITAR"];
    }
    if (status === "objected") {
      return ["darkred", "OBJETADO"];
    }
    if (status === "rejected" || status === "appealed") {
      return ["darkred", "RECHAZADO"];
    }

    return ["warning_yellow", "POR AUDITAR"];
  }
  return (
    <div>
      {appState.userType !== "AUD" && appState.userType !== "ADM" ? (
        <>
          <div tw="bg-resies_lightgreen font-sans text-header1 font-bold text-resies_blue1 py-2 px-6 flex flex-row">
            <div tw="w-full">{title}</div>
          </div>
          <div tw="bg-resies_blue2 font-sans text-barra font-bold text-ghostwhite px-6">
            {`(${evaluationVariable?.indicator?.slug}) ${evaluationVariable?.indicator?.title}`}
          </div>
          <div tw="bg-resies_lightpurple font-sans text-body font-normal text-black px-6 py-2">
            {evaluationVariable?.description}
          </div>
        </>
      ) : (
        <>
          <div tw="bg-resies_lightgreen font-sans text-header1 font-bold text-resies_blue1 py-2 px-6">
            {title}
          </div>

          <div tw="bg-resies_blue2 font-sans text-barra font-bold text-ghostwhite px-6">
            {`(${evaluationVariable?.indicator?.slug}) ${evaluationVariable?.indicator?.title}`}
          </div>
          <div tw="flex flex-row h-20">
            <div tw="w-1/12 bg-resies_lightpurple flex flex-wrap flex-row content-center">
              <>
                <div
                  className={`${"rounded-full h-3 w-3 my-auto mx-auto bg-"}${
                    statusData(responseVariable?.audit_status)[0]
                  }`}
                />
                <p
                  className={`text-body text-${
                    statusData(responseVariable?.audit_status)[0]
                  } font-normal mx-auto`}
                >
                  {statusData(responseVariable?.audit_status)[1]}
                </p>
              </>
            </div>
            <div tw="bg-resies_lightpurple font-sans text-body font-normal text-black px-6 py-2 w-10/12">
              {evaluationVariable?.description}
            </div>
            <div tw="w-1/12 bg-resies_blue2 flex flex-wrap content-center">
              <div tw="mx-auto flex flex-row">
                {responseVariable !== undefined ? (
                  <>
                    <img
                      tw="object-contain mr-2"
                      src="/accept.png"
                      alt="accept"
                    />
                    <p tw="text-barra text-ghostwhite font-bold">
                      {responseVariable?.response}
                    </p>
                  </>
                ) : (
                  <>
                    <p tw="text-barra text-ghostwhite font-bold">
                      Sin respuesta
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default EvidenceTitle;
