import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import useRESIESDates from "../../hooks/useRESIESDates";
import Routes from "../../routers/routes";

/**
 * Fila en la tabla de apelaciones del RDI.
 *
 * @param props props react
 * @param props.slug slug del indicador al que pertenece la variable
 * @param props.variableId id de la variable
 * @param props.description descripción de la apelación
 * @param props.status El estado de la variable
 * @param props.responseId La id de respuesta
 */
function InstitutionalEvidenceStatusRow(props: {
  slug: string;
  responseId: number;
  variableId: number;
  description: string;
  status: string;
}): ReactElement {
  const { slug, variableId, description, status, responseId } = props;
  const [, currentProcess, currentInstitutionProcess] = useRESIESDates();

  const getActualCurrentProcess = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess.current_process;
    }

    return currentProcess;
  };

  return (
    <tr tw="flex w-full my-1">
      <td tw="text-header2 font-semibold text-very_darkgray text-left pl-10 py-1 w-full">
        {slug} {">"} Variable {variableId} | {description}
      </td>
      {(status === "rejected" || status === "pending") &&
        getActualCurrentProcess()?.name === "Proceso de Apelación" && (
          <td tw="text-center p-2 w-2/12">
            <Link
              to={Routes.executeInstitutionalAppeal
                .replace(":responseId", responseId.toString())
                .replace(":variableId", variableId.toString())}
            >
              <button
                type="button"
                tw="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md"
              >
                <p tw="font-bold text-base text-ghostwhite mx-3">Apelar</p>
              </button>
            </Link>
          </td>
        )}
      {(status === "rejected" || status === "pending") &&
        getActualCurrentProcess()?.name !== "Proceso de Apelación" && (
          <td tw="text-center text-body p-2 w-2/12">
            <button
              type="button"
              tw="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md"
              disabled
            >
              <p tw="font-bold text-base text-ghostwhite mx-3">Apelar</p>
            </button>
            <br />
            Proceso de apelación cerrado
          </td>
        )}
      {status === "objected" &&
        getActualCurrentProcess()?.name === "Rectificación de Objetados" && (
          <td tw="text-center p-2 w-2/12">
            <Link
              to={Routes.executeInstitutionalObjected
                .replace(":responseId", responseId.toString())
                .replace(":variableId", variableId.toString())}
            >
              <button
                type="button"
                tw="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md"
              >
                <p tw="font-bold text-base text-ghostwhite mx-3">
                  Resubir evidencia
                </p>
              </button>
            </Link>
          </td>
        )}
      {status === "objected" &&
        getActualCurrentProcess()?.name !== "Rectificación de Objetados" && (
          <td tw="text-center text-body p-2 w-2/12">
            <button
              type="button"
              tw="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md"
            >
              <p tw="font-bold text-base text-ghostwhite mx-3">
                Resubir evidencia
              </p>
            </button>
            <br />
            Proceso de rectificación cerrado
          </td>
        )}
      {(status === "final_accepted" ||
        status === "final_rejected" ||
        status === "appealed") && (
        <td tw="text-center p-2 w-2/12">
          {status === "final_accepted" && "Aceptado"}
          {status === "final_rejected" && "Rechazado"}
          {status === "appealed" && "En proceso"}
        </td>
      )}
    </tr>
  );
}

export default InstitutionalEvidenceStatusRow;
