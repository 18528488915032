/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ANTLRErrorListener, Recognizer, Token } from "antlr4ts";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";

export interface ExcelSyntaxError {
  offendingSymbol: any;
  line: number;
  charPositionInLine: number;
  msg: string;
  mode: Modes;
}

enum Modes {
  LEXER,
  PARSER,
}

export class ExcelLangErrorListener implements ANTLRErrorListener<Token> {
  private errors: ExcelSyntaxError[] = [];

  public mode: Modes = Modes.LEXER;

  syntaxError(
    recognizer: Recognizer<Token, LexerATNSimulator | ParserATNSimulator>,
    offendingSymbol: any,
    line: number,
    charPositionInLine: number,
    msg: string
  ): void {
    this.errors.push({
      offendingSymbol,
      line,
      charPositionInLine,
      msg,
      mode: this.mode,
    });
  }

  getErrors(): ExcelSyntaxError[] {
    return this.errors;
  }
}
