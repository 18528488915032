import React, { ReactElement } from "react";
import "twin.macro";
import {
  EvaluationVariablesFromIndicator,
  EvaluationVariableStatus,
  IndicatorStatus,
} from "../../../../../types/utilTypes";
import ADMEditIndicator from "./ADMEditIndicator";
import ADMReadyIndicator from "./ADMReadyIndicator";
/**
 * Lista lateral de indicadores.
 *
 * @param props react props.
 * @param props.indicatorSelected Indicador actualmente seleccionado.
 * @param props.indicators Status de todos los indicadores
 * @param props.setIndicatorSelected setState que cambio el indicador seleccionado
 * @param props.setIndicators setState que cambia el status de los indicadores
 * @param props.setEvaluationVariables setState que maneja el cambio en las variables.
 * @param props.evaluationVariables Objeto que contiene todas las variables de cada indicador
 * @param props.deletedVariables Variables que han sido eliminadas
 * @param props.setDeletedVariables setState para manejar las variables que han sido eliminadas.
 * @param props.deletedIndicators Indicadores que han sido eliminados
 * @param props.setDeletedIndicators setState para manejar los indicadores que han sido eliminadas.
 * @param props.setChanges setState de cambios
 */
function IndicatorList(props: {
  indicators: IndicatorStatus[];
  indicatorSelected: IndicatorStatus | undefined;
  setIndicatorSelected: React.Dispatch<
    React.SetStateAction<IndicatorStatus | undefined>
  >;
  setIndicators: React.Dispatch<React.SetStateAction<IndicatorStatus[]>>;
  setEvaluationVariables: React.Dispatch<
    React.SetStateAction<EvaluationVariablesFromIndicator[]>
  >;
  evaluationVariables: EvaluationVariablesFromIndicator[];
  deletedIndicators: IndicatorStatus[];
  setDeletedIndicators: React.Dispatch<React.SetStateAction<IndicatorStatus[]>>;
  deletedVariables: EvaluationVariableStatus[];
  setDeletedVariables: React.Dispatch<
    React.SetStateAction<EvaluationVariableStatus[]>
  >;
  setChanges: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement {
  const {
    indicators,
    indicatorSelected,
    setIndicatorSelected,
    setIndicators,
    setEvaluationVariables,
    evaluationVariables,
    deletedIndicators,
    setDeletedIndicators,
    deletedVariables,
    setDeletedVariables,
    setChanges,
  } = props;

  /**
   * Funcion que al hacer click crea un nuevo indicador
   */
  function onClickNewIndicatorHandler() {
    const copyIndicators = [...indicators];
    let id = 0;
    copyIndicators.forEach((ind) => {
      if (ind.id >= id) {
        id = ind.id;
      }
    });
    const copyEvaluationVariables = [...evaluationVariables];
    const newIndicator: IndicatorStatus = {
      slug: "Codigo",
      title: "Nombre",
      color: "#3B9A9B",
      id: id + 1,
      statusSlug: "edit",
      statusTitle: "ready",
      generalStatus: "new",
      formula: "1",
      unreplaced_formula: "1",
      score_weight: 0,
      statusWeight: "ready",
      statusColor: "ready",
      auxiliaryFormula: [],
    };
    copyIndicators.push(newIndicator);
    copyEvaluationVariables.push({
      id: newIndicator.id,
      evaluationVariables: [],
    });
    setIndicators(copyIndicators);
    setEvaluationVariables(copyEvaluationVariables);
    setIndicatorSelected(newIndicator);
    setChanges(true);
  }

  /**
   * Función que al hacer click elimina un indicador
   *
   * @param e event
   */
  function onClickDeleteIndicator(e: React.MouseEvent<HTMLButtonElement>) {
    const copyIndicators = [...indicators];
    const copyEvaluationVariables = [...evaluationVariables];
    const copyDeletedIndicators = [...deletedIndicators];
    const copyDeletedVariables = [...deletedVariables];
    const clickedIndicator = [
      ...copyIndicators.filter(
        (indi) => indi.id.toString() === e.currentTarget.name
      ),
    ][0];
    const evalcopy = copyEvaluationVariables.filter(
      (vari) => vari.id === clickedIndicator.id
    )[0];
    copyDeletedIndicators.push(clickedIndicator);
    evalcopy.evaluationVariables.forEach((variable) =>
      copyDeletedVariables.push(variable)
    );
    const index = copyIndicators.indexOf(clickedIndicator);
    const index2 = copyEvaluationVariables.indexOf(evalcopy);
    copyIndicators.splice(index, 1);
    copyEvaluationVariables.splice(index2, 1);
    if (
      copyIndicators.filter((ind) => ind.generalStatus !== "no-click").length
    ) {
      setIndicatorSelected(copyIndicators[0]);
    } else {
      setIndicatorSelected(undefined);
    }
    setIndicators([...copyIndicators]);
    setEvaluationVariables(copyEvaluationVariables);
    setDeletedIndicators(copyDeletedIndicators);
    setDeletedVariables(copyDeletedVariables);
    setChanges(true);
  }
  return (
    <div tw="flex flex-col bg-resies_lightpurple w-28 overflow-y-scroll">
      <div tw="flex justify-center h-28">
        <button
          type="button"
          tw="flex flex-row my-auto"
          onClick={() => onClickNewIndicatorHandler()}
        >
          <img src="/add.png" alt="add" tw="my-auto ml-2" />
          <p tw="text-resies_purple font-semibold text-body my-auto">
            Agregar indicador
          </p>
        </button>
      </div>
      {indicators.map((ind) =>
        ind.statusSlug === "ready" ? (
          <ADMReadyIndicator
            ind={ind}
            indicatorSelected={indicatorSelected}
            indicators={indicators}
            setIndicatorSelected={setIndicatorSelected}
            setIndicators={setIndicators}
            key={ind.id}
            onClickDeleteIndicator={onClickDeleteIndicator}
          />
        ) : (
          <ADMEditIndicator
            ind={ind}
            indicatorSelected={indicatorSelected}
            indicators={indicators}
            setIndicatorSelected={setIndicatorSelected}
            setIndicators={setIndicators}
            key={ind.id}
            onClickDeleteIndicator={onClickDeleteIndicator}
            setChanges={setChanges}
          />
        )
      )}
    </div>
  );
}

export default IndicatorList;
