import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import "twin.macro";
import { Category } from "../../../types/backendTypes";
/**
 * Elemento de la navbar de la vista de indicadores.
 *
 * @param props react props.
 * @param props.name Nombre de la categoria que se mostrara en este elemento del navbar.
 * @param props.selectedCategory Corresponde al nombre de la categoria actualmente seleccionada.
 * @param props.indicators Diccionario que contiene los nombres de los indicadores de cada categoria.
 */
function CategorieNavbarElement(props: {
  category: Category;
  selectedCategory: Category;
}): ReactElement {
  const { category, selectedCategory } = props;
  const history = useHistory();

  /**
   * Función que maneja el cambio de categoria seleccionada e indicador seleccionado al cambiar de categoria.
   */
  function clickHandler() {
    history.push(`${history.location.pathname}?category=${category.id}`);
  }

  return (
    <button
      type="button"
      className={
        selectedCategory.id === category.id
          ? "text-resies_blue2 w-full text-xs md:text-xs xl:text-xl lg:px-3 px-0 py-3 font-semibold bg-resies_lightgreen "
          : "text-black w-full text-xs md:text-xs xl:text-xl lg:px-3 px-0 py-3 font-semibold opacity-50"
      }
      onClick={clickHandler}
    >
      {category.name}
    </button>
  );
}

export default CategorieNavbarElement;
