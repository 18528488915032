import React, { ReactElement } from "react";
import "twin.macro";
import {
  Category,
  InstitutionalDataVariable,
} from "../../../types/backendTypes";
import { InstitutionalVariableForm } from "../../../types/utilTypes";
import Container from "../../common/Container";
import ADMDataForm from "./ADMDataForm";

/**
 * Menu principal para modificar datos de la institución.
 *
 * @param props react props.
 * @param props.indicators Diccionario que contiene los nombres de los indicadores de cada categoria.
 * @param props.selected Corresponde al nombre de la categoria actualmente seleccionada.
 * @param props.variables Diccionario de las variables.
 * @param props.variablesForm Lista de diccionarios que guarda el estado de cada variable en el formulario.
 */
function ADMMainInstitutionDataMenu(props: {
  indicators: Record<string, string[]>;
  selectedCategory: Category;
  variables: Record<string, InstitutionalDataVariable>;
  variablesForm: InstitutionalVariableForm[];
}): ReactElement {
  const { indicators, selectedCategory, variables, variablesForm } = props;

  return (
    <div>
      <Container>
        <table tw="text-darkgray h-full bg-ghostwhite w-full">
          <thead tw="flex flex-row h-5 w-full font-semibold flex-wrap bg-resies_blue2 content-center justify-between">
            <tr tw="flex w-full font-semibold bg-resies_blue2 content-center justify-between pb-1">
              <td tw="font-bold text-base text-ghostwhite h-5 w-1/12 text-center">
                Indicador
              </td>
              <td tw="font-bold text-base text-ghostwhite h-5 w-3/12 text-center">
                Variable
              </td>
              <td tw="font-bold text-base text-ghostwhite h-5 w-3/12 text-center">
                Respuesta
              </td>
              <td tw="font-bold text-base text-ghostwhite h-5 w-3/12 text-center">
                Descripción
              </td>
              <td tw="font-bold text-base text-ghostwhite h-5 w-2/12 text-center">
                Verificación
              </td>
            </tr>
          </thead>
          <tbody tw="w-full">
            {!indicators[selectedCategory.name] && variables ? (
              <div tw="text-header2 my-28 text-darkgray">Sin indicadores</div>
            ) : (
              indicators[selectedCategory.name].map((indi) => (
                <ADMDataForm
                  slug={indi}
                  variables={variables}
                  variablesForm={variablesForm}
                />
              ))
            )}
          </tbody>
        </table>
      </Container>
    </div>
  );
}

export default ADMMainInstitutionDataMenu;
