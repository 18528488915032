import React, { ReactElement } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "twin.macro";
import Footer from "../components/common/Footer";
import LoginNavbar from "../components/common/LoginNavbar";
import Navbar from "../components/common/Navbar";
import ChangePasswordPage from "../pages/common/ChangePasswordPage";
import MyProfileRDI from "../pages/rdi/MyProfileRDI";
import RDIAppealedEvidence from "../pages/rdi/RDIAppealedEvidence";
import RDIAssignInstitutionalMdiPage from "../pages/rdi/RDIAssignInstitutionalMDI";
import RDIAssignMdiPage from "../pages/rdi/RDIAssignMdiPage";
import RDIEvidenceView from "../pages/rdi/RDIEvidenceView";
import RDIExecuteAppeal from "../pages/rdi/RDIExecuteAppeal";
import RDIExecuteObjected from "../pages/rdi/RDIExecuteObjected";
import RDIIndicatorsView from "../pages/rdi/RDIIndicatorsView";
import RDIInstitutionalEvidenceView from "../pages/rdi/RDIInstitutionalEvidenceView";
import RDIInstitutionData from "../pages/rdi/RDIInstitutionData";
import RDIInstitutionIndexPage from "../pages/rdi/RDIInstitutionIndexPage";
import RDIInviteMDI from "../pages/rdi/RDIInviteMDI";
import RDIMyInstitution from "../pages/rdi/RDIMyInstitution";
import RDIEditInstitution from "../pages/rdi/RDIEditInstitution";
import RDIObjectedEvidence from "../pages/rdi/RDIObjectedEvidence";
import RDIRejectedEvidence from "../pages/rdi/RDIRejectedEvidence";
import Routes from "./routes";
import RDIReports from "../pages/rdi/RDIReports";
import RDIExecuteInstitutionalObjected from "../pages/rdi/RDIExecuteInstitutionalObjected";
import RDIExecuteInstitutionalAppeal from "../pages/rdi/RDIExecuteInstitutionalAppeal";
import RDIHistoricReports from "../pages/rdi/RDIHistoricReports";
import RDIOdsReport from "../pages/rdi/RDIOdsReport";

/**
 * El router del usuario responsable de institución
 *
 * Al haber iniciado sesión como responsable de institución, este es el router que se muestra.
 */
function RDIRouter(): ReactElement {
  return (
    <Router>
      <LoginNavbar />
      <Navbar
        items={[
          { name: "Administración", href: Routes.myInstitution },
          { name: "Reportes", href: Routes.reports },
          { name: "Otras instituciones", href: Routes.institutionsIndex },
          { name: "Auditoría", href: Routes.auditProcesses },
        ]}
      />
      <div tw="bg-ghostwhite">
        <Switch>
          <Route exact path={Routes.indicators}>
            <RDIIndicatorsView />
          </Route>
          <Route exact path={Routes.assignMDI}>
            <RDIAssignMdiPage />
          </Route>
          <Route exact path={Routes.assignInstitutionalMDI}>
            <RDIAssignInstitutionalMdiPage />
          </Route>
          <Route exact path={Routes.myInstitution}>
            <RDIMyInstitution />
          </Route>
          <Route exact path={Routes.evidence}>
            <RDIEvidenceView />
          </Route>
          <Route exact path={Routes.institutionsIndex}>
            <RDIInstitutionIndexPage />
          </Route>
          <Route exact path={Routes.inviteMDI}>
            <RDIInviteMDI />
          </Route>
          <Route exact path={Routes.myProfile}>
            <MyProfileRDI />
          </Route>
          <Route exact path={Routes.institutionData}>
            <RDIInstitutionData />
          </Route>
          <Route exact path={Routes.changePassword}>
            <ChangePasswordPage />
          </Route>
          <Route exact path={Routes.rdieditInstitution}>
            <RDIEditInstitution />
          </Route>
          <Route exact path={Routes.institutionalEvidence}>
            <RDIInstitutionalEvidenceView />
          </Route>
          <Route exact path={Routes.auditProcesses}>
            <Redirect to={Routes.objected} />
          </Route>
          <Route exact path={Routes.appeals}>
            <RDIAppealedEvidence />
          </Route>
          <Route exact path={Routes.rejected}>
            <RDIRejectedEvidence />
          </Route>
          <Route exact path={Routes.objected}>
            <RDIObjectedEvidence />
          </Route>
          <Route exact path={Routes.executeAppeal}>
            <RDIExecuteAppeal />
          </Route>
          <Route exact path={Routes.executeObjected}>
            <RDIExecuteObjected />
          </Route>
          <Route exact path={Routes.executeInstitutionalAppeal}>
            <RDIExecuteInstitutionalAppeal />
          </Route>
          <Route exact path={Routes.executeInstitutionalObjected}>
            <RDIExecuteInstitutionalObjected />
          </Route>
          <Route exact path={Routes.reports}>
            <RDIReports />
          </Route>
          <Route exact path={Routes.historicReports}>
            <RDIHistoricReports />
          </Route>
          <Route exact path={Routes.odsReports}>
            <RDIOdsReport />
          </Route>
          <Redirect to={Routes.myInstitution} />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default RDIRouter;
