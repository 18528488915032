enum Routes {
  landing = "/",
  login = "/login",
  register = "/register/:token",
  institutionsIndex = "/institutions",
  institutionsView = "/institutions/:institutionId",
  administration = "/administration",
  admInstitutionsView = "/administration/institutions/:institutionId",
  admDeleteInstitution = "/administration/institutions/:institutionId/delete",
  assignInstitutionRDI = "/administration/institutions/:institutionId/assign-rdi",
  assignInstitutionAUD = "/administration/institutions/:institutionId/assign-aud",
  inviteRDI = "/administration/institutions/:institutionId/invite-rdi",
  inviteAUD = "/administration/institutions/:institutionId/invite-aud",
  inviteADM = "/administration/users/invite-adm",
  admPanelUsers = "/administration/users",
  admPanelInstitutions = "/administration/institutions",
  deleteUser = "/administration/users/delete/:userId",
  addInstitution = "/administration/institutions/add",
  myInstitution = "/my-institution",
  indicators = "/my-institution/indicators",
  assignMDI = "/my-institution/assign-mdi",
  institutionInfo = "/my-institution/info",
  inviteMDI = "/my-institution/invite-mdi",
  evidence = "/my-institution/indicators/:variableId/:responseId/evidence",
  institutionData = "/my-institution/information",
  myInstitutions = "/institutions",
  AUDindicators = "/institutions/:institutionId/indicators",
  AUDevidence = "/institutions/:institutionId/indicators/:variableId/:responseId/evidence",
  evaluation_instruments = "/evaluation_instruments",
  editIndicators = "/evaluation_instruments/:subcategoryId/editIndicators",
  changeIndicatorFormula = "/evaluation_instruments/:subcategoryId/editIndicators/:indicatorId/changeFormula",
  auditProcesses = "/audit",
  auditDeadlines = "/audit/deadlines",
  auditorPortal = "/audit/auditor-portal",
  appeals = "/audit/appeals",
  executeAppeal = "/audit/appeals/:variableId/:responseId",
  executeObjected = "/audit/objected/:variableId/:responseId",
  executeInstitutionalAppeal = "/audit/appeals/institutional/:variableId/:responseId",
  executeInstitutionalObjected = "/audit/objected/institutional/:variableId/:responseId",
  rejected = "/audit/rejected",
  objected = "/audit/objected",
  audProfile = "/audit/auditor-portal/:audId",
  audDeleteProfile = "/audit/auditor-portal/:audId/delete",
  audProfileAssignInstitutions = "/audit/auditor-portal/:audId/assign-institutions",
  appealsInstitution = "/audit/appeals/:institutionId",
  reports = "/reports",
  historicReports = "/reports/historic",
  odsReports = "/reports/ods",
  singleAppealView = "/audit/appeals/:institutionId/:variableId/:responseId",
  singleInstitutionalAppealView = "/audit/appeals/institutional/:institutionId/:variableId/:responseId",
  myProfile = "/profile",
  changePassword = "/profile/change-password",
  myInstitutionMDI = "/institution",
  editInstitutionIndicators = "/evaluation_instruments/:categoryId/editInstitutionVariables",
  assignInstitutionalMDI = "/my-institution/assign-institutional-mdi",
  ADMindicators = "/administration/institutions/:institutionId/indicators",
  ADMevidence = "/administration/institutions/:institutionId/indicators/:variableId/:responseId/evidence",
  ADMinstitutionData = "/administration/institutions/:institutionId/institution-data",
  ADMinstitutionResiesVersion = "/administration/institutions/:institutionId/resies-version",
  forgotPassword = "/forgot-password",
  recoverPassword = "/recover-password/:token",
  institutionalEvidence = "/my-institution/information/:variableId/:responseId/evidence",
  admeditInstitution = "/administration/institutions/:institutionId/edit",
  rdieditInstitution = "/my-institution/edit",
  audInstitutionData = "/institutions/:institutionId/institution-data",
  audInstitutionReport = "/institutions/:institutionId/report",
  audInstitutionHistoricReport = "/institutions/:institutionId/historic_report",
  audInstitutionODSReport = "/institutions/:institutionId/ods_report",
  audInstitutionalEvidence = "/institutions/:institutionId/institution-data/:variableId/:responseId/evidence",
  admInstitutionalEvidence = "/administration/institutions/:institutionId/institution-data/:variableId/:responseId/evidence",
}

export default Routes;
