import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import {
  CategoryOverview,
  Indicator,
  InstitutionMember,
  Subcategory,
} from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import ADMDetailProgress from "../admin/ADMDetailProgress";
import RDIDetailProgress from "../rdi/RDIDetailProgress";

/**
 * Componente que muestra el avance detallado de una categoría
 *
 * @param props props
 * @param props.categoriesData datos de todas las categorias
 * @param props.selectedCategory categoria seleccionada
 * @param props.styles estilos para el color dependiendo de si es completo, incompleto o faltante.
 * @param props.textTraductor traduccion al español de lo que viene el backend
 */
function CategoryDetailProgress(props: {
  categoriesData: CategoryOverview[];
  selectedCategory: string;
  styles: Record<string, string>;
  textTraductor: Record<string, string>;
}): ReactElement {
  const { categoriesData, selectedCategory, styles, textTraductor } = props;
  const [appState] = useContext(AppContext);
  const [indicators, setIndicators] = useState<Indicator[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [institutionMembers, setInstitutionMembers] = useState<
    InstitutionMember[]
  >([]);
  const { institutionId } = useParams<{ institutionId: string }>();

  useEffect(() => {
    if (appState.token) {
      fetchAPI<APIActions["fetchIndicators"]>(
        APIRoutes.fetchIndicators,
        {
          method: "GET",
        },
        appState.token
      ).then((indis) => setIndicators(indis));
      fetchAPI<APIActions["fetchSubCategories"]>(
        APIRoutes.fetchSubCategories,
        {
          method: "GET",
        },
        appState.token
      ).then((subcats) => setSubcategories(subcats));
      if (appState.userType === "ADM") {
        fetchAPI<APIActions["membersOverviewAdmin"]>(
          APIRoutes.membersOverviewAdmin,
          {
            method: "GET",
            routeParams: { institution_id: institutionId },
          },
          appState.token
        ).then((inst) => setInstitutionMembers(inst.institution_members));
      }
    }
  }, [appState, institutionId]);
  return (
    <>
      <div tw="flex flex-row bg-resies_lightgreen h-10 overflow-y-auto mt-20 w-full">
        <p tw="w-4/12 text-center my-auto text-very_darkgray text-barra font-bold">
          INDICADOR
        </p>
        <p tw="w-3/12 text-center my-auto text-very_darkgray text-barra font-bold">
          SUBCATEGORÍA
        </p>
        <p tw="w-2/12 text-center my-auto text-very_darkgray text-barra font-bold">
          ESTADO
        </p>
        {appState.userType === "MDI" ? (
          <></>
        ) : (
          <p tw="w-3/12 text-center my-auto text-very_darkgray text-barra font-bold">
            RESPONSABLE
          </p>
        )}
      </div>
      <div tw="flex flex-col bg-resies_lightpurple h-56 overflow-y-auto w-full">
        {categoriesData
          .filter((cate) => cate.name === selectedCategory)[0]
          .indicators.sort((a, b) => a.id - b.id)
          .map((ind) => (
            <div tw="flex flex-row">
              <p tw="w-4/12 my-auto text-very_darkgray text-barra font-bold pl-2">
                {ind.slug} {">"} {ind.title}
              </p>
              <p tw="w-3/12 my-auto text-very_darkgray text-barra font-bold text-center">
                {
                  subcategories.filter(
                    (subcate) =>
                      indicators.filter(
                        (indicator) => indicator.id === ind.id
                      )[0]?.subcategory_id === subcate.id
                  )[0]?.name
                }
              </p>
              <div tw="w-2/12 flex flex-row justify-center">
                <div tw="flex flex-row w-full">
                  <div
                    className={`rounded-full h-3 w-3 my-auto mr-2 ml-10 bg-${
                      styles[ind.status]
                    }`}
                  />
                  <p
                    className={`my-auto text-barra font-bold text-center ml-2 text-${
                      styles[ind.status]
                    }`}
                  >
                    {textTraductor[ind.status]}
                  </p>
                </div>
              </div>
              {appState.userType === "MDI" ? (
                <></>
              ) : (
                <p tw="w-3/12 my-auto text-very_darkgray text-barra font-bold text-center">
                  {indicators !== undefined && (
                    <>
                      {appState.userType === "ADM" ? (
                        <ADMDetailProgress
                          indicators={indicators}
                          institutionMembers={institutionMembers}
                          ind={ind}
                        />
                      ) : (
                        <RDIDetailProgress indicators={indicators} ind={ind} />
                      )}
                    </>
                  )}
                </p>
              )}
            </div>
          ))}
      </div>
    </>
  );
}

export default CategoryDetailProgress;
