import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Botón genérico para añadir algo. Se pinta en color morado con un ícono más al lado izquierdo
 *
 * @param props react props.
 * @param props.to La vista a donde lleva el botón
 * @param props.text texto
 */
function AddButton(props: { to: string; text: string }): ReactElement {
  const { to, text } = props;
  return (
    <Link to={to} tw="text-resies_purple text-body inline-block h-10 mt-1.5">
      <div tw="flex flex-row flex-wrap content-center align-middle">
        <FontAwesomeIcon
          icon={faPlusCircle}
          tw="text-resies_purple mr-2"
          size="2x"
        />
        <p tw="my-auto">{text}</p>
      </div>
    </Link>
  );
}

export default AddButton;
