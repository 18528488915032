import React, { ReactElement } from "react";
import "twin.macro";

/**
 * Componente que muestra un input de los datos de una Variable
 *
 * @param props react props
 * @param props.statusVerificator Ve si el estado esta en modo de edición o no.
 * @param props.typeHandler Ve que tipo de respuesta es, ya sea de descripción, description_helper o verificación.
 * @param props.value Value que debe llevar el input
 * @param props.onChangeHandler funcion que maneja los cambios en el input.
 * @param props.onKeyDownHandler funcion que cierra el estado de edición al presionar ENTER.
 * @param props.onClickHandler funcion que abre el estado de edición al hacer click.
 * @param props.onBlurHandler on blur handler
 * @param props.reference reference
 */
function ADMEntryVariableValue(props: {
  statusVerificator: string;
  typeHandler: string;
  value: string;
  onChangeHandler: (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    type: string
  ) => void;
  onKeyDownHandler: (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
    type: string
  ) => void;
  onClickHandler: (type: string) => void;
  onBlurHandler: (type: string) => void;
  reference: React.RefObject<HTMLTextAreaElement>;
}): ReactElement {
  const {
    statusVerificator,
    typeHandler,
    value,
    onChangeHandler,
    onClickHandler,
    onKeyDownHandler,
    onBlurHandler,
    reference,
  } = props;
  return (
    <div
      tw="flex text-justify min-h-100 mx-auto"
      className={
        typeHandler === "description"
          ? "col-span-4 2xl:w-80 lg:w-52"
          : "col-span-2 2xl:w-52 lg:w-40"
      }
    >
      {statusVerificator === "ready" ? (
        <button
          type="button"
          tw="2xl:text-sm md:text-xs font-normal text-black min-h-100 w-full"
          onClick={() => onClickHandler(typeHandler)}
        >
          {value.trim() ? value : "Ingrese un texto haciendo click aqui..."}
        </button>
      ) : (
        <textarea
          tw="2xl:text-sm md:text-xs font-normal text-black bg-ghostwhite w-full border border-darkgray resize-none"
          value={value}
          onChange={(e) => onChangeHandler(e, typeHandler)}
          onKeyDown={(e) => onKeyDownHandler(e, typeHandler)}
          onBlur={() => onBlurHandler(typeHandler)}
          ref={reference}
        >
          {value}
        </textarea>
      )}
    </div>
  );
}

export default ADMEntryVariableValue;
