/* eslint-disable import/no-cycle */
import React, { ReactElement } from "react";
import "twin.macro";
// eslint-disable-next-line import/no-cycle
import AnonLoginForm from "../../components/anon/AnonLoginForm";
/**
 * Vista de login de la aplicacion.
 */
function LoginPage(): ReactElement {
  return (
    <>
      <AnonLoginForm />
    </>
  );
}

export default LoginPage;
