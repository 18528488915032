import React, { ReactElement } from "react";
import { Prompt } from "react-router";
import "twin.macro";
import useRESIESDates from "../../../hooks/useRESIESDates";
import {
  Category,
  InstitutionalDataVariable,
} from "../../../types/backendTypes";
import { InstitutionalVariableForm } from "../../../types/utilTypes";
import Container from "../Container";
import DataForm from "./DataForm";

/**
 * Menu principal para modificar datos de la institución.
 *
 * @param props react props.
 * @param props.indicators Diccionario que contiene los nombres de los indicadores de cada categoria.
 * @param props.selectedCategory Corresponde al nombre de la categoria actualmente seleccionada.
 * @param props.variables Diccionario de las variables.
 * @param props.variablesForm Lista de diccionarios que guarda el estado de cada variable en el formulario.
 * @param props.setVariablesForm setter de variablesForm.
 * @param props.setChanges setter para avisar que hay cambios sin guardar
 * @param props.changes booleano que indica cambios.
 */
function MainInstitutionDataMenu(props: {
  indicators: Record<string, string[]>;
  selectedCategory: Category;
  variables: Record<string, InstitutionalDataVariable>;
  variablesForm: InstitutionalVariableForm[];
  setVariablesForm: React.Dispatch<
    React.SetStateAction<InstitutionalVariableForm[]>
  >;
  setChanges: (value: React.SetStateAction<boolean>) => void;
  changes: boolean;
}): ReactElement {
  const {
    indicators,
    selectedCategory,
    variables,
    variablesForm,
    setVariablesForm,
    setChanges,
    changes,
  } = props;
  const [, currentProcess, currentInstitutionProcess] = useRESIESDates();

  const getActualCurrentProcess = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess.current_process;
    }

    return currentProcess;
  };

  /**
   * Función que maneja los cambios de estado cuando se producen cambios en los formularios.
   *
   * @param key llave
   * @param e evento
   */
  function changeHandler(
    key: number,
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) {
    const copy = [...variablesForm];
    copy[key].response = e.target.value;
    copy[key].modified = true;
    setVariablesForm(copy);
    setChanges(true);
  }

  /**
   * Función que maneja los cambios de estado cuando se producen cambios en la descripció de los formularios.
   *
   * @param key llave
   * @param e evento
   */
  function descriptionChangeHandler(
    key: number,
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) {
    const copy = [...variablesForm];
    copy[key].description = e.target.value;
    copy[key].modified = true;
    setVariablesForm(copy);
  }

  return (
    <div>
      <Prompt
        when={changes}
        message="Tienes cambios sin guardar, ¿seguro que quieres abandonar la pagina?"
      />
      <Container>
        <table tw="text-darkgray h-full bg-ghostwhite w-full">
          <thead tw="flex flex-row h-5 w-full font-semibold flex-wrap bg-resies_blue2 content-center justify-between">
            <tr tw="flex flex-row h-5 w-full font-semibold flex-wrap bg-resies_blue2 content-center justify-between pb-1">
              <td tw="font-bold text-base text-ghostwhite h-5 w-1/12 text-center">
                Indicador
              </td>
              <td tw="font-bold text-base text-ghostwhite h-5 w-2/12 text-center">
                Variable
              </td>
              <td tw="font-bold text-base text-ghostwhite h-5 w-2/12 text-center">
                Respuesta
              </td>
              <td tw="font-bold text-base text-ghostwhite h-5 w-3/12 text-center">
                Descripción
              </td>
              <td tw="font-bold text-base text-ghostwhite h-5 w-2/12 text-center">
                Verificación
              </td>
              <td tw="font-bold text-base text-ghostwhite h-5 w-2/12 text-center">
                Estado de auditoría
              </td>
            </tr>
          </thead>
          <tbody tw="w-full">
            {!indicators[selectedCategory.name] && variables ? (
              <div tw="text-header2 my-28 text-darkgray">Sin indicadores</div>
            ) : (
              indicators[selectedCategory.name].map((indi) => (
                <DataForm
                  slug={indi}
                  variables={variables}
                  variablesForm={variablesForm}
                  changeHandler={changeHandler}
                  descriptionChangeHandler={descriptionChangeHandler}
                  currentProcess={getActualCurrentProcess()}
                />
              ))
            )}
          </tbody>
        </table>
      </Container>
    </div>
  );
}

export default MainInstitutionDataMenu;
