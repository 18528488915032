import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import VolverButton from "../../components/common/VolverButton";
import AssignInstitutionalMdiTitle from "../../components/rdi/AssignInstitutionalDataMDI/AssignInstitutionalMDITitle";
import InstitutionalVariableList from "../../components/rdi/AssignInstitutionalDataMDI/InstitutionalVariableList";
import { AppContext } from "../../dispatcher";
import {
  InstitutionCategory,
  InstitutionVariables,
  User,
} from "../../types/backendTypes";
import CargandoScreen from "../../components/common/CargandoScreen";
import Routes from "../../routers/routes";
import dropDownInstitutionalData from "../../utils/data/dropDownInstitutionalData";
import getUsersForInstitutionalDataAssign from "../../utils/data/getUsersForInstitutionalDataAssign";
import UsersListInstitutionData from "../../components/rdi/AssignInstitutionalDataMDI/UsersListInstitutionData";
import SelectedInstitutionVariables from "../../components/rdi/AssignInstitutionalDataMDI/SelectedInstitutionVariables";
import InstitutionalCategoriesNavbar from "../../components/rdi/AssignInstitutionalDataMDI/InstitutionalCategoriesNavbar";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * La página para asinar MDI Institucional del RDI.
 */
function RDIAssignInstitutionalMdiPage(): ReactElement {
  const [institutionalCategories, setInstitutionalCategories] = useState<
    Array<InstitutionCategory>
  >([]);
  const [institutionVariables, setInstitutionVariables] = useState<
    Record<string, InstitutionVariables[]>
  >({});
  const [selectedCategory, setSelectedCategory] = useState<InstitutionCategory>(
    {
      name: "",
      id: 0,
    }
  );
  const [ready, setReady] = useState(false);
  const [selectedVariables, setSelectedVariables] = useState<
    Array<InstitutionVariables>
  >([]);
  const [userSelectedVariables, setUserSelectedVariables] = useState<
    Record<number, InstitutionVariables[]>
  >({});
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [appState] = useContext(AppContext);
  const [allcheck, setAllcheck] = useState<InstitutionVariables[]>([]);

  const [variablesReady, setVariablesReady] = useState(true);

  useEffect(
    function loadData() {
      dropDownInstitutionalData(
        setInstitutionalCategories,
        setInstitutionVariables,
        appState.token,
        setReady,
        setSelectedCategory
      );
      getUsersForInstitutionalDataAssign(
        setUsers,
        appState.token,
        appState.institutionId,
        setUserSelectedVariables
      );
    },
    [appState]
  );

  /**
   * Funcion que envia el POST a la api para asignar los indicadores al usuario.
   */
  function onSubmitHandler() {
    if (!appState.token || !selectedUser) return;
    const { token } = appState;
    setVariablesReady(false);
    Promise.all(
      Object.keys(userSelectedVariables).map((user_id) => {
        return fetchAPI<APIActions["assignInstitutionVariableBatch"]>(
          APIRoutes.assignInstitutionVariableBatch,
          {
            method: "POST",
            routeParams: { user_id: user_id.toString() },
            body: {
              variables: userSelectedVariables[parseInt(user_id, 10)].map(
                (vari) => vari.id
              ),
            },
          },
          token
        );
      })
    ).then(() => {
      if (!selectedUser.mdiuser) return;
      setSelectedVariables([...userSelectedVariables[selectedUser.id]]);
      setVariablesReady(true);
    });
  }

  const onCheckedAllIndicators = () => {
    setAllcheck(institutionVariables[selectedCategory.name]);
  };

  return (
    <CargandoScreen ready={ready}>
      {!ready ? (
        "??"
      ) : (
        <div tw="bg-ghostwhite">
          <Container>
            <AssignInstitutionalMdiTitle />
            <div tw="grid grid-cols-11 mb-10 gap-4 lg:gap-20">
              <UsersListInstitutionData
                users={users}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                setSelectedVariables={setSelectedVariables}
                userSelectedVariables={userSelectedVariables}
              />
              <SelectedInstitutionVariables
                selectedVariables={selectedVariables}
                setSelectedVariables={setSelectedVariables}
                variablesReady={variablesReady}
                selectedUser={selectedUser}
                userSelectedVariables={userSelectedVariables}
                setUserSelectedVariables={setUserSelectedVariables}
              />
            </div>
            <div tw="bg-resies_blue2 font-sans text-barra font-bold text-ghostwhite px-6">
              Variables
            </div>
            <InstitutionalCategoriesNavbar
              categories={institutionalCategories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
            <div tw="flex flex-col mb-10 bg-resies_lightgreen h-52 overflow-y-auto pl-5 py-3">
              <CargandoScreen ready={variablesReady}>
                {institutionVariables[selectedCategory.name].map((variable) => (
                  <InstitutionalVariableList
                    variable={variable}
                    selectedVariables={selectedVariables}
                    setSelectedVariables={setSelectedVariables}
                    key={variable.indicator_slug}
                    selectedUser={selectedUser}
                    userSelectedVariables={userSelectedVariables}
                    setUserSelectedVariables={setUserSelectedVariables}
                    forCheckAll={allcheck}
                  />
                ))}
              </CargandoScreen>
            </div>
            <div tw="flex flex-row justify-between w-full">
              <div tw="flex flex-col justify-center mt-5">
                <button
                  type="button"
                  tw="bg-resies_purple text-barra font-bold rounded-3xl text-white py-5 px-10"
                  onClick={onCheckedAllIndicators}
                >
                  Seleccionar todo
                </button>
              </div>
              <div tw="flex flex-col justify-center mt-5">
                <button
                  type="button"
                  tw="bg-resies_purple text-barra font-bold rounded-3xl text-white py-5 px-10"
                  onClick={onSubmitHandler}
                >
                  Actualizar
                </button>
              </div>
            </div>
            <VolverButton to={Routes.myInstitution} />
          </Container>
        </div>
      )}
    </CargandoScreen>
  );
}

export default RDIAssignInstitutionalMdiPage;
