import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";

/**
 * Boton que se ubica en la vista de control de avance de institución
 *
 * @param props react props.
 * @param props.label Es el label del boton.
 * @param props.href Es el link a donde llevara el boton
 */
function InstitutionViewButton(props: {
  label: string;
  href: string;
}): ReactElement {
  const { label, href } = props;
  return (
    <Link to={href}>
      <button
        type="button"
        tw="text-barra font-bold text-white min-h-77 bg-resies_purple px-7 py-4 rounded-lg"
      >
        <p tw="">{label}</p>
      </button>
    </Link>
  );
}

export default InstitutionViewButton;
