import React, { ReactElement } from "react";
import "twin.macro";
import { Indicator } from "../../../types/backendTypes";
/**
 * Titulo del form correspondiente a cada indicador.
 *
 * @param props react props.
 * @param props.indicatorSelected State del indicador actualmente seleccionado.
 * @param props.onSaveHandler Funcion que envia todos los datos ingresados a la base de datos.
 * @param props.formState Estado que maneja los datos actualmente ingresados en los formularios de cada variable.
 * @param props.changes booleano que indica si hay cambios.
 */
function TitleIndicatorForm(props: {
  indicatorSelected: Indicator;
  onSaveHandler: (indicator: Indicator) => void;
  formState: Record<string, string>[];
  changes: boolean;
}): ReactElement {
  const { indicatorSelected, onSaveHandler, formState, changes } = props;
  /**
   * Funcion que retorna el titulo del indicador seleccionado junto con un color dependiendo de su estado actual.
   *
   * @param indicator indicador
   */
  function complete(indicator: Indicator) {
    const indicatorVars = formState.filter(
      (ind) => ind.name === indicator.slug
    );
    const nocompletedVars = indicatorVars.filter((variable) => !variable.value);
    let styleCircle;
    let styleLabel;
    let label;
    if (nocompletedVars.length === 0) {
      styleCircle = "rounded-full bg-resies_green h-3 w-3 my-auto mr-2";
      styleLabel = "text-resies_green font-normal my-auto";
      label = "COMPLETADO";
    } else if (nocompletedVars.length < indicatorVars.length) {
      styleCircle = "rounded-full bg-warning_yellow h-3 w-3 my-auto mr-2";
      styleLabel = "text-warning_yellow font-normal my-auto";
      label = "INCOMPLETO";
    } else {
      styleCircle = "rounded-full bg-darkred h-3 w-3 my-auto mr-2";
      styleLabel = "text-darkred font-normal my-auto";
      label = "FALTANTE";
    }

    return (
      <div tw="flex flex-row flex-wrap content-center">
        <p tw="text-resies_blue1 font-bold text-4xl ml-4 mr-4 max-w-3xl">
          {indicatorSelected.title}
        </p>
        <div className={styleCircle} />
        <p className={styleLabel}>{label}</p>
      </div>
    );
  }

  return (
    <div tw="flex flex-col">
      <div tw="flex h-16 bg-resies_lightgreen flex-wrap content-center justify-between min-h-100">
        {complete(indicatorSelected)}

        {indicatorSelected.score !== undefined && (
          <div tw="flex flex-row flex-wrap content-center">
            <p tw="text-base font-bold text-xl ml-4 mr-4 max-w-3xl">
              Puntaje: {indicatorSelected.score}
            </p>
          </div>
        )}

        <div tw="flex flex-row">
          <p tw="flex flex-wrap content-center text-darkred mr-2 text-header2 font-bold">
            {changes ? `Hay cambios sin guardar` : ""}
          </p>
          <button
            type="button"
            tw="flex flex-row flex-wrap content-center bg-resies_purple mr-20 rounded-sm max-h-12"
            onClick={(e) => onSaveHandler(indicatorSelected)}
          >
            <img src="/save.png" alt="save" tw="mr-4 ml-2" />
            <p tw="font-bold text-base text-ghostwhite mr-4">Guardar</p>
          </button>
        </div>
      </div>
      <div tw="flex grid grid-cols-11 h-5 bg-resies_blue2 flex-wrap content-center justify-between">
        <p tw="font-bold text-base text-ghostwhite col-span-3 text-center h-full">
          Variable
        </p>
        <p tw="font-bold text-base text-ghostwhite col-span-1 text-center">
          Dato
        </p>
        <p tw="font-bold text-base text-ghostwhite col-span-3 text-center">
          Descripcion
        </p>
        <p tw="font-bold text-base text-ghostwhite col-span-2 text-center">
          Verificación
        </p>
        <p tw="font-bold text-base text-ghostwhite col-span-2 text-center h-5">
          Estado de auditoría
        </p>
      </div>
    </div>
  );
}

export default TitleIndicatorForm;
