import React, { ReactElement, useContext, useState } from "react";
import "twin.macro";
import { Link } from "react-router-dom";
import Container from "../../components/common/Container";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import { AppContext } from "../../dispatcher";
import InstitutionForm from "../../components/admin/InstitutionForm";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "../../components/common/CargandoScreen";
import ADMPanelNavbar from "../../components/admin/ADMPanelNavbar";

/**
 * La página que muestra la vista para crear una nueva institución
 */
function ADMAddInstitution(): ReactElement {
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState<File>();
  const [logo, setLogo] = useState<File>();
  const [error, setError] = useState(false);
  const [appState] = useContext(AppContext);
  const [created, setCreated] = useState(false);
  const [ready, setReady] = useState(false);
  const [id, setId] = useState(0);
  /**
   * Función para enviar los datos de la nueva institución al backend
   */
  function onSave() {
    if (!error && appState.token) {
      let createdInstId = 0;
      setCreated(true);
      fetchAPI<APIActions["createInstitution"]>(
        APIRoutes.createInstitution,
        {
          method: "POST",
          body: {
            name,
            description: name,
            member_since: Date.now().toString(),
          },
        },
        appState.token
      )
        .then((institution) => {
          createdInstId = institution.id;
          if (!appState.token || !photo) return;
          const formData = new FormData();
          formData.append("file", photo);
          fetch(
            `${process.env.REACT_APP_API_URL}/api/institutions/upload_photo/by_institution/${institution.id}`,
            {
              method: "POST",
              headers: {
                "api-key": appState.token,
              },
              body: formData,
            }
          );
        })
        .then(() => {
          if (!appState.token || !logo) return;
          const formData2 = new FormData();
          formData2.append("file", logo);
          fetch(
            `${process.env.REACT_APP_API_URL}/api/institutions/upload_logo/by_institution/${createdInstId}`,
            {
              method: "POST",
              headers: {
                "api-key": appState.token,
              },
              body: formData2,
            }
          );
        })
        .then(() => {
          setId(createdInstId);
          setReady(true);
        });
    }
  }
  return (
    <Container>
      <ADMPanelNavbar />
      {!created && !id ? (
        <>
          <p tw="text-resies_blue1 text-header1 font-bold ml-4">
            Nueva Institución
          </p>
          <div tw="flex justify-end">
            {error ? (
              <p tw="text-darkred mr-2 font-bold">
                El nombre no puede estar en blanco
              </p>
            ) : (
              <></>
            )}
            <button
              type="button"
              tw="flex flex-row flex-wrap content-center bg-resies_purple py-1 rounded-sm max-h-12"
              onClick={() => onSave()}
            >
              <img src="/save.png" alt="save" tw="mr-4 ml-2" />
              <p tw="font-bold text-base text-ghostwhite mr-4">
                Guardar Cambios
              </p>
            </button>
          </div>
          <InstitutionForm
            name={name}
            setName={setName}
            setError={setError}
            setPhoto={setPhoto}
            setLogo={setLogo}
          />
        </>
      ) : (
        <CargandoScreen ready={ready}>
          <div tw="h-96 flex flex-wrap flex-col">
            <div tw="my-auto mx-auto">
              <p tw="text-header2 font-semibold text-black mb-2">
                Institución {name} se ha creado con éxito
              </p>
              <Link
                to={Routes.assignInstitutionRDI.replace(
                  ":institutionId",
                  id.toString()
                )}
              >
                <button
                  type="button"
                  tw="px-3 py-3 bg-resies_purple rounded-lg mx-auto text-white text-barra font-bold flex flex-wrap"
                >
                  Asignar Responsable de Institución
                </button>
              </Link>
            </div>
          </div>
        </CargandoScreen>
      )}
      <VolverButton to={Routes.institutionsIndex} />
    </Container>
  );
}

export default ADMAddInstitution;
