import React, { ReactElement } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import PageTitle from "../../components/common/PageTitle";
import VolverButton from "../../components/common/VolverButton";
import EvidenceObjectedIndex from "../../components/rdi/EvidenceObjectedIndex";
import Routes from "../../routers/routes";

/**
 * Página para MDI del proceso de auditoría
 * Muestra la evidencia objetada de sus indicadores.
 */
function MDIAudit(): ReactElement {
  return (
    <Container>
      <PageTitle title="Procesos de auditoría" />
      <EvidenceObjectedIndex />
      <VolverButton to={Routes.myInstitutionMDI} />
    </Container>
  );
}

export default MDIAudit;
