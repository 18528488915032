import React, {
  ChangeEvent,
  Fragment,
  ReactElement,
  useContext,
  useState,
} from "react";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import { AuditableResponses, Institution } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import PageSection from "../common/PageSection";
import ProgressBar from "../common/ProgressBar";
import SearchBar from "../common/SearchBar";

/**
 * Componente que muestra las instituciones asignadas a un auditor junto con el progreso
 *
 * @param props react props
 * @param props.responses Todas las respuestas asignadas al auditor
 * @param props.reloadResponses La función para recargar las respuestas y las instituciones
 */
function ADMAUDAssignedInstitutions(props: {
  responses: AuditableResponses[];
  reloadResponses: () => void;
}): ReactElement {
  const [state] = useContext(AppContext);
  const { responses, reloadResponses } = props;
  const [searchText, setSearchText] = useState("");

  /**
   * Handler al cambiar la searchBar
   *
   * @param e El evento de cambio
   */
  function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
  }

  /**
   * Desasignar institucion del auditor.
   *
   * @param institution La institucion a desasignar
   */
  function unassignAUDInstitution(institution: Institution) {
    if (!state.token) return;
    fetchAPI<APIActions["unassignAuditor"]>(
      APIRoutes.unassignAuditor,
      {
        method: "POST",
        routeParams: { institution_id: institution.id.toString() },
      },
      state.token
    ).then(() => {
      reloadResponses();
    });
  }
  return (
    <>
      <div tw="grid grid-cols-2">
        <div>
          <PageSection text="Instituciones Asignadas" />
        </div>
        <div tw="w-full">
          <div tw="inline-block float-right">
            <SearchBar
              searchText={searchText}
              onChangeHandler={onChangeHandler}
            />
          </div>
        </div>
      </div>
      <div tw="bg-resies_lightpurple h-52 shadow w-full overflow-y-auto">
        <table tw="bg-resies_lightpurple w-full">
          <thead tw="pb-2">
            <tr>
              <td tw="bg-resies_blue1 text-ghostwhite text-center w-1/3">
                Institución
              </td>
              <td tw="bg-resies_blue1 text-ghostwhite text-center w-1/3">
                Porcentaje de avance
              </td>
              <td tw="bg-resies_blue1 text-ghostwhite text-center w-1/3">
                Acciones
              </td>
            </tr>
          </thead>
          <tbody tw="max-h-5 overflow-scroll">
            {responses.length ? (
              responses
                .filter((res) => res.institution.name.includes(searchText))
                .map((res) => (
                  <Fragment key={res.institution.id}>
                    <tr>
                      <td tw="justify-center content-center px-8 w-1/3">
                        <tr tw="w-full text-left">
                          <td tw="w-1/12">
                            <img
                              src={
                                res.institution.logo_url
                                  ? res.institution.logo_url
                                  : "/standard-logo.png"
                              }
                              alt="ins"
                              tw="h-full pb-1"
                              style={{ height: "32px" }}
                            />
                          </td>
                          <td tw="w-full">{res.institution.name}</td>
                        </tr>
                      </td>
                      <td tw="px-2 justify-center w-1/3">
                        <div tw="h-5">
                          <ProgressBar
                            completed={
                              (100 *
                                res.responses.reduce(
                                  (acc, resp) =>
                                    acc +
                                    (resp.audit_status === "accepted" ||
                                    resp.audit_status === "rejected"
                                      ? 1
                                      : 0),
                                  0
                                )) /
                              (res.responses.length !== 0
                                ? res.responses.length
                                : 0)
                            }
                          />
                        </div>
                      </td>
                      <td tw="px-2 w-1/3" style={{ height: "32px" }}>
                        <div tw="flex flex-row justify-around">
                          <div
                            tw="w-auto flex flex-row justify-center"
                            style={{ minWidth: "3rem" }}
                          >
                            <button
                              tw="px-2 py-1 bg-darkred text-ghostwhite rounded-md w-full"
                              type="button"
                              onClick={() =>
                                unassignAUDInstitution(res.institution)
                              }
                            >
                              Desasignar
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                ))
            ) : (
              <tr>
                <td colSpan={3}>
                  <div tw="text-center col-span-3">
                    No hay instituciones asignadas
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ADMAUDAssignedInstitutions;
