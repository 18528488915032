import React, { ReactElement } from "react";
import "twin.macro";
import { ResponseForm } from "../../../types/utilTypes";
import AUDCompleteIndicator from "./ADMCompleteIndicator";
/**
 * Lista lateral de indicadores.
 *
 * @param props react props.
 * @param props.indicators Diccionario que contiene los nombres de los indicadores de cada categoria.
 * @param props.selected Corresponde al nombre de la categoria actualmente seleccionada.
 * @param props.clickHandler Funcion que maneja el cambio de indicador seleccionado.
 * @param props.indicatorSelected State del indicador actualmente seleccionado.
 * @param props.formState Estado que maneja los datos actualmente ingresados en los formularios de cada variable.
 */
function ADMIndicatorList(props: {
  indicators: Record<string, string[]>;
  selected: string;
  clickHandler: (ind: string) => void;
  indicatorSelected: string;
  formState: ResponseForm[];
}): ReactElement {
  const { indicators, selected, clickHandler, formState, indicatorSelected } =
    props;

  return (
    <div tw="flex flex-col bg-resies_lightpurple w-28 overflow-y-scroll">
      {indicators[selected].map((ind) => (
        <AUDCompleteIndicator
          indicator={ind}
          formState={formState}
          clickHandler={clickHandler}
          indicatorSelected={indicatorSelected}
          key={ind}
        />
      ))}
    </div>
  );
}

export default ADMIndicatorList;
