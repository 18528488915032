import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import { AppContext } from "../../../../dispatcher";
import { InstitutionCategoryStatus } from "../../../../types/utilTypes";
import getInstitutionCategories from "../../../../utils/data/AdmData/getInstitutionCategories";
import fetchAPI, { APIActions, APIRoutes } from "../../../../utils/fetchAPI";
import CargandoScreen from "../../../common/CargandoScreen";
import InstitutionCategoryOption from "./InstitutionCategoryOption";
import NewInstitutionCategoryOption from "./NewInstitutionCategoryOption";

/**
 * Componente que muestra las categorias institucionales que existen, permite editar sus nombres y crear nuevas categorias.
 */
function EditInstitutionCategories(): ReactElement {
  const [ready, setReady] = useState(false);
  const [maxId, setMaxId] = useState(0);
  const [categoryStatus, setCategoryStatus] = useState<
    InstitutionCategoryStatus[]
  >([]);
  const [appState] = useContext(AppContext);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [reload, setReload] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getInstitutionCategories(appState, setReady, setMaxId, setCategoryStatus);
  }, [appState, reload]);

  /**
   * Funcion que crea una nueva categoria institucional cuando al hacer click el boton +
   */
  function onClickNewCategory() {
    if (!appState.token) return;
    const newCategory: InstitutionCategoryStatus = {
      name: newCategoryName,
      id: maxId + 1,
      status: "ready",
    };
    setMaxId(maxId + 1);
    const copyCategories = [...categoryStatus];
    copyCategories.push(newCategory);
    let errorNameRepeated = false;
    const names = copyCategories.map((cate) => cate.name);
    errorNameRepeated = new Set(names).size !== names.length;
    if (errorNameRepeated) {
      setErrorMessage("No se pueden tener nombres repetidos");
      return;
    }
    setReady(false);
    fetchAPI<APIActions["createInstitutionCategory"]>(
      APIRoutes.createInstitutionCategory,
      {
        method: "POST",
        body: {
          name: newCategory.name,
        },
      },
      appState.token
    )
      .then(() => {
        setCategoryStatus([...copyCategories]);
        setNewCategoryName("");
        setErrorMessage("");
      })
      .then(() => setReload(reload + 1));
  }

  /**
   * Funcion que cambia el estado de la categoria para ser editable.
   *
   * @param e evento que desencadena la funcion.
   */
  function changeStatus(e: React.MouseEvent<HTMLButtonElement>) {
    if (!appState.token) return;
    const copyStatus = [...categoryStatus];
    let errorNameRepeated = false;
    const names = copyStatus.map((cate) => cate.name);
    errorNameRepeated = new Set(names).size !== names.length;
    if (errorNameRepeated) {
      setErrorMessage("No se pueden tener nombres repetidos");
      return;
    }
    const element = copyStatus.filter(
      (status) => status.id.toString() === e.currentTarget.name
    )[0];
    if (element.status === "ready") {
      element.status = "edit";
    } else {
      setReady(false);
      fetchAPI<APIActions["editInstitutionCategory"]>(
        APIRoutes.editInstitutionCategory,
        {
          method: "PUT",
          routeParams: {
            institutional_data_category_id: element.id.toString(),
          },
          body: {
            name: element.name,
          },
        },
        appState.token
      ).then(() => {
        setReload(reload + 1);
        setErrorMessage("");
      });
      element.status = "ready";
    }
    setCategoryStatus([...copyStatus]);
  }

  /**
   * Funcion que maneja el cambio de nombre de la nueva categoria creada.
   *
   * @param e evento que desencadena la funcion.
   */
  function onChangeNewCategory(e: React.ChangeEvent<HTMLInputElement>) {
    setNewCategoryName(e.target.value);
  }

  /**
   * Funcion que maneja el cambio de nombre de las categorias existentes.
   *
   * @param e evento que desencadena la funcion.
   */
  function onChangeEditCategory(e: React.ChangeEvent<HTMLInputElement>) {
    const copyStatus = [...categoryStatus];
    const element = copyStatus.filter(
      (status) => status.id.toString() === e.target.name
    )[0];
    element.name = e.target.value;
    setCategoryStatus([...copyStatus]);
  }

  /**
   * Handler para eliminar categorias
   *
   * @param e evento que desencadena la eliminación.
   */
  function onDeleteCategory(e: React.MouseEvent<HTMLButtonElement>) {
    if (!appState.token) return;
    const copyCategories = [...categoryStatus];
    const deletedCategoryStatus = copyCategories.filter(
      (cat) => cat.id.toString() === e.currentTarget.name
    )[0];
    const indexStatus = copyCategories.indexOf(deletedCategoryStatus);
    setReady(false);
    fetchAPI<APIActions["deleteInstitutionCategory"]>(
      APIRoutes.deleteInstitutionCategory,
      {
        method: "DELETE",
        routeParams: {
          institutional_data_category_id: deletedCategoryStatus.id.toString(),
        },
      },
      appState.token
    )
      .then(() => {
        copyCategories.splice(indexStatus, 1);
        setCategoryStatus([...copyCategories]);
      })
      .then(() => setReload(reload + 1));
  }
  return (
    <CargandoScreen ready={ready}>
      <div tw="">
        <p tw="ml-4 text-header1 font-bold text-resies_blue1 mb-2">
          Categorías Institucionales
        </p>
        <p tw="text-darkred font-bold text-header2">{errorMessage}</p>
        <InstitutionCategoryOption
          categoryStatus={categoryStatus}
          onChangeEditCategory={onChangeEditCategory}
          changeStatus={changeStatus}
          ready={ready}
          onDeleteCategory={onDeleteCategory}
        />
        <NewInstitutionCategoryOption
          newCategoryName={newCategoryName}
          onChangeNewCategory={onChangeNewCategory}
          onClickNewCategory={onClickNewCategory}
        />
      </div>
    </CargandoScreen>
  );
}

export default EditInstitutionCategories;
