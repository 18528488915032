import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MutableRefObject, useState } from "react";
import "twin.macro";
import { CenteredPopup } from "../utils/Popup";

/**
 * Popup genérico de confirmación
 *
 * @param props react props
 * @param props.popupText el texto del popup
 * @param props.errorText El texto a mostrar en caso de error
 * @param props.ref La ref al botón
 * @param props.callback El callback a llamar cuando se aprete si
 */
const ConfirmationPopup = React.forwardRef(
  (
    {
      popupText,
      errorText,
      callback,
    }: {
      popupText: string;
      errorText: string;
      callback: () => Promise<void>;
    },
    ref: React.ForwardedRef<() => void>
  ) => {
    const [error, setError] = useState(false);
    return (
      <CenteredPopup ref={ref} width={500} height={200}>
        <div tw="h-full w-full bg-ghostwhite px-2 py-2 flex flex-col rounded-md">
          <div tw="">{popupText}</div>
          <div tw="flex flex-col h-full justify-center">
            <div tw="flex flex-row justify-between px-20 w-full">
              <div tw="flex h-full content-center">
                <button
                  type="button"
                  tw="px-4 py-2 rounded-md bg-darkred text-ghostwhite"
                  onClick={() =>
                    callback()
                      .then(() => {
                        setError(false);
                        if ((ref as MutableRefObject<() => void>).current)
                          (ref as MutableRefObject<() => void>).current();
                      })
                      .catch(() => setError(true))
                  }
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} tw="mr-2" />
                  Si
                </button>
              </div>
              <div tw="flex h-full content-center">
                <button
                  type="button"
                  tw="px-4 py-2 rounded-md bg-resies_purple text-ghostwhite"
                  onClick={() => {
                    setError(false);
                    if ((ref as MutableRefObject<() => void>).current)
                      (ref as MutableRefObject<() => void>).current();
                  }}
                >
                  No
                </button>
              </div>
            </div>
            <div>
              {error && <span tw="text-darkred text-barra">{errorText}</span>}
            </div>
          </div>
        </div>
      </CenteredPopup>
    );
  }
);

export default ConfirmationPopup;
