import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import EvidenceInfo from "../../components/common/EvidenceView/EvidenceInfo";
import InstitutionalEvidenceComments from "../../components/common/EvidenceView/InstitutionalEvidenceComments";
import InstitutionalEvidenceDataRow from "../../components/common/InstitutionalEvidenceView/InstitutionalEvidenceDataRow";
import PageTitle from "../../components/common/PageTitle";
import VolverButton from "../../components/common/VolverButton";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import {
  InstitutionalResponse,
  InstitutionVariables,
} from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import useRESIESDates from "../../hooks/useRESIESDates";

/**
 * Página para ver la evidencia de las variables institucionales
 */
function AUDInstitutionalEvidenceView(): ReactElement {
  const { variableId } = useParams<{ variableId: string }>();
  const { responseId } = useParams<{ responseId: string }>();
  const { institutionId } = useParams<{ institutionId: string }>();
  const [institutionalVariable, setInstitutionalVariable] =
    useState<InstitutionVariables>();
  const [ready, setReady] = useState(false);
  const [appState] = useContext(AppContext);
  const [uploadFile, setUploadFile] = useState(0);
  const [reload, setReload] = useState(0);
  const [response, setResponse] = useState<InstitutionalResponse>();
  const [institutionName, setInstitutionName] = useState("");
  const [, currentProcess, currentInstitutionProcess] = useRESIESDates();

  const getActualCurrentProcess = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess.current_process;
    }

    return currentProcess;
  };

  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["getInstitutionalVariable"]>(
      APIRoutes.getInstitutionalVariable,
      {
        method: "GET",
        routeParams: { variable_id: variableId },
      },
      appState.token
    ).then((data) => {
      setInstitutionalVariable(data);
      if (!appState.token) return;
      fetchAPI<APIActions["audInstitutionalDataResponses"]>(
        APIRoutes.audInstitutionalDataResponses,
        {
          method: "GET",
        },
        appState.token
      ).then((resps) => {
        const institutionFromResponse = resps.filter(
          (dat) => dat.institution.id.toString() === institutionId
        )[0];
        if (institutionFromResponse) {
          setInstitutionName(institutionFromResponse.institution.name);
        }
        if (responseId !== "missing") {
          setResponse(
            resps
              .filter(
                (dat) => dat.institution.id.toString() === institutionId
              )[0]
              .responses.filter((re) => re.id.toString() === responseId)[0]
          );
          setReady(true);
        } else {
          setTimeout(function () {
            setReady(true);
          }, 2000);
        }
      });
    });
  }, [
    variableId,
    appState.token,
    responseId,
    uploadFile,
    reload,
    institutionId,
  ]);

  function onAuditMissingVariable(e: React.MouseEvent<HTMLButtonElement>) {
    if (!appState.token) return;
    if (!institutionalVariable) return;
    setReady(false);
    fetchAPI<APIActions["auditInstitutionalMissingVariable"]>(
      APIRoutes.auditInstitutionalMissingVariable,
      {
        method: "PUT",
        routeParams: {
          institution_id: institutionId.toString(),
          institution_variable_id: institutionalVariable.id.toString(),
        },
      },
      appState.token
    ).then((response: Record<string, any>) => {
      window.location.href = `/institutions/${institutionId.toString()}/institution-data/${institutionalVariable.id.toString()}/${
        response["id"]
      }/evidence`;
    });
  }

  return (
    <Container>
      <PageTitle
        title={`Evidencia ${institutionName ? "de" : ""} ${institutionName}`}
      />
      <CargandoScreen ready={ready}>
        {institutionalVariable && (
          <>
            <EvidenceInfo
              info={institutionalVariable.description}
              title={institutionalVariable.indicator_slug}
            />
            {response ? (
              <EvidenceInfo info={response?.description} title="Descripción" />
            ) : (
              <EvidenceInfo info="Sin respuesta" title="Descripción" />
            )}
          </>
        )}
        {response ? (
          <>
            <InstitutionalEvidenceDataRow
              response={response}
              setUploadFile={setUploadFile}
              reload={reload}
              setReload={setReload}
            />
            <InstitutionalEvidenceComments />
          </>
        ) : (
          <>
            {appState.userType === "AUD" &&
              getActualCurrentProcess()?.name === "Auditoría" && (
                <div tw="flex justify-end">
                  <button
                    type="button"
                    tw="bg-resies_purple mt-2 text-ghostwhite font-bold flex flex-row px-3 py-2 mr-3"
                    name="objected"
                    onClick={(e) => onAuditMissingVariable(e)}
                  >
                    <img
                      tw="object-contain mr-2"
                      src="/object.png"
                      alt="object"
                    />
                    Objetar
                  </button>
                </div>
              )}
          </>
        )}
      </CargandoScreen>
      <VolverButton
        to={`${Routes.audInstitutionData.replace(
          ":institutionId",
          institutionId
        )}?category=${institutionalVariable?.category_id}`}
      />
    </Container>
  );
}

export default AUDInstitutionalEvidenceView;
