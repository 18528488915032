import React, { ReactElement, ReactNode } from "react";
import "twin.macro";

/**
 * Un wrapper para las instituciones en la vista índice de instituciones.
 *
 * @param props react props.
 * @param props.children Los elementos hijos
 */
function InstitutionIndexWrapper(props: { children: ReactNode }): ReactElement {
  const { children } = props;
  return <div tw="grid grid-cols-6 row-gap-4 col-gap-4">{children}</div>;
}
export default InstitutionIndexWrapper;
