import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import "twin.macro";
import Routes from "../../routers/routes";
import { AdminInstitutionOverview } from "../../types/backendTypes";

const COLORS = ["#7cc199", "#d91111"];

/**
 * Una institución a mostrar en la vista de instituciones del administrador.
 *
 * @param props react props
 * @param props.institution institucion
 */
function InstitutionOverviewIndexEntry(props: {
  institution: AdminInstitutionOverview;
}): ReactElement {
  const { institution } = props;

  const totalIndicators = institution.categories.reduce(
    (prev, category) => prev + category.total_indicators,
    0
  );
  const totalResponses = institution.categories.reduce(
    (prev, category) => prev + category.complete_indicators,
    0
  );

  const percentage = parseFloat(
    ((totalResponses / totalIndicators) * 100).toFixed(1)
  );
  return (
    <div tw="flex col-span-2 bg-resies_lightpurple rounded-md px-5 inline-block shadow-md h-56">
      <div>
        <div tw="flex h-10 mt-2 mb-2">
          <img
            tw="object-contain"
            src={
              institution.institution_logo
                ? institution.institution_logo
                : "/standard-logo.png"
            }
            alt="uc"
          />
          <Link
            to={Routes.admInstitutionsView.replace(
              ":institutionId",
              institution.institution_id.toString()
            )}
            tw="flex flex-wrap content-center ml-2 mt-2.5 hover:text-resies_purple text-black font-bold text-header2 text-very_darkgray"
          >
            {institution.institution_name}
          </Link>
        </div>
        <div tw="flex flex-row content-center">
          <div tw="ml-2 mt-2">
            <PieChart width={140} height={140}>
              <Pie
                dataKey="value"
                data={[
                  {
                    name: "Completado",
                    value: parseFloat(percentage.toFixed(1)),
                  },
                  {
                    name: "Incompleto",
                    value: 100 - parseFloat(percentage.toFixed(1)),
                  },
                ]}
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={70}
              >
                {[
                  {
                    name: "Completado",
                    value: parseFloat(percentage.toFixed(1)),
                  },
                  { name: "Incompleto", value: 100 },
                ].map((item, index) => (
                  <Cell fill={COLORS[index % COLORS.length]} key={item.name} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
          <div tw="flex flex-col justify-center w-full text-center">
            <div tw="text-header2 font-bold">Avance global</div>
            <div tw="text-barra text-resies_blue1 font-bold">{percentage}%</div>
          </div>
        </div>
      </div>
      <div tw="flex justify-end mt-2">
        {/* <Link
          to={Routes.institutionsView.replace(
            ":institutionId",
            institution.institution_id.toString()
          )}
        >
          <button type="button" tw="mb-4 mr-6 text-darkgray text-base">
            Ver más sobre esta institución
          </button>
        </Link> */}
      </div>
    </div>
  );
}

export default InstitutionOverviewIndexEntry;
