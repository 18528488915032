import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import "twin.macro";
import { AppContext, AppState, Dispatcher } from "../../dispatcher";
import Routes from "../../routers/routes";
import {
  AuditableResponses,
  InstitutionWithAuditor,
  User,
} from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import PageSection from "../../components/common/PageSection";
import VolverButton from "../../components/common/VolverButton";
import ADMAUDInstitutionAssigner from "../../components/admin/ADMAUDInstitutionAssigner";
import AUDContactData from "../../components/admin/AUDContactData";
import AuditedPercentage from "../../components/admin/AuditedPercentage";
import AuditorAssignedNumber from "../../components/admin/AuditorAssignedNumber";
import PageTitleSubtitle from "../../components/common/PageTitleSubtitle";

/**
 * Componente para asignar instituciones a auditor.
 */
function ADMAUDProfileAssignInstitutions(): ReactElement {
  const { audId } = useParams<{ audId: string }>();
  const [ready, setReady] = useState(false);
  const [appState]: [AppState, Dispatcher] = useContext(AppContext);
  const [auditor, setAuditor] = useState<User>({
    id: 0,
    first_name: "",
    last_name: "",
    description: "",
    email: "",
    phone_number: "",
    user_type: "AUD",
    photo_url: "",
    mdiuser: null,
    rdiuser: null,
    auduser: null,
    admuser: null,
  });
  const [institutions, setInstitutions] = useState<InstitutionWithAuditor[]>(
    []
  );
  const [auditableResponses, setAuditableResponses] = useState<
    AuditableResponses[]
  >([]);
  useEffect(() => {
    // eslint-disable-next-line prefer-destructuring
    const token = appState.token;
    if (token) {
      fetchAPI<APIActions["userProfile"]>(
        APIRoutes.userProfile,
        { method: "GET", routeParams: { user_id: audId } },
        token
      ).then((aud) => {
        if (aud.user_type === "AUD" && appState.token) {
          setAuditor(aud);
          fetchAPI<APIActions["getInstitutionsWithAuditor"]>(
            APIRoutes.getInstitutionsWithAuditor,
            { method: "GET" },
            token
          ).then((ins) => {
            setInstitutions(
              ins.sort(
                (ins1, ins2) => ins1.institution.id - ins2.institution.id
              )
            );
            fetchAPI<APIActions["auditableResponsesWithId"]>(
              APIRoutes.auditableResponsesWithId,
              { method: "GET", routeParams: { user_id: audId } },
              token
            )
              .then((audres) => {
                setAuditableResponses(audres);
                setReady(true);
              })
              .catch();
          });
        }
      });
    }
  }, [appState.token, audId, setAuditableResponses]);
  return (
    <Container>
      <CargandoScreen ready={ready}>
        <PageTitleSubtitle
          title={`Auditor: ${auditor.first_name} ${auditor.last_name}`}
          subtitle="ASIGNAR INSTITUCIÓN A AUDITOR"
        />
        <div tw="grid grid-cols-2 col-gap-2">
          <div tw="flex flex-col">
            <div>
              <PageSection text="Datos Personales" />
              <AUDContactData auditor={auditor} />
            </div>
            <div tw="mt-4">
              <div tw="grid grid-cols-2 col-gap-2">
                <div>
                  <AuditedPercentage
                    percentage={(() => {
                      const completed = auditableResponses.reduce(
                        (acc, response) =>
                          acc +
                          response.responses.reduce(
                            (acc2, res) =>
                              acc2 +
                              (res.audit_status === "accepted" ||
                              res.audit_status === "rejected"
                                ? 1
                                : 0),
                            0
                          ),
                        0
                      );
                      const total = auditableResponses.reduce(
                        (acc, response) => acc + response.responses.length,
                        0
                      );
                      return completed / (total !== 0 ? total : 1);
                    })()}
                  />
                </div>
                <div>
                  <AuditorAssignedNumber
                    number={auditor.auduser?.institution_set.length || 0}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <ADMAUDInstitutionAssigner
              institutions={institutions}
              auditor={auditor}
              setAuditor={setAuditor}
              setInstitutions={setInstitutions}
            />
          </div>
        </div>
        <VolverButton
          to={Routes.audProfile.replace(":audId", auditor.id.toString())}
        />
      </CargandoScreen>
    </Container>
  );
}

export default ADMAUDProfileAssignInstitutions;
