import React from "react";
import { Indicator, User } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../fetchAPI";

/**
 * obtener usuarios
 *
 * @param setUsers users setter
 * @param token token
 * @param institutionId id de institucion
 * @param setUserSelectedIndicators setter de indicadores
 */
function getUsers(
  setUsers: React.Dispatch<React.SetStateAction<User[]>>,
  token: string | null,
  institutionId: string | null,
  setUserSelectedIndicators: React.Dispatch<
    React.SetStateAction<Record<number, Indicator[]>>
  >
): void {
  const userIndicators: Record<number, Indicator[]> = {};
  const users: Array<User> = [];
  let apiKey = "";
  let id = "";
  if (token !== null) {
    apiKey = token;
  }
  if (institutionId !== null) {
    id = institutionId;
  }

  if (apiKey) {
    fetchAPI<APIActions["fetchInstitutionDetails"]>(
      APIRoutes.fetchInstitutionDetails,
      {
        method: "GET",
        routeParams: { institution_id: id },
      },
      apiKey
    )
      .then((data) => {
        data.members.forEach((element: User) => {
          if (element.user_type === "MDI") {
            users.push(element);
            fetchAPI<APIActions["getMdiIndicators"]>(
              APIRoutes.getMdiIndicators,
              {
                method: "GET",
                routeParams: { user_id: element.id.toString() },
              },
              apiKey
            ).then((inds) => {
              userIndicators[element.id] = inds;
            });
          }
        });
      })
      .then(() => {
        setUsers([...users]);
        setUserSelectedIndicators(userIndicators);
      })
      .catch(() => {}); // TODO
  }
}
export default getUsers;
