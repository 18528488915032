import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import React, { ChangeEvent, ReactElement } from "react";
import "twin.macro";

/**
 * barra de busqueda
 *
 * @param props react props
 * @param props.searchText texto de busqueda
 * @param props.onChangeHandler handler
 */
function SearchBar(props: {
  searchText: string;
  onChangeHandler: (param: ChangeEvent<HTMLInputElement>) => void;
}): ReactElement {
  const { searchText, onChangeHandler } = props;
  return (
    <div tw="mb-4 bg-resies_lightpurple py-2 flex flex-row flex-nowrap rounded-lg w-auto">
      <div tw="h-full inline-block">
        <div tw="flex flex-row content-center w-10 h-full">
          <button type="button" tw="focus:outline-none">
            <FontAwesomeIcon icon={faSearch} color="#5b5b5b" tw="ml-4" />
          </button>
        </div>
      </div>
      <input
        type="text"
        placeholder="Buscar"
        tw="bg-resies_lightpurple pl-3 h-5 focus:outline-none max-w-full flex-grow flex-shrink inline-block text-very_darkgray"
        value={searchText}
        onChange={(e) => onChangeHandler(e)}
      />
    </div>
  );
}

export default SearchBar;
