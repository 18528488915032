import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import EvidenceInfo from "../../components/common/EvidenceView/EvidenceInfo";
import InstitutionalEvidenceComments from "../../components/common/EvidenceView/InstitutionalEvidenceComments";
import InstitutionalEvidenceDataRow from "../../components/common/InstitutionalEvidenceView/InstitutionalEvidenceDataRow";
import PageTitle from "../../components/common/PageTitle";
import VolverButton from "../../components/common/VolverButton";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import {
  InstitutionalDataResponse,
  InstitutionalResponse,
  InstitutionVariables,
} from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * Página para ver la evidencia de las variables institucionales
 */
function ADMInstitutionalEvidenceView(): ReactElement {
  const { variableId } = useParams<{ variableId: string }>();
  const { responseId } = useParams<{ responseId: string }>();
  const { institutionId } = useParams<{ institutionId: string }>();
  const [institutionalVariable, setInstitutionalVariable] =
    useState<InstitutionVariables>();
  const [ready, setReady] = useState(false);
  const [appState] = useContext(AppContext);
  const [uploadFile, setUploadFile] = useState(0);
  const [reload, setReload] = useState(0);
  const [response, setResponse] = useState<InstitutionalResponse | null>();
  const [institutionName, setInstitutionName] = useState("");

  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["fetchInstitutionDetails"]>(
      APIRoutes.fetchInstitutionDetails,
      {
        method: "GET",
        routeParams: { institution_id: institutionId },
      },
      appState.token
    ).then((inst) => setInstitutionName(inst.institution.name));
    fetchAPI<APIActions["getInstitutionalVariable"]>(
      APIRoutes.getInstitutionalVariable,
      {
        method: "GET",
        routeParams: { variable_id: variableId },
      },
      appState.token
    ).then((data) => {
      setInstitutionalVariable(data);
      if (!appState.token) return;
      fetchAPI<APIActions["fetchInstitutionalDataForAdmin"]>(
        APIRoutes.fetchInstitutionalData,
        {
          method: "GET",
          queryParams: {
            institution_id: institutionId,
          },
        },
        appState.token
      ).then((resps) => {
        let respon: InstitutionalDataResponse | null = null;
        resps.categories.forEach((category) => {
          category.variables.forEach((variable) => {
            if (variable.variable.id.toString() === variableId) {
              respon = variable.response;
            }
          });
        });
        setResponse(respon);
        setReady(true);
      });
    });
  }, [
    variableId,
    appState.token,
    responseId,
    uploadFile,
    reload,
    institutionId,
  ]);
  return (
    <Container>
      <PageTitle title={`Evidencia de ${institutionName}`} />
      <CargandoScreen ready={ready}>
        {institutionalVariable ? (
          <>
            <EvidenceInfo
              info={institutionalVariable.description}
              title={institutionalVariable.indicator_slug}
            />
            <EvidenceInfo info={response?.description} title="Descripción" />
          </>
        ) : (
          ""
        )}
        {response ? (
          <InstitutionalEvidenceDataRow
            response={response}
            setUploadFile={setUploadFile}
            reload={reload}
            setReload={setReload}
          />
        ) : (
          ""
        )}
        <InstitutionalEvidenceComments />
      </CargandoScreen>
      <VolverButton
        to={`${Routes.ADMinstitutionData.replace(
          ":institutionId",
          institutionId
        )}?category=${institutionalVariable?.category_id}`}
      />
    </Container>
  );
}

export default ADMInstitutionalEvidenceView;
