import { Evidence, Indicator } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../fetchAPI";

/**
 * Función que retorna los datos de formulario de cada variable de los indicadores.
 *
 * @param indicators indicadores
 * @param setIndicatorForm setter de formulario de indicador
 * @param apiKey token
 * @param setReady setter de ready.
 */
function setForms(
  indicators: Indicator[],
  setIndicatorForm: React.Dispatch<
    React.SetStateAction<
      Record<string, string | boolean | number | null | Evidence[]>[]
    >
  >,
  apiKey: string,
  setReady: React.Dispatch<React.SetStateAction<boolean>>
): void {
  const forms: Record<string, string | boolean | number | null | Evidence[]>[] =
    [];
  fetchAPI<APIActions["fetchEvaluationVariables"]>(
    APIRoutes.fetchEvaluationVariables,
    {
      method: "GET",
    },
    apiKey
  )
    .then((variables) => {
      variables.sort((a, b) => a.id - b.id);
      indicators.forEach((indicator) => {
        const variablesIndicator = variables.filter(
          (variable) => variable.indicator_id === indicator.id
        );
        variablesIndicator.forEach((variable) => {
          forms.push({
            name: indicator.slug,
            type: variable.type,
            slug: variable.description,
            value: "",
            comment: "",
            description: "",
            variable_id: variable.id,
            modified: "",
            json_config: JSON.stringify(variable.json_config),
            audit_status: "",
            evidences: [],
            descriptionHelper: variable.description_field_helper_text,
          });
        });
      });
    })
    .then(() => {
      fetchAPI<APIActions["fetchResponseVariables"]>(
        APIRoutes.fetchResponseVariables,
        {
          method: "GET",
        },
        apiKey
      )
        .then((responses) => {
          forms.forEach((form) => {
            const response = responses.filter(
              (elem) => elem.variable_id === form.variable_id
            );
            if (response.length) {
              // eslint-disable-next-line no-param-reassign
              form.value = response[0].response;
              // eslint-disable-next-line no-param-reassign
              form.response_id = response[0].id;
              // eslint-disable-next-line no-param-reassign
              form.completed = true;
              // eslint-disable-next-line no-param-reassign
              form.audit_status = response[0].audit_status;
              // eslint-disable-next-line no-param-reassign
              form.evidences = response[0].evidences;
              // eslint-disable-next-line no-param-reassign
              form.description = response[0].description;
            } else {
              // eslint-disable-next-line no-param-reassign
              form.response_id = null;
              // eslint-disable-next-line no-param-reassign
              form.completed = false;
              // eslint-disable-next-line no-param-reassign
              form.audit_status = "pending";
            }
          });
        })
        .then(() => {
          setIndicatorForm(forms);
        })
        .then(() => setReady(true))
        .catch(() => {}); // TODO;
    })
    .catch(() => {}); // TODO;
}
export default setForms;
