import React, { ReactElement, useState, useEffect, useContext } from "react";
import "twin.macro";
import { useParams } from "react-router";
import getData from "../../utils/data/getData";
import { AppContext } from "../../dispatcher";
import CargandoScreen from "../../components/common/CargandoScreen";
import { InstitutionDetails } from "../../types/backendTypes";
import Container from "../../components/common/Container";
import AUDCategoriesNavbar from "../../components/aud/IndicatorsView/AUDCategoriesNavbar";
import AUDMainIndicatorsMenu from "../../components/aud/IndicatorsView/AUDMainIndicatorsMenu";
import audIndicatorsTitle from "../../utils/data/AudData/audIndicatorsTitle";
import { ResponseForm } from "../../types/utilTypes";
import getInstitutionDetails from "../../utils/data/AudData/getInstitutionDetails";
import { SearchOnUrl } from "../../utils/commonFunction";

/**
 * Vista de indicadores del AUD.
 */
function AUDIndicatorsMenu(): ReactElement {
  const [data, setData] = useState([""]);
  const [indicators, setIndicators] = useState<Record<string, string[]>>({});
  const [indicatorsNames, setIndicatorsNames] = useState({});
  const [indicatorForm, setIndicatorForm] = useState<ResponseForm[]>([]);
  const [selected, setSelected] = useState("");
  const [indicatorSelected, setIndicatorSelected] = useState("");
  const [appState] = useContext(AppContext);
  const { institutionId } = useParams<{ institutionId: string }>();
  const [institution, setInstitution] = useState<InstitutionDetails>();
  const query = SearchOnUrl();

  const [readyData, setReadyData] = useState(false);

  useEffect(
    function loadData() {
      if (appState.token) {
        getData(
          setData,
          setIndicators,
          appState.token,
          setSelected,
          setIndicatorSelected,
          true
        );
        getInstitutionDetails(institutionId, appState.token, setInstitution);
        audIndicatorsTitle(
          setIndicatorsNames,
          setIndicatorForm,
          appState.token,
          institutionId,
          setReadyData
        );
      }
    },
    [appState.token, institutionId]
  );

  useEffect(() => {
    const position =
      query.get("tab") !== null ? Number(query.get("tab")) - 1 : 0;
    setSelected(data[position]);

    if (
      Object.keys(indicators).length > 0 &&
      data[position] !== undefined &&
      data[position] !== "" &&
      query.get("indic") === null &&
      indicators[data[position]].length > 0
    ) {
      setIndicatorSelected(indicators[data[position]][0]);
    }
  }, [data, indicators, query]);

  return (
    <CargandoScreen ready={readyData}>
      <Container>
        <div tw="bg-resies_lightgreen font-sans text-header1 font-bold text-resies_blue1 py-2 px-6">
          {institution?.institution.description}
        </div>
      </Container>
      <div tw="flex flex-col relative">
        <div>
          <AUDCategoriesNavbar
            labels={data}
            selected={selected}
            setSelected={setSelected}
            setIndicatorSelected={setIndicatorSelected}
            indicators={indicators}
          />
          <AUDMainIndicatorsMenu
            indicators={indicators}
            selected={selected}
            indicatorsName={indicatorsNames}
            indicatorSelected={indicatorSelected}
            setIndicatorSelected={setIndicatorSelected}
            indicatorForm={indicatorForm}
          />
        </div>
      </div>
    </CargandoScreen>
  );
}

export default AUDIndicatorsMenu;
