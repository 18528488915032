import React, { ReactElement } from "react";
import "twin.macro";
import {
  EvaluationVariablesFromIndicator,
  EvaluationVariableStatus,
  IndicatorStatus,
} from "../../../../../types/utilTypes";
import ADMVariableRow from "./ADMVariableRow";

/**
 * Componente que muestra la lista de variables
 *
 * @param props react props.
 * @param props.indicatorSelected Indicador actualmente seleccionado.
 * @param props.setEvaluationVariables setState que cambia las variables de cada indicador.
 * @param props.evaluationVariables Objeto que muestra todas las variables de cada indicador
 * @param props.maxVariableId Id mas alto de las variables actuales
 * @param props.setMaxVariableId setState de maxVariableId
 * @param props.deletedVariables variables eliminadas
 * @param props.setDeletedVariables eliminar variables
 * @param props.setChanges set changes
 */
function ADMVariableList(props: {
  indicatorSelected: IndicatorStatus;
  setEvaluationVariables: React.Dispatch<
    React.SetStateAction<EvaluationVariablesFromIndicator[]>
  >;
  evaluationVariables: EvaluationVariablesFromIndicator[];
  maxVariableId: number;
  setMaxVariableId: React.Dispatch<React.SetStateAction<number>>;
  deletedVariables: EvaluationVariableStatus[];
  setDeletedVariables: React.Dispatch<
    React.SetStateAction<EvaluationVariableStatus[]>
  >;
  setChanges: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement {
  const {
    indicatorSelected,
    setEvaluationVariables,
    evaluationVariables,
    maxVariableId,
    setMaxVariableId,
    deletedVariables,
    setDeletedVariables,
    setChanges,
  } = props;
  /**
   * Funcion que crea una nueva variable al hacer click en el boton.
   */
  function onClickHandler() {
    const copyEvaluationVariables = [...evaluationVariables];
    const copyIndicator = copyEvaluationVariables.filter(
      (evalu) => evalu.id === indicatorSelected.id
    )[0];
    let maxDisplay = 0;
    copyIndicator.evaluationVariables.forEach((variable) => {
      if (variable.display_order >= maxDisplay) {
        maxDisplay = variable.display_order;
      }
    });
    copyIndicator.evaluationVariables.push({
      indicator_id: indicatorSelected.id,
      type: "MULTIPLE_CHOICE",
      description: "",
      is_omittable: false,
      requires_verificator: true,
      description_field_helper_text: "",
      verification_field_helper_text: "",
      id: maxVariableId + 1,
      statusDescription: "edit",
      statusDescriptionFieldHelper: "edit",
      statusVerification: "edit",
      generalStatus: "new",
      display_order: maxDisplay + 1,
      default_score: 0,
      json_config: [
        {
          option_text: "Sí",
          option_score: 1,
        },
        {
          option_text: "No",
          option_score: 0,
        },
      ],
      statusWeight: "edit",
    });
    setEvaluationVariables(copyEvaluationVariables);
    setMaxVariableId(maxVariableId + 1);
    setChanges(true);
  }
  return (
    <div tw="flex flex-col bg-ghostwhite overflow-y-scroll mb-10 h-screen">
      <div tw="flex justify-start my-3">
        <button
          type="button"
          tw="flex flex-row my-auto"
          onClick={() => onClickHandler()}
        >
          <img src="/add.png" alt="add" tw="my-auto ml-2 mr-2" />
          <p tw="text-resies_purple font-semibold text-body my-auto">
            Agregar Variable
          </p>
        </button>
      </div>
      {evaluationVariables.length ? (
        evaluationVariables
          .filter((variable) => variable.id === indicatorSelected.id)[0]
          .evaluationVariables.map((variable) => (
            <ADMVariableRow
              variable={variable}
              key={variable.id}
              evaluationVariables={evaluationVariables}
              setEvaluationVariables={setEvaluationVariables}
              deletedVariables={deletedVariables}
              setDeletedVariables={setDeletedVariables}
              setChanges={setChanges}
            />
          ))
      ) : (
        <></>
      )}
    </div>
  );
}

export default ADMVariableList;
