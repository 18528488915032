import React, {
  ChangeEvent,
  Fragment,
  ReactElement,
  useContext,
  useState,
} from "react";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import { AuditableResponses, Institution } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import PageSection from "../common/PageSection";
import ProgressBar from "../common/ProgressBar";
import SearchBar from "../common/SearchBar";

/**
 * Componente que muestra las instituciones asignadas a un auditor junto con el progreso
 *
 * @param props react props
 * @param props.responses Todas las respuestas asignadas al auditor
 * @param props.reloadResponses La función para recargar las respuestas y las instituciones
 * @param props.inProfile Si la tabla se está mostrando en el perfil del auditor
 */
function AUDAssignedInstitutions(props: {
  responses: AuditableResponses[];
  reloadResponses: () => void;
  inProfile?: boolean;
}): ReactElement {
  const [state] = useContext(AppContext);
  const { responses, reloadResponses, inProfile } = props;
  const [searchText, setSearchText] = useState("");

  /**
   * Handler al cambiar la searchBar
   *
   * @param e El evento de cambio
   */
  function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
  }

  /**
   * Desasignar institucion del auditor.
   *
   * @param institution La institucion a desasignar
   */
  function unassignAUDInstitution(institution: Institution) {
    if (!state.token) return;
    fetchAPI<APIActions["unassignAuditor"]>(
      APIRoutes.unassignAuditor,
      {
        method: "POST",
        routeParams: { institution_id: institution.id.toString() },
      },
      state.token
    ).then(() => {
      reloadResponses();
    });
  }
  return (
    <>
      <div tw="grid grid-cols-2">
        <div>
          <PageSection text="Instituciones Asignadas" />
        </div>
        <div tw="w-full">
          <div tw="inline-block float-right">
            <SearchBar
              searchText={searchText}
              onChangeHandler={onChangeHandler}
            />
          </div>
        </div>
      </div>
      <table tw="bg-resies_lightpurple shadow h-52 w-full">
        <div
          tw="grid row-gap-2 pb-2"
          className={inProfile ? "grid-cols-2" : "grid-cols-2"}
        >
          <div tw="bg-resies_blue1 text-ghostwhite text-center">
            Institución
          </div>
          <div tw="bg-resies_blue1 text-ghostwhite text-center">
            Porcentaje de avance
          </div>
          {!inProfile && (
            <div tw="bg-resies_blue1 text-ghostwhite text-center">Acciones</div>
          )}
        </div>
        <div
          tw="grid row-gap-2 pb-2 overflow-scroll"
          className={inProfile ? "grid-cols-2" : "grid-cols-3"}
        >
          {responses.length ? (
            responses
              .filter((res) => res.institution.name.includes(searchText))
              .map((res) => (
                <Fragment key={res.institution.id}>
                  <div
                    tw="flex flex-row justify-center content-center px-2"
                    style={{ height: "32px" }}
                  >
                    <div tw="flex w-auto h-full inline-block">
                      <img
                        src={
                          res.institution.logo_url
                            ? res.institution.logo_url
                            : "/standard-logo.png"
                        }
                        alt="ins"
                        tw="object-contain h-full pb-1"
                      />
                    </div>
                    <div tw="pl-2 align-middle">{res.institution.name}</div>
                  </div>
                  <div
                    tw="px-2 flex flex-col justify-center"
                    style={{ height: "32px" }}
                  >
                    <div tw="h-3/4">
                      <ProgressBar
                        completed={
                          (100 *
                            res.responses.reduce(
                              (acc, resp) =>
                                acc +
                                (resp.audit_status === "accepted" ||
                                resp.audit_status === "rejected"
                                  ? 1
                                  : 0),
                              0
                            )) /
                          (res.responses.length !== 0
                            ? res.responses.length
                            : 0)
                        }
                      />
                    </div>
                  </div>
                  {!inProfile && (
                    <div tw="px-2" style={{ height: "32px" }}>
                      <div tw="flex flex-row justify-around">
                        <div
                          tw="w-auto flex flex-row justify-center"
                          style={{ minWidth: "3rem" }}
                        >
                          <button
                            tw="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md w-full"
                            type="button"
                          >
                            Recordar
                          </button>
                        </div>
                        <div
                          tw="w-auto flex flex-row justify-center"
                          style={{ minWidth: "3rem" }}
                        >
                          <button
                            tw="px-2 py-1 bg-darkred text-ghostwhite rounded-md w-full"
                            type="button"
                            onClick={() =>
                              unassignAUDInstitution(res.institution)
                            }
                          >
                            Desasignar
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              ))
          ) : (
            <div>No tienes instituciones</div>
          )}
        </div>
      </table>
    </>
  );
}

export default AUDAssignedInstitutions;
