import { useCallback, useContext, useEffect, useState } from "react";
import fetchAPI, { APIActions, APIRoutes } from "../utils/fetchAPI";
import { AppContext, AppState, Dispatcher } from "../dispatcher";
import { AuditableResponses, User } from "../types/backendTypes";

const defaultUser: User = {
  id: 0,
  photo_url: "",
  first_name: "",
  last_name: "",
  description: "",
  email: "",
  phone_number: "",
  user_type: "AUD",
  mdiuser: null,
  rdiuser: null,
  auduser: null,
  admuser: null,
};

/**
 * Custom hook para buscar un auditor en la base de datos como usuario ADM.
 *
 * @param setReady state para decir "ya tengo los datos del auditor"
 * @param userId id de usuario
 */
function useAuditor(
  setReady: (ready: boolean) => void,
  userId: string | null
): [User, AuditableResponses[], () => void] {
  const [user, setUser] = useState(defaultUser);
  const [auditableResponses, setAuditableResponses] = useState<
    AuditableResponses[]
  >([]);
  const [appState]: [AppState, Dispatcher] = useContext(AppContext);
  const reloadResponses = useCallback(() => {
    if (!userId || !appState.token) return;
    if (appState.token && userId) {
      fetchAPI<APIActions["userProfile"]>(
        APIRoutes.userProfile,
        { method: "GET", routeParams: { user_id: userId } },
        appState.token
      ).then((userr) => {
        setUser(userr);
      });
    }
    if (appState.userType === "AUD") {
      fetchAPI<APIActions["auditableResponses"]>(
        APIRoutes.auditableResponses,
        { method: "GET" },
        appState.token
      ).then((audres) => {
        setAuditableResponses(audres);
        setReady(true);
      });
    } else {
      fetchAPI<APIActions["auditableResponsesWithId"]>(
        APIRoutes.auditableResponsesWithId,
        { method: "GET", routeParams: { user_id: userId } },
        appState.token
      ).then((audres) => {
        setAuditableResponses(audres);
        setReady(true);
      });
    }
  }, [appState.token, appState.userType, setReady, userId]);
  useEffect(() => {
    reloadResponses();
  }, [reloadResponses]);
  return [user, auditableResponses, reloadResponses];
}

export default useAuditor;
