import React, { useEffect, useContext, useState, ReactElement } from "react";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import VolverButton from "../../components/common/VolverButton";
import CategoryProgressRow from "../../components/common/CategoryProgressRow";
import { AppContext, AppState, Dispatcher } from "../../dispatcher";
import Routes from "../../routers/routes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import InstitutionViewButton from "../../components/common/InstitutionViewButton";
import ResultsBox from "../../components/common/ResultsBox";
import "twin.macro";
import MembersWidget from "../../components/common/MembersWidget";

/**
 * La página que muestra una institución
 */
function ADMInstitutionView(): ReactElement {
  const { institutionId } = useParams<{ institutionId: string }>();
  const [institutionName, setInstitutionName] = useState("");
  const [appState]: [AppState, Dispatcher] = useContext(AppContext);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["fetchInstitutionDetails"]>(
      APIRoutes.fetchInstitutionDetails,
      { method: "GET", routeParams: { institution_id: institutionId } },
      appState.token
    )
      .then((details) => {
        setInstitutionName(details.institution.description);
        setReady(true);
      })
      .catch(() => {}); // TODO
  }, [appState, institutionId]);
  return (
    <div tw="bg-ghostwhite">
      <CargandoScreen ready={ready}>
        <Container>
          <div tw="flex bg-resies_lightgreen font-sans text-header1 font-bold text-resies_blue1 py-2 px-6 mb-12">
            {institutionName}
            <Link
              to={Routes.admeditInstitution.replace(
                ":institutionId",
                institutionId
              )}
            >
              <div tw="cursor-pointer font-normal text-body text-resies_purple">
                <FontAwesomeIcon icon={faEdit} color="#5b5b5b" tw="ml-4" />{" "}
                Editar institución{" "}
              </div>
            </Link>
            <Link
              to={Routes.admDeleteInstitution.replace(
                ":institutionId",
                institutionId
              )}
            >
              <div tw="cursor-pointer font-normal text-body text-resies_purple">
                <FontAwesomeIcon icon={faTrash} color="#5b5b5b" tw="ml-4" />{" "}
                Eliminar institución{" "}
              </div>
            </Link>
          </div>
          <div tw="flex flex-row justify-between mb-2">
            <InstitutionViewButton
              label="Asignar responsable de institución"
              href={Routes.assignInstitutionRDI.replace(
                ":institutionId",
                institutionId.toString()
              )}
            />
            <InstitutionViewButton
              label="Asignar Auditor"
              href={Routes.assignInstitutionAUD.replace(
                ":institutionId",
                institutionId
              )}
            />
            <InstitutionViewButton
              label="Respuestas de la institución"
              href={Routes.ADMindicators.replace(
                ":institutionId",
                institutionId.toString()
              )}
            />
            <InstitutionViewButton
              label="Datos de la institución"
              href={Routes.ADMinstitutionData.replace(
                ":institutionId",
                institutionId.toString()
              )}
            />
            <InstitutionViewButton
              label="Proceso Auditoría"
              href={Routes.ADMinstitutionResiesVersion.replace(
                ":institutionId",
                institutionId.toString()
              )}
            />
          </div>
          <div tw="my-7">
            <MembersWidget institutionId={institutionId} />
          </div>
          <div tw="my-7">
            <CategoryProgressRow wantProgressbar={false} />
          </div>
          <div tw="my-28 w-full">
            <ResultsBox />
          </div>
          <VolverButton to={Routes.admPanelInstitutions} />
        </Container>
      </CargandoScreen>
    </div>
  );
}

export default ADMInstitutionView;
