import React, { ReactElement, useContext } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import "twin.macro";
import { AppContext } from "../../../dispatcher";
import useRESIESDates from "../../../hooks/useRESIESDates";
import { InstitutionalResponse } from "../../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../../utils/fetchAPI";

type LinkInput = {
  link: string;
};

/**
 * Componente que muestras los links que se han ingresado como evidencia.
 *
 * @param props props del componente
 * @param props.response response institucional de cual se esta viendo la evidencia
 * @param props.setUploadFile setter para indicar que se subio evidencia.
 * @param props.deleteEvidence función que elimina una evidencia.
 */
function InstitutionalLinksColumn(props: {
  response: InstitutionalResponse;
  setUploadFile: React.Dispatch<React.SetStateAction<number>>;
  deleteEvidence: (e: React.MouseEvent<HTMLButtonElement>) => void;
}): ReactElement {
  const { response, setUploadFile, deleteEvidence } = props;
  const { register, handleSubmit, formState, reset } = useForm<LinkInput>();
  const [appState] = useContext(AppContext);
  const { responseId } = useParams<{ responseId: string }>();
  const [, currentProcess, currentInstitutionProcess] = useRESIESDates();

  const getActualCurrentProcess = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess.current_process;
    }

    return currentProcess;
  };

  /**
   * Funcion que maneja el envio del link al backend.
   *
   * @param link link ingresado
   */
  function onSubmitLink(link: LinkInput) {
    if (!appState.token) return;
    fetchAPI<APIActions["addInstitutionalLink"]>(
      APIRoutes.addInstitutionalLink,
      {
        method: "POST",
        body: {
          response_variable_id: parseInt(responseId, 10),
          link_or_text: link.link,
        },
      },
      appState.token
    ).then((resp) => {
      setUploadFile(resp.id);
      reset({ link: "" });
    });
  }
  const getClickableLink = (link: string) => {
    if (link.startsWith("http://") || link.startsWith("https://")) {
      return link;
    }
    return `http://${link}`;
  };

  return (
    <div tw="w-8/12">
      <div tw="bg-resies_blue2 font-sans text-barra font-bold text-ghostwhite px-6">
        <p tw="flex justify-center">Links</p>
      </div>
      <div tw="bg-resies_lightpurple font-sans text-body font-normal text-black px-6 py-2 flex flex-col">
        {(appState.userType === "MDI" || appState.userType === "RDI") &&
        (getActualCurrentProcess()?.name === "Llenado de Indicadores" ||
          getActualCurrentProcess()?.name === "Rectificación de Objetados") ? (
          <form onSubmit={handleSubmit(onSubmitLink)}>
            <div tw="flex flex-row justify-between">
              <input
                type="text"
                tw="w-9/12 py-2 px-2"
                placeholder="Ingrese su link"
                {...register("link", { required: true })}
              />
              <button
                type="submit"
                tw="bg-resies_green text-ghostwhite text-body font-normal py-3 rounded-md w-2/12"
              >
                Guardar
              </button>
            </div>
          </form>
        ) : (
          <></>
        )}

        {formState.errors.link && <span>Debe ingresar un link</span>}
        <div tw="bg-ghostwhite font-sans text-barra font-bold text-ghostwhite px-2 py-2 mt-2 h-32 overflow-auto">
          {response.evidences
            ?.filter((evidence) => evidence.evidence_type === "text")
            .map((evidence) => (
              <div tw="flex flex-row" key={evidence.id}>
                {(appState.userType === "MDI" || appState.userType === "RDI") &&
                  (getActualCurrentProcess()?.name ===
                    "Llenado de Indicadores" ||
                    getActualCurrentProcess()?.name ===
                      "Rectificación de Objetados") && (
                    <button
                      type="button"
                      tw="w-4"
                      name={evidence.id.toString()}
                      onClick={(e) => deleteEvidence(e)}
                    >
                      <img src="/cancel.png" tw="" alt="cancel" />
                    </button>
                  )}
                <a
                  tw="text-black text-body font-normal truncate w-full"
                  href={getClickableLink(evidence.evidence_link_or_text)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {evidence.evidence_link_or_text}
                </a>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default InstitutionalLinksColumn;
