import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import Routes from "../../routers/routes";

/**
 * Fila en la tabla de apelaciones por estado.
 *
 * @param props react props.
 * @param props.indicatorSlug Slug del indicador al que pertenece la variable.
 * @param props.responseId Id de la responseVariable
 * @param props.variableId ID de la variable
 * @param props.institutionId ID de la institución que apela.
 */
function AppealsRow(props: {
  indicatorSlug: string;
  responseId: number;
  variableId: number;
  institutionId: string;
}): ReactElement {
  const { indicatorSlug, responseId, variableId, institutionId } = props;
  return (
    <tr tw="flex w-full my-1">
      <td tw="text-header2 font-semibold text-very_darkgray text-left pl-10 py-1 w-10/12">
        {indicatorSlug} &gt; Variable {variableId}
      </td>
      <td tw="text-center p-2 w-2/12">
        <Link
          to={Routes.singleAppealView
            .replace(":responseId", responseId.toString())
            .replace(":variableId", variableId.toString())
            .replace(":institutionId", institutionId)}
        >
          <button
            type="button"
            tw="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md"
          >
            <p tw="font-bold text-base text-ghostwhite mx-3">Ver</p>
          </button>
        </Link>
      </td>
    </tr>
  );
}

export default AppealsRow;
