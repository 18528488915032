import React, {
  ReactElement,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import "twin.macro";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Container from "../../components/common/Container";
import VolverButton from "../../components/common/VolverButton";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import { User } from "../../types/backendTypes";
import { AppContext } from "../../dispatcher";
import CargandoScreen from "../../components/common/CargandoScreen";
import Routes from "../../routers/routes";
import UserRdiAssignRow from "../../components/admin/UserRdiAssignRow";
import PageTitleSubtitle from "../../components/common/PageTitleSubtitle";
import SearchBar from "../../components/common/SearchBar";

/**
 * La página que muestra otras instituciones al RDI
 */
function ADMInstitutionRDIAssignment(): ReactElement {
  const [state] = useContext(AppContext);
  const [users, setUsers] = useState<Array<User>>([]);
  const [institutionName, setInstitutionName] = useState("");
  const { institutionId } = useParams<{ institutionId: string }>();
  const [ready, setReady] = useState(false);
  const [searchText, setSearchText] = useState("");
  useLayoutEffect(() => {
    if (!state.token) return;
    fetchAPI<APIActions["fetchInstitutionDetails"]>(
      APIRoutes.fetchInstitutionDetails,
      { method: "GET", routeParams: { institution_id: institutionId } },
      state.token
    )
      .then((institution) => {
        setUsers(
          institution.members
            .filter(
              (user) => user.user_type === "MDI" || user.user_type === "RDI"
            )
            .sort((user1, user2) => user1.id - user2.id)
        );
        setInstitutionName(institution.institution.name);
        setReady(true);
      })
      .catch(() => {}); // TODO
  }, [institutionId, state.token]);

  /**
   * handler de cambio de texto
   *
   * @param e event
   */
  function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
  }
  return (
    <CargandoScreen ready={ready}>
      <Container>
        <PageTitleSubtitle
          title={institutionName}
          subtitle="Asignar responsable de institución"
        />
        <div tw="flex flex-row justify-between">
          <div>
            <Link
              to={Routes.inviteRDI.replace(":institutionId", institutionId)}
            >
              <button
                type="button"
                tw="text-barra bg-resies_purple text-ghostwhite py-2 px-4 font-bold rounded-md"
              >
                Invitar a una persona
              </button>
            </Link>
          </div>
          <div tw="px-10">
            <SearchBar
              searchText={searchText}
              onChangeHandler={onChangeHandler}
            />
          </div>
        </div>
        <div id="RDIs-to-assign">
          <div tw="bg-resies_lightpurple my-3 text-body text-center align-middle">
            <div tw="grid grid-cols-5">
              <div tw="text-barra font-bold bg-resies_blue1 text-ghostwhite text-center">
                Nombre
              </div>
              <div tw="text-barra font-bold bg-resies_blue1 text-ghostwhite text-center">
                Email
              </div>
              <div tw="text-barra font-bold bg-resies_blue1 text-ghostwhite text-center">
                Responsabilidades
              </div>
              <div tw="text-barra font-bold bg-resies_blue1 text-ghostwhite col-span-2 text-center">
                Acciones
              </div>
            </div>
            <div tw="overflow-y-scroll h-60">
              <div tw="grid grid-cols-5">
                {users
                  .filter((user) =>
                    `${user.first_name} ${user.last_name}`
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  )
                  .map((user) => (
                    <UserRdiAssignRow
                      key={user.id}
                      user={user}
                      setUsers={setUsers}
                      institutionId={institutionId}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
        <VolverButton
          to={Routes.admInstitutionsView.replace(
            ":institutionId",
            institutionId
          )}
        />
      </Container>
    </CargandoScreen>
  );
}

export default ADMInstitutionRDIAssignment;
