import React, { ReactElement } from "react";
import "twin.macro";
import { Institution, User } from "../../types/backendTypes";
import PaginatedListNavigator from "../common/PaginatedListNavigator";
import UsersListRow from "./UsersListRow";

const headerStyle = { minWidth: "100px" };

/**
 * lista de usuarios.
 *
 * @param props react props
 * @param props.users usuarios
 * @param props.currentPage pagina actual
 * @param props.setCurrentPage setear pagina actual
 * @param props.maxPages maximo de paginas
 * @param props.institutionList La lista de instituciones existentes
 * @param props.reloadUsers función para recargar los usuarios
 */
function UsersList(props: {
  users: User[];
  institutionList: Institution[];
  currentPage: number;
  setCurrentPage: (arg0: number) => void;
  maxPages: number;
  reloadUsers: () => void;
}): ReactElement {
  const {
    users,
    currentPage,
    setCurrentPage,
    maxPages,
    institutionList,
    reloadUsers,
  } = props;

  return (
    <div>
      <div tw="min-h-625">
        <table tw="w-full">
          <thead>
            <tr tw="text-barra text-ghostwhite font-bold bg-resies_blue1 text-center w-full">
              <th style={headerStyle}>Nombre</th>
              <th style={headerStyle}>Mail</th>
              <th style={headerStyle}>Institución</th>
              <th style={headerStyle}>Rol</th>
              <th colSpan={2} style={headerStyle}>
                Acciones
              </th>
            </tr>
          </thead>
          <tbody tw="text-center text-body">
            {users.map((user) => (
              <UsersListRow
                user={user}
                institutionList={institutionList}
                reloadUsers={reloadUsers}
              />
            ))}
          </tbody>
        </table>
      </div>
      <PaginatedListNavigator
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        maxPages={maxPages}
      />
    </div>
  );
}

export default UsersList;
