import React, { useRef, ReactElement, ChangeEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "twin.macro";

/**
 * Un par label-dropdown mostrado en la vista de editar información de institución del RDI
 *
 * @param props react props.
 * @param props.options Las opciones del dropdown
 * @param props.selectedValue valor seleccionado
 * @param props.selectedValueChangeHandler handler
 */
function StyledSelectElement(props: {
  options: Array<{ value: string | number; text: string }>;
  selectedValue: string;
  selectedValueChangeHandler: (e: ChangeEvent<HTMLSelectElement>) => void;
}): ReactElement {
  const { options, selectedValue, selectedValueChangeHandler } = props;

  const selectRef = useRef<HTMLSelectElement>(null);
  return (
    <div tw="h-auto inline-block bg-resies_lightpurple text-very_darkgray rounded-md w-full">
      <div tw="relative h-full w-full">
        <button
          type="button"
          tw="absolute right-0 inline-block h-full py-2 pr-2 cursor-default focus:outline-none"
        >
          <div tw="flex flex-col justify-center content-center h-full appearance-none">
            <FontAwesomeIcon icon={faChevronDown} color="#5b5b5b" />
          </div>
        </button>
        <select
          value={selectedValue}
          onChange={selectedValueChangeHandler}
          style={{ background: "transparent" }}
          tw="appearance-none w-auto relative pr-6 pl-2 w-full h-full py-2"
          ref={selectRef}
        >
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default StyledSelectElement;
