import React, { ReactElement } from "react";

/**
 * Foto de la landing page principal.
 */
function AnonLandingPhoto(): ReactElement {
  return (
    <div tw="w-full h-auto">
      <img
        src="/anon_landing_photo.jpg"
        tw="h-auto object-fill"
        alt="anon_landing_photo"
        style={{ width: "100%" }}
      />
    </div>
  );
}
export default AnonLandingPhoto;
