import React, { ReactElement } from "react";
import "twin.macro";
import { InstitutionVariables, User } from "../../../types/backendTypes";
import CargandoScreen from "../../common/CargandoScreen";

/**
 * Lista que muestra los variables seleccionados.
 *
 * @param props react props.
 * @param props.selectedVariables Son los variables seleccionados.
 * @param props.setSelectedVariables SetState de los variables seleccionados.
 * @param props.variablesReady Indica si ya se procesó una request de fetch de variables
 * @param props.selectedUser usuario seleccionado
 * @param props.userSelectedVariables variables seleccionados
 * @param props.setUserSelectedVariables settear variables seleccionados del usuario
 */
function SelectedInstitutionVariables(props: {
  selectedVariables: InstitutionVariables[];
  variablesReady: boolean;
  setSelectedVariables: React.Dispatch<
    React.SetStateAction<InstitutionVariables[]>
  >;
  selectedUser: User | undefined;
  userSelectedVariables: Record<number, InstitutionVariables[]>;
  setUserSelectedVariables: React.Dispatch<
    React.SetStateAction<Record<number, InstitutionVariables[]>>
  >;
}): ReactElement {
  const {
    selectedVariables,
    setSelectedVariables,
    variablesReady,
    selectedUser,
    userSelectedVariables,
    setUserSelectedVariables,
  } = props;

  /**
   * Funcion que maneja la des-seleccion de indicadores
   *
   * @param vari variable que se des-seleccionara.
   */
  function onClickHandler(vari: InstitutionVariables) {
    const copy = [...selectedVariables];
    const index = copy.findIndex((vari2) => vari2.id === vari.id);
    const userVariables = { ...userSelectedVariables };
    if (index !== -1) {
      copy.splice(index, 1);
      setSelectedVariables(copy);
    } else {
      copy.push(vari);
      setSelectedVariables(copy);
    }
    if (selectedUser) {
      userVariables[selectedUser.id] = [...copy];
      setUserSelectedVariables({ ...userVariables });
    }
  }

  return (
    <div tw="col-span-8">
      <div tw="bg-resies_blue1 font-sans text-header2 font-semibold text-resies_lightpurple px-6">
        Variables Asignadas
      </div>
      <div tw="flex flex-col bg-ghostwhite h-64 overflow-y-auto pl-5 py-3 border border-black">
        <CargandoScreen ready={variablesReady}>
          {selectedVariables.map((variable) => (
            <div tw="flex flex-row" key={variable.id}>
              <button
                type="button"
                tw="mr-2"
                onClick={() => onClickHandler(variable)}
                data-testid={`selected${variable.id}`}
              >
                <img src="/cancel.png" alt="cancel" />
              </button>
              <p>
                {variable.indicator_slug}: {variable.description}
              </p>
            </div>
          ))}
        </CargandoScreen>
      </div>
    </div>
  );
}

export default SelectedInstitutionVariables;
