import React, { ReactElement } from "react";
import "twin.macro";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/**
 * Fila en la tabla de plazos de los procesos de auditoría.
 *
 * @param props react props.
 * @param props.processName Nombre del proceso o evento.
 * @param props.finalDate fecha final
 * @param props.initDate fecha inicial
 */
function CommonDeadlinesRow(props: {
  processName: string;
  initDate: string;
  finalDate: string;
}): ReactElement {
  const { processName, initDate, finalDate } = props;
  return (
    <tr tw="flex w-full bg-resies_lightgreen my-3 justify-between">
      <td tw="text-header2 font-semibold text-left pl-10 py-2 w-4/12">
        {processName}
      </td>
      <td tw="text-center p-2 w-3/12">
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={new Date(initDate)}
          onChange={() => {}}
          selectsStart
          startDate={new Date(initDate)}
          endDate={new Date(finalDate)}
          disabled
          tw="w-full"
        />
      </td>
      <td tw="text-center p-2 w-3/12">
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={new Date(finalDate)}
          onChange={() => {}}
          selectsEnd
          startDate={new Date(initDate)}
          endDate={new Date(finalDate)}
          minDate={new Date(initDate)}
          disabled
          tw="w-full"
        />
      </td>
    </tr>
  );
}

export default CommonDeadlinesRow;
