import React, { Fragment, ReactElement, useContext, useState } from "react";
import "twin.macro";
import LoadingFormButton from "../common/LoadingFormButton";
import { AppContext } from "../../dispatcher";
import { User } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * fila de asignacion de usuario por RDI
 *
 * @param props react props
 * @param props.user usuario
 * @param props.institutionId id de institucion
 * @param props.setUsers setear usuarios
 */
function UserRdiAssignRow(props: {
  user: User;
  institutionId: string;
  setUsers: (users: User[]) => void;
}): ReactElement {
  const [state] = useContext(AppContext);
  const { user, setUsers, institutionId } = props;
  const [loading, setLoading] = useState(false);

  /**
   * asignar rol
   */
  function assignRole() {
    if (state.token) {
      setLoading(true);
      const role = user.user_type === "RDI" ? "MDI" : "RDI";
      fetchAPI<APIActions["assignRole"]>(
        APIRoutes.assignRole,
        {
          method: "POST",
          routeParams: {
            userId: user.id.toString(),
          },
          body: { role },
        },
        state.token
      )
        .then(() => {
          if (!state.token) return;
          fetchAPI<APIActions["fetchInstitutionDetails"]>(
            APIRoutes.fetchInstitutionDetails,
            { method: "GET", routeParams: { institution_id: institutionId } },
            state.token
          )
            .then((institution) => {
              setUsers(
                institution.members
                  .filter(
                    (nuser) =>
                      nuser.user_type === "MDI" || nuser.user_type === "RDI"
                  )
                  .sort((user1, user2) => user1.id - user2.id)
              );
              setLoading(false);
            })
            .catch(() => {}); // TODO
        })
        .catch(() => {}); // TODO;
    }
  }
  return (
    <>
      <div tw="py-1" data-testid={user.id}>
        {user.first_name} {user.last_name}
      </div>
      <div tw="py-1">{user.email}</div>
      <div tw="py-1">
        {(() => {
          if (user.user_type === "MDI") {
            if (user.mdiuser?.reviewable_indicators.length) {
              return `Asignado a ${user.mdiuser?.reviewable_indicators.length} indicadores`;
            }
            return "No asignado(a)";
          }
          if (user.user_type === "RDI") {
            return "Actual RDI";
          }
          return "Error";
        })()}
      </div>
      <div tw="py-1">
        <button
          type="button"
          tw="px-4 text-ghostwhite rounded-md font-bold bg-resies_blue1"
        >
          Ver Perfil
        </button>
      </div>
      <div tw="py-1">
        <div
          role="button"
          onClick={assignRole}
          tabIndex={0}
          onKeyDown={() => {}} // solo por un error de eslint
          data-testid={`button-${user.id}`}
        >
          <LoadingFormButton
            loading={loading}
            label={
              user.user_type === "RDI" ? "Desasignar RDI" : "Asignar a ser RDI"
            }
            className={`px-4 text-ghostwhite rounded-md font-bold ${
              user.user_type === "RDI" ? "bg-darkred" : "bg-resies_blue1"
            }`}
          />
        </div>
      </div>
    </>
  );
}

export default UserRdiAssignRow;
