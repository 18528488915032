import { useEffect, useState } from "react";
import { Institution } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * Un hook para buscar las instituciones en la api.
 *
 * @param pageSize size de pagina
 * @param apiKey token
 */
function useInstitutions(
  pageSize: number,
  apiKey: string | null
): [Institution[], number, number, (arg0: number) => void, boolean] {
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [ready, setReady] = useState(false);
  const [visibleInstitutions, setVisibleInstitutions] = useState<
    Array<Institution>
  >([]);
  const [institutions, setInstitutions] = useState<Array<Institution>>([]);
  useEffect(() => {
    if (apiKey) {
      fetchAPI<APIActions["fetchInstitutions"]>(
        APIRoutes.fetchInstitutions,
        {
          method: "GET",
        },
        apiKey
      )
        .then((ins) => {
          setInstitutions(ins);
          setMaxPages(Math.ceil(ins.length / pageSize));
          setReady(true);
        })
        .catch(() => {}); // TODO
    }
  }, [apiKey, pageSize]);
  useEffect(() => {
    setVisibleInstitutions(
      institutions.slice(
        pageSize * (pageNumber - 1),
        Math.min(pageSize * pageNumber, institutions.length)
      )
    );
  }, [pageNumber, institutions, pageSize]);
  return [visibleInstitutions, maxPages, pageNumber, setPageNumber, ready];
}

export default useInstitutions;
