import { useCallback, useContext, useEffect, useState } from "react";
import fetchAPI, { APIActions, APIRoutes } from "../utils/fetchAPI";
import { AppContext, AppState, Dispatcher } from "../dispatcher";
import { User } from "../types/backendTypes";

const defaultUser: User = {
  id: 0,
  first_name: "",
  photo_url: "",
  last_name: "",
  description: "",
  email: "",
  phone_number: "",
  user_type: "AUD",
  mdiuser: null,
  rdiuser: null,
  auduser: null,
  admuser: null,
};

/**
 * Custom hook para buscar un auditor en la base de datos como usuario ADM.
 *
 * @param setReady state para decir "ya tengo los datos del auditor"
 * @param userId id de usuario
 */
function useUser(
  setReady: (ready: boolean) => void,
  userId: string | null
): [User, () => void] {
  const [user, setUser] = useState(defaultUser);
  const [appState]: [AppState, Dispatcher] = useContext(AppContext);
  const reloadResponses = useCallback(() => {
    if (appState.token && userId) {
      fetchAPI<APIActions["userProfile"]>(
        APIRoutes.userProfile,
        { method: "GET", routeParams: { user_id: userId } },
        appState.token
      ).then((userr) => {
        setUser(userr);
        setReady(true);
      });
    }
  }, [appState.token, userId, setReady]);
  useEffect(() => {
    reloadResponses();
  }, [reloadResponses]);
  return [user, reloadResponses];
}

export default useUser;
