import React, { ReactElement, useContext, useState } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import LoadingFormButton from "../../components/common/LoadingFormButton";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import { User } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * Fila de la tabla de auditores para asignar desde la institución
 *
 * @param props props
 * @param props.aud usuario auditor
 * @param props.institutionId Id de la institución visitada
 * @param props.setAuds setear auditores
 */
function ADMInstitutionAUDAssignmentRow(props: {
  aud: User;
  institutionId: string;
  setAuds: (users: User[]) => void;
}): ReactElement {
  const { aud, institutionId, setAuds } = props;
  const [state] = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  /**
   * Asigna auditor
   */
  function assignAud() {
    if (state.token) {
      setLoading(true);
      fetchAPI<APIActions["assignInstitution"]>(
        APIRoutes.assignInstitution,
        {
          method: "POST",
          routeParams: {
            user_id: aud.id.toString(),
            institution_id: institutionId,
          },
        },
        state.token
      )
        .then(() => {
          if (!state.token) return;
          fetchAPI<APIActions["fetchUsers"]>(
            APIRoutes.fetchUsers,
            { method: "GET" },
            state.token
          ).then((details) => {
            setAuds(details.filter((user) => user.user_type === "AUD"));
            setLoading(false);
          });
        })
        .catch(() => {}); // TODO;
    }
  }
  /**
   * Desasigna auditor
   */
  function unassignAud() {
    if (state.token) {
      setLoading(true);
      fetchAPI<APIActions["unassignInstitution"]>(
        APIRoutes.unassignInstitution,
        {
          method: "POST",
          routeParams: {
            institution_id: institutionId,
          },
        },
        state.token
      )
        .then(() => {
          if (!state.token) return;
          fetchAPI<APIActions["fetchUsers"]>(
            APIRoutes.fetchUsers,
            { method: "GET" },
            state.token
          ).then((details) => {
            setAuds(details.filter((user) => user.user_type === "AUD"));
            setLoading(false);
          });
        })
        .catch(() => {}); // TODO;
    }
  }
  return (
    <>
      <div tw="py-1">
        {aud.first_name} {aud.last_name}
      </div>
      <div tw="py-1">{aud.email}</div>
      <div tw="py-1">
        {aud.auduser?.institution_set.length
          ? `Asignado a ${aud.auduser.institution_set.length} instituciones`
          : "No asignado(a)"}
      </div>
      <div tw="flex py-1">
        <Link to={Routes.audProfile.replace(":audId", aud.id.toString())}>
          <button
            type="button"
            tw="px-4 bg-resies_blue1 mr-2 text-ghostwhite rounded-md font-bold"
          >
            Ver perfil
          </button>
        </Link>
        <div
          role="button"
          onClick={
            aud.auduser?.institution_set
              .map((i) => i.id)
              .includes(parseInt(institutionId, 10))
              ? unassignAud
              : assignAud
          }
          tabIndex={0}
          onKeyDown={() => {}} // solo por un error de eslint
        >
          <LoadingFormButton
            loading={loading}
            label={
              aud.auduser?.institution_set
                .map((i) => i.id)
                .includes(parseInt(institutionId, 10))
                ? "Desasignar auditor"
                : "Asignar a ser auditor"
            }
            className={`px-4 text-ghostwhite rounded-md font-bold ${
              aud.auduser?.institution_set
                .map((i) => i.id)
                .includes(parseInt(institutionId, 10))
                ? "bg-darkred"
                : "bg-resies_blue1"
            }`}
          />
        </div>
      </div>
    </>
  );
}

export default ADMInstitutionAUDAssignmentRow;
