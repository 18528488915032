import React, { ReactElement } from "react";
import "twin.macro";
import { useParams } from "react-router";
import Container from "../../components/common/Container";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import InsideNavigationBar from "../../components/common/InsideNavigationBar";
import ADMAppealsByInstitutionDisplay from "../../components/admin/AppealsByIntitutionDisplay";

/**
 * Página que muestra las apelaciones de una institución.
 */
function ADMAppealsByinstitutionPage(): ReactElement {
  const { institutionId } = useParams<{ institutionId: string }>();
  return (
    <Container>
      <InsideNavigationBar
        items={[
          { name: "Plazos", href: Routes.auditDeadlines },
          { name: "Portal auditores", href: Routes.auditorPortal },
          { name: "Apelaciones", href: Routes.appeals },
        ]}
        title="Gestión de auditorías"
      />
      <ADMAppealsByInstitutionDisplay institutionId={institutionId} />
      <VolverButton to={Routes.appeals} />
    </Container>
  );
}

export default ADMAppealsByinstitutionPage;
