/* eslint-disable import/no-cycle */
import React, { ReactElement } from "react";
import "twin.macro";
import Container from "../../components/common/Container";
import PageTitle from "../../components/common/PageTitle";
import MyInstitutionColumn from "../../components/common/MyInstitutionColumn";

/**
 * Landing page del Administrador.
 */
function ADMLandingPage(): ReactElement {
  return (
    <Container>
      <PageTitle title="Portal del administrador" />
      <MyInstitutionColumn
        title="Administración"
        institutionName="Campus Sustentable"
        imageUrl="/anon_landing_photo.png"
        logoUrl="/logoNoTitle.png"
        buttons={[
          { text: "Reportes", href: "#" },
          { text: "Instrumentos de evaluación", href: "#" },
          { text: "Información pública", href: "#" },
          { text: "Newsfeed", href: "#" },
        ]}
      />
    </Container>
  );
}

export default ADMLandingPage;
