import { ReactElement, useRef, useState } from "react";
import "twin.macro";
import {
  Process,
  ResiesVersion,
  InstitutionResiesVersion,
} from "../../types/backendTypes";
import CargandoScreen from "./CargandoScreen";
import CommonDeadlinesRow from "./CommonDeadlinesRow";
import { CenteredPopup } from "./utils/Popup";
/**
 * Boton para mostrar las fechas de los procesos de la plataforma.
 *
 * @param props react props
 * @param props.currentProcess proceso actual en la plataforma.
 */
function DatesPopUp(props: {
  resiesVersion: ResiesVersion | InstitutionResiesVersion | undefined;
  currentProcess: Process | undefined;
}): ReactElement {
  const { resiesVersion, currentProcess } = props;
  const ref = useRef<() => void>(null);
  const [ready] = useState(true);

  const printCurrentPhaseName = () => {
    if (!currentProcess) {
      return "Cargando...";
    }

    if (currentProcess.name) {
      if (currentProcess.name !== "Fin proceso RESIES") {
        return `${currentProcess.name} (termina el ${new Date(
          currentProcess.end
        ).toLocaleDateString("es-CL")})`;
      }

      return currentProcess.name;
    }

    return "Ninguna";
  };

  return (
    <>
      <button
        type="button"
        tw="text-resies_lightpurple font-semibold border border-resies_lightpurple rounded-md pl-2 pr-2"
        onClick={() => {
          if (ref.current !== null) ref.current();
        }}
      >
        <div tw="font-bold inline">Fase actual:</div> {printCurrentPhaseName()}
      </button>
      <CenteredPopup width={700} height={500} ref={ref}>
        <div className="bg-resies_lightpurple font-sans text-black rounded-lg popup flex flex-col pb-10 h-full py-10">
          <div tw="flex flex-row justify-start mb-2">
            <p tw="text-resies_blue1 text-header2 font-semibold ml-5">
              Plazos de Resies
            </p>
          </div>
          <CargandoScreen ready={ready}>
            {resiesVersion && resiesVersion.start ? (
              <div tw="">
                <table tw="w-full h-full">
                  <thead tw="bg-resies_blue2 flex text-white w-full">
                    <tr tw="flex w-full h-5">
                      <th tw="text-barra font-bold text-ghostwhite text-center w-4/12 h-5">
                        PROCESO
                      </th>
                      <th tw="text-barra font-bold text-ghostwhite text-center w-4/12 h-5">
                        FECHA INICIO
                      </th>
                      <th tw="text-barra font-bold text-ghostwhite text-center w-4/12 h-5">
                        FECHA TÉRMINO
                      </th>
                    </tr>
                  </thead>
                  <tbody tw="bg-resies_lightpurple">
                    {resiesVersion ? (
                      <>
                        <CommonDeadlinesRow
                          processName="Ciclo Resies"
                          initDate={resiesVersion.start}
                          finalDate={resiesVersion.end}
                        />
                        {resiesVersion.processes.map((process) => (
                          <CommonDeadlinesRow
                            processName={process.name}
                            initDate={process.start}
                            finalDate={process.end}
                            key={process.name}
                          />
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <></>
            )}
          </CargandoScreen>
        </div>
      </CenteredPopup>
    </>
  );
}

export default DatesPopUp;
