import { AppState } from "../../../dispatcher";
import { OdsObjective } from "../../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

function getOdsObjectives(
  appState: AppState,
  setReady: React.Dispatch<React.SetStateAction<boolean>>,
  setOdsObjectives: React.Dispatch<React.SetStateAction<OdsObjective[]>>
): void {
  if (!appState.token) return;
  fetchAPI<APIActions["fetchOdsObjectives"]>(
    APIRoutes.fetchOdsObjectives,
    {
      method: "GET",
    },
    appState.token
  ).then((objectives) => {
    setOdsObjectives(objectives);
    setReady(true);
  });
}

export default getOdsObjectives;
