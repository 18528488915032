import { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import PageTitle from "../../components/common/PageTitle";
import VolverButton from "../../components/common/VolverButton";
import DeadlinesRow from "../../components/admin/DeadlinesRow";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";
import {
  Institution,
  InstitutionResiesVersion,
  ResiesVersion,
} from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * Página perteneciente al RDI para ver y modificar los datos de la institución
 */
function ADMInstitutionResiesVersion(): ReactElement {
  const [appState] = useContext(AppContext);
  const [ready, setReady] = useState(false);
  const { institutionId } = useParams<{ institutionId: string }>();
  const [institution, setInstitution] = useState<Institution>({
    name: "",
    id: 0,
    description: "",
    photo_url: "",
    member_since: "",
    logo_url: "",
  });
  const [institutionVersion, setInstitutionVersion] =
    useState<InstitutionResiesVersion | null>(null);
  const [resiesVersion, setResiesVersion] = useState<ResiesVersion | null>(
    null
  );
  const [errorList, setErrorList] = useState<string[]>([]);
  const [convert, setConvert] = useState<Record<string, string>>({});

  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["getActiveResiesVersionPerInstitution"]>(
      APIRoutes.getActiveVersionPerInstitution,
      { method: "GET", routeParams: { institution_id: institutionId } },
      appState.token
    ).then((version) => {
      setInstitutionVersion(version);
      setConvert({
        "Llenado de Indicadores": "filling",
        Auditoría: "audit",
        "Rectificación de Objetados": "objection_rectification",
        "Revisión de Objetados": "objection_revision",
        "Proceso de Apelación": "appeal",
        "Resultados de Apelación": "appeal_results",
        "Fin proceso RESIES": "finish_stage",
      });
      setInstitution(version.institution);
      setResiesVersion(version.resies_version);
      setReady(true);
    });
  }, [appState.token, institutionId]);

  /**
   * Funcion de cambio de fecha de inicio
   *
   * @param name nombre del proceso
   * @param date fecha de inicio
   */
  function onChangeHandlerStart(name: string, date: Date) {
    if (institutionVersion) {
      const copy = { ...institutionVersion };
      const process = copy.processes.filter((pros) => pros.name === name)[0];
      process.start = new Date(date.setHours(0, 0, 0, 0)).toISOString();
      if (convert[process.name] === "filling") {
        copy.start = process.start;
      }
      setInstitutionVersion(copy);
    }
  }

  /**
   * Funcion de cambio de fecha de fin
   *
   * @param name nombre del proceso
   * @param date fecha de inicio
   */
  function onChangeHandlerEnd(name: string, date: Date) {
    if (institutionVersion) {
      const copy = { ...institutionVersion };
      const process = copy.processes.filter((pros) => pros.name === name)[0];
      process.end = new Date(date.setHours(23, 59, 59, 999)).toISOString();
      if (convert[process.name] === "finish_stage") {
        copy.end = process.end;
      }
      setInstitutionVersion(copy);
    }
  }

  /**
   * funcion que guarda el cambio de las fechas
   */
  function onSaveProcess() {
    if (!appState.token || errorList.length || institutionVersion == null)
      return;
    setReady(false);
    fetchAPI<APIActions["editActiveInstitutionVersion"]>(
      APIRoutes.editActiveInstitutionVersion,
      {
        method: "PUT",
        routeParams: { institution_id: institution.id.toString() },
        body: {
          version_name: institutionVersion.version_name,
          start: institutionVersion.start,
          end: institutionVersion.end,
        },
      },
      appState.token
    ).then(() => {
      const processList = institutionVersion.processes.map((pro) => {
        return {
          process_string: convert[pro.name],
          start: pro.start,
          end: pro.end,
        };
      });
      if (appState.token) {
        fetchAPI<APIActions["editActiveVersionPerInstitutionProcess"]>(
          APIRoutes.editActiveVersionPerInstitutionProcess,
          {
            method: "PUT",
            routeParams: { institution_id: institution.id.toString() },
            body: processList,
          },
          appState.token
        ).then(() => {
          setErrorList([]);
          setReady(true);
          window.location.reload();
        });
      }
    });
  }

  function getResiesGlobalProcessDates(processName: string) {
    const process = institutionVersion?.resies_version.processes.filter(
      (process) => process.name === processName
    )[0];

    if (!process) {
      return [];
    }
    return [process.start, process.end];
  }

  return (
    <>
      <Container>
        <PageTitle
          title={`Proceso de auditoría de ${institution.description}`}
        />

        <CargandoScreen ready={ready}>
          {institutionVersion && resiesVersion && institutionVersion.start ? (
            <div tw="mb-10">
              <div tw="flex justify-between">
                <button
                  type="button"
                  tw="flex bg-resies_purple rounded-sm max-h-12 mb-2 p-1 disabled:opacity-50 ml-20"
                  onClick={onSaveProcess}
                  disabled={errorList.length !== 0}
                >
                  <img src="/save.png" alt="save" tw="mr-2 ml-2" />
                  <p tw="font-bold text-base text-ghostwhite mr-3">Guardar</p>
                </button>
              </div>
              <table tw="w-full">
                <thead tw="bg-resies_blue2 flex text-white w-full">
                  <tr tw="flex w-full h-5">
                    <th tw="text-barra font-bold text-ghostwhite text-center w-4/12 h-5">
                      PROCESO
                    </th>
                    <th tw="text-barra font-bold text-ghostwhite text-center w-4/12 h-5">
                      FECHA INICIO
                    </th>
                    <th tw="text-barra font-bold text-ghostwhite text-center w-4/12 h-5">
                      FECHA TÉRMINO
                    </th>
                  </tr>
                </thead>
                <tbody tw="bg-resies_lightpurple">
                  {institutionVersion ? (
                    <>
                      <DeadlinesRow
                        processName="Ciclo Resies"
                        initDate={institutionVersion.start}
                        finalDate={institutionVersion.end}
                        onChangeHandlerStart={onChangeHandlerStart}
                        onChangeHandlerEnd={onChangeHandlerEnd}
                        disabled
                        globalDates={[resiesVersion.start, resiesVersion.end]}
                      />
                      {institutionVersion.processes.map((process) => (
                        <DeadlinesRow
                          processName={process.name}
                          initDate={process.start}
                          finalDate={process.end}
                          globalDates={getResiesGlobalProcessDates(
                            process.name
                          )}
                          onChangeHandlerStart={onChangeHandlerStart}
                          onChangeHandlerEnd={onChangeHandlerEnd}
                          key={process.name}
                        />
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
              {errorList.map((msg) => (
                <p key={msg} tw="text-darkred">
                  {msg}
                </p>
              ))}
            </div>
          ) : (
            <></>
          )}
          <VolverButton
            to={Routes.admInstitutionsView.replace(
              ":institutionId",
              institutionId
            )}
          />
        </CargandoScreen>
      </Container>
    </>
  );
}

export default ADMInstitutionResiesVersion;
