import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import { Redirect, useHistory, useParams } from "react-router";
import { useForm } from "react-hook-form";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import PageTitle from "../../components/common/PageTitle";
import LoadingFormButton from "../../components/common/LoadingFormButton";
import setLoginInfo from "../../utils/setLoginInfo";
import { AppContext, AppState, Dispatcher } from "../../dispatcher";
import Routes from "../../routers/routes";

type PasswordInput = {
  password: string;
  confirmpassword: string;
};

/**
 * Pagina de recuperación de contraseña.
 */
function AnonRecoverPage(): ReactElement {
  const [appState, appDispatch]: [AppState, Dispatcher] =
    useContext(AppContext);
  const { token } = useParams<{ token: string }>();
  const [ready, setReady] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordInput>();
  const history = useHistory();

  /**
   * handler de registro al dar contrasena
   *
   * @param data input de contrasena
   */
  function onSubmit(data: PasswordInput) {
    setLoading(true);
    fetchAPI<APIActions["recoverPassword"]>(APIRoutes.recoverPassword, {
      method: "POST",
      routeParams: {
        recovery_token: token,
      },
      body: {
        password: data.password,
      },
    })
      .then((user) => {
        setLoginInfo(
          user,
          setError,
          appDispatch,
          history as unknown as History
        );
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }
  useEffect(() => {
    fetchAPI<APIActions["getRecoveryMetaData"]>(APIRoutes.getRecoveryMetaData, {
      method: "GET",
      routeParams: { recovery_token: token },
    })
      .then((user) => {
        setFirstName(user.first_name);
        setReady(true);
      })
      .catch(() => {}); // TODO
  }, [token]);
  return (
    <CargandoScreen ready={ready}>
      {appState.token !== null ? <Redirect to={Routes.landing} /> : null}
      <Container>
        <PageTitle title="Regístrate" />
        <div tw="h-96 flex flex-wrap flex-col content-center justify-center">
          <div tw="inline-block w-auto">
            {firstName}, ingresa tu nueva contraseña:
          </div>
          <form tw="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="password"
              placeholder="Ingresa una contraseña"
              tw="block my-1 rounded px-2 py-1 border-darkgray border"
              {...register("password", { required: true })}
            />
            <input
              type="password"
              placeholder="Repite la contraseña"
              tw="block my-1 rounded px-2 py-1 border-darkgray border"
              {...register("confirmpassword", {
                required: true,
                validate: (value) => value === watch("password"),
              })}
            />
            {errors.confirmpassword && <div>Las contraseñas no coinciden</div>}
            <div tw="flex flex-row w-full justify-center mt-4">
              <LoadingFormButton
                loading={loading}
                label="Enviar"
                className="bg-resies_purple text-ghostwhite text-barra font-bold px-6 py-2 rounded-2xl"
              />
            </div>
          </form>
          {error && (
            <div tw="block w-auto">
              Ha ocurrido un error. Por favor intenta de nuevo
            </div>
          )}
        </div>
      </Container>
    </CargandoScreen>
  );
}

export default AnonRecoverPage;
