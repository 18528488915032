import React, { ReactElement } from "react";
import "twin.macro";
import InsideNavigationBar from "../../components/common/InsideNavigationBar";
import Routes from "../../routers/routes";

/**
 * La barra de navegación de la vista de evidencias aceptadas, rechazadas, etc del RDI
 */
function RDIEvidenceNavigationBar(): ReactElement {
  return (
    <InsideNavigationBar
      items={[
        { name: "Evidencia objetada", href: Routes.objected },
        { name: "Evidencia rechazada", href: Routes.rejected },
        { name: "Evidencia en apelación", href: Routes.appeals },
      ]}
      title="Procesos de auditoría"
    />
  );
}

export default RDIEvidenceNavigationBar;
