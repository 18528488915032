import React, { ReactElement, useContext, useState } from "react";
import "twin.macro";
import { Redirect } from "react-router";
import { useForm } from "react-hook-form";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import Container from "../../components/common/Container";
import PageTitle from "../../components/common/PageTitle";
import LoadingFormButton from "../../components/common/LoadingFormButton";
import { AppContext } from "../../dispatcher";
import Routes from "../../routers/routes";

type PasswordInput = {
  email: string;
};

/**
 * Pagina de olvidar contraseña.
 */
function AnonForgotPassword(): ReactElement {
  const [appState] = useContext(AppContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sended, setSended] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordInput>();

  /**
   * handler para cuando se envia el mail para recuperar contraseña
   *
   * @param data input de email
   */
  function onSubmit(data: PasswordInput) {
    setLoading(true);
    fetchAPI<APIActions["sendRecoveryMail"]>(APIRoutes.sendRecoveryMail, {
      method: "POST",
      body: {
        email: data.email,
      },
    })
      .then((response) => {
        if (response.detail === "Not Found") {
          setError(true);
          setSended(false);
          setLoading(false);
        } else {
          setError(false);
          setSended(true);
          setLoading(false);
        }
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }
  return (
    <>
      {appState.token !== null ? <Redirect to={Routes.landing} /> : null}
      <Container>
        <PageTitle title="Recupera tu cuenta" />
        <div tw="h-96 flex flex-wrap flex-col content-center justify-center">
          <div tw="inline-block w-auto">
            Ingresa tu email para recuperar tu contraseña.
          </div>
          <form tw="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="email"
              placeholder="Ingresa tu email"
              tw="block my-1 rounded px-2 py-1 border-darkgray border"
              {...register("email", { required: true })}
            />
            {errors.email && <div>El formato de email no es válido</div>}
            <div tw="flex flex-row w-full justify-center mt-4">
              <LoadingFormButton
                loading={loading}
                label="Enviar"
                className="bg-resies_purple text-ghostwhite text-barra font-bold px-6 py-2 rounded-2xl"
              />
            </div>
          </form>
          {error && (
            <div tw="block w-auto">Este email no existe en la plataforma</div>
          )}
          {sended && (
            <div tw="block w-auto">
              Mail de recuperación correctamente enviado
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

export default AnonForgotPassword;
