import React, { ReactElement } from "react";
import ReactLoading from "react-loading";
import "twin.macro";
/**
 * Un botón que se queda cargando
 *
 * @param props react props.
 * @param props.loading si el botón está cargando o no
 * @param props.label El label del botón
 * @param props.className la clase de estilo del botón
 * @param props.onClick El handler de click del botón
 */
function LoadingFormButton(props: {
  loading: boolean;
  label: string;
  className: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}): ReactElement {
  const { loading, label, className, onClick } = props;
  return (
    <>
      {loading ? (
        <button
          type="submit"
          className={className}
          tw="cursor-wait flex flex-row content-center"
          disabled
        >
          <ReactLoading
            type="spin"
            color="white"
            height={20}
            width={20}
            tw="ml-auto mr-auto"
          />
        </button>
      ) : (
        <button
          type="submit"
          className={className}
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
          }}
        >
          {label}
        </button>
      )}
    </>
  );
}

export default LoadingFormButton;
