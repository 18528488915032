import React, { ReactElement, useEffect } from "react";
import "twin.macro";
import { InstitutionVariables, User } from "../../../types/backendTypes";

/**
 * Checkbox de una variable
 *
 * @param props react props.
 * @param props.variable variable con el que se creara un checkbox.
 * @param props.selectedVariables Son las variables seleccionados.
 * @param props.setSelectedVariables SetState de las variables seleccionados.
 * @param props.selectedUser usuario seleccionado
 * @param props.userSelectedVariables variables seleccionados
 * @param props.setUserSelectedVariables setter de variables seleccionados
 * @param props.forCheckAll arreglo de variables institucionales
 */
function InstitutionalVariableList(props: {
  variable: InstitutionVariables;
  selectedVariables: InstitutionVariables[];
  setSelectedVariables: React.Dispatch<
    React.SetStateAction<InstitutionVariables[]>
  >;
  selectedUser: User | undefined;
  userSelectedVariables: Record<number, InstitutionVariables[]>;
  setUserSelectedVariables: React.Dispatch<
    React.SetStateAction<Record<number, InstitutionVariables[]>>
  >;
  forCheckAll: InstitutionVariables[];
}): ReactElement {
  const {
    variable,
    selectedVariables,
    setSelectedVariables,
    selectedUser,
    userSelectedVariables,
    setUserSelectedVariables,
    forCheckAll,
  } = props;

  /**
   * Funcion que maneja el cambio de estado de los variables seleccionados.
   *
   * @param vari variable que fue checkeada.
   */
  function onChange(vari: InstitutionVariables) {
    const copy = [...selectedVariables];
    const index = copy.findIndex((vari2) => vari2.id === vari.id);
    const userVariables = { ...userSelectedVariables };
    if (index !== -1) {
      copy.splice(index, 1);
      setSelectedVariables(copy);
    } else {
      copy.push(vari);
      setSelectedVariables(copy);
    }
    if (selectedUser) {
      userVariables[selectedUser.id] = [...copy];
      setUserSelectedVariables({ ...userVariables });
    }
  }
  /**
   * Funcion que retorna si una variable esta seleccionado.
   *
   * @param vari variable que se vera si esta en la lista de seleccionados.
   * @returns booleano indicando si esta seleccionado o no.
   */
  function selected(vari: InstitutionVariables) {
    return selectedVariables.map((vari2) => vari2.id).includes(vari.id);
  }

  useEffect(() => {
    if (selectedUser && forCheckAll) {
      const listForCheck: InstitutionVariables[] = [];
      forCheckAll.forEach((ind) => {
        const isCheck = selectedVariables
          .map((ind2) => ind2.id)
          .includes(ind.id);
        if (!isCheck) {
          listForCheck.push(ind);
        }
      });
      const userIndicators = { ...userSelectedVariables };
      const indicatorSelectedConc =
        userSelectedVariables[selectedUser.id].concat(listForCheck);
      userIndicators[selectedUser.id] = indicatorSelectedConc;

      setSelectedVariables(indicatorSelectedConc);
      setUserSelectedVariables({ ...userIndicators });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, forCheckAll]);

  return (
    <div>
      <div>
        <input
          type="checkbox"
          name={variable.indicator_slug}
          onChange={() => onChange(variable)}
          checked={selected(variable)}
          tw="font-normal text-body"
          data-testid={variable.id}
        />
        <label htmlFor={variable.indicator_slug}>
          {variable.indicator_slug}: {variable.description}
        </label>
      </div>
    </div>
  );
}

export default InstitutionalVariableList;
