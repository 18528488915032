import React, { ReactElement } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "twin.macro";
import LoginPage from "../pages/anon/LoginPage";
import AnonLandingPage from "../pages/anon/AnonLandingPage";
import AnonLoginNavbar from "../components/anon/AnonLoginNavbar";
import AnonNavBar from "../components/anon/AnonNavbar";
import Footer from "../components/common/Footer";
import Routes from "./routes";
import AnonRegisterPage from "../pages/anon/AnonRegisterPage";
import AnonForgotPassword from "../pages/anon/AnonForgotPassword";
import AnonRecoverPassword from "../pages/anon/AnonRecoverPassword";

/**
 * El router del usuario anónimo
 *
 * Al no estar registrado, este es el router que se muestra.
 */
function AnonRouter(): ReactElement {
  return (
    <Router>
      <AnonLoginNavbar />
      <AnonNavBar />
      <Switch>
        <Route exact path={Routes.login}>
          <LoginPage />
        </Route>
        <Route exact path={Routes.landing}>
          <AnonLandingPage />
        </Route>
        <Route exact path={Routes.register}>
          <AnonRegisterPage />
        </Route>
        <Route exact path={Routes.forgotPassword}>
          <AnonForgotPassword />
        </Route>
        <Route exact path={Routes.recoverPassword}>
          <AnonRecoverPassword />
        </Route>
        <Redirect to={Routes.landing} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default AnonRouter;
