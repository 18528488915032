/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-plusplus */
import {
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import { AppContext } from "../../dispatcher";
import { Institution } from "../../types/backendTypes";
import { HistoricScorePattern, ItemScore } from "../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import useRESIESDates from "../../hooks/useRESIESDates";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useParams } from "react-router-dom";

const RDIHistoricReports = (): ReactElement => {
  const [ready, setReady] = useState(false);
  const [state] = useContext(AppContext);
  const [institution, setInstitution] = useState<Institution>();
  const [institutionScores, setInstitutionScores] = useState<
    HistoricScorePattern[]
  >([]);
  const [downloadExcelText, setDownloadExcelText] = useState(
    "Descargar respuestas históricas"
  );
  const [, , currentInstitutionProcess] = useRESIESDates();
  const { institutionId } = useParams<{ institutionId: string }>();

  type Export = {
    file_link: string;
  };

  useEffect(() => {
    if (!state.token) return;

    if (currentInstitutionProcess) {
      setInstitution(currentInstitutionProcess.institution);
      fetchAPI<APIActions["fetchHistoricInstitutionScores"]>(
        APIRoutes.fetchHistoricInstitutionScores,
        {
          method: "GET",
          routeParams: {
            institution_id: currentInstitutionProcess.institution.id.toString(),
          },
          queryParams: { strict_mode: "false" },
        },
        state.token
      ).then((scores) => {
        const transformedResponse:
          | SetStateAction<HistoricScorePattern[]>
          | {
              version?: { id: number; start: string } | undefined;
              categories: {};
              score: number;
            }[] = [];
        scores.forEach((score) => {
          const toAppend: HistoricScorePattern = {
            version: score.version,
            categories: {},
            score: score.score,
          };

          score.categories.forEach(
            (c) =>
              (toAppend.categories[c.category_name] = {
                name: c.category_name,
                slug: c.category_slug,
                score: c.score,
                weighted_score: c.weighted_score,
                weight: c.weight,
                id: c.category_id,
                color: c.color,
                raw_subelements: c.indicators,
                subelements: c.indicators.reduce((rv: any, x: ItemScore) => {
                  (rv[x["subcategory"]] = rv[x["subcategory"]] || []).push(x);
                  return rv;
                }, {}),
              })
          );

          if (toAppend.version !== null && toAppend.version !== undefined) {
            transformedResponse.push(toAppend);
          }
        });
        setInstitutionScores(transformedResponse);
        setReady(true);
      });
    }
  }, [currentInstitutionProcess, state.token]);

  const getDataForGlobalChart = () => {
    const categories = Object.entries(institutionScores[0].categories).map(
      ([categoryName]) => categoryName
    );
    const data: Array<Record<string, any>> = [];

    institutionScores.forEach((scores) => {
      const obj: Record<string, any> = {
        name: scores.version?.start,
      };

      categories.forEach((category) => {
        obj[category] =
          scores.categories[category].weight *
          scores.categories[category].score;
      });

      data.push(obj);
    });

    return data;
  };

  const getDataForCategoryChart = (categoryName: string) => {
    const data: Array<Record<string, any>> = [];

    institutionScores.forEach((score) => {
      const obj: Record<string, any> = {
        name: score.version?.start,
      };

      score.categories[categoryName].raw_subelements?.forEach((indicator) => {
        obj[indicator.slug || ""] = indicator.weight * indicator.score;
      });

      data.push(obj);
    });

    return data;
  };

  const downloadExport = (url: string) => {
    const a = document.createElement("a");
    a.download = "export_RESIES.xls";
    a.href = url;
    a.click();
    setDownloadExcelText("Descargar respuestas históricas");
  };

  const getExportPromise = () =>
    fetchAPI<APIActions["exportHistoricInstitutionFile"]>(
      APIRoutes.exportHistoricInstitutionFile,
      {
        method: "GET",
        routeParams: { institution_id: institution!.id.toString() },
      },
      state.token || ""
    );

  const exportClickFunction = () => {
    setDownloadExcelText("Descargando...");
    getExportPromise().then((thing: Export) => {
      downloadExport(thing.file_link);
    });
  };

  return (
    <Container>
      <CargandoScreen ready={ready}>
        {institution && institutionScores?.length > 0 && (
          <div id="vista-principal" tw="flex flex-col justify-around">
            <div
              id="caja-superior"
              tw="bg-resies_lightgreen flex flex-row text-resies_blue1 justify-between"
            >
              <p tw="font-bold text-xl m-10 ml-5 print:ml-5">
                Reporte y evaluación de la sustentabilidad
                {state.institutionId === "0" && (
                  <span>: {institution.name}</span>
                )}
              </p>

              <div tw="m-10 mr-5 print:hidden">
                <button onClick={(e) => exportClickFunction()}>
                  {downloadExcelText}
                </button>
              </div>
            </div>

            <div>
              <p tw="mb-4 mt-4">
                A continuación se presentan los puntajes brutos históricos de
                las categorías e indicadores RESIES en las tablas
                correspondientes. Para cada tabla se construye un gráfico de
                barras apiladas utilizando la información de puntajes brutos del
                año por el peso de cada indicador o categoría. Los puntajes
                totales que se muestran en esta sección pueden diferir de los
                reportados en los informes RESIES, debido a que los pesos
                utilizados aquí son los del año más actual.
              </p>
              <p tw="mb-4">
                Cabe mencionar que el año mostrado en cada columna es el del
                ciclo RESIES, es decir, la columna <strong>2023</strong>{" "}
                contiene la información del ciclo RESIES <strong>2023</strong>,
                que corresponde a el año de evaluación <strong>2022</strong>.
              </p>
              <div tw="flex mb-4 print:flex-col">
                <div tw="w-3/5 print:flex-1 print:w-full">
                  <table tw="my-2">
                    <tbody tw="flex flex-col items-center justify-between w-full">
                      <tr tw="flex border-b">
                        <td tw="w-64">
                          <strong>
                            Puntaje bruto de categorías y total global
                          </strong>
                        </td>
                        <td tw="w-20 text-center">
                          <strong>Peso</strong>
                        </td>
                        {institutionScores.map((scores) => (
                          <td tw="w-20 text-center">
                            <strong>{scores.version?.start}</strong>
                          </td>
                        ))}
                      </tr>
                      {Object.entries(institutionScores[0].categories).map(
                        ([categoryName, data], index: number) => (
                          <tr
                            tw="flex border-b"
                            style={{
                              borderStyle:
                                index !==
                                Object.entries(institutionScores[0].categories)
                                  .length -
                                  1
                                  ? "dashed"
                                  : "solid",
                            }}
                          >
                            <td
                              tw="font-bold w-64 pt-1 pb-1"
                              style={{
                                backgroundColor: data.color,
                              }}
                            >
                              {categoryName}
                            </td>
                            <td tw="w-20 text-center">
                              {data.weight.toFixed(2)}
                            </td>
                            {institutionScores.map((score) => (
                              <td tw="w-20 text-center">
                                {score.categories[categoryName].score.toFixed(
                                  2
                                )}
                              </td>
                            ))}
                          </tr>
                        )
                      )}
                      <tr tw="flex border-b">
                        <td tw="w-64">
                          <strong>RESIES Global</strong>
                        </td>
                        <td tw="w-20 text-center"></td>
                        {institutionScores.map((score) => (
                          <td tw="w-20 text-center">
                            <strong>{score.score.toFixed(2)}</strong>
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div tw="w-2/5 print:flex-1 print:w-full">
                  <div tw="flex overflow-x-scroll print:overflow-x-visible print:pl-5">
                    <div>
                      <h3 tw="mb-4">
                        <strong>Puntaje bruto</strong>
                      </h3>

                      <BarChart
                        width={700}
                        height={300}
                        data={getDataForGlobalChart()}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 0,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis domain={[0, 100]} />
                        <Tooltip
                          formatter={(value: number) => `${value.toFixed(2)}%`}
                        />
                        <Legend
                          layout="vertical"
                          verticalAlign="middle"
                          align="right"
                          width={200}
                          wrapperStyle={{ paddingLeft: "10px" }}
                        />

                        {Object.entries(institutionScores[0].categories).map(
                          ([categoryName, category]) => (
                            <Bar
                              dataKey={categoryName}
                              stackId="a"
                              fill={category.color}
                            />
                          )
                        )}
                      </BarChart>
                    </div>
                  </div>
                </div>
              </div>

              <h2 tw="text-header2 font-bold mb-4 print:text-2xl print:mt-10 print:mb-5">
                Resultados por categoría
              </h2>

              {Object.entries(institutionScores[0].categories).map(
                ([categoryName, data], index) => (
                  <div
                    tw="p-5 border-t-2"
                    style={{ backgroundColor: data.color }}
                  >
                    <h3 tw="text-header3 font-bold">{categoryName}</h3>

                    <div tw="flex mb-4 print:flex-col">
                      <div tw="w-3/5 print:flex-1 print:w-full">
                        <table>
                          <thead>
                            <tr tw="border-b">
                              <th tw="w-56">Subcategoría</th>
                              <th tw="w-16 text-center">ID</th>
                              <th tw="w-80">Indicador</th>
                              <th tw="w-20 text-center">Peso</th>
                              {institutionScores.map((scores) => (
                                <td tw="w-20 text-center">
                                  <strong>{scores.version?.start}</strong>
                                </td>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(data.subelements).map(
                              (subcategory: string, position: number) => (
                                <>
                                  {data.subelements[subcategory].map(
                                    (
                                      indicator: ItemScore,
                                      indicatorIndex: number
                                    ) => (
                                      <tr
                                        style={{
                                          borderBottom:
                                            indicatorIndex ===
                                              data.subelements[subcategory]
                                                .length -
                                                1 &&
                                            position !==
                                              Object.keys(data.subelements)
                                                .length -
                                                1
                                              ? "1px dashed"
                                              : data.subelements[subcategory]
                                                  .length -
                                                  1 ===
                                                  indicatorIndex &&
                                                position ===
                                                  Object.keys(data.subelements)
                                                    .length -
                                                    1
                                              ? "1px solid"
                                              : "",
                                        }}
                                      >
                                        {indicatorIndex === 0 && (
                                          <td
                                            tw="w-56"
                                            rowSpan={
                                              data.subelements[subcategory]
                                                .length
                                            }
                                          >
                                            {subcategory}
                                          </td>
                                        )}
                                        <td tw="w-16 text-center">
                                          {indicator.slug}
                                        </td>
                                        <td tw="w-80">{indicator.title}</td>
                                        <td tw="w-20 text-center">
                                          {indicator.weight.toFixed(2)}
                                        </td>

                                        {institutionScores.map((score) => (
                                          <td tw="w-20 text-center">
                                            {score.categories[
                                              categoryName
                                            ].subelements[subcategory][
                                              indicatorIndex
                                            ].score.toFixed(2)}
                                          </td>
                                        ))}
                                      </tr>
                                    )
                                  )}
                                </>
                              )
                            )}
                            <tr tw="border-b">
                              <th tw="w-56"></th>
                              <th tw="w-16 text-center"></th>
                              <th tw="w-80"></th>
                              <th tw="w-20 text-center"></th>
                              {institutionScores.map((score) => (
                                <td tw="w-20 text-center">
                                  <strong>
                                    {score.categories[
                                      categoryName
                                    ].score.toFixed(2)}
                                  </strong>
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div tw="w-2/5 print:flex-1 print:w-full print:mt-5">
                        <div tw="pl-10 flex overflow-x-scroll print:overflow-x-visible print:pl-0">
                          <div>
                            <h3 tw="mb-4">
                              <strong>Puntaje bruto</strong>
                            </h3>
                            <BarChart
                              width={800}
                              height={400}
                              data={getDataForCategoryChart(categoryName)}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 0,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis domain={[0, 100]} />
                              <Tooltip
                                formatter={(value: number) =>
                                  `${value.toFixed(2)}%`
                                }
                              />
                              <Legend
                                layout="vertical"
                                verticalAlign="middle"
                                align="right"
                                width={300}
                                wrapperStyle={{ paddingLeft: "10px" }}
                              />

                              {institutionScores[0].categories[
                                categoryName
                              ].raw_subelements?.map((indicator) => (
                                <Bar
                                  dataKey={indicator.slug || ""}
                                  stackId="a"
                                  fill={indicator.color}
                                />
                              ))}
                            </BarChart>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </CargandoScreen>

      {institutionId !== undefined && (
        <>
          {institutionId === null || institutionId === "0" ? (
            <VolverButton to={Routes.myInstitutions} />
          ) : (
            <VolverButton
              to={Routes.audInstitutionReport.replace(
                ":institutionId",
                institutionId
              )}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default RDIHistoricReports;
