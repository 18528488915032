import { useEffect, useContext, useState } from "react";
import { AppContext } from "../dispatcher";
import { useParams } from "react-router-dom";
import {
  Process,
  ResiesVersion,
  InstitutionResiesVersion,
} from "../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../utils/fetchAPI";

/**
 * Hook que devuelve información sobre las fechas actuales de procesos de RESIES
 */
function useRESIESDates(): [
  ResiesVersion | undefined,
  Process | undefined,
  InstitutionResiesVersion | undefined
] {
  const [appState] = useContext(AppContext);
  const [activeVersion, setActiveVersion] = useState<ResiesVersion>();
  const [currentProcess, setCurrentProcess] = useState<Process>();
  const [currentInstitutionProcess, setCurrentInstitutionProcess] =
    useState<InstitutionResiesVersion>();
  const { institutionId } = useParams<{ institutionId: string }>();

  console.log("institution id is ", institutionId, window.location.pathname);

  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["getActiveResiesVersion"]>(
      APIRoutes.getActiveVersion,
      { method: "GET" },
      appState.token
    ).then((version) => {
      setActiveVersion(version);
      setCurrentProcess(version.processes.find((p) => p.ongoing));

      let institutionId = appState.institutionId;

      if (institutionId === null || institutionId === "0") {
        if (window.location.pathname.includes("/institutions")) {
          institutionId = window.location.pathname.split("/")[2] || "0";
        }
      }

      if (institutionId && appState.token && institutionId !== "0") {
        fetchAPI<APIActions["getActiveResiesVersionPerInstitution"]>(
          APIRoutes.getActiveVersionPerInstitution,
          {
            method: "GET",
            routeParams: { institution_id: institutionId },
          },
          appState.token
        ).then((version) => {
          if (version.processes) {
            version.current_process = version.processes.find((p) => p.ongoing);
          }
          setCurrentInstitutionProcess(version);
        });
      }
    });
  }, [appState.token, appState.institutionId]);
  return [activeVersion, currentProcess, currentInstitutionProcess];
}

export default useRESIESDates;
