import React, { ReactElement, useEffect } from "react";
import "twin.macro";
import {
  EvaluationVariablesFromIndicator,
  EvaluationVariableStatus,
} from "../../../../../types/utilTypes";
import ADMEntryVariableValue from "./ADMEntryVariableValue";

/**
 * Formulario correspondiente a una variable de un indicador.
 *
 * @param props react props.
 * @param props.variable Variable que se muestra en esta fila.
 * @param props.setEvaluationVariables setState que maneja los cambios en las variables.
 * @param props.evaluationVariables Objeto que muestra todas las variables de cada indicador.
 * @param props.deletedVariables Variables que han sido eliminadas
 * @param props.setDeletedVariables setState para manejar las variables que han sido eliminadas.
 * @param props.setChanges set changes
 */
function ADMVariableRow(props: {
  variable: EvaluationVariableStatus;
  setEvaluationVariables: React.Dispatch<
    React.SetStateAction<EvaluationVariablesFromIndicator[]>
  >;
  evaluationVariables: EvaluationVariablesFromIndicator[];
  deletedVariables: EvaluationVariableStatus[];
  setDeletedVariables: React.Dispatch<
    React.SetStateAction<EvaluationVariableStatus[]>
  >;
  setChanges: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement {
  const {
    variable,
    setEvaluationVariables,
    evaluationVariables,
    deletedVariables,
    setDeletedVariables,
    setChanges,
  } = props;
  const descriptionRef = React.createRef<HTMLTextAreaElement>();
  const descriptionHelperRef = React.createRef<HTMLTextAreaElement>();
  const verificationRef = React.createRef<HTMLTextAreaElement>();

  useEffect(() => {
    descriptionRef.current?.focus();
  }, [descriptionRef]);

  useEffect(() => {
    descriptionHelperRef.current?.focus();
  }, [descriptionHelperRef]);

  useEffect(() => {
    verificationRef.current?.focus();
  }, [verificationRef]);

  /**
   * Funcion que cambia al estado de edición un elemento de la variable al hacer click.
   *
   * @param type tipo de objeto, que puede ser description, descriptionHelper o verification.
   */
  function onClickHandler(type: string) {
    const copyEvaluationVariables = [...evaluationVariables];
    const copyVariable = copyEvaluationVariables
      .filter((evalu) => evalu.id === variable.indicator_id)[0]
      .evaluationVariables.filter((vari) => vari.id === variable.id)[0];
    if (type === "description") {
      copyVariable.statusDescription = "edit";
    } else if (type === "descriptionHelper") {
      copyVariable.statusDescriptionFieldHelper = "edit";
    } else if (type === "verification") {
      copyVariable.statusVerification = "edit";
    }
    setEvaluationVariables(copyEvaluationVariables);
  }

  /**
   * Función que maneja el cambio de cada elemento de la variable en el modo de edición.
   *
   * @param e event
   * @param type type
   */
  function onChangeHandler(
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: string
  ) {
    const copyEvaluationVariables = [...evaluationVariables];
    const copyVariable = copyEvaluationVariables
      .filter((evalu) => evalu.id === variable.indicator_id)[0]
      .evaluationVariables.filter((vari) => vari.id === variable.id)[0];
    if (type === "description") {
      copyVariable.description = e.currentTarget.value;
    } else if (type === "descriptionHelper") {
      copyVariable.description_field_helper_text = e.currentTarget.value;
    } else if (type === "verification") {
      copyVariable.verification_field_helper_text = e.currentTarget.value;
    } else if (type === "type") {
      copyVariable.type = e.currentTarget.value;
    } else if (type === "option") {
      copyVariable.json_config[parseInt(e.currentTarget.name, 10)].option_text =
        e.currentTarget.value;
    } else if (type === "score") {
      copyVariable.json_config[
        parseInt(e.currentTarget.name, 10)
      ].option_score = parseFloat(e.currentTarget.value);
    } else if (type === "newOption") {
      copyVariable.json_config.push({
        option_text: "texto",
        option_score: 0,
      });
    } else if (type === "deleteOption") {
      copyVariable.json_config.splice(parseInt(e.currentTarget.name, 10), 1);
    }
    if (copyVariable.generalStatus === "no modified") {
      copyVariable.generalStatus = "modified";
    }
    setEvaluationVariables(copyEvaluationVariables);
    setChanges(true);
  }

  /**
   * Función que cierra el modo de edición al hacer click en ENTER.
   *
   * @param e keyboard event
   * @param type tipo puede ser description, descriptionHelper o verification.
   */
  function onKeyDownHandler(
    e:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
    type: string
  ) {
    if (e.key === "Enter") {
      const copyEvaluationVariables = [...evaluationVariables];
      const copyVariable = copyEvaluationVariables
        .filter((evalu) => evalu.id === variable.indicator_id)[0]
        .evaluationVariables.filter((vari) => vari.id === variable.id)[0];
      if (type === "description") {
        copyVariable.statusDescription = "ready";
      } else if (type === "descriptionHelper") {
        copyVariable.statusDescriptionFieldHelper = "ready";
      } else if (type === "verification") {
        copyVariable.statusVerification = "ready";
      }
      setEvaluationVariables(copyEvaluationVariables);
    }
  }

  /**
   * Funcion que elimina la variable al hacer click.
   */
  function onClickDeleteVariable() {
    const copyDeletedVariables = [...deletedVariables];
    const copyEvaluationVariables = [...evaluationVariables];
    const copyVariable = copyEvaluationVariables
      .filter((evalu) => evalu.id === variable.indicator_id)[0]
      .evaluationVariables.filter((vari) => vari.id === variable.id)[0];
    const copyIndicator = copyEvaluationVariables.filter(
      (evalu) => evalu.id === variable.indicator_id
    )[0];
    copyDeletedVariables.push(copyVariable);
    const index = copyIndicator.evaluationVariables.indexOf(copyVariable);
    copyIndicator.evaluationVariables.splice(index, 1);
    setEvaluationVariables(copyEvaluationVariables);
    setDeletedVariables(copyDeletedVariables);
    setChanges(true);
  }

  /**
   * Funcion que cierra el modo de edicion al hacer focus en otro elemento
   *
   * @param type type
   */
  function onBlurHandler(type: string) {
    const copyEvaluationVariables = [...evaluationVariables];
    const copyVariable = copyEvaluationVariables
      .filter((evalu) => evalu.id === variable.indicator_id)[0]
      .evaluationVariables.filter((vari) => vari.id === variable.id)[0];
    if (type === "description") {
      copyVariable.statusDescription = "ready";
    } else if (type === "descriptionHelper") {
      copyVariable.statusDescriptionFieldHelper = "ready";
    } else if (type === "verification") {
      copyVariable.statusVerification = "ready";
    }
    setEvaluationVariables(copyEvaluationVariables);
  }
  return (
    <div
      tw="flex flex-row grid grid-cols-12
          content-center border-b-2 border-darkgray"
    >
      <ADMEntryVariableValue
        statusVerificator={variable.statusDescription}
        typeHandler="description"
        value={variable.description}
        onChangeHandler={onChangeHandler}
        onClickHandler={onClickHandler}
        onKeyDownHandler={onKeyDownHandler}
        onBlurHandler={onBlurHandler}
        reference={descriptionRef}
      />

      <ADMEntryVariableValue
        statusVerificator={variable.statusDescriptionFieldHelper}
        typeHandler="descriptionHelper"
        value={variable.description_field_helper_text}
        onChangeHandler={onChangeHandler}
        onClickHandler={onClickHandler}
        onKeyDownHandler={onKeyDownHandler}
        onBlurHandler={onBlurHandler}
        reference={descriptionHelperRef}
      />

      <ADMEntryVariableValue
        statusVerificator={variable.statusVerification}
        typeHandler="verification"
        value={variable.verification_field_helper_text}
        onChangeHandler={onChangeHandler}
        onClickHandler={onClickHandler}
        onKeyDownHandler={onKeyDownHandler}
        onBlurHandler={onBlurHandler}
        reference={verificationRef}
      />
      <div tw="flex flex-col text-justify min-h-100 mx-auto col-span-3 xl:w-72 lg:w-52">
        <select
          tw="mx-auto h-2/6 rounded-md border-resies_blue1 border"
          value={variable.type}
          onChange={(e) => onChangeHandler(e, "type")}
        >
          <option value="NUMBER">Numérica</option>
          <option value="MULTIPLE_CHOICE">Elección multiple</option>
        </select>
        {variable.type === "MULTIPLE_CHOICE" ? (
          <div tw="flex flex-col">
            <div tw="flex flex-row">
              <p tw="w-4/6 font-normal text-black xl:text-sm text-xs">Opción</p>
              <p tw="w-1/6 font-normal text-black xl:text-sm text-xs">
                Puntuación
              </p>
            </div>
            {variable.json_config.map((option) => (
              <div
                tw="flex flex-row justify-between my-1"
                key={variable.json_config.indexOf(option)}
              >
                <input
                  tw="w-3/6"
                  type="text"
                  value={option.option_text}
                  name={variable.json_config.indexOf(option).toString()}
                  onChange={(e) => onChangeHandler(e, "option")}
                />
                <div tw="w-1/6" />
                <input
                  tw="w-1/6"
                  type="number"
                  value={option.option_score}
                  name={variable.json_config.indexOf(option).toString()}
                  onChange={(e) => onChangeHandler(e, "score")}
                />
                <button
                  type="button"
                  tw="w-1/6"
                  name={variable.json_config.indexOf(option).toString()}
                  onClick={(e) => onChangeHandler(e, "deleteOption")}
                >
                  <img
                    src="/cancel.png"
                    alt="delete"
                    tw="object-contain mx-auto"
                  />
                </button>
              </div>
            ))}
            <button
              type="button"
              tw="bg-resies_green font-normal text-black 2xl:text-sm md:text-xs text-xs my-2 py-1 rounded-md"
              onClick={(e) => onChangeHandler(e, "newOption")}
            >
              Agregar opción
            </button>
          </div>
        ) : (
          <p tw="2xl:text-sm md:text-xs text-xs mx-4">
            La variable tendra valor numérico
          </p>
        )}
      </div>
      <div tw="flex text-justify min-h-100 mx-auto col-span-1 xl:w-24 lg:w-20">
        <button
          type="button"
          tw="w-2/12 my-auto mx-auto"
          onClick={onClickDeleteVariable}
        >
          <img
            src="/trash.png"
            alt="trash"
            tw="object-contain my-auto mx-auto"
          />
        </button>
      </div>
    </div>
  );
}

export default ADMVariableRow;
