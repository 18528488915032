import React, { ReactElement, useState } from "react";
import "twin.macro";
import { ResponseComment } from "../../../types/backendTypes";
import SingleComment from "./SingleComment";

/**
 * comentarios
 *
 * @param props react props
 * @param props.onSubmit funcion que envia los comentarios a backend
 * @param props.allComments todos los comentarios
 * @param props.reloadComments Recarga los comentarios
 */
function NormalComments(props: {
  onSubmit(
    comment: string,
    setComment: React.Dispatch<React.SetStateAction<string>>
  ): void;
  allComments: ResponseComment[];
  reloadComments: () => void;
}): ReactElement {
  const { onSubmit, allComments, reloadComments } = props;
  const [comment, setComment] = useState("");

  /**
   * Funcion que maneja el cambio en el input de comentario.
   *
   * @param e Evento del cambio en el input
   */
  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setComment(e.target.value);
  }

  return (
    <div>
      <div tw="bg-resies_blue2 font-sans text-barra font-bold text-ghostwhite px-6 mt-10">
        Comentarios auditoría
      </div>
      <div tw="bg-ghostwhite font-sans text-body font-normal text-black px-8 py-2 mb-10 shadow-xl">
        <div tw="flex flex-col h-40 overflow-auto">
          {allComments.map((comm) => (
            <SingleComment comm={comm} reloadComments={reloadComments} />
          ))}
        </div>
        <div>
          <div tw="flex flex-row justify-between">
            <input
              type="text"
              tw="w-10/12 py-2 px-2 border"
              placeholder="Ingrese comentario"
              value={comment}
              onChange={(e) => onChange(e)}
            />
            <button
              type="button"
              tw="bg-resies_green text-ghostwhite text-body font-normal px-2 py-3 rounded-md w-1/12 mx-auto"
              onClick={() => onSubmit(comment, setComment)}
            >
              Comentar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NormalComments;
