import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import { ResiesVersion } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "../common/CargandoScreen";
import DeadlinesRow from "./DeadlinesRow";
import BroadcastMail from "../adm/BroadcastMail";
import ConfirmationPopup from "../common/EvidenceView/ConfirmationPopup";

/**
 * Tabla de plazos de los procesos de auditoría manejados por el administrador.
 */
function AuditDeadlinesIndex(): ReactElement {
  const [appState] = useContext(AppContext);
  const [resiesVersion, setResiesVersion] = useState<ResiesVersion>({
    id: 0,
    version_name: "",
    start: "",
    processes: [],
    end: "",
    active: true,
  });
  const [ready, setReady] = useState(false);
  const [convert, setConvert] = useState<Record<string, string>>({});
  const [errorList, setErrorList] = useState<string[]>([]);
  const [reload, setReload] = useState(0);
  const ref = useRef<() => void>(null);
  const reloadView = useCallback(() => {
    if (!appState.token) return;
    setReady(false);
    fetchAPI<APIActions["getActiveResiesVersion"]>(
      APIRoutes.getActiveVersion,
      {
        method: "GET",
      },
      appState.token
    ).then((version) => {
      setConvert({
        "Llenado de Indicadores": "filling",
        Auditoría: "audit",
        "Rectificación de Objetados": "objection_rectification",
        "Revisión de Objetados": "objection_revision",
        "Proceso de Apelación": "appeal",
        "Resultados de Apelación": "appeal_results",
        "Fin proceso RESIES": "finish_stage",
      });
      setResiesVersion(version);
      setReady(true);
    });
  }, [appState.token]);
  useEffect(() => {
    reloadView();
  }, [reloadView]);

  useEffect(() => {
    setErrorList([]);
    const auxErrorList: string[] = [];
    const copy = { ...resiesVersion };
    copy.processes.forEach((process, index) => {
      if (new Date(process.start) >= new Date(process.end)) {
        auxErrorList.push(
          `La fecha de inicio del proceso ${process.name} no puede ser después o la misma que su fecha de fin`
        );
      }
      if (
        convert[process.name] !== "finish_stage" &&
        new Date(process.end) > new Date(copy.processes[index + 1].start)
      ) {
        auxErrorList.push(
          `El proceso ${
            process.name
          } no puede terminar despues del comienzo del proceso ${
            copy.processes[index + 1].name
          }`
        );
      }
    });
    if (new Date(copy.start) >= new Date(copy.end)) {
      auxErrorList.push(
        `La fecha de inicio del ciclo ${copy.version_name} no puede ser después o la misma que su fecha de fin`
      );
    }
    setErrorList(auxErrorList);
  }, [convert, resiesVersion]);

  /**
   * Funcion de cambio de fecha de inicio
   *
   * @param name nombre del proceso
   * @param date fecha de inicio
   */
  function onChangeHandlerStart(name: string, date: Date) {
    const copy = { ...resiesVersion };
    const process = copy.processes.filter((pros) => pros.name === name)[0];
    process.start = new Date(date.setHours(0, 0, 0, 0)).toISOString();
    if (convert[process.name] === "filling") {
      copy.start = process.start;
    }
    setResiesVersion(copy);
  }

  /**
   * Funcion de cambio de fecha de fin
   *
   * @param name nombre del proceso
   * @param date fecha de inicio
   */
  function onChangeHandlerEnd(name: string, date: Date) {
    const copy = { ...resiesVersion };
    const process = copy.processes.filter((pros) => pros.name === name)[0];
    process.end = new Date(date.setHours(23, 59, 59, 999)).toISOString();
    if (convert[process.name] === "finish_stage") {
      copy.end = process.end;
    }
    setResiesVersion(copy);
  }

  /**
   * funcion que guarda el cambio de las fechas
   */
  function onSaveProcess() {
    if (!appState.token || errorList.length) return;
    setReady(false);
    fetchAPI<APIActions["editActiveVersion"]>(
      APIRoutes.editActiveVersion,
      {
        method: "PUT",
        body: {
          version_name: resiesVersion.version_name,
          start: resiesVersion.start,
          end: resiesVersion.end,
        },
      },
      appState.token
    ).then(() => {
      const processList = resiesVersion.processes.map((pro) => {
        return {
          process_string: convert[pro.name],
          start: pro.start,
          end: pro.end,
        };
      });
      if (appState.token) {
        fetchAPI<APIActions["editActiveVersionProcess"]>(
          APIRoutes.editActiveVersionProcess,
          {
            method: "PUT",
            body: processList,
          },
          appState.token
        ).then(() => {
          setErrorList([]);
          setReload(reload + 1);
          setReady(true);
        });
      }
    });
  }
  /**
   * Crea un nuevo ciclo de RESIES, borrando la base de datos
   */
  function restartCycle(): Promise<void> {
    if (!appState.token) return Promise.resolve();
    return fetchAPI<APIActions["newResiesVersion"]>(
      APIRoutes.newResiesVersion,
      { method: "POST" },
      appState.token
    ).then(() => {
      setReload(reload + 1);
      reloadView();
    });
  }
  return (
    <CargandoScreen ready={ready}>
      {resiesVersion.start ? (
        <div tw="mb-10">
          <div tw="flex justify-between">
            <button
              type="button"
              onClick={() => {
                if (ref.current) ref.current();
              }}
              tw="flex bg-resies_purple rounded-sm max-h-12 mb-2 py-1 px-2 disabled:opacity-50"
            >
              <FontAwesomeIcon
                icon={faRedo}
                color="ghostwhite"
                tw="mr-2 ml-1 h-full"
              />
              <p tw="font-bold text-base text-ghostwhite">
                Iniciar nuevo ciclo
              </p>
              <ConfirmationPopup
                popupText="Esta seguro de querer iniciar un nuevo ciclo? Se reiniciarán las respuestas de todas las instituciónes"
                callback={restartCycle}
                errorText="Ha ocurrido un error. Por favor intentelo de nuevo"
                ref={ref}
              />
            </button>
            {errorList.length !== 0 ? (
              <p tw="text-darkred my-auto mr-2">
                No se puede guardar mientras existan errores
              </p>
            ) : (
              <></>
            )}
            <BroadcastMail />
            <button
              type="button"
              tw="flex bg-resies_purple rounded-sm max-h-12 mb-2 p-1 disabled:opacity-50 ml-20"
              onClick={onSaveProcess}
              disabled={errorList.length !== 0}
            >
              <img src="/save.png" alt="save" tw="mr-2 ml-2" />
              <p tw="font-bold text-base text-ghostwhite mr-3">Guardar</p>
            </button>
          </div>
          <table tw="w-full">
            <thead tw="bg-resies_blue2 flex text-white w-full">
              <tr tw="flex w-full h-5">
                <th tw="text-barra font-bold text-ghostwhite text-center w-4/12 h-5">
                  PROCESO
                </th>
                <th tw="text-barra font-bold text-ghostwhite text-center w-4/12 h-5">
                  FECHA INICIO
                </th>
                <th tw="text-barra font-bold text-ghostwhite text-center w-4/12 h-5">
                  FECHA TÉRMINO
                </th>
              </tr>
            </thead>
            <tbody tw="bg-resies_lightpurple">
              {resiesVersion ? (
                <>
                  <DeadlinesRow
                    processName="Ciclo Resies"
                    initDate={resiesVersion.start}
                    finalDate={resiesVersion.end}
                    onChangeHandlerStart={onChangeHandlerStart}
                    onChangeHandlerEnd={onChangeHandlerEnd}
                    disabled
                  />
                  {resiesVersion.processes.map((process) => (
                    <DeadlinesRow
                      processName={process.name}
                      initDate={process.start}
                      finalDate={process.end}
                      onChangeHandlerStart={onChangeHandlerStart}
                      onChangeHandlerEnd={onChangeHandlerEnd}
                      key={process.name}
                    />
                  ))}
                </>
              ) : (
                <></>
              )}
            </tbody>
          </table>
          {errorList.map((msg) => (
            <p key={msg} tw="text-darkred">
              {msg}
            </p>
          ))}
        </div>
      ) : (
        <></>
      )}
    </CargandoScreen>
  );
}

export default AuditDeadlinesIndex;
