import React, { ReactElement } from "react";
import "twin.macro";
import { ScorePattern } from "../../types/utilTypes";
import { QData } from "../adm/d3Graph";

/**
 * La tabla de resumen en la vista de generar reportes.
 *
 * @param props React props
 * @param props.data Los datos a visualizar
 * @param props.institutionScore El puntaje de la institución en lo encontrado
 */
function MagicTable(props: {
  data: QData;
  institutionScore: ScorePattern | undefined;
}): ReactElement {
  const { data, institutionScore } = props;
  return (
    <div tw="bg-resies_lightpurple h-80 overflow-y-auto mt-5 w-full">
      <table tw="w-full table-auto">
        <thead tw="bg-resies_blue1 text-white">
          <tr>
            <th>Referente</th>
            <th>Mínimo</th>
            <th>Q1</th>
            <th>Mediana</th>
            <th>Q3</th>
            <th>Máximo</th>
            {institutionScore && <th>Institución</th>}
          </tr>
        </thead>
        <tbody tw="text-center">
          <tr>
            <td>{data.general.name}</td>
            <td>{Math.round(data.general.stats.q0 * 100) / 100}</td>
            <td>{Math.round(data.general.stats.q1 * 100) / 100}</td>
            <td>{Math.round(data.general.stats.q2 * 100) / 100}</td>
            <td>{Math.round(data.general.stats.q3 * 100) / 100}</td>
            <td>{Math.round(data.general.stats.q4 * 100) / 100}</td>
            {institutionScore && Math.round(institutionScore.score * 100) / 100}
          </tr>
          {data.elements.map((element, i) => (
            <tr key={element.index}>
              <td>{element.name}</td>
              <td>{Math.round(element.stats.q0 * 100) / 100}</td>
              <td>{Math.round(element.stats.q1 * 100) / 100}</td>
              <td>{Math.round(element.stats.q2 * 100) / 100}</td>
              <td>{Math.round(element.stats.q3 * 100) / 100}</td>
              <td>{Math.round(element.stats.q4 * 100) / 100}</td>
              {institutionScore && (
                <td>
                  {Math.round(institutionScore.elements[i]?.score * 100) / 100}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MagicTable;
