/* eslint-disable no-nested-ternary */
import React, { ReactElement } from "react";
import { Link, useParams } from "react-router-dom";
import "twin.macro";
import Routes from "../../../routers/routes";
import { InstitutionalDataVariable } from "../../../types/backendTypes";
import { InstitutionalVariableForm } from "../../../types/utilTypes";

/**
 * Formulario para modificar un indicador de los datos de la institución.
 *
 * @param props react props
 * @param props.slug nombre del indicador
 * @param props.variables todas las variables en un diccionario
 * @param props.variablesForm Diccionario de la forma { id : descripción } que guarda el estado del formulario
 * */
function AUDDataForm(props: {
  slug: string;
  variables: Record<string, InstitutionalDataVariable>;
  variablesForm: InstitutionalVariableForm[];
}): ReactElement {
  const { slug, variables, variablesForm } = props;
  const variable = variables[slug];
  const elem = variablesForm.filter((va) => va.variable_id === variable.id)[0];
  const key = variablesForm.indexOf(elem);
  const { institutionId } = useParams<{ institutionId: string }>();
  let status = "N/A";

  if (variable?.requires_verificator) {
    if (
      elem.audit_status === "accepted" ||
      elem.audit_status === "final_accepted"
    ) {
      status = "Auditado";
    } else if (elem.audit_status === "pending") {
      status = "Por auditar";
    } else {
      status = "Rechazado";
    }
  }
  return (
    <tr tw="w-full min-h-40 py-5 my-20 text-body text-black font-normal justify-between">
      {variable ? (
        <div tw="flex flex-col w-full">
          <div tw="flex min-h-40 py-3 flex-wrap content-center justify-between">
            <th tw="w-1/12 text-center">{slug}</th>
            <th tw="w-2/12 text-left">{variable.description}</th>
            <th tw="w-2/12 text-left px-10">
              <p tw="mx-auto w-full">
                {variablesForm[key] ? (
                  <>
                    {variablesForm[key].response
                      ? variablesForm[key].response
                      : "No hay respuesta"}
                  </>
                ) : (
                  ""
                )}
              </p>
            </th>
            <th tw="w-3/12 text-center">
              <p tw="mx-auto w-full">
                {variablesForm[key] ? (
                  <>
                    {variablesForm[key].description
                      ? variablesForm[key].description
                      : "No hay descripción"}
                  </>
                ) : (
                  ""
                )}
              </p>
            </th>
            <div tw="text-center w-2/12">
              {variable && variable.requires_verificator ? (
                elem && elem.response !== "" ? (
                  <>
                    <Link
                      to={Routes.audInstitutionalEvidence
                        .replace(":institutionId", institutionId.toString())
                        .replace(":variableId", elem.variable_id.toString())
                        .replace(":responseId", elem.response_id.toString())}
                    >
                      <button
                        type="button"
                        tw="bg-resies_green py-1 mb-4 w-1/2 h-5/6 mx-auto rounded-md 
                    flex flex-row flex-wrap content-center max-h-12"
                      >
                        <p tw="text-ghostwhite mr-2 font-normal text-xs lg:text-sm mx-auto">
                          Ir a la evidencia
                        </p>
                      </button>
                    </Link>
                    <p tw="text-xs lg:text-sm mx-auto text-darkgray">
                      {elem.evidences.length ? "Evidencia subida" : "Faltante"}
                    </p>
                  </>
                ) : (
                  <>
                    {elem && elem.response_id !== 0 ? (
                      <Link
                        to={Routes.audInstitutionalEvidence
                          .replace(":institutionId", institutionId.toString())
                          .replace(":variableId", elem.variable_id.toString())
                          .replace(":responseId", elem.response_id.toString())}
                      >
                        <button
                          type="button"
                          tw="bg-resies_green  py-1 mb-2 w-3/4 h-5/6 mx-auto rounded-md 
                    flex flex-row flex-wrap content-center max-h-12"
                        >
                          <p tw="text-ghostwhite font-normal text-xs lg:text-sm mx-auto">
                            Aun no ha sido respondido
                          </p>
                        </button>
                      </Link>
                    ) : (
                      <Link
                        to={Routes.audInstitutionalEvidence
                          .replace(":institutionId", institutionId.toString())
                          .replace(":variableId", elem.variable_id.toString())
                          .replace(":responseId", "missing")}
                      >
                        <button
                          type="button"
                          tw="bg-resies_green  py-1 mb-2 w-3/4 h-5/6 mx-auto rounded-md 
                    flex flex-row flex-wrap content-center max-h-12"
                        >
                          <p tw="text-ghostwhite font-normal text-xs lg:text-sm mx-auto">
                            Aun no ha sido respondido
                          </p>
                        </button>
                      </Link>
                    )}
                  </>
                )
              ) : (
                "No requiere verificación"
              )}
            </div>
            <div tw="text-center w-2/12">{status}</div>
          </div>
        </div>
      ) : (
        ""
      )}
    </tr>
  );
}

export default AUDDataForm;
