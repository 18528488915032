import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import "twin.macro";
import Routes from "../../routers/routes";
import { User } from "../../types/backendTypes";

/**
 * Datos de contacto del auditor
 *
 * @param props react props
 * @param props.auditor auditor
 */
function AUDContactData(props: { auditor: User }): ReactElement {
  const { auditor } = props;
  return (
    <div tw="w-full bg-resies_lightpurple grid grid-cols-6 px-4 py-2 shadow-md">
      <div tw="h-full flex pr-4">
        <img
          src={auditor.photo_url || "/blank_avatar.png"}
          alt="perfil-aud"
          tw="object-cover h-24"
        />
      </div>
      <div tw="h-full col-span-3">
        <div tw="flex flex-col flex-wrap justify-around h-full w-full">
          <div tw="">
            Nombre: {auditor.first_name} {auditor.last_name}{" "}
          </div>
          <div tw="">
            Cargo: {auditor.description ? auditor.description : "sin info"}
          </div>
          <div tw="">Teléfono: {auditor.phone_number}</div>
          <div tw="">Correo: {auditor.email}</div>
        </div>
      </div>
      <div tw="h-full col-span-2">
        <div tw="flex flex-col justify-around h-full">
          <div tw="">
            <Link
              to={Routes.audProfileAssignInstitutions.replace(
                ":audId",
                auditor.id.toString()
              )}
            >
              <button
                tw="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md w-full"
                type="button"
              >
                Asignar institución
              </button>
            </Link>
          </div>
          <div tw="">
            <Link
              to={Routes.audDeleteProfile.replace(
                ":audId",
                auditor.id.toString()
              )}
            >
              <button
                tw="px-2 py-1 bg-darkred text-ghostwhite rounded-md w-full"
                type="button"
              >
                Borrar cuenta
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AUDContactData;
