import { useEffect, useState } from "react";

/**
 * hook de paginacion
 *
 * @param elements elementos
 * @param pageSize size de pagina
 */
function usePagination<T>(
  elements: T[] | undefined,
  pageSize: number
): [T[], number, number, (arg0: number) => void] {
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [visibleElements, setVisibleElements] = useState<Array<T>>([]);

  useEffect(() => {
    if (elements !== undefined) {
      setMaxPages(Math.ceil(elements.length / pageSize));
    }
  }, [elements, pageSize]);

  useEffect(() => {
    if (elements !== undefined) {
      setVisibleElements(
        elements.slice(
          pageSize * (pageNumber - 1),
          Math.min(pageSize * pageNumber, elements.length)
        )
      );
    }
  }, [pageNumber, elements, pageSize]);
  return [visibleElements, maxPages, pageNumber, setPageNumber];
}

export default usePagination;
