import React, { ReactElement } from "react";
import "twin.macro";
/**
 * Titulo del form correspondiente a cada indicador.
 *
 * @param props react props.
 * @param props.indicatorSelected State del indicador actualmente seleccionado.
 * @param props.indicatorNames Diccionario que contiene los nombres de cada indicador.
 * @param props.formState Estado que maneja los datos actualmente ingresados en los formularios de cada variable.
 */
function ADMTitleIndicatorForm(props: {
  indicatorSelected: string;
  indicatorNames: Record<string, string>;
  formState: Record<string, string | number | boolean | null>[];
}): ReactElement {
  const { indicatorSelected, formState, indicatorNames } = props;
  /**
   * Funcion que retorna el titulo del indicador seleccionado junto con un color dependiendo de su estado actual.
   *
   * @param indicator indicador
   */
  function complete(indicator: string) {
    const indicatorVars = formState.filter((ind) => ind.name === indicator);
    let styleCircle;
    let styleLabel;
    let label;
    const pendingVars = indicatorVars.filter((variable) => !variable.value);
    const completedVars = indicatorVars.filter((variable) => variable.value);
    if (completedVars.length === indicatorVars.length) {
      styleCircle = "rounded-full bg-resies_green h-3 w-3 my-auto mr-2";
      styleLabel = "text-resies_green font-normal my-auto";
      label = "COMPLETADO";
    } else if (pendingVars.length === indicatorVars.length) {
      styleCircle = "rounded-full bg-warning_yellow h-3 w-3 my-auto mr-2";
      styleLabel = "text-warning_yellow font-normal my-auto";
      label = "PENDIENTE";
    } else if (
      pendingVars.length <= indicatorVars.length &&
      pendingVars.length >= 1
    ) {
      styleCircle = "rounded-full bg-warning_yellow h-3 w-3 my-auto mr-2";
      styleLabel = "text-warning_yellow font-normal my-auto";
      label = "INCOMPLETO";
    }
    return (
      <div tw="flex flex-row flex-wrap content-center">
        <p tw="text-resies_blue1 font-bold text-4xl ml-4 mr-4 max-w-3xl">
          {indicatorNames[indicatorSelected]}
        </p>
        <div className={styleCircle} />
        <p className={styleLabel}>{label}</p>
      </div>
    );
  }
  return (
    <div tw="flex flex-col">
      <div tw="flex h-16 bg-resies_lightgreen flex-wrap content-center justify-between min-h-100">
        {complete(indicatorSelected)}
      </div>
      <div tw="flex grid grid-cols-10 h-5 bg-resies_blue2 flex-wrap content-center justify-between">
        <p tw="font-bold text-base text-ghostwhite col-span-4 text-center">
          Variable
        </p>
        <p tw="font-bold text-base text-ghostwhite col-span-1 text-center">
          Dato
        </p>
        <p tw="font-bold text-base text-ghostwhite col-span-2 text-center">
          Estado
        </p>
        <p tw="font-bold text-base text-ghostwhite col-span-2 text-center">
          Verificación
        </p>
      </div>
    </div>
  );
}

export default ADMTitleIndicatorForm;
