import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import { Link, useParams } from "react-router-dom";
import Container from "../../components/common/Container";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import { AppContext } from "../../dispatcher";
import InstitutionForm from "../../components/admin/InstitutionForm";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "../../components/common/CargandoScreen";
import ADMPanelNavbar from "../../components/admin/ADMPanelNavbar";
import { Institution } from "../../types/backendTypes";

/**
 * La página que muestra la vista para crear una nueva institución
 */
function ADMEditInstitution(): ReactElement {
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState<File>();
  const [logo, setLogo] = useState<File>();
  const [error, setError] = useState(false);
  const [appState] = useContext(AppContext);
  const [created, setCreated] = useState(false);
  const [ready, setReady] = useState(false);
  const [readyStart, setReadyStart] = useState(false);
  const [id, setId] = useState(0);
  const { institutionId } = useParams<{ institutionId: string }>();
  const [institution, setInstitution] = useState<Institution>();

  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["fetchInstitutionDetails"]>(
      APIRoutes.fetchInstitutionDetails,
      { method: "GET", routeParams: { institution_id: institutionId } },
      appState.token
    )
      .then((details) => {
        setName(details.institution.description);
        setReady(true);
        setInstitution(details.institution);
        setReadyStart(true);
      })
      .catch(() => {}); // TODO
  }, [appState, institutionId]);
  /**
   * Función para enviar los datos de la nueva institución al backend
   */
  function onEdit() {
    if (!error && appState.token) {
      setCreated(true);
      fetchAPI<APIActions["editInstitution"]>(
        APIRoutes.editInstitution,
        {
          method: "PUT",
          routeParams: { institution_id: institutionId },
          body: {
            name,
            description: name,
            member_since: Date.now().toString(),
          },
        },
        appState.token
      )
        .then(() => {
          if (!appState.token || !photo) return;
          const formData = new FormData();
          formData.append("file", photo);
          fetch(
            `${process.env.REACT_APP_API_URL}/api/institutions/upload_photo/by_institution/${institutionId}`,
            {
              method: "POST",
              headers: {
                "api-key": appState.token,
              },
              body: formData,
            }
          );
        })
        .then(() => {
          if (!appState.token || !logo) return;
          const formData2 = new FormData();
          formData2.append("file", logo);
          fetch(
            `${process.env.REACT_APP_API_URL}/api/institutions/upload_logo/by_institution/${institutionId}`,
            {
              method: "POST",
              headers: {
                "api-key": appState.token,
              },
              body: formData2,
            }
          );
        })
        .then(() => {
          setId(parseInt(institutionId, 10));
          setReady(true);
        });
    }
  }
  return (
    <Container>
      <ADMPanelNavbar />
      {!created && !id ? (
        <CargandoScreen ready={readyStart}>
          <p tw="text-resies_blue1 text-header1 font-bold ml-4">
            Edición de institución
          </p>
          <div tw="flex justify-end">
            {error ? (
              <p tw="text-darkred mr-2 font-bold">
                El nombre no puede estar en blanco
              </p>
            ) : (
              <></>
            )}
            <button
              type="button"
              tw="flex flex-row flex-wrap content-center bg-resies_purple py-1 rounded-sm max-h-12"
              onClick={() => onEdit()}
            >
              <img src="/save.png" alt="save" tw="mr-4 ml-2" />
              <p tw="font-bold text-base text-ghostwhite mr-4">
                Guardar Cambios
              </p>
            </button>
          </div>
          <InstitutionForm
            name={name}
            setName={setName}
            setError={setError}
            setPhoto={setPhoto}
            setLogo={setLogo}
            institution={institution}
          />
        </CargandoScreen>
      ) : (
        <CargandoScreen ready={ready}>
          <div tw="h-96 flex flex-wrap flex-col">
            <div tw="my-auto mx-auto">
              <p tw="text-header2 font-semibold text-black mb-2">
                Institución {name} se ha modificado con éxito
              </p>
              <Link
                to={Routes.admInstitutionsView.replace(
                  ":institutionId",
                  id.toString()
                )}
              >
                <button
                  type="button"
                  tw="px-3 py-3 bg-resies_purple rounded-lg mx-auto text-white text-barra font-bold flex flex-wrap"
                >
                  Volver
                </button>
              </Link>
            </div>
          </div>
        </CargandoScreen>
      )}
      <VolverButton to={Routes.institutionsIndex} />
    </Container>
  );
}

export default ADMEditInstitution;
