import { InstitutionDetails } from "../../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

/**
 * Funcion que obtiene los datos de una institución
 *
 * @param institutionId Id de institucion a buscar
 * @param token Yoken de autenticación obtenido del appstate
 * @param setInstitution Setter para el valor de retorno del fetch
 */
function getInstitutionDetails(
  institutionId: string,
  token: string | null,
  setInstitution: React.Dispatch<
    React.SetStateAction<InstitutionDetails | undefined>
  >
): void {
  if (!token) return;
  fetchAPI<APIActions["fetchInstitutionDetails"]>(
    APIRoutes.fetchInstitutionDetails,
    {
      method: "GET",
      routeParams: { institution_id: institutionId },
    },
    token
  ).then((inst) => setInstitution(inst));
}

export default getInstitutionDetails;
