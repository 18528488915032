import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import ProfileData from "../../components/admin/ProfileData";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import PageSection from "../../components/common/PageSection";
import PageTitle from "../../components/common/PageTitle";
import useUser from "../../hooks/useUser";
import { NotificationElement } from "../../types/backendTypes";
import { AppContext } from "../../dispatcher";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * La página de perfil del auditor
 */
function MyProfileMDI(): ReactElement {
  const [ready, setReady] = useState(false);
  const [appState] = useContext(AppContext);
  const audId = appState.userId;
  const [user, reloadResponses] = useUser(setReady, audId);
  const [notifications, setNotifications] = useState<NotificationElement[]>([]);

  // TODO: datos reales como en audProfile

  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["fetchNotifications"]>(
      APIRoutes.fetchNotificacions,
      { method: "GET" },
      appState.token
    ).then((nots) => {
      setNotifications(nots);
      setReady(true);
    });
  }, [appState.token, notifications]);

  return (
    <CargandoScreen ready={ready}>
      <Container>
        <PageTitle title="Mi Perfil" />
        <div tw="grid grid-cols-2 mb-8 col-gap-2">
          <div>
            <PageSection text="Datos Personales" />
            <div tw="w-full">
              <ProfileData user={user} reload={reloadResponses} />
            </div>
          </div>
          <div>
            <PageSection text="Notificaciones" />
            <div tw="h-48 bg-resies_lightpurple overflow-scroll shadow">
              <table tw="table-auto w-full h-full">
                <tbody tw="w-full h-full">
                  {notifications.map((n) => (
                    <tr tw="border-b border-gray">
                      <td>{n.content} 2</td>
                      <td>{new Date(n.created_at).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>

                {!notifications.length && (
                  <tr tw="w-full h-full flex flex-col justify-center">
                    <td tw="w-full text-center">No tienes notificaciones</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
        <div tw="grid grid-cols-4 mb-8 col-gap-2">
          <div tw="w-full h-48 bg-resies_lightpurple shadow flex flex-col">
            <div tw="w-full text-center text-ghostwhite bg-resies_blue1 font-bold">
              Institucion
            </div>
            <div tw="p-2 text-center">{user.mdiuser?.institution?.name}</div>

            <div tw="w-full text-center text-ghostwhite bg-resies_blue1 font-bold mt-4">
              Rol
            </div>
            <div tw="p-2 text-center">Informante</div>
          </div>
        </div>
      </Container>
    </CargandoScreen>
  );
}

export default MyProfileMDI;
