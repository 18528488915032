import { Indicator } from "../../../types/backendTypes";
import { ResponseForm } from "../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

/**
 * Función que retorna los datos de formulario de cada variable de los indicadores.
 *
 * @param indicators indicadores
 * @param setIndicatorForm setter de formulario de indicador
 * @param apiKey token
 * @param setReady setter de listo
 * @param institutionId id de institucion
 */
function audSetForms(
  indicators: Indicator[],
  setIndicatorForm: React.Dispatch<React.SetStateAction<ResponseForm[]>>,
  apiKey: string,
  setReady: React.Dispatch<React.SetStateAction<boolean>>,
  institutionId: string
): void {
  const forms: ResponseForm[] = [];
  fetchAPI<APIActions["fetchEvaluationVariables"]>(
    APIRoutes.fetchEvaluationVariables,
    {
      method: "GET",
    },
    apiKey
  )
    .then((variables) => {
      variables.sort((a, b) => a.id - b.id);
      indicators.forEach((indicator) => {
        const variablesIndicator = variables.filter(
          (variable) => variable.indicator_id === indicator.id
        );
        variablesIndicator.forEach((variable) => {
          forms.push({
            name: indicator.slug,
            type: "option",
            slug: variable.description,
            value: "",
            comment: "",
            description: "",
            variable_id: variable.id,
            modified: "",
            audit_status: "",
            response_id: null,
            completed: false,
          });
        });
      });
      setIndicatorForm(forms);
    })
    .then(() => {
      fetchAPI<APIActions["auditableResponses"]>(
        APIRoutes.auditableResponses,
        {
          method: "GET",
        },
        apiKey
      )
        .then((responses) => {
          forms.forEach((form) => {
            const institutionResponses = responses.filter(
              (elem) => elem.institution.id.toString() === institutionId
            );
            const response = institutionResponses[0].responses.filter(
              (elem) => elem.variable_id === form.variable_id
            );
            if (response.length) {
              // eslint-disable-next-line no-param-reassign
              form.value = response[0].response;
              // eslint-disable-next-line no-param-reassign
              form.response_id = response[0].id;
              // eslint-disable-next-line no-param-reassign
              form.completed = true;
              // eslint-disable-next-line no-param-reassign
              form.audit_status = response[0].audit_status;
            } else {
              // eslint-disable-next-line no-param-reassign
              form.response_id = null;
              // eslint-disable-next-line no-param-reassign
              form.completed = false;
              // eslint-disable-next-line no-param-reassign
              form.audit_status = "pending";
            }
          });
        })
        .then(() => setReady(true))
        .catch(() => {}); // TODO;
    })
    .catch(() => {}); // TODO;
}
export default audSetForms;
