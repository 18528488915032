import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Prompt, useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import { AppContext } from "../../dispatcher";
import {
  InstitutionCategoryStatus,
  InstitutionVariableStatus,
} from "../../types/utilTypes";
import VolverButton from "../../components/common/VolverButton";
import Routes from "../../routers/routes";
import singleInstitutionCategorieData from "../../utils/data/AdmData/singleInstitutionCategory";
import ADMEditInstitutionIndicatorsTitle from "../../components/adm/EvalInstruments/EditInstitutionInstruments/EditInstitutionIndicatorsView.tsx/ADMEditInstitutionVariablesTitle";
import ADMTitleInstitutionVariableForm from "../../components/adm/EvalInstruments/EditInstitutionInstruments/EditInstitutionIndicatorsView.tsx/ADMTitleInstitutionVariableForm";
import ADMInstitutionVariableList from "../../components/adm/EvalInstruments/EditInstitutionInstruments/EditInstitutionIndicatorsView.tsx/ADMInstitutionVariableList";
import sendInstitutionChangesToBackend from "../../utils/data/AdmData/sendInstitutionChangesToBackend";

/**
 * Componente que muestra la vista para editar variables de institución
 */
function ADMEditInstitutionVariables(): ReactElement {
  const { categoryId } = useParams<{ categoryId: string }>();
  const [institutionVariables, setInstitutionVariables] = useState<
    InstitutionVariableStatus[]
  >([]);
  const [otherInstitutionVariables, setOtherInstitutionVariables] = useState<
    InstitutionVariableStatus[]
  >([]);
  const [deletedVariables, setDeletedVariables] = useState<
    InstitutionVariableStatus[]
  >([]);
  const [ready, setReady] = useState(false);
  const [category, setCategory] = useState<InstitutionCategoryStatus>();
  const [appState] = useContext(AppContext);
  const [maxVariableId, setMaxVariableId] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [changes, setChanges] = useState(false);
  const [reload, setReload] = useState(0);
  useEffect(() => {
    singleInstitutionCategorieData(
      appState,
      categoryId,
      setCategory,
      setInstitutionVariables,
      setOtherInstitutionVariables,
      setReady,
      setMaxVariableId
    );
    setDeletedVariables([]);
  }, [appState, categoryId, reload]);

  /**
   * Funcion que se activa al apretar el boton de guardar cambios y envia datos al backend.
   */
  function onSave() {
    let errorSlugRepeated = false;
    let errorOtherSlugRepeated = false;
    const repeatedSlugs: string[] = [];
    const repeatedOtherSlugs: string[] = [];
    institutionVariables.forEach((vari) => {
      repeatedSlugs.push(vari.indicator_slug);
      repeatedOtherSlugs.push(vari.indicator_slug);
    });
    otherInstitutionVariables.forEach((vari) => {
      repeatedOtherSlugs.push(vari.indicator_slug);
    });
    errorSlugRepeated = new Set(repeatedSlugs).size !== repeatedSlugs.length;
    errorOtherSlugRepeated =
      new Set(repeatedOtherSlugs).size !== repeatedOtherSlugs.length;
    if (errorSlugRepeated) {
      setErrorMessage(" y no pueden existir nombres repetidos");
    } else if (errorOtherSlugRepeated) {
      setErrorMessage(
        " y no puede existir un nombre repetido en otra categoría"
      );
    } else if (category) {
      setErrorMessage("");
      sendInstitutionChangesToBackend(
        setReady,
        deletedVariables,
        appState,
        institutionVariables,
        reload,
        setReload,
        setChanges
      );
    }
  }

  return (
    <CargandoScreen ready={ready}>
      {ready ? (
        <>
          <Prompt
            when={changes}
            message="Tienes cambios sin guardar, ¿seguro que quieres abandonar la pagina?"
          />
          <Container>
            {category ? (
              <ADMEditInstitutionIndicatorsTitle
                category={category}
                errorMessage={errorMessage}
                onSave={onSave}
                changes={changes}
              />
            ) : (
              <></>
            )}
            <div tw="flex flex-col flex-grow bg-ghostwhite relative">
              <>
                <ADMTitleInstitutionVariableForm />
                <ADMInstitutionVariableList
                  setInstitutionVariables={setInstitutionVariables}
                  institutionVariables={institutionVariables}
                  maxVariableId={maxVariableId}
                  setMaxVariableId={setMaxVariableId}
                  deletedVariables={deletedVariables}
                  setDeletedVariables={setDeletedVariables}
                  setChanges={setChanges}
                  categoryId={parseInt(categoryId, 10)}
                />
                <VolverButton to={Routes.evaluation_instruments} />
              </>
            </div>
          </Container>
        </>
      ) : (
        <></>
      )}
    </CargandoScreen>
  );
}

export default ADMEditInstitutionVariables;
