import { ResponseForm } from "../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";
import setForms from "./admSetForms";

/**
 * Función que retorna un diccionario con el titulo de cada indicador.
 *
 * @param setIndicatorsNames setter de nombres de indicadores
 * @param setIndicatorForm setter de formulario de indicador
 * @param token token
 * @param institutionId id de institucion
 * @param setReady setter de ready
 */
function admIndicatorsTitle(
  setIndicatorsNames: React.Dispatch<
    React.SetStateAction<Record<string, string | boolean | number | null>>
  >,
  setIndicatorForm: React.Dispatch<React.SetStateAction<ResponseForm[]>>,
  token: string | null,
  institutionId: string,
  setReady: React.Dispatch<React.SetStateAction<boolean>>
): void {
  let apiKey = "";
  if (token !== null) {
    apiKey = token;
  }
  const titles: Record<string, string> = {};
  if (apiKey) {
    fetchAPI<APIActions["fetchIndicators"]>(
      APIRoutes.fetchIndicators,
      {
        method: "GET",
      },
      apiKey
    )
      .then((indicators) => {
        indicators.forEach((indicator) => {
          titles[indicator.slug] = indicator.title;
        });
        setIndicatorsNames(titles);
        setForms(indicators, setIndicatorForm, apiKey, setReady, institutionId);
      })
      .catch(() => {}); // TODO
  }
}
export default admIndicatorsTitle;
