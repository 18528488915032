/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { ReactElement, useContext, useRef, useState } from "react";
import "twin.macro";
import { Link } from "react-router-dom";
import { AppContext } from "../../dispatcher";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import Modal from "../common/Modal";
import { CenteredPopup } from "../common/utils/Popup";
import { User } from "../../types/backendTypes";
import LoadingFormButton from "../common/LoadingFormButton";
import Routes from "../../routers/routes";

/**
 * Datos de contacto del auditor
 *
 * @param props react props
 * @param props.user El usuario
 * @param props.reload La función de recarga de la página
 */
function ProfileData(props: { user: User; reload: () => void }): ReactElement {
  const { user, reload } = props;
  const [appState] = useContext(AppContext);
  return (
    <div tw="w-full bg-resies_lightpurple grid grid-cols-6 px-4 py-2 shadow-md">
      <div tw="h-full flex pr-2">
        <img
          src={user.photo_url || "/blank_avatar.png"}
          alt="perfil-aud"
          tw="object-cover h-24"
        />
      </div>
      <div tw="h-full col-span-3">
        <div tw="flex flex-col flex-wrap justify-around h-full w-full">
          <div tw="">
            Nombre: {user.first_name} {user.last_name}{" "}
          </div>
          <div tw="">
            Cargo: {user.description ? user.description : "sin info"}
          </div>
          <div tw="">Teléfono: {user.phone_number}</div>
          <div tw="">Correo: {user.email}</div>
        </div>
      </div>
      <div tw="h-full col-span-2">
        <div tw="flex flex-col justify-around h-full">
          {user.id.toString() !== appState.userId && (
            <div tw="my-1">
              <Modal user={user} />
            </div>
          )}
          <div tw="my-1">
            <EditorPopUp user={user} reload={reload} />
          </div>
          <div tw="my-1">
            <Link to={Routes.changePassword}>
              <button
                type="button"
                tw="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md w-full text-center cursor-pointer"
              >
                Cambiar contraseña
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

/** EditorPopUp. It handles user profile edition. Neat, right?
 *
 * @param props0 react props
 * @param props0.user user
 * @param props0.reload la función para recargar los elementos de la página
 */
export function EditorPopUp({
  user,
  reload,
}: {
  user: User;
  reload: () => void;
}): ReactElement {
  const ref = useRef<() => void>(null);
  const [state] = useContext(AppContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <button
      type="button"
      onClick={() => {
        if (ref.current !== null) ref.current();
      }}
      id="editor"
      className="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md w-full text-center cursor-pointer"
      onKeyUpCapture={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      Editar Perfil
      <CenteredPopup ref={ref} width={500} height={500}>
        <div className="bg-resies_lightgreen font-sans text-black rounded-lg popup h-full flex flex-col items-center py-10">
          <div tw="my-auto">
            ¿Qué desea editar?
            <div tw="flex flex-col items-center">
              {[
                ["first_name", "Nombre"],
                ["last_name", "Apellido"],
                ["description", "Cargo"],
                ["email", "Correo"],
                ["phone_number", "Teléfono"],
              ].map((obj) => {
                const [attrName, title] = obj;
                return (
                  <div className="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md w-1/2 text-center my-1 pointer click w-full">
                    {title}
                    <div className="bg-resies_lightgreen font-sans text-black rounded-lg popup">
                      <input
                        type="text"
                        tw="text-center w-full"
                        id={`${attrName}-input`}
                        defaultValue={user[attrName as keyof User] as string}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                  </div>
                );
              })}
              <input
                type="password"
                placeholder="Contraseña"
                id="password-input"
                tw="rounded-lg text-center"
              />
              <LoadingFormButton
                className="px-2 py-1 bg-resies_blue1 text-ghostwhite rounded-md w-1/2 text-center my-1"
                label="OK"
                loading={loading}
                onClick={() => {
                  if (!state.token) return;
                  setError(false);
                  setLoading(true);
                  const gen = (attrName: string) =>
                    (
                      document.getElementById(
                        `${attrName}-input`
                      ) as HTMLInputElement
                    ).value;
                  const obj: {
                    password: string;
                    first_name?: string;
                    last_name?: string;
                    description?: string;
                    email?: string;
                    phone_number?: string;
                  } = { password: "" };
                  (
                    [
                      "password",
                      "first_name",
                      "last_name",
                      "description",
                      "email",
                      "phone_number",
                    ] as Array<
                      | "password"
                      | "first_name"
                      | "last_name"
                      | "description"
                      | "email"
                      | "phone_number"
                    >
                  ).forEach(
                    (
                      a:
                        | "password"
                        | "first_name"
                        | "last_name"
                        | "description"
                        | "email"
                        | "phone_number"
                    ) => {
                      const val = gen(a);
                      if (val.length > 0) {
                        obj[a] = val;
                      }
                    }
                  );
                  fetchAPI<APIActions["editProfile"]>(
                    APIRoutes.editProfile,
                    {
                      method: "PUT",
                      body: obj,
                    },
                    state.token
                  )
                    .then(() => reload())
                    .then(() => {
                      setLoading(false);
                      if (ref.current) ref.current();
                    })
                    .catch(() => {
                      setError(true);
                      setLoading(false);
                    });
                }}
              />
              {error && <div>Ha ocurrido un error. inténtelo de nuevo</div>}
            </div>
          </div>
        </div>
      </CenteredPopup>
    </button>
  );
}
export default ProfileData;
