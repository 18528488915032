import React, { ReactElement } from "react";
import "twin.macro";
import { Indicator, User } from "../../../types/backendTypes";
import CargandoScreen from "../../common/CargandoScreen";

/**
 * Lista que muestra los indicadores seleccionados.
 *
 * @param props react props.
 * @param props.selectedIndicators Son los indicadores seleccionados.
 * @param props.setSelectedIndicators SetState de los indicadores seleccionados.
 * @param props.indicatorsReady Indica si ya se procesó una request de fetch de indicadores
 * @param props.selectedUser usuario seleccionado
 * @param props.userSelectedIndicators indicadores seleccionados
 * @param props.setUserSelectedIndicators settear indicadores seleccionados del usuario
 */
function SelectedIndicators(props: {
  selectedIndicators: Indicator[];
  indicatorsReady: boolean;
  setSelectedIndicators: React.Dispatch<React.SetStateAction<Indicator[]>>;
  selectedUser: User | undefined;
  userSelectedIndicators: Record<number, Indicator[]>;
  setUserSelectedIndicators: React.Dispatch<
    React.SetStateAction<Record<number, Indicator[]>>
  >;
}): ReactElement {
  const {
    selectedIndicators,
    setSelectedIndicators,
    indicatorsReady,
    selectedUser,
    userSelectedIndicators,
    setUserSelectedIndicators,
  } = props;

  /**
   * Funcion que maneja la des-seleccion de indicadores
   *
   * @param ind indicador que se des-seleccionara.
   */
  function onClickHandler(ind: Indicator) {
    const copy = [...selectedIndicators];
    const index = copy.findIndex((ind2) => ind2.id === ind.id);
    const userIndicators = { ...userSelectedIndicators };
    if (index !== -1) {
      copy.splice(index, 1);
      setSelectedIndicators(copy);
    } else {
      copy.push(ind);
      setSelectedIndicators(copy);
    }
    if (selectedUser) {
      userIndicators[selectedUser.id] = [...copy];
      setUserSelectedIndicators({ ...userIndicators });
    }
  }

  return (
    <div tw="col-span-8">
      <div tw="bg-resies_blue1 font-sans text-header2 font-semibold text-resies_lightpurple px-6">
        Indicadores Asignados
      </div>
      <div tw="flex flex-col bg-ghostwhite h-64 overflow-y-auto pl-5 py-3 border border-black">
        <CargandoScreen ready={indicatorsReady}>
          {selectedIndicators.map((indicator) => (
            <div tw="flex flex-row" key={indicator.id}>
              <button
                type="button"
                tw="mr-2"
                onClick={() => onClickHandler(indicator)}
                data-testid={`selected${indicator.id}`}
              >
                <img src="/cancel.png" alt="cancel" />
              </button>
              <p>
                {indicator.slug}: {indicator.title}
              </p>
            </div>
          ))}
        </CargandoScreen>
      </div>
    </div>
  );
}

export default SelectedIndicators;
