import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import "twin.macro";
import { AppContext } from "../../../dispatcher";
import useRESIESDates from "../../../hooks/useRESIESDates";
import { ResponseComment } from "../../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../../utils/fetchAPI";
import NormalInstitutionalComments from "./NormalInstitutionalComments";

/**
 * Componente donde se muestran los comentarios sobre un dato institucional
 */
function InstitutionalEvidenceComments(): ReactElement {
  const [appState] = useContext(AppContext);
  const { responseId } = useParams<{ responseId: string }>();
  const [allComments, setAllComments] = useState<ResponseComment[]>([]);
  const [reload, setReload] = useState(0);
  const [, currentProcess, currentInstitutionProcess] = useRESIESDates();

  const getActualCurrentProcess = () => {
    if (currentInstitutionProcess) {
      return currentInstitutionProcess.current_process;
    }

    return currentProcess;
  };

  const reloadComments = useCallback(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["fetchInstitutionalResponseComments"]>(
      APIRoutes.fetchInstitutionalResponseComments,
      {
        method: "GET",
        routeParams: {
          response_variable_id: responseId,
        },
      },
      appState.token
    ).then((comments) => {
      setAllComments(comments);
    });
  }, [appState.token, responseId]);

  useEffect(() => reloadComments(), [reloadComments, reload]);

  /**
   * Funcion que maneja el envio del comentario al backend
   *
   * @param comment comentario que se quiere enviar
   * @param setComment Setter del comentario
   */
  function onSubmit(
    comment: string,
    setComment: React.Dispatch<React.SetStateAction<string>>
  ) {
    if (!appState.token) return;
    fetchAPI<APIActions["createInstitutionalResponseComment"]>(
      APIRoutes.createInstitutionalResponseComment,
      {
        method: "POST",
        body: {
          response_variable_id: parseInt(responseId, 10),
          content: comment,
          type: getActualCurrentProcess()?.name || "",
        },
      },
      appState.token
    ).then(() => {
      setComment("");
      setReload(reload + 1);
    });
  }

  return (
    <>
      <NormalInstitutionalComments
        onSubmit={onSubmit}
        allComments={allComments}
        reloadComments={reloadComments}
      />
    </>
  );
}

export default InstitutionalEvidenceComments;
