import React, { useCallback, useContext, useEffect, useState } from "react";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import { InstitutionMember, User } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "../common/CargandoScreen";
import Modal from "../common/Modal";
import ProgressBar from "../common/ProgressBar";

/**
 * Miembros de la institución del RDI
 */
function MembersMyInstitution(): React.ReactElement {
  const [appState] = useContext(AppContext);
  const [members, setMembers] = useState<InstitutionMember[]>([]);
  const [ready, setReady] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const { token } = appState;
  const loadData = useCallback(() => {
    if (!token || !appState.institutionId) return;
    fetchAPI<APIActions["membersOverview"]>(
      APIRoutes.membersOverview,
      { method: "GET" },
      token
    ).then((details) => {
      setMembers(
        details.institution_members.filter((member) => member.role === "MDI")
      );
    });
    fetchAPI<APIActions["fetchInstitutionDetails"]>(
      APIRoutes.fetchInstitutionDetails,
      {
        method: "GET",
        routeParams: { institution_id: appState.institutionId },
      },
      token
    ).then((details) => {
      setUsers(details.members.filter((member) => member.user_type));
      setReady(true);
    });
  }, [appState.institutionId, token]);

  useEffect(() => loadData(), [loadData]);

  /**
   * Desasigna un mdi de una institución
   *
   * @param mdi El mdi a desasignar
   */
  function unassignMdi(mdi: InstitutionMember) {
    if (mdi.role !== "MDI" || !appState.token) return;
    fetchAPI<APIActions["unassignMDI"]>(
      APIRoutes.unassignMDI,
      {
        method: "POST",
        routeParams: {
          user_id: mdi.id.toString(),
        },
      },
      appState.token
    ).then(() => loadData());
  }

  return (
    <div tw="mb-5">
      <CargandoScreen ready={ready}>
        {ready ? (
          <>
            <div className="font-semibold text-header2 text-resies_blue1">
              Completitud por responsable
            </div>
            <div tw="flex-col flex bg-resies_lightpurple">
              <div tw="flex flex-row bg-resies_blue2 text-white font-bold h-5">
                <div tw="text-center w-3/12">NOMBRE</div>
                <div tw="text-center w-3/12">PORCENTAJE DE AVANCE</div>
                <div tw="text-center w-3/12">VARIABLES COMPLETADAS</div>
                <div tw="text-center w-3/12">ACCIONES</div>
              </div>
              <div
                className="overflow-y-scroll flex-grow-1"
                style={{ height: "250px" }}
              >
                {members.map((d) => {
                  return (
                    <div
                      key={d.id}
                      tw="flex flex-row justify-evenly p-3"
                      style={{ height: "40px" }}
                    >
                      <div tw="text-center w-3/12">{`${d.name}`}</div>
                      <div tw="text-center w-3/12">
                        <div tw="h-full">
                          <ProgressBar
                            completed={
                              (d.total_responses /
                                (d.total_assigned_variables !== 0
                                  ? d.total_assigned_variables
                                  : 1)) *
                              100
                            }
                          />
                        </div>
                      </div>
                      <div tw="text-center w-3/12">
                        {d.total_responses}/{d.total_assigned_variables}
                      </div>
                      <div tw="text-center w-1/12">
                        {users.filter((uss) => uss.id === d.id)[0] ? (
                          <Modal
                            user={users.filter((uss) => uss.id === d.id)[0]}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <div tw="text-center w-2/12 flex flex-row justify-evenly">
                        <div>
                          {d.role === "MDI" && (
                            <button
                              type="button"
                              tw="px-4 bg-resies_blue1 text-ghostwhite rounded-md"
                              onClick={() => unassignMdi(d)}
                            >
                              Desasignar
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </CargandoScreen>
    </div>
  );
}

export default MembersMyInstitution;
