import React, { ReactElement } from "react";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import ADMPanelNavbar from "../../components/admin/ADMPanelNavbar";
import useCategoriesOverview from "../../hooks/useCategoriesOverview";
import InstitutionOverviewIndex from "../../components/common/InstitutionOverviewIndex";
import TotalInstitutionProgress from "../../components/admin/TotalInstitutionProgress";

/**
 * Instituciones del panel de administracion
 */
function ADMPanelInstitutions(): ReactElement {
  const [overview, ready] = useCategoriesOverview();
  return (
    <div>
      <Container>
        <ADMPanelNavbar />
        <CargandoScreen ready={ready}>
          <Container>
            <TotalInstitutionProgress overview={overview || []} />
            <InstitutionOverviewIndex overview={overview || []} />
          </Container>
        </CargandoScreen>
      </Container>
    </div>
  );
}

export default ADMPanelInstitutions;
