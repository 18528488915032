import { Tooltip } from "@material-ui/core";
import React, { ReactElement } from "react";
import "twin.macro";
import {
  Indicator,
  IndicatorOverview,
  InstitutionMember,
} from "../../types/backendTypes";

/**
 * Componente que muestra la ultima columna del Category Detail Progress para ADMIN
 *
 * @param props react props
 * @param props.indicators lista de indicadores
 * @param props.ind indicador mostrado en la fila
 * @param props.institutionMembers lista de miembros de la institución
 */
function ADMDetailProgress(props: {
  indicators: Indicator[];
  ind: IndicatorOverview;
  institutionMembers: InstitutionMember[];
}): ReactElement {
  const { indicators, ind, institutionMembers } = props;
  const indicator = indicators.filter(
    (indicator) => indicator.id === ind.id
  )[0];

  return (
    <>
      {indicator && indicator.mdiusers && (
        <>
          {indicator.mdiusers.filter((mdi) =>
            institutionMembers.map((member) => member.id).includes(mdi.user_id)
          ) ? (
            <Tooltip
              title={
                indicator.mdiusers.filter((mdi) =>
                  institutionMembers
                    .map((member) => member.id)
                    .includes(mdi.user_id)
                ).length > 1
                  ? indicator.mdiusers
                      .filter((mdi) =>
                        institutionMembers
                          .map((member) => member.id)
                          .includes(mdi.user_id)
                      )
                      .map((mdi, index) => {
                        if (index > 0) {
                          return (
                            <div>
                              {mdi.first_name} {mdi.last_name}
                            </div>
                          );
                        }
                        return <></>;
                      })
                  : ""
              }
              placement="top"
            >
              <div>
                {
                  indicator.mdiusers.filter((mdi) =>
                    institutionMembers
                      .map((member) => member.id)
                      .includes(mdi.user_id)
                  )[0]?.first_name
                }{" "}
                {
                  indicator.mdiusers.filter((mdi) =>
                    institutionMembers
                      .map((member) => member.id)
                      .includes(mdi.user_id)
                  )[0]?.last_name
                }{" "}
                {indicator.mdiusers.filter((mdi) =>
                  institutionMembers
                    .map((member) => member.id)
                    .includes(mdi.user_id)
                ).length > 1 ? (
                  <>
                    y{" "}
                    {indicator.mdiusers.filter((mdi) =>
                      institutionMembers
                        .map((member) => member.id)
                        .includes(mdi.user_id)
                    ).length - 1}{" "}
                    más
                  </>
                ) : (
                  ""
                )}
              </div>
            </Tooltip>
          ) : (
            "No hay nadie asignado"
          )}
        </>
      )}
    </>
  );
}

export default ADMDetailProgress;
