import { AppState } from "../../../dispatcher";
import { ResponseVariable } from "../../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

/**
 * Funcion que obtiene las respuesta con id responseId
 *
 * @param appState estado de app
 * @param institutionId id de institucion
 * @param responseId id de respuesta
 * @param setResponseVariable setter de variable de respuesta
 * @param setError setter de error
 * @param setReady setter de listo
 */
function getAuditableResponses(
  appState: AppState,
  institutionId: string,
  responseId: string,
  setResponseVariable: React.Dispatch<
    React.SetStateAction<ResponseVariable | undefined>
  >,
  setError: React.Dispatch<React.SetStateAction<boolean>>,
  setReady: React.Dispatch<React.SetStateAction<boolean>>
): void {
  if (!appState.token) {
    return;
  }
  fetchAPI<APIActions["auditableResponses"]>(
    APIRoutes.auditableResponses,
    {
      method: "GET",
    },
    appState.token
  )
    .then((responses) => {
      const institutionResponses = responses.filter(
        (elem) => elem.institution.id.toString() === institutionId
      );
      const response = institutionResponses[0].responses.filter(
        (elem) => elem.id.toString() === responseId
      );
      setResponseVariable(response[0]);
      setReady(true);
    })
    .catch(() => setError(true));
}

export default getAuditableResponses;
