import React, { ReactElement } from "react";
import "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

/**
 * Un botón en forma de círculo con un texto dentro, para usar dentro del componente PaginatedListNavigator
 *
 * @param props react props.
 * @param props.label El número de página
 * @param props.active Si el link está activo o no
 * @param props.onClick El callback a llamar cuando se haga click
 */
function CircledButton(props: {
  label: number | "<" | ">";
  active: boolean;
  onClick: () => void;
}): ReactElement {
  const { active, onClick, label } = props;
  if (label === "<") {
    return (
      <FontAwesomeIcon
        tw="text-resies_purple cursor-pointer"
        icon={faArrowCircleLeft}
        size="2x"
        title="Retroceder una página"
        onClick={onClick}
      />
    );
  }
  if (label === ">") {
    return (
      <FontAwesomeIcon
        tw="text-resies_purple cursor-pointer"
        icon={faArrowCircleRight}
        size="2x"
        title="Avanzar una página"
        onClick={onClick}
      />
    );
  }

  return (
    <button
      type="button"
      tw="rounded-full p-2 h-8 w-8 text-header2 font-bold leading-4"
      className={
        active
          ? "bg-ghostwhite border border-resies_purple text-resies_purple"
          : "bg-resies_purple text-ghostwhite "
      }
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export default CircledButton;
