import React, { ReactElement } from "react";
import "twin.macro";
import { IndicatorStatus } from "../../../../../types/utilTypes";

/**
 * Componente que muestra los indicadores que no estan en modo de edición.
 *
 * @param props react props.
 * @param props.ind Indicador que se mostrara en el componente
 * @param props.indicatorSelected Indicador actualmente seleccionado.
 * @param props.indicators Status de todos los indicadores
 * @param props.setIndicatorSelected setState que cambio el indicador seleccionado
 * @param props.setIndicators setState que cambia el status de los indicadores
 * @param props.onClickDeleteIndicator Funcion que elimina el indicador clickeado.
 */
function ADMReadyIndicator(props: {
  ind: IndicatorStatus;
  indicatorSelected: IndicatorStatus | undefined;
  indicators: IndicatorStatus[];
  setIndicatorSelected: React.Dispatch<
    React.SetStateAction<IndicatorStatus | undefined>
  >;
  setIndicators: React.Dispatch<React.SetStateAction<IndicatorStatus[]>>;
  onClickDeleteIndicator: (e: React.MouseEvent<HTMLButtonElement>) => void;
}): ReactElement {
  const {
    ind,
    indicatorSelected,
    indicators,
    setIndicatorSelected,
    setIndicators,
    onClickDeleteIndicator,
  } = props;

  /**
   * Funcion que selecciona el indicador clickeado.
   *
   * @param e event
   */
  function onClickIndicator(e: React.MouseEvent<HTMLButtonElement>) {
    const copyIndicators = [...indicators];
    const clickedIndicator = [
      ...copyIndicators.filter(
        (indi) => indi.id.toString() === e.currentTarget.name
      ),
    ][0];
    clickedIndicator.statusSlug = "edit";
    setIndicators([...copyIndicators]);
    setIndicatorSelected(clickedIndicator);
  }
  const color = ind.generalStatus === "no-click" ? "bg-gray" : "";

  return (
    <div
      tw="flex flex-row"
      className={
        indicatorSelected === ind
          ? "border-l-4 border-resies_blue2 bg-resies_lightgreen"
          : ""
      }
      key={ind.id}
    >
      <div
        tw="font-semibold flex flex-row justify-between w-full px-2 focus:outline-none"
        className={
          indicatorSelected === ind
            ? `text-resies_blue2 ${color}`
            : `text-black opacity-50 ${color}`
        }
      >
        {ind.generalStatus === "no-click" ? (
          <>
            <button
              type="button"
              tw="w-6/12 focus:outline-none font-bold"
              name={ind.id.toString()}
              onClick={(e) => onClickIndicator(e)}
              disabled
            >
              {ind.slug}
            </button>
            <button
              type="button"
              tw="w-2/12 my-auto"
              onClick={(e) => onClickDeleteIndicator(e)}
              name={ind.id.toString()}
              disabled
            >
              <img
                src="/trash.png"
                alt="trash"
                tw="object-contain my-auto mr-2"
              />
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              tw="w-6/12 focus:outline-none font-bold"
              name={ind.id.toString()}
              onClick={(e) => onClickIndicator(e)}
            >
              {ind.slug}
            </button>
            <button
              type="button"
              tw="w-2/12 my-auto"
              onClick={(e) => onClickDeleteIndicator(e)}
              name={ind.id.toString()}
            >
              <img
                src="/trash.png"
                alt="trash"
                tw="object-contain my-auto mr-2"
              />
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default ADMReadyIndicator;
