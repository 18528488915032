import React, { ReactElement } from "react";
import { Link, useParams } from "react-router-dom";
import "twin.macro";
import Routes from "../../../routers/routes";

/**
 * Formulario correspondiente a una variable de un indicador.
 *
 * @param props react props.
 * @param props.elem Datos del formulario de la variable.
 * @param props.formState Estado que maneja los datos actualmente ingresados en los formularios de cada variable.
 */
function AUDIndicatorForm(props: {
  elem: Record<string, string | number | boolean | null>;
  formState: Record<string, string | number | boolean | null>[];
}): ReactElement {
  const { elem, formState } = props;
  const key = formState.indexOf(elem);
  const { institutionId } = useParams<{ institutionId: string }>();
  let status = "";
  if (
    elem.audit_status === "accepted" ||
    elem.audit_status === "final_accepted"
  ) {
    status = "Auditado";
  } else if (elem.audit_status === "pending") {
    status = "Por auditar";
  } else {
    status = "Rechazado";
  }
  return (
    <div
      tw="flex flex-row grid grid-cols-10
          content-center border-b-2 border-darkgray"
    >
      <div tw="flex col-span-4 text-justify min-h-100 xl:w-96 lg:w-52 mx-auto">
        <p tw="xl:text-sm text-xs font-normal text-black">{elem.slug}</p>
      </div>
      <div tw="flex col-span-1 h-full mx-auto flex-wrap">
        <p tw="h-1/6 my-auto text-barra text-very_darkgray font-bold">
          {formState[key].value ? formState[key].value : "Incompleto"}
        </p>
      </div>

      <div tw="flex col-span-2 justify-center h-full flex-wrap max-h-28">
        <p tw="h-1/6 my-auto text-body text-very_darkgray font-normal">
          {status}
        </p>
      </div>
      <div tw="flex flex-col col-span-2 text-center h-full flex-wrap mr-8 justify-center">
        {elem.response_id && elem.variable_id && elem.value ? (
          <Link
            to={Routes.AUDevidence.replace(
              ":variableId",
              elem.variable_id.toString()
            )
              .replace(":responseId", elem.response_id.toString())
              .replace(":institutionId", institutionId)}
          >
            <button
              type="button"
              tw="bg-resies_green  py-1 mb-2 xl:w-8/12 w-full h-5/6 mx-auto rounded-md 
                    flex flex-row flex-wrap content-center max-h-12"
            >
              <p tw="text-ghostwhite mr-2 font-normal text-xs lg:text-sm mx-auto">
                Ir a la evidencia
              </p>
            </button>
          </Link>
        ) : (
          <>
            {elem.variable_id && (
              <Link
                to={Routes.AUDevidence.replace(
                  ":variableId",
                  elem.variable_id.toString()
                )
                  .replace(":responseId", "missing")
                  .replace(":institutionId", institutionId)}
              >
                <button
                  type="button"
                  tw="bg-resies_green  py-1 mb-2 xl:w-8/12 w-full h-5/6 mx-auto rounded-md 
                    flex flex-row flex-wrap content-center max-h-12 disabled:opacity-70"
                >
                  <p tw="text-ghostwhite mr-2 font-normal text-xs lg:text-sm mx-auto">
                    No se ha ingresado una respuesta
                  </p>
                </button>
              </Link>
            )}
          </>
        )}
        <div tw="xl:w-10/12 w-full  h-1/6 mx-auto text-xs lg:text-sm max-h-8">
          <p tw="text-darkgray font-normal text-body">{status}</p>
        </div>
      </div>
    </div>
  );
}

export default AUDIndicatorForm;
