/* eslint-disable no-nested-ternary */
import React, { ReactElement, useContext, useState } from "react";
import "twin.macro";
import { AppContext, AppState, Dispatcher } from "../../dispatcher";
import { InstitutionWithAuditor, User } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import LoadingFormButton from "../common/LoadingFormButton";

/**
 * Asignador de instituciones a auditor
 *
 * @param props react props
 * @param props.auditor auditor
 * @param props.audId ID del auditor
 * @param props.institution institucion
 * @param props.setAuditor función que desasigna el auditor
 * @param props.setInstitutions función que asigna el auditor
 */
function ADMAUDInstitutionAssignerRow(props: {
  institution: InstitutionWithAuditor;
  audId: string;
  auditor: User;
  setAuditor: (auditor: User) => void;
  setInstitutions: React.Dispatch<
    React.SetStateAction<InstitutionWithAuditor[]>
  >;
}): ReactElement {
  const { institution, audId, auditor, setAuditor, setInstitutions } = props;
  const [loading, setLoading] = useState(false);
  const [appState]: [AppState, Dispatcher] = useContext(AppContext);

  /**
   * Asigna una institución al auditor
   */
  function setAuditorInstitution() {
    setLoading(true);
    if (appState.token) {
      fetchAPI<APIActions["assignInstitution"]>(
        APIRoutes.assignInstitution,
        {
          method: "POST",
          routeParams: {
            user_id: audId,
            institution_id: institution.institution.id.toString(),
          },
        },
        appState.token
      ).then((aud) => {
        if (aud.user_type === "AUD") {
          setAuditor(aud);
        }
        if (!appState.token) return;
        fetchAPI<APIActions["getInstitutionsWithAuditor"]>(
          APIRoutes.getInstitutionsWithAuditor,
          { method: "GET" },
          appState.token
        ).then((ins) => {
          setInstitutions(
            ins.sort((ins1, ins2) => ins1.institution.id - ins2.institution.id)
          );
          setLoading(false);
        });
      });
    }
    return institution;
  }

  /**
   * Desasigna la institución al auditor
   */
  function unassignAuditorInstitution() {
    setLoading(true);
    if (!appState.token) return;
    fetchAPI<APIActions["unassignAuditor"]>(
      APIRoutes.unassignAuditor,
      {
        method: "POST",
        routeParams: { institution_id: institution.institution.id.toString() },
      },
      appState.token
    ).then(() => {
      if (!appState.token) return;
      fetchAPI<APIActions["getInstitutionsWithAuditor"]>(
        APIRoutes.getInstitutionsWithAuditor,
        { method: "GET" },
        appState.token
      ).then((ins) => {
        setInstitutions(
          ins.sort((ins1, ins2) => ins1.institution.id - ins2.institution.id)
        );
      });
      fetchAPI<APIActions["userProfile"]>(
        APIRoutes.userProfile,
        { method: "GET", routeParams: { user_id: audId } },
        appState.token
      ).then((aud) => {
        if (aud.user_type === "AUD") {
          setAuditor(aud);
        }
        setLoading(false);
      });
    });
  }
  return (
    <tr key={institution.institution.id}>
      <td tw="flex flex-row pl-10" style={{ height: "32px" }}>
        <div tw="flex w-auto h-full inline-block">
          <img
            src={
              institution.institution.logo_url
                ? institution.institution.logo_url
                : "/standard-logo.png"
            }
            alt="ins"
            tw="object-contain h-full pb-1"
          />
        </div>
        <div tw="pl-2 align-middle">{institution.institution.name}</div>
      </td>
      <td tw="text-center" style={{ height: "32px" }}>
        {institution.responsible_auditor
          ? `${institution.responsible_auditor.first_name} ${institution.responsible_auditor.last_name}`
          : ""}
      </td>
      <td tw="text-center" style={{ height: "32px" }}>
        {institution.responsible_auditor ? (
          institution.responsible_auditor.id === auditor.id ? (
            <div
              role="button"
              onClick={unassignAuditorInstitution}
              tabIndex={0}
              onKeyDown={() => {}} // solo por un error de eslint
            >
              <LoadingFormButton
                loading={loading}
                label="Desasignar"
                className="px-2 py-1 w-24 bg-darkred text-ghostwhite rounded-md ml-auto"
              />
            </div>
          ) : (
            ""
          )
        ) : (
          <div
            role="button"
            onClick={setAuditorInstitution}
            tabIndex={0}
            onKeyDown={() => {}} // solo por un error de eslint
          >
            <LoadingFormButton
              loading={loading}
              label="Asignar"
              className="px-2 py-1 w-24 bg-resies_blue1 text-ghostwhite rounded-md ml-auto"
            />
          </div>
        )}
      </td>
    </tr>
  );
}

export default ADMAUDInstitutionAssignerRow;
