import React, { ReactElement, useContext, useState } from "react";
import "twin.macro";
import { AppContext } from "../../../../dispatcher";
import { CategoryStatus, SubCategoryStatus } from "../../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../../../utils/fetchAPI";

/**
 * Componente que muestra la opcion de añadir una nueva subcategoria.
 *
 * @param props props
 * @param props.maxSubId id más alto de subcategoria
 * @param props.setMaxSubId setter de id más alto
 * @param props.category categoria de la subcategoria nueva
 * @param props.subCategoryStatus status de las subcategorias
 * @param props.setSubCategoryStatus setter del status de las subcategorias
 * @param props.reload numero de reloads
 * @param props.setReload setter de numero de reloads
 * @param props.setReady setter para el estado de carga
 * @param props.setErrorMessage setter de mensaje de error
 */
function NewSubcategoryOption(props: {
  maxSubId: number;
  setMaxSubId: React.Dispatch<React.SetStateAction<number>>;
  category: CategoryStatus;
  subCategoryStatus: SubCategoryStatus[];
  setSubCategoryStatus: React.Dispatch<
    React.SetStateAction<SubCategoryStatus[]>
  >;
  reload: number;
  setReload: React.Dispatch<React.SetStateAction<number>>;
  setReady: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}): ReactElement {
  const {
    maxSubId,
    setMaxSubId,
    category,
    subCategoryStatus,
    setSubCategoryStatus,
    reload,
    setReload,
    setReady,
    setErrorMessage,
  } = props;
  const [newSubcategoryName, setNewSubcategoryName] = useState("");
  const [appState] = useContext(AppContext);
  /**
   * Funcion que maneja el cambio de nombre de la nueva subcategoria creada.
   *
   * @param e evento que desencadena la funcion.
   */
  function onChangeNewSubcategory(e: React.ChangeEvent<HTMLInputElement>) {
    setNewSubcategoryName(e.target.value);
  }
  /**
   * Funcion que crea una nueva subcategoria cuando al hacer click el boton +
   */
  function onClickNewSubCategory() {
    if (!appState.token) return;
    const newSubcategory: SubCategoryStatus = {
      name: newSubcategoryName,
      id: maxSubId + 1,
      status: "ready",
      categoryId: category.id,
      categoryName: category.name,
      categoryDescription: category.description,
    };
    setMaxSubId(maxSubId + 1);
    const copySubCategories = [...subCategoryStatus];
    copySubCategories.push(newSubcategory);
    let errorNameRepeated = false;
    const names = copySubCategories.map((cate) => cate.name);
    errorNameRepeated = new Set(names).size !== names.length;
    if (errorNameRepeated) {
      setErrorMessage("No se pueden tener nombres repetidos");
      return;
    }
    setReady(false);
    fetchAPI<APIActions["createSubcategory"]>(
      APIRoutes.createSubCategory,
      {
        method: "POST",
        queryParams: {
          category_id: newSubcategory.categoryId.toString(),
          subcategory_name: newSubcategory.name,
        },
      },
      appState.token
    ).then(() => {
      setSubCategoryStatus(copySubCategories);
      setNewSubcategoryName("");
      setErrorMessage("");
      setReload(reload + 1);
    });
  }
  return (
    <div tw="flex flex-row">
      <input
        tw="text-header2 text-resies_purple font-normal w-10/12 bg-resies_lightgreen"
        placeholder="Nombre de la nueva subcategoría..."
        value={newSubcategoryName}
        onChange={(e) => onChangeNewSubcategory(e)}
      />
      <button
        type="button"
        tw="w-2/12 h-2/6 flex flex-wrap focus:outline-none"
        onClick={onClickNewSubCategory}
      >
        <img tw="object-contain my-auto mx-auto" src="/add.png" alt="add" />
      </button>
    </div>
  );
}

export default NewSubcategoryOption;
