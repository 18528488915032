import React, { ReactElement } from "react";
import "twin.macro";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/**
 * Fila en la tabla de plazos de los procesos de auditoría.
 *
 * @param props react props.
 * @param props.processName Nombre del proceso o evento.
 * @param props.finalDate fecha final
 * @param props.initDate fecha inicial
 * @param props.onChangeHandlerStart funcion que maneja el cambio de fechas iniciales
 * @param props.onChangeHandlerEnd funcion que maneja el cambio de fechas finales
 * @param props.disabled funcion para mostrar la fecha deshabilitada
 */
function ADMDeadlinesRow(props: {
  processName: string;
  initDate: string;
  finalDate: string;
  onChangeHandlerStart: (name: string, date: Date) => void;
  onChangeHandlerEnd: (name: string, date: Date) => void;
  disabled?: boolean;
  globalDates?: Array<string>;
}): ReactElement {
  const {
    processName,
    initDate,
    finalDate,
    onChangeHandlerStart,
    onChangeHandlerEnd,
    disabled,
    globalDates,
  } = props;
  return (
    <tr tw="flex w-full bg-resies_lightgreen my-3">
      <td tw="text-header2 font-semibold text-left pl-10 py-2 w-4/12">
        {processName}
      </td>
      <td tw="text-center p-2 w-4/12">
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={new Date(initDate)}
          onChange={(date: Date) => onChangeHandlerStart(processName, date)}
          selectsStart
          startDate={new Date(initDate)}
          endDate={new Date(finalDate)}
          disabled={disabled}
        />
        {globalDates && globalDates.length === 2 && (
          <p>
            <span>Fecha global:&nbsp;</span>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={new Date(globalDates[0])}
              onChange={(date: Date) => onChangeHandlerStart(processName, date)}
              disabled={true}
            />
          </p>
        )}
      </td>
      <td tw="text-center p-2 w-4/12">
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={new Date(finalDate)}
          onChange={(date: Date) => onChangeHandlerEnd(processName, date)}
          selectsEnd
          startDate={new Date(initDate)}
          endDate={new Date(finalDate)}
          minDate={new Date(initDate)}
          disabled={disabled}
        />
        {globalDates && globalDates.length === 2 && (
          <p>
            <span>Fecha global:&nbsp;</span>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={new Date(globalDates[1])}
              onChange={(date: Date) => onChangeHandlerStart(processName, date)}
              disabled={true}
            />
          </p>
        )}
      </td>
    </tr>
  );
}

export default ADMDeadlinesRow;
