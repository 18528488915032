import React, { ReactElement } from "react";
import "twin.macro";

const ProgressBar = ({ completed }: { completed: number }): ReactElement => {
  let bgcolor = "red";
  if (completed < 30) {
    bgcolor = "darkred";
  } else if (completed < 90) {
    bgcolor = "warning_yellow";
  } else {
    bgcolor = "resies_green";
  }

  const fillerStyles = {
    width: `${completed}%`,
  };

  return (
    <div tw="h-full w-full bg-resies_lightpurple rounded-lg overflow-hidden border border-gray flex flex-row">
      <div className={`bg-${bgcolor} h-full`} style={fillerStyles} />
    </div>
  );
};

export default ProgressBar;
