import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import { Institution } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "../common/CargandoScreen";
import { CenteredPopup } from "../common/utils/Popup";
/**
 * Boton para mails de recordatorio
 */
function BroadcastMail(): ReactElement {
  const ref = useRef<() => void>(null);
  const [mailType, setMailType] = useState("General");
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [ready, setReady] = useState(false);
  const [institutionSelected, setInstitutionSelected] = useState(0);
  const [typeSelected, setTypeSelected] = useState("RDI and MDI");
  const [message, setMessage] = useState("");
  const [sentMessage, setSentMessage] = useState("");
  const [appState] = useContext(AppContext);

  useEffect(() => {
    if (!appState.token) return;
    fetchAPI<APIActions["fetchInstitutions"]>(
      APIRoutes.fetchInstitutions,
      {
        method: "GET",
      },
      appState.token
    ).then((insts) => {
      insts.sort((a, b) => a.id - b.id);
      setInstitutions(insts);
      setInstitutionSelected(insts[0].id);
      setReady(true);
    });
  }, [appState.token]);

  /**
   * Función que maneja el cambio del tipo de usuario al cual se le mandara el mail de difusión.
   *
   * @param e Evento que desencadena el cambio
   */
  function onChangeTypeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
    setTypeSelected(e.target.value);
  }

  /**
   * Funcion que maneja el cambio en el mensaje de difusión
   *
   * @param e evento que desencadena el cambio
   */
  function onChangeMessage(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setMessage(e.target.value);
  }

  /**
   * Funcion que maneja el cambio del tipo de mensaje
   *
   * @param e evento que desencadena el cambio
   */
  function onChangeMailType(e: React.ChangeEvent<HTMLSelectElement>) {
    setMailType(e.target.value);
  }

  /**
   * Funcion que maneja el cambio institución seleccionada
   *
   * @param e evento que desencadena el cambio
   */
  function onChangeInstitution(e: React.ChangeEvent<HTMLSelectElement>) {
    setInstitutionSelected(parseInt(e.target.value, 10));
  }

  /**
   * Funcion que envia el mensaje de difusión
   */
  function onSend() {
    if (!appState.token) return;
    if (mailType === "General") {
      let typeList: string[] = [];
      if (typeSelected === "RDI and MDI") {
        typeList = ["RDI", "MDI"];
      } else if (typeSelected === "RDI") {
        typeList = ["RDI"];
      } else if (typeSelected === "AUD") {
        typeList = ["AUD"];
      }
      fetchAPI<APIActions["generalBroadcast"]>(
        APIRoutes.generalBroadcast,
        {
          method: "POST",
          body: {
            message,
            user_type: typeList,
          },
        },
        appState.token
      )
        .then(() => setSentMessage("Mensaje enviado correctamente."))
        .catch(() => setSentMessage("El mensaje no se ha podido enviar"));
    } else if (mailType === "Institution") {
      fetchAPI<APIActions["institutionBroadcast"]>(
        APIRoutes.institutionBroadcast,
        {
          method: "POST",
          body: {
            message,
            institution_id: institutionSelected,
          },
        },
        appState.token
      )
        .then(() => setSentMessage("Mensaje enviado correctamente."))
        .catch(() => setSentMessage("El mensaje no se ha podido enviar"));
    }
  }
  return (
    <CargandoScreen ready={ready}>
      <button
        type="button"
        onClick={() => {
          if (ref.current !== null) ref.current();
        }}
        className="flex bg-resies_purple rounded-sm max-h-12 mb-2 p-1 px-4 mr-4"
      >
        <p tw="font-bold text-base text-ghostwhite">Enviar Recordatorio</p>
      </button>
      <CenteredPopup width={700} height={500} ref={ref}>
        <div className="bg-resies_lightpurple font-sans text-black rounded-lg popup flex flex-col pb-10 h-full py-10">
          <div tw="flex flex-row justify-start mb-10">
            <p tw="text-resies_blue1 text-header2 font-semibold ml-5">
              Correo de difusión
            </p>
          </div>
          <div tw="flex flex-row justify-start mb-6">
            <p tw="text-resies_blue1 text-barra font-bold ml-16 my-auto mr-6">
              Tipo de correo:
            </p>
            <select
              tw="bg-ghostwhite p-2"
              value={mailType}
              onChange={(e) => onChangeMailType(e)}
            >
              <option value="General">General</option>
              <option value="Institution">Institución especifica</option>
            </select>
          </div>
          {mailType === "General" ? (
            <div tw="flex flex-row justify-start mb-6">
              <p tw="text-resies_blue1 text-barra font-bold ml-16 my-auto mr-6">
                Destinatarios:
              </p>
              <select
                tw="bg-ghostwhite p-2"
                value={typeSelected}
                onChange={(e) => onChangeTypeHandler(e)}
              >
                <option value="RDI and MDI">
                  Responsables e Informantes de Institución
                </option>
                <option value="RDI">Responsables de Institución</option>
                <option value="AUD">Auditores</option>
              </select>
            </div>
          ) : (
            <div tw="flex flex-row justify-start mb-6">
              <p tw="text-resies_blue1 text-barra font-bold ml-16 my-auto mr-6">
                Institución:
              </p>
              <select
                tw="bg-ghostwhite p-2"
                value={institutionSelected}
                onChange={(e) => onChangeInstitution(e)}
              >
                {institutions.map((ins) => (
                  <option value={ins.id}>{ins.name}</option>
                ))}
              </select>
            </div>
          )}
          <div tw="flex flex-row justify-start">
            <p tw="text-resies_blue1 text-barra font-bold ml-16 my-auto mr-6 mb-2">
              Mensaje:
            </p>
          </div>
          <div tw="flex flex-row justify-start mb-8">
            <textarea
              tw="text-black text-barra font-bold ml-16 my-auto mr-16 w-full h-40 opacity-50 px-2 py-4 resize-none"
              placeholder="Escriba su mensaje..."
              value={message}
              onChange={(e) => onChangeMessage(e)}
            />
          </div>
          <div tw="flex flex-row justify-end mr-16">
            {sentMessage ? (
              <p tw="px-8 py-2 text-black text-barra font-bold mr-4">
                {sentMessage}
              </p>
            ) : (
              <></>
            )}
            <button
              type="button"
              tw="rounded-xl px-8 py-2 bg-resies_purple text-white text-barra font-bold mr-4"
              onClick={() => {
                if (ref.current !== null) ref.current();
              }}
            >
              Cancelar
            </button>
            <button
              type="button"
              className={
                message
                  ? "rounded-xl px-8 py-2 bg-resies_purple text-white text-barra font-bold"
                  : "rounded-xl px-8 py-2 bg-resies_purple text-white text-barra font-bold opacity-50"
              }
              onClick={() => onSend()}
              disabled={message === ""}
            >
              Enviar
            </button>
          </div>
        </div>
      </CenteredPopup>
    </CargandoScreen>
  );
}

export default BroadcastMail;
