import { ANTLRInputStream, CommonTokenStream, Token } from "antlr4ts";
import { ExcelLangGrammarLexer } from "../../ANTLR/ExcelLangGrammarLexer";
import {
  ExcelLangGrammarParser,
  StartContext,
} from "../../ANTLR/ExcelLangGrammarParser";
import { AuxiliarFormula } from "../../types/backendTypes";
import {
  ExcelSyntaxError,
  ExcelLangErrorListener,
} from "./ExcelLangErrorListener";

/**
 * Parses a formula with the generated grammar. Must be the same grammar as in backend, or
 * it will not work fine.
 *
 * @param code The code to parse
 */
function parseFormula(code: string): {
  start: StartContext | null;
  errors: ExcelSyntaxError[];
} {
  const errorListener = new ExcelLangErrorListener();
  // TODO: change for non-deprecated class (WIP)
  const inputStream = new ANTLRInputStream(code);
  const lexer = new ExcelLangGrammarLexer(inputStream);
  lexer.removeErrorListeners();
  lexer.addErrorListener(errorListener);
  const tokenStream = new CommonTokenStream(lexer);
  const parser = new ExcelLangGrammarParser(tokenStream);
  parser.removeErrorListeners();
  parser.addErrorListener(errorListener);
  return { start: parser.start(), errors: errorListener.getErrors() };
}

/**
 * Retorna un html resaltado del token actual y los elementos anteriores a el
 *
 * @param code El código tokenizado
 * @param token El token actual
 * @param errors Los errores de sintaxis encontrados en el lexing y parsing
 * @param auxiliarFormulas Las fórmulas auxiliares actuales
 * @param variables Las variables del indicador
 * @param institutionalVariables Los datos de la institución
 */
function tokenHighlight(
  code: string,
  token: Token,
  errors: ExcelSyntaxError[],
  auxiliarFormulas?: AuxiliarFormula[],
  variables?: Set<string>,
  institutionalVariables?: Set<string>
) {
  const next = code.substring(token.startIndex, token.stopIndex + 1);
  // Si hay algun error de parsing relacionado con este token
  if (
    errors.find(
      (err) =>
        err.line === token.line &&
        err.charPositionInLine === token.charPositionInLine
    )
  ) {
    return `<span class="token error">${next}</span>`;
  }

  // Agregar acá las nuevas clases de highlight
  switch (token.type) {
    case ExcelLangGrammarLexer.NUMBER:
      return `<span class="token number">${next}</span>`;
    case ExcelLangGrammarLexer.LPAREN:
    case ExcelLangGrammarLexer.RPAREN:
      return `<span class="token paren">${next}</span>`;
    case ExcelLangGrammarParser.SUMA:
    case ExcelLangGrammarParser.SI:
    case ExcelLangGrammarParser.CLIP:
      return `<span class="token fun">${next}</span>`;
    case ExcelLangGrammarParser.PLUS:
    case ExcelLangGrammarParser.MINUS:
    case ExcelLangGrammarParser.MULSIGN:
    case ExcelLangGrammarParser.DIVSIGN:
    case ExcelLangGrammarParser.GTSIGN:
    case ExcelLangGrammarParser.LTSIGN:
    case ExcelLangGrammarParser.LEQSIGN:
    case ExcelLangGrammarParser.GEQSIGN:
    case ExcelLangGrammarParser.EQSIGN:
      return `<span class="token op">${next}</span>`;
    case ExcelLangGrammarParser.VAR:
      if (variables?.has(next)) {
        return `<span class="token var">${next}</span>`;
      }
      return `<span class="token error">${next}</span>`;
    case ExcelLangGrammarParser.AUX:
      if (auxiliarFormulas?.find((aux) => aux.name === next)?.code) {
        return `<span class="token aux">${next}</span>`;
      }
      return `<span class="token error">${next}</span>`;
    case ExcelLangGrammarParser.DI:
      if (institutionalVariables?.has(next)) {
        return `<span class="token di">${next}</span>`;
      }
      return `<span class="token error">${next}</span>`;
    case ExcelLangGrammarParser.COMMA:
      return `<span class="token comma">${next}</span>`;
    default:
      return next;
  }
}

/**
 * Resalta un código siguiendo la gramática para luego
 * pasarla al editor de texto
 *
 * @param code El código a resaltar
 * @param auxiliarFormulas Las formulas auxiliares utilizadas
 * @param variablesSet Una set con strings representando variables del indicador
 * @param institutionalVariablesSet Un set con string representando los datos de la institución
 * @returns El html del código resaltado
 */
export function highlightFormula(
  code: string,
  auxiliarFormulas?: AuxiliarFormula[],
  variablesSet?: Set<string>,
  institutionalVariablesSet?: Set<string>
): string {
  const errorListener = new ExcelLangErrorListener();
  const inputStream = new ANTLRInputStream(code);
  const lexer = new ExcelLangGrammarLexer(inputStream);
  lexer.removeErrorListeners();
  lexer.addErrorListener(errorListener);
  const tokenStream = new CommonTokenStream(lexer);
  const parser = new ExcelLangGrammarParser(tokenStream);
  parser.removeErrorListeners();
  parser.addErrorListener(errorListener);
  parser.start();
  tokenStream.seek(0);
  let result = "";
  let lastPosition = 0;
  let nextToken: Token | undefined;
  do {
    nextToken = tokenStream.tryLT(1);
    // eslint-disable-next-line no-continue
    if (nextToken === undefined) continue;
    if (nextToken.type === ExcelLangGrammarLexer.EOF) break;
    tokenStream.consume();
    // Lo que no sea un token, es un error
    if (nextToken.startIndex !== lastPosition) {
      result += code
        .substring(lastPosition, nextToken.startIndex)
        .replaceAll(/([^ \n\t]+)/g, '<span class="token error">$1</span>');
    }
    result += tokenHighlight(
      code,
      nextToken,
      errorListener.getErrors(),
      auxiliarFormulas,
      variablesSet,
      institutionalVariablesSet
    );
    lastPosition = nextToken.stopIndex + 1;
  } while (
    !nextToken ||
    (nextToken.type !== ExcelLangGrammarLexer.EOF &&
      lastPosition !== code.length)
  );
  // Highlight de lo que hay entre el ultimo token y el final del código
  if (lastPosition !== code.length) {
    result += code
      .substring(lastPosition, code.length)
      .replaceAll(/([^ \n\t]+)/g, '<span class="token error">$1</span>');
  }
  // console.log(result);
  return `<span class="editor-code">${result}</span>`;
}

export default parseFormula;
