import { AppState } from "../../../dispatcher";
import { SubCategoryStatus } from "../../../types/utilTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../fetchAPI";

/**
 * Funcion que retorna las subcategorias para el menu de editar categorias.
 *
 * @param appState estado de la aplicacion
 * @param setMaxSubId setter de id maxima
 * @param setSubCategoryStatus setter de estado de categoria
 */
function getSubCategories(
  appState: AppState,
  setMaxSubId: React.Dispatch<React.SetStateAction<number>>,
  setSubCategoryStatus: React.Dispatch<
    React.SetStateAction<SubCategoryStatus[]>
  >
): void {
  if (!appState.token) return;
  fetchAPI<APIActions["fetchSubCategories"]>(
    APIRoutes.fetchSubCategories,
    {
      method: "GET",
    },
    appState.token
  ).then((subCategs) => {
    const catStatus: SubCategoryStatus[] = [];
    let id = 0;
    subCategs.forEach((sub) => {
      catStatus.push({
        id: sub.id,
        status: "ready",
        name: sub.name,
        categoryDescription: sub.category.description,
        categoryId: sub.category.id,
        categoryName: sub.category.name,
      });
      if (sub.id >= id) {
        id = sub.id;
      }
      catStatus.sort((a, b) => a.id - b.id);
      setMaxSubId(id);
    });
    setSubCategoryStatus([...catStatus]);
  });
}

export default getSubCategories;
