import { createContext } from "react";
import Routes from "./routers/routes";

// type for the state of the application.
// token: api-key in store
// userType: type of the user.
// name: name of the user. field "name"
export type AppState = {
  userId: string | null;
  token: string | null;
  userType: string | null;
  name: string | null;
  institutionId: string | null;
  institutionName: string | null;
};

export const initialAppState: AppState = {
  userId: null,
  token: null,
  userType: null,
  name: null,
  institutionId: null,
  institutionName: null,
};

export enum ActionTypes {
  setLoginInfo = "setLoginInfo",
  reloadStorage = "reloadStorage",
  logout = "logout",
}

export type Actions = {
  setLoginInfo: {
    type: ActionTypes.setLoginInfo;
    payload: {
      userId: string;
      token: string;
      userType: string;
      name: string;
      institutionId: string;
      institutionName: string | null;
      history: unknown;
    };
  };
  reloadStorage: {
    type: ActionTypes.reloadStorage;
    payload: Record<string, never>;
  };
  logout: {
    type: ActionTypes.logout;
    payload: Record<string, never>; // this means empty object lol
  };
};

export type Action = Actions[keyof Actions];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Dispatcher = (action: Action) => void;

// the AppContext is what will be available in every component
// throughout the application.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppContext = createContext<[AppState, Dispatcher]>([
  initialAppState,
  () => {},
]);
export type Reducer = (
  state: AppState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: Action
) => AppState;

// The appReducer is the function that gets executed each time you call an action
// from a component. It receives the current state and the action and changes the
// state according to the action. The ActionType should be correct for each situation,
// as it determines the payload type.
/**
 * This is the reducer, that changes the state according to the action.
 *
 * @param state current app state.
 * @param action the action to execute on the state.
 * @returns returns the new AppState object of the app state.
 */
export const appReducer: Reducer = (
  state: AppState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: Action
): AppState => {
  // eslint-disable-next-line no-console
  console.log("action: ", action.type);
  switch (action.type) {
    case ActionTypes.setLoginInfo: {
      localStorage.setItem("userId", action.payload.userId);
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("userType", action.payload.userType);
      localStorage.setItem("name", action.payload.name);
      localStorage.setItem("institutionId", action.payload.institutionId);
      if (action.payload.institutionName) {
        localStorage.setItem("institutionName", action.payload.institutionName);
      }
      (action.payload.history as Array<string>).push(Routes.landing);
      return {
        ...state,
        userId: action.payload.userId,
        token: action.payload.token,
        userType: action.payload.userType,
        name: action.payload.name,
        institutionId: action.payload.institutionId,
        institutionName: action.payload.institutionName,
      };
    }
    case ActionTypes.reloadStorage: {
      // eslint-disable-next-line no-console
      console.log("reloading...");
      return {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
        userType: localStorage.getItem("userType"),
        name: localStorage.getItem("name"),
        institutionId: localStorage.getItem("institutionId"),
        institutionName: localStorage.getItem("institutionName"),
      };
    }
    case ActionTypes.logout: {
      // eslint-disable-next-line no-console
      console.log("logging out");
      localStorage.clear();
      return {
        userId: null,
        token: null,
        userType: null,
        name: null,
        institutionId: null,
        institutionName: null,
      };
    }
    default:
      throw new Error("Aciton not recognized");
  }
};
