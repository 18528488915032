import React, { ReactElement, useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import "twin.macro";
import CargandoScreen from "../../components/common/CargandoScreen";
import Container from "../../components/common/Container";
import VolverButton from "../../components/common/VolverButton";
import { AppContext } from "../../dispatcher";
import useUser from "../../hooks/useUser";
import Routes from "../../routers/routes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";

/**
 * Componente para borrar usuario
 */
function ADMDeleteUser(): ReactElement {
  const [ready, setReady] = useState(false);
  const { userId } = useParams<{ userId: string }>();

  const [user] = useUser(setReady, userId);

  const history = useHistory();
  const [appState] = useContext(AppContext);

  /**
   * Eliminar cuenta de usuario
   */
  function deleteAccount() {
    if (appState.token) {
      fetchAPI<APIActions["softdeleteAccount"]>(
        APIRoutes.softdeleteAccount,
        { method: "DELETE", routeParams: { user_id: user.id.toString() } },
        appState.token
      ).then(() => {
        history.push(Routes.admPanelUsers);
      });
    }
  }
  return (
    <CargandoScreen ready={ready}>
      <Container>
        <div tw="w-full">
          <div tw="h-96 flex flex-wrap flex-col content-center justify-center">
            <div tw="text-header2 font-bold py-5">
              Estás seguro de querer borrar la cuenta de {user.first_name}{" "}
              {user.last_name}?
            </div>
            <div tw="flex flex-row justify-center">
              <button
                tw="py-2 px-4 bg-darkred text-white w-auto rounded-md"
                onClick={deleteAccount}
                type="button"
              >
                Borrar cuenta
              </button>
            </div>
          </div>
        </div>
        <VolverButton to={Routes.admPanelUsers} />
      </Container>
    </CargandoScreen>
  );
}

export default ADMDeleteUser;
