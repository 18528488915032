import { AppState } from "../../dispatcher";
import { EvaluationVariableDetails } from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../fetchAPI";

/**
 * Funcion que obtiene los datos de una variable de un indicador
 *
 * @param appState Estado general de la aplicación
 * @param variableId Id de la variable
 * @param setEvaluationVariable setState de la variable
 * @param setError setState que indica si es que ocurrio un error
 */
function getVariableDetails(
  appState: AppState,
  variableId: string,
  setEvaluationVariable: React.Dispatch<
    React.SetStateAction<EvaluationVariableDetails | undefined>
  >,
  setError: React.Dispatch<React.SetStateAction<boolean>>
): void {
  let vari: EvaluationVariableDetails;
  if (!appState.token) {
    return;
  }
  const { token } = appState;
  fetchAPI<APIActions["variableDetails"]>(
    APIRoutes.variableDetails,
    {
      method: "GET",
      routeParams: { variableId },
    },
    appState.token
  )
    .then((variable) => {
      vari = variable;
      fetchAPI<APIActions["fetchIndicatorDetails"]>(
        APIRoutes.fetchIndicatorDetails,
        {
          method: "GET",
          routeParams: {
            indicator_id: variable.indicator_id.toString(),
          },
        },
        token
      ).then((indicator) => {
        vari.indicator = indicator;
        setEvaluationVariable(vari);
      });
    })
    .catch(() => {
      setError(true);
    });
}
export default getVariableDetails;
