import React, { ReactElement, useContext, useEffect, useState } from "react";
import "twin.macro";
import { AppContext } from "../../dispatcher";
import {
  EvaluationVariable,
  InstitutionalDataResponse,
  InstitutionalDataVariable,
  InstitutionDetails,
  ResponseVariable,
} from "../../types/backendTypes";
import fetchAPI, { APIActions, APIRoutes } from "../../utils/fetchAPI";
import CargandoScreen from "../common/CargandoScreen";
import AppealsByState from "./AppealsByState";

// const institutions = [
//   {
//     id: 1,
//     name: "Pontificia Universidad Católica",
//     total: 2,
//     pending: 2,
//     variables: [
//       {
//         id: 1,
//         slug: "GOB1",
//         indicatorName: "Participación en grupos de interés",
//         variable: 1,
//         state: "pending",
//       },
//       {
//         id: 2,
//         slug: "CU2",
//         indicatorName: "Induccción a la sustentibilidad para estudiantes",
//         variable: 3,
//         state: "pending",
//       },
//     ],
//   },
//   {
//     id: 2,
//     name: "UTEM",
//     total: 1,
//     pending: 1,
//     variables: [
//       {
//         id: 2,
//         slug: "GOB1",
//         indicatorName: "Participación en grupos de interés",
//         variable: 1,
//         state: "pending",
//       },
//     ],
//   },
// ];
/**
 * Display de las apelaciones por institución.
 *
 * @param props react props
 * @param props.institutionId id de institucion
 */
function ADMAppealsByInstitutionDisplay(props: {
  institutionId: string;
}): ReactElement {
  const { institutionId } = props;

  const [institutionResponses, setInstitutionResponses] = useState<
    ResponseVariable[]
  >([]);
  const [
    institutionInstitutionalResponses,
    setInstitutionInstitutionalResponses,
  ] = useState<InstitutionalDataResponse[]>([]);
  const [institution, setInstitution] = useState<InstitutionDetails>();
  const [evaluationVariables, setEvaluationVariables] =
    useState<EvaluationVariable[]>();
  const [institutionalDataVariables, setInstitutionalDataVariables] = useState<
    InstitutionalDataVariable[]
  >([]);
  const [ready, setReady] = useState(false);
  const [appState] = useContext(AppContext);

  useEffect(() => {
    const tok = appState.token;
    if (!tok) return;
    fetchAPI<APIActions["fetchInstitutionDetails"]>(
      APIRoutes.fetchInstitutionDetails,
      {
        method: "GET",
        routeParams: {
          institution_id: institutionId,
        },
      },
      tok
    ).then((ins) => {
      setInstitution(ins);

      fetchAPI<APIActions["fetchEvaluationVariables"]>(
        APIRoutes.fetchEvaluationVariables,
        { method: "GET" },
        tok
      ).then((evs) => {
        setEvaluationVariables(evs);
        setReady(true);
      });
      fetchAPI<APIActions["fetchInstitutionResponseVariables"]>(
        APIRoutes.fetchInstitutionResponseVariables,
        {
          method: "GET",
          routeParams: {
            institution_id: institutionId,
          },
        },
        tok
      ).then((res) => {
        setInstitutionResponses(res);
        fetchAPI<APIActions["fetchInstitutionalDataResponses"]>(
          APIRoutes.fetchInstitutionalDataResponses,
          { method: "GET", routeParams: { institution_id: institutionId } },
          tok
        ).then((ress) => {
          setInstitutionInstitutionalResponses(ress);
          fetchAPI<APIActions["fetchInstitutionVariables"]>(
            APIRoutes.fetchInstitutionVariables,
            { method: "GET" },
            tok
          ).then((resss) => {
            setInstitutionalDataVariables(resss);
          });
        });
      });
    });
  }, [appState.token, institutionId]);
  return (
    <CargandoScreen ready={ready}>
      <div tw="mb-10">
        <table tw="w-full">
          <thead tw="bg-resies_lightgreen flex text-white w-full">
            <tr tw="flex w-full h-10">
              <th tw="text-header2 font-bold text-resies_blue1 text-left h-5 pt-2 pl-10">
                {institution?.institution.name}
              </th>
            </tr>
          </thead>
          <tbody tw="bg-resies_lightpurple">
            <AppealsByState
              title="Pendientes"
              appeals={institutionResponses.filter(
                (res) => res.audit_status === "appealed"
              )}
              institutionalAppeals={institutionInstitutionalResponses.filter(
                (res) => res.audit_status === "appealed"
              )}
              institutionId={institutionId}
              evaluationVariables={evaluationVariables || []}
              institutionalDataVariables={institutionalDataVariables}
            />
            <AppealsByState
              title="Aprobadas"
              appeals={institutionResponses.filter(
                (res) => res.audit_status === "final_accepted"
              )}
              institutionalAppeals={institutionInstitutionalResponses.filter(
                (res) => res.audit_status === "final_accepted"
              )}
              institutionId={institutionId}
              evaluationVariables={evaluationVariables || []}
              institutionalDataVariables={institutionalDataVariables}
            />
            <AppealsByState
              title="Rechazadas"
              appeals={institutionResponses.filter(
                (res) => res.audit_status === "final_rejected"
              )}
              institutionalAppeals={institutionInstitutionalResponses.filter(
                (res) => res.audit_status === "final_rejected"
              )}
              institutionId={institutionId}
              evaluationVariables={evaluationVariables || []}
              institutionalDataVariables={institutionalDataVariables}
            />
          </tbody>
        </table>
      </div>
    </CargandoScreen>
  );
}

export default ADMAppealsByInstitutionDisplay;
