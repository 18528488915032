import React, { ReactElement, useState } from "react";
import "twin.macro";
import { InstitutionVariables, User } from "../../../types/backendTypes";
import SearchBar from "../../common/SearchBar";

/**
 * Lista de usuarios mdi de la institución.
 *
 * @param props react props.
 * @param props.users Lista de usuarios MDI.
 * @param props.selectedUser Usuario Seleccionado.
 * @param props.setSelectedUser SetState para seleccionar usuario.
 * @param props.setSelectedVariables SetState para seleccionar variables.
 * @param props.userSelectedVariables variables seleccionados.
 */
function UsersListInstitutionData(props: {
  users: User[];
  selectedUser: User | undefined;
  setSelectedUser: (inds: User | undefined) => void;
  setSelectedVariables: React.Dispatch<
    React.SetStateAction<InstitutionVariables[]>
  >;
  userSelectedVariables: Record<number, InstitutionVariables[]>;
}): ReactElement {
  const {
    users,
    selectedUser,
    setSelectedUser,
    setSelectedVariables,
    userSelectedVariables,
  } = props;
  const [searchText, setSearchText] = useState("");

  /**
   * Funcion que maneja la seleccion de usuario.
   *
   * @param user usuario a seleccionar.
   */
  function onClickHandler(user: User) {
    const variables = userSelectedVariables[user.id];
    setSelectedUser(user);
    setSelectedVariables([...variables]);
  }

  /**
   * Funcion que maneja el cambio en el buscador de usuarios.
   *
   * @param e Evento de cambio en el buscador.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onChangeHandler(e: any) {
    setSearchText(e.target.value);
  }

  return (
    <div tw="col-span-3">
      <div tw="bg-resies_blue1 font-sans text-header2 font-semibold text-resies_lightpurple px-6">
        Selecciona Informante
      </div>
      <div tw="flex flex-col bg-ghostwhite h-64 overflow-y-auto px-1 py-3 border border-black">
        <SearchBar onChangeHandler={onChangeHandler} searchText={searchText} />
        {users
          .filter((user) => user.email.includes(searchText))
          .map((user) => (
            <button
              className={
                user === selectedUser
                  ? "flex flex-row bg-resies_blue1"
                  : "flex flex-row bg-resies_lightpurple"
              }
              type="button"
              onClick={() => onClickHandler(user)}
              key={user.id}
            >
              <p tw="py-4 ml-20 text-body font-normal">{user.email}</p>
            </button>
          ))}
      </div>
    </div>
  );
}

export default UsersListInstitutionData;
