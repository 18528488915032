// Generated from ./ExcelLangGrammar.g4 by ANTLR 4.9.0-SNAPSHOT

import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { ExcelLangGrammarListener } from "./ExcelLangGrammarListener";
import { ExcelLangGrammarVisitor } from "./ExcelLangGrammarVisitor";

export class ExcelLangGrammarParser extends Parser {
  public static readonly SI = 1;
  public static readonly SUMA = 2;
  public static readonly CLIP = 3;
  public static readonly LPAREN = 4;
  public static readonly RPAREN = 5;
  public static readonly COMMA = 6;
  public static readonly PLUS = 7;
  public static readonly MINUS = 8;
  public static readonly DIVSIGN = 9;
  public static readonly MULSIGN = 10;
  public static readonly GTSIGN = 11;
  public static readonly LTSIGN = 12;
  public static readonly GEQSIGN = 13;
  public static readonly LEQSIGN = 14;
  public static readonly EQSIGN = 15;
  public static readonly NUMBER = 16;
  public static readonly VAR = 17;
  public static readonly DI = 18;
  public static readonly AUX = 19;
  public static readonly WS = 20;
  public static readonly RULE_start = 0;
  public static readonly RULE_compareExpr = 1;
  public static readonly RULE_gt = 2;
  public static readonly RULE_lt = 3;
  public static readonly RULE_geq = 4;
  public static readonly RULE_leq = 5;
  public static readonly RULE_eq = 6;
  public static readonly RULE_addExpr = 7;
  public static readonly RULE_mulExpr = 8;
  public static readonly RULE_neg = 9;
  public static readonly RULE_composite = 10;
  public static readonly RULE_fun = 11;
  public static readonly RULE_si = 12;
  public static readonly RULE_clip = 13;
  public static readonly RULE_suma = 14;
  public static readonly RULE_parens = 15;
  // tslint:disable:no-trailing-whitespace
  public static readonly ruleNames: string[] = [
    "start",
    "compareExpr",
    "gt",
    "lt",
    "geq",
    "leq",
    "eq",
    "addExpr",
    "mulExpr",
    "neg",
    "composite",
    "fun",
    "si",
    "clip",
    "suma",
    "parens",
  ];

  private static readonly _LITERAL_NAMES: Array<string | undefined> = [
    undefined,
    "'SI'",
    "'SUMA'",
    "'CLIP'",
    "'('",
    "')'",
    "','",
    "'+'",
    "'-'",
    "'/'",
    "'*'",
    "'>'",
    "'<'",
    "'>='",
    "'<='",
    "'='",
  ];
  private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
    undefined,
    "SI",
    "SUMA",
    "CLIP",
    "LPAREN",
    "RPAREN",
    "COMMA",
    "PLUS",
    "MINUS",
    "DIVSIGN",
    "MULSIGN",
    "GTSIGN",
    "LTSIGN",
    "GEQSIGN",
    "LEQSIGN",
    "EQSIGN",
    "NUMBER",
    "VAR",
    "DI",
    "AUX",
    "WS",
  ];
  public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(
    ExcelLangGrammarParser._LITERAL_NAMES,
    ExcelLangGrammarParser._SYMBOLIC_NAMES,
    []
  );

  // @Override
  // @NotNull
  public get vocabulary(): Vocabulary {
    return ExcelLangGrammarParser.VOCABULARY;
  }
  // tslint:enable:no-trailing-whitespace

  // @Override
  public get grammarFileName(): string {
    return "ExcelLangGrammar.g4";
  }

  // @Override
  public get ruleNames(): string[] {
    return ExcelLangGrammarParser.ruleNames;
  }

  // @Override
  public get serializedATN(): string {
    return ExcelLangGrammarParser._serializedATN;
  }

  protected createFailedPredicateException(
    predicate?: string,
    message?: string
  ): FailedPredicateException {
    return new FailedPredicateException(this, predicate, message);
  }

  constructor(input: TokenStream) {
    super(input);
    this._interp = new ParserATNSimulator(ExcelLangGrammarParser._ATN, this);
  }
  // @RuleVersion(0)
  public start(): StartContext {
    let _localctx: StartContext = new StartContext(this._ctx, this.state);
    this.enterRule(_localctx, 0, ExcelLangGrammarParser.RULE_start);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 32;
        this.compareExpr();
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public compareExpr(): CompareExprContext {
    let _localctx: CompareExprContext = new CompareExprContext(
      this._ctx,
      this.state
    );
    this.enterRule(_localctx, 2, ExcelLangGrammarParser.RULE_compareExpr);
    try {
      this.state = 40;
      this._errHandler.sync(this);
      switch (this.interpreter.adaptivePredict(this._input, 0, this._ctx)) {
        case 1:
          this.enterOuterAlt(_localctx, 1);
          {
            this.state = 34;
            this.addExpr(0);
          }
          break;

        case 2:
          this.enterOuterAlt(_localctx, 2);
          {
            this.state = 35;
            this.gt();
          }
          break;

        case 3:
          this.enterOuterAlt(_localctx, 3);
          {
            this.state = 36;
            this.lt();
          }
          break;

        case 4:
          this.enterOuterAlt(_localctx, 4);
          {
            this.state = 37;
            this.geq();
          }
          break;

        case 5:
          this.enterOuterAlt(_localctx, 5);
          {
            this.state = 38;
            this.leq();
          }
          break;

        case 6:
          this.enterOuterAlt(_localctx, 6);
          {
            this.state = 39;
            this.eq();
          }
          break;
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public gt(): GtContext {
    let _localctx: GtContext = new GtContext(this._ctx, this.state);
    this.enterRule(_localctx, 4, ExcelLangGrammarParser.RULE_gt);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 42;
        _localctx._left = this.addExpr(0);
        this.state = 43;
        this.match(ExcelLangGrammarParser.GTSIGN);
        this.state = 44;
        _localctx._right = this.addExpr(0);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public lt(): LtContext {
    let _localctx: LtContext = new LtContext(this._ctx, this.state);
    this.enterRule(_localctx, 6, ExcelLangGrammarParser.RULE_lt);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 46;
        _localctx._left = this.addExpr(0);
        this.state = 47;
        this.match(ExcelLangGrammarParser.LTSIGN);
        this.state = 48;
        _localctx._right = this.addExpr(0);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public geq(): GeqContext {
    let _localctx: GeqContext = new GeqContext(this._ctx, this.state);
    this.enterRule(_localctx, 8, ExcelLangGrammarParser.RULE_geq);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 50;
        _localctx._left = this.addExpr(0);
        this.state = 51;
        this.match(ExcelLangGrammarParser.GEQSIGN);
        this.state = 52;
        _localctx._right = this.addExpr(0);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public leq(): LeqContext {
    let _localctx: LeqContext = new LeqContext(this._ctx, this.state);
    this.enterRule(_localctx, 10, ExcelLangGrammarParser.RULE_leq);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 54;
        _localctx._left = this.addExpr(0);
        this.state = 55;
        this.match(ExcelLangGrammarParser.LEQSIGN);
        this.state = 56;
        _localctx._right = this.addExpr(0);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public eq(): EqContext {
    let _localctx: EqContext = new EqContext(this._ctx, this.state);
    this.enterRule(_localctx, 12, ExcelLangGrammarParser.RULE_eq);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 58;
        _localctx._left = this.addExpr(0);
        this.state = 59;
        this.match(ExcelLangGrammarParser.EQSIGN);
        this.state = 60;
        _localctx._right = this.addExpr(0);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }

  public addExpr(): AddExprContext;
  public addExpr(_p: number): AddExprContext;
  // @RuleVersion(0)
  public addExpr(_p?: number): AddExprContext {
    if (_p === undefined) {
      _p = 0;
    }

    let _parentctx: ParserRuleContext = this._ctx;
    let _parentState: number = this.state;
    let _localctx: AddExprContext = new AddExprContext(this._ctx, _parentState);
    let _prevctx: AddExprContext = _localctx;
    let _startState: number = 14;
    this.enterRecursionRule(
      _localctx,
      14,
      ExcelLangGrammarParser.RULE_addExpr,
      _p
    );
    try {
      let _alt: number;
      this.enterOuterAlt(_localctx, 1);
      {
        {
          _localctx = new AddexContext(_localctx);
          this._ctx = _localctx;
          _prevctx = _localctx;

          this.state = 63;
          this.mulExpr(0);
        }
        this._ctx._stop = this._input.tryLT(-1);
        this.state = 73;
        this._errHandler.sync(this);
        _alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
        while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
          if (_alt === 1) {
            if (this._parseListeners != null) {
              this.triggerExitRuleEvent();
            }
            _prevctx = _localctx;
            {
              this.state = 71;
              this._errHandler.sync(this);
              switch (
                this.interpreter.adaptivePredict(this._input, 1, this._ctx)
              ) {
                case 1:
                  {
                    _localctx = new SubContext(
                      new AddExprContext(_parentctx, _parentState)
                    );
                    (_localctx as SubContext)._left = _prevctx;
                    this.pushNewRecursionContext(
                      _localctx,
                      _startState,
                      ExcelLangGrammarParser.RULE_addExpr
                    );
                    this.state = 65;
                    if (!this.precpred(this._ctx, 2)) {
                      throw this.createFailedPredicateException(
                        "this.precpred(this._ctx, 2)"
                      );
                    }
                    this.state = 66;
                    this.match(ExcelLangGrammarParser.MINUS);
                    this.state = 67;
                    (_localctx as SubContext)._right = this.mulExpr(0);
                  }
                  break;

                case 2:
                  {
                    _localctx = new AddContext(
                      new AddExprContext(_parentctx, _parentState)
                    );
                    (_localctx as AddContext)._left = _prevctx;
                    this.pushNewRecursionContext(
                      _localctx,
                      _startState,
                      ExcelLangGrammarParser.RULE_addExpr
                    );
                    this.state = 68;
                    if (!this.precpred(this._ctx, 1)) {
                      throw this.createFailedPredicateException(
                        "this.precpred(this._ctx, 1)"
                      );
                    }
                    this.state = 69;
                    this.match(ExcelLangGrammarParser.PLUS);
                    this.state = 70;
                    (_localctx as AddContext)._right = this.mulExpr(0);
                  }
                  break;
              }
            }
          }
          this.state = 75;
          this._errHandler.sync(this);
          _alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.unrollRecursionContexts(_parentctx);
    }
    return _localctx;
  }

  public mulExpr(): MulExprContext;
  public mulExpr(_p: number): MulExprContext;
  // @RuleVersion(0)
  public mulExpr(_p?: number): MulExprContext {
    if (_p === undefined) {
      _p = 0;
    }

    let _parentctx: ParserRuleContext = this._ctx;
    let _parentState: number = this.state;
    let _localctx: MulExprContext = new MulExprContext(this._ctx, _parentState);
    let _prevctx: MulExprContext = _localctx;
    let _startState: number = 16;
    this.enterRecursionRule(
      _localctx,
      16,
      ExcelLangGrammarParser.RULE_mulExpr,
      _p
    );
    try {
      let _alt: number;
      this.enterOuterAlt(_localctx, 1);
      {
        {
          _localctx = new MulexContext(_localctx);
          this._ctx = _localctx;
          _prevctx = _localctx;

          this.state = 77;
          this.composite();
        }
        this._ctx._stop = this._input.tryLT(-1);
        this.state = 87;
        this._errHandler.sync(this);
        _alt = this.interpreter.adaptivePredict(this._input, 4, this._ctx);
        while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
          if (_alt === 1) {
            if (this._parseListeners != null) {
              this.triggerExitRuleEvent();
            }
            _prevctx = _localctx;
            {
              this.state = 85;
              this._errHandler.sync(this);
              switch (
                this.interpreter.adaptivePredict(this._input, 3, this._ctx)
              ) {
                case 1:
                  {
                    _localctx = new DivContext(
                      new MulExprContext(_parentctx, _parentState)
                    );
                    (_localctx as DivContext)._left = _prevctx;
                    this.pushNewRecursionContext(
                      _localctx,
                      _startState,
                      ExcelLangGrammarParser.RULE_mulExpr
                    );
                    this.state = 79;
                    if (!this.precpred(this._ctx, 2)) {
                      throw this.createFailedPredicateException(
                        "this.precpred(this._ctx, 2)"
                      );
                    }
                    this.state = 80;
                    this.match(ExcelLangGrammarParser.DIVSIGN);
                    this.state = 81;
                    (_localctx as DivContext)._right = this.composite();
                  }
                  break;

                case 2:
                  {
                    _localctx = new MulContext(
                      new MulExprContext(_parentctx, _parentState)
                    );
                    (_localctx as MulContext)._left = _prevctx;
                    this.pushNewRecursionContext(
                      _localctx,
                      _startState,
                      ExcelLangGrammarParser.RULE_mulExpr
                    );
                    this.state = 82;
                    if (!this.precpred(this._ctx, 1)) {
                      throw this.createFailedPredicateException(
                        "this.precpred(this._ctx, 1)"
                      );
                    }
                    this.state = 83;
                    this.match(ExcelLangGrammarParser.MULSIGN);
                    this.state = 84;
                    (_localctx as MulContext)._right = this.composite();
                  }
                  break;
              }
            }
          }
          this.state = 89;
          this._errHandler.sync(this);
          _alt = this.interpreter.adaptivePredict(this._input, 4, this._ctx);
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.unrollRecursionContexts(_parentctx);
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public neg(): NegContext {
    let _localctx: NegContext = new NegContext(this._ctx, this.state);
    this.enterRule(_localctx, 18, ExcelLangGrammarParser.RULE_neg);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 90;
        this.match(ExcelLangGrammarParser.MINUS);
        this.state = 91;
        _localctx._single = this.composite();
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public composite(): CompositeContext {
    let _localctx: CompositeContext = new CompositeContext(
      this._ctx,
      this.state
    );
    this.enterRule(_localctx, 20, ExcelLangGrammarParser.RULE_composite);
    try {
      this.state = 100;
      this._errHandler.sync(this);
      switch (this._input.LA(1)) {
        case ExcelLangGrammarParser.VAR:
          this.enterOuterAlt(_localctx, 1);
          {
            this.state = 93;
            this.match(ExcelLangGrammarParser.VAR);
          }
          break;
        case ExcelLangGrammarParser.DI:
          this.enterOuterAlt(_localctx, 2);
          {
            this.state = 94;
            this.match(ExcelLangGrammarParser.DI);
          }
          break;
        case ExcelLangGrammarParser.AUX:
          this.enterOuterAlt(_localctx, 3);
          {
            this.state = 95;
            this.match(ExcelLangGrammarParser.AUX);
          }
          break;
        case ExcelLangGrammarParser.NUMBER:
          this.enterOuterAlt(_localctx, 4);
          {
            this.state = 96;
            this.match(ExcelLangGrammarParser.NUMBER);
          }
          break;
        case ExcelLangGrammarParser.MINUS:
          this.enterOuterAlt(_localctx, 5);
          {
            this.state = 97;
            this.neg();
          }
          break;
        case ExcelLangGrammarParser.SI:
        case ExcelLangGrammarParser.SUMA:
        case ExcelLangGrammarParser.CLIP:
          this.enterOuterAlt(_localctx, 6);
          {
            this.state = 98;
            this.fun();
          }
          break;
        case ExcelLangGrammarParser.LPAREN:
          this.enterOuterAlt(_localctx, 7);
          {
            this.state = 99;
            this.parens();
          }
          break;
        default:
          throw new NoViableAltException(this);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public fun(): FunContext {
    let _localctx: FunContext = new FunContext(this._ctx, this.state);
    this.enterRule(_localctx, 22, ExcelLangGrammarParser.RULE_fun);
    try {
      this.state = 105;
      this._errHandler.sync(this);
      switch (this._input.LA(1)) {
        case ExcelLangGrammarParser.SI:
          this.enterOuterAlt(_localctx, 1);
          {
            this.state = 102;
            this.si();
          }
          break;
        case ExcelLangGrammarParser.SUMA:
          this.enterOuterAlt(_localctx, 2);
          {
            this.state = 103;
            this.suma();
          }
          break;
        case ExcelLangGrammarParser.CLIP:
          this.enterOuterAlt(_localctx, 3);
          {
            this.state = 104;
            this.clip();
          }
          break;
        default:
          throw new NoViableAltException(this);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public si(): SiContext {
    let _localctx: SiContext = new SiContext(this._ctx, this.state);
    this.enterRule(_localctx, 24, ExcelLangGrammarParser.RULE_si);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 107;
        this.match(ExcelLangGrammarParser.SI);
        this.state = 108;
        this.match(ExcelLangGrammarParser.LPAREN);
        this.state = 109;
        _localctx._first = this.compareExpr();
        this.state = 110;
        this.match(ExcelLangGrammarParser.COMMA);
        this.state = 111;
        _localctx._second = this.compareExpr();
        this.state = 112;
        this.match(ExcelLangGrammarParser.COMMA);
        this.state = 113;
        _localctx._third = this.compareExpr();
        this.state = 114;
        this.match(ExcelLangGrammarParser.RPAREN);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public clip(): ClipContext {
    let _localctx: ClipContext = new ClipContext(this._ctx, this.state);
    this.enterRule(_localctx, 26, ExcelLangGrammarParser.RULE_clip);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 116;
        this.match(ExcelLangGrammarParser.CLIP);
        this.state = 117;
        this.match(ExcelLangGrammarParser.LPAREN);
        this.state = 118;
        _localctx._first = this.compareExpr();
        this.state = 119;
        this.match(ExcelLangGrammarParser.COMMA);
        this.state = 120;
        _localctx._second = this.compareExpr();
        this.state = 121;
        this.match(ExcelLangGrammarParser.COMMA);
        this.state = 122;
        _localctx._third = this.compareExpr();
        this.state = 123;
        this.match(ExcelLangGrammarParser.RPAREN);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public suma(): SumaContext {
    let _localctx: SumaContext = new SumaContext(this._ctx, this.state);
    this.enterRule(_localctx, 28, ExcelLangGrammarParser.RULE_suma);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 125;
        this.match(ExcelLangGrammarParser.SUMA);
        this.state = 126;
        this.match(ExcelLangGrammarParser.LPAREN);
        this.state = 127;
        this.compareExpr();
        this.state = 132;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while (_la === ExcelLangGrammarParser.COMMA) {
          {
            {
              this.state = 128;
              this.match(ExcelLangGrammarParser.COMMA);
              this.state = 129;
              this.compareExpr();
            }
          }
          this.state = 134;
          this._errHandler.sync(this);
          _la = this._input.LA(1);
        }
        this.state = 135;
        this.match(ExcelLangGrammarParser.RPAREN);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public parens(): ParensContext {
    let _localctx: ParensContext = new ParensContext(this._ctx, this.state);
    this.enterRule(_localctx, 30, ExcelLangGrammarParser.RULE_parens);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 137;
        this.match(ExcelLangGrammarParser.LPAREN);
        this.state = 138;
        _localctx._single = this.compareExpr();
        this.state = 139;
        this.match(ExcelLangGrammarParser.RPAREN);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }

  public sempred(
    _localctx: RuleContext,
    ruleIndex: number,
    predIndex: number
  ): boolean {
    switch (ruleIndex) {
      case 7:
        return this.addExpr_sempred(_localctx as AddExprContext, predIndex);

      case 8:
        return this.mulExpr_sempred(_localctx as MulExprContext, predIndex);
    }
    return true;
  }
  private addExpr_sempred(
    _localctx: AddExprContext,
    predIndex: number
  ): boolean {
    switch (predIndex) {
      case 0:
        return this.precpred(this._ctx, 2);

      case 1:
        return this.precpred(this._ctx, 1);
    }
    return true;
  }
  private mulExpr_sempred(
    _localctx: MulExprContext,
    predIndex: number
  ): boolean {
    switch (predIndex) {
      case 2:
        return this.precpred(this._ctx, 2);

      case 3:
        return this.precpred(this._ctx, 1);
    }
    return true;
  }

  public static readonly _serializedATN: string =
    "\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03\x16\x90\x04\x02" +
    "\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07" +
    "\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r\x04" +
    "\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x03\x02\x03\x02\x03" +
    "\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x05\x03+\n\x03\x03\x04\x03" +
    "\x04\x03\x04\x03\x04\x03\x05\x03\x05\x03\x05\x03\x05\x03\x06\x03\x06\x03" +
    "\x06\x03\x06\x03\x07\x03\x07\x03\x07\x03\x07\x03\b\x03\b\x03\b\x03\b\x03" +
    "\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x07\tJ\n\t\f\t\x0E" +
    "\tM\v\t\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x07\nX\n" +
    "\n\f\n\x0E\n[\v\n\x03\v\x03\v\x03\v\x03\f\x03\f\x03\f\x03\f\x03\f\x03" +
    "\f\x03\f\x05\fg\n\f\x03\r\x03\r\x03\r\x05\rl\n\r\x03\x0E\x03\x0E\x03\x0E" +
    "\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x0F" +
    "\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x10\x03\x10\x03\x10" +
    "\x03\x10\x03\x10\x07\x10\x85\n\x10\f\x10\x0E\x10\x88\v\x10\x03\x10\x03" +
    "\x10\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x02\x02\x04\x10\x12\x12\x02" +
    "\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x0E\x02\x10\x02\x12\x02\x14\x02" +
    '\x16\x02\x18\x02\x1A\x02\x1C\x02\x1E\x02 \x02\x02\x02\x02\x91\x02"\x03' +
    "\x02\x02\x02\x04*\x03\x02\x02\x02\x06,\x03\x02\x02\x02\b0\x03\x02\x02" +
    "\x02\n4\x03\x02\x02\x02\f8\x03\x02\x02\x02\x0E<\x03\x02\x02\x02\x10@\x03" +
    "\x02\x02\x02\x12N\x03\x02\x02\x02\x14\\\x03\x02\x02\x02\x16f\x03\x02\x02" +
    "\x02\x18k\x03\x02\x02\x02\x1Am\x03\x02\x02\x02\x1Cv\x03\x02\x02\x02\x1E" +
    '\x7F\x03\x02\x02\x02 \x8B\x03\x02\x02\x02"#\x05\x04\x03\x02#\x03\x03' +
    "\x02\x02\x02$+\x05\x10\t\x02%+\x05\x06\x04\x02&+\x05\b\x05\x02'+\x05" +
    "\n\x06\x02(+\x05\f\x07\x02)+\x05\x0E\b\x02*$\x03\x02\x02\x02*%\x03\x02" +
    "\x02\x02*&\x03\x02\x02\x02*'\x03\x02\x02\x02*(\x03\x02\x02\x02*)\x03" +
    "\x02\x02\x02+\x05\x03\x02\x02\x02,-\x05\x10\t\x02-.\x07\r\x02\x02./\x05" +
    "\x10\t\x02/\x07\x03\x02\x02\x0201\x05\x10\t\x0212\x07\x0E\x02\x0223\x05" +
    "\x10\t\x023\t\x03\x02\x02\x0245\x05\x10\t\x0256\x07\x0F\x02\x0267\x05" +
    "\x10\t\x027\v\x03\x02\x02\x0289\x05\x10\t\x029:\x07\x10\x02\x02:;\x05" +
    "\x10\t\x02;\r\x03\x02\x02\x02<=\x05\x10\t\x02=>\x07\x11\x02\x02>?\x05" +
    "\x10\t\x02?\x0F\x03\x02\x02\x02@A\b\t\x01\x02AB\x05\x12\n\x02BK\x03\x02" +
    "\x02\x02CD\f\x04\x02\x02DE\x07\n\x02\x02EJ\x05\x12\n\x02FG\f\x03\x02\x02" +
    "GH\x07\t\x02\x02HJ\x05\x12\n\x02IC\x03\x02\x02\x02IF\x03\x02\x02\x02J" +
    "M\x03\x02\x02\x02KI\x03\x02\x02\x02KL\x03\x02\x02\x02L\x11\x03\x02\x02" +
    "\x02MK\x03\x02\x02\x02NO\b\n\x01\x02OP\x05\x16\f\x02PY\x03\x02\x02\x02" +
    "QR\f\x04\x02\x02RS\x07\v\x02\x02SX\x05\x16\f\x02TU\f\x03\x02\x02UV\x07" +
    "\f\x02\x02VX\x05\x16\f\x02WQ\x03\x02\x02\x02WT\x03\x02\x02\x02X[\x03\x02" +
    "\x02\x02YW\x03\x02\x02\x02YZ\x03\x02\x02\x02Z\x13\x03\x02\x02\x02[Y\x03" +
    "\x02\x02\x02\\]\x07\n\x02\x02]^\x05\x16\f\x02^\x15\x03\x02\x02\x02_g\x07" +
    "\x13\x02\x02`g\x07\x14\x02\x02ag\x07\x15\x02\x02bg\x07\x12\x02\x02cg\x05" +
    "\x14\v\x02dg\x05\x18\r\x02eg\x05 \x11\x02f_\x03\x02\x02\x02f`\x03\x02" +
    "\x02\x02fa\x03\x02\x02\x02fb\x03\x02\x02\x02fc\x03\x02\x02\x02fd\x03\x02" +
    "\x02\x02fe\x03\x02\x02\x02g\x17\x03\x02\x02\x02hl\x05\x1A\x0E\x02il\x05" +
    "\x1E\x10\x02jl\x05\x1C\x0F\x02kh\x03\x02\x02\x02ki\x03\x02\x02\x02kj\x03" +
    "\x02\x02\x02l\x19\x03\x02\x02\x02mn\x07\x03\x02\x02no\x07\x06\x02\x02" +
    "op\x05\x04\x03\x02pq\x07\b\x02\x02qr\x05\x04\x03\x02rs\x07\b\x02\x02s" +
    "t\x05\x04\x03\x02tu\x07\x07\x02\x02u\x1B\x03\x02\x02\x02vw\x07\x05\x02" +
    "\x02wx\x07\x06\x02\x02xy\x05\x04\x03\x02yz\x07\b\x02\x02z{\x05\x04\x03" +
    "\x02{|\x07\b\x02\x02|}\x05\x04\x03\x02}~\x07\x07\x02\x02~\x1D\x03\x02" +
    "\x02\x02\x7F\x80\x07\x04\x02\x02\x80\x81\x07\x06\x02\x02\x81\x86\x05\x04" +
    "\x03\x02\x82\x83\x07\b\x02\x02\x83\x85\x05\x04\x03\x02\x84\x82\x03\x02" +
    "\x02\x02\x85\x88\x03\x02\x02\x02\x86\x84\x03\x02\x02\x02\x86\x87\x03\x02" +
    "\x02\x02\x87\x89\x03\x02\x02\x02\x88\x86\x03\x02\x02\x02\x89\x8A\x07\x07" +
    "\x02\x02\x8A\x1F\x03\x02\x02\x02\x8B\x8C\x07\x06\x02\x02\x8C\x8D\x05\x04" +
    "\x03\x02\x8D\x8E\x07\x07\x02\x02\x8E!\x03\x02\x02\x02\n*IKWYfk\x86";
  public static __ATN: ATN;
  public static get _ATN(): ATN {
    if (!ExcelLangGrammarParser.__ATN) {
      ExcelLangGrammarParser.__ATN = new ATNDeserializer().deserialize(
        Utils.toCharArray(ExcelLangGrammarParser._serializedATN)
      );
    }

    return ExcelLangGrammarParser.__ATN;
  }
}

export class StartContext extends ParserRuleContext {
  public compareExpr(): CompareExprContext {
    return this.getRuleContext(0, CompareExprContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_start;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterStart) {
      listener.enterStart(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitStart) {
      listener.exitStart(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitStart) {
      return visitor.visitStart(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class CompareExprContext extends ParserRuleContext {
  public addExpr(): AddExprContext | undefined {
    return this.tryGetRuleContext(0, AddExprContext);
  }
  public gt(): GtContext | undefined {
    return this.tryGetRuleContext(0, GtContext);
  }
  public lt(): LtContext | undefined {
    return this.tryGetRuleContext(0, LtContext);
  }
  public geq(): GeqContext | undefined {
    return this.tryGetRuleContext(0, GeqContext);
  }
  public leq(): LeqContext | undefined {
    return this.tryGetRuleContext(0, LeqContext);
  }
  public eq(): EqContext | undefined {
    return this.tryGetRuleContext(0, EqContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_compareExpr;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterCompareExpr) {
      listener.enterCompareExpr(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitCompareExpr) {
      listener.exitCompareExpr(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitCompareExpr) {
      return visitor.visitCompareExpr(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class GtContext extends ParserRuleContext {
  public _left!: AddExprContext;
  public _right!: AddExprContext;
  public GTSIGN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.GTSIGN, 0);
  }
  public addExpr(): AddExprContext[];
  public addExpr(i: number): AddExprContext;
  public addExpr(i?: number): AddExprContext | AddExprContext[] {
    if (i === undefined) {
      return this.getRuleContexts(AddExprContext);
    } else {
      return this.getRuleContext(i, AddExprContext);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_gt;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterGt) {
      listener.enterGt(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitGt) {
      listener.exitGt(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitGt) {
      return visitor.visitGt(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class LtContext extends ParserRuleContext {
  public _left!: AddExprContext;
  public _right!: AddExprContext;
  public LTSIGN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.LTSIGN, 0);
  }
  public addExpr(): AddExprContext[];
  public addExpr(i: number): AddExprContext;
  public addExpr(i?: number): AddExprContext | AddExprContext[] {
    if (i === undefined) {
      return this.getRuleContexts(AddExprContext);
    } else {
      return this.getRuleContext(i, AddExprContext);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_lt;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterLt) {
      listener.enterLt(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitLt) {
      listener.exitLt(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitLt) {
      return visitor.visitLt(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class GeqContext extends ParserRuleContext {
  public _left!: AddExprContext;
  public _right!: AddExprContext;
  public GEQSIGN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.GEQSIGN, 0);
  }
  public addExpr(): AddExprContext[];
  public addExpr(i: number): AddExprContext;
  public addExpr(i?: number): AddExprContext | AddExprContext[] {
    if (i === undefined) {
      return this.getRuleContexts(AddExprContext);
    } else {
      return this.getRuleContext(i, AddExprContext);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_geq;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterGeq) {
      listener.enterGeq(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitGeq) {
      listener.exitGeq(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitGeq) {
      return visitor.visitGeq(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class LeqContext extends ParserRuleContext {
  public _left!: AddExprContext;
  public _right!: AddExprContext;
  public LEQSIGN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.LEQSIGN, 0);
  }
  public addExpr(): AddExprContext[];
  public addExpr(i: number): AddExprContext;
  public addExpr(i?: number): AddExprContext | AddExprContext[] {
    if (i === undefined) {
      return this.getRuleContexts(AddExprContext);
    } else {
      return this.getRuleContext(i, AddExprContext);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_leq;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterLeq) {
      listener.enterLeq(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitLeq) {
      listener.exitLeq(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitLeq) {
      return visitor.visitLeq(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class EqContext extends ParserRuleContext {
  public _left!: AddExprContext;
  public _right!: AddExprContext;
  public EQSIGN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.EQSIGN, 0);
  }
  public addExpr(): AddExprContext[];
  public addExpr(i: number): AddExprContext;
  public addExpr(i?: number): AddExprContext | AddExprContext[] {
    if (i === undefined) {
      return this.getRuleContexts(AddExprContext);
    } else {
      return this.getRuleContext(i, AddExprContext);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_eq;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterEq) {
      listener.enterEq(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitEq) {
      listener.exitEq(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitEq) {
      return visitor.visitEq(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class AddExprContext extends ParserRuleContext {
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_addExpr;
  }
  public copyFrom(ctx: AddExprContext): void {
    super.copyFrom(ctx);
  }
}
export class AddexContext extends AddExprContext {
  public mulExpr(): MulExprContext {
    return this.getRuleContext(0, MulExprContext);
  }
  constructor(ctx: AddExprContext) {
    super(ctx.parent, ctx.invokingState);
    this.copyFrom(ctx);
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterAddex) {
      listener.enterAddex(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitAddex) {
      listener.exitAddex(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitAddex) {
      return visitor.visitAddex(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}
export class SubContext extends AddExprContext {
  public _left!: AddExprContext;
  public _right!: MulExprContext;
  public MINUS(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.MINUS, 0);
  }
  public addExpr(): AddExprContext {
    return this.getRuleContext(0, AddExprContext);
  }
  public mulExpr(): MulExprContext {
    return this.getRuleContext(0, MulExprContext);
  }
  constructor(ctx: AddExprContext) {
    super(ctx.parent, ctx.invokingState);
    this.copyFrom(ctx);
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterSub) {
      listener.enterSub(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitSub) {
      listener.exitSub(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitSub) {
      return visitor.visitSub(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}
export class AddContext extends AddExprContext {
  public _left!: AddExprContext;
  public _right!: MulExprContext;
  public PLUS(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.PLUS, 0);
  }
  public addExpr(): AddExprContext {
    return this.getRuleContext(0, AddExprContext);
  }
  public mulExpr(): MulExprContext {
    return this.getRuleContext(0, MulExprContext);
  }
  constructor(ctx: AddExprContext) {
    super(ctx.parent, ctx.invokingState);
    this.copyFrom(ctx);
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterAdd) {
      listener.enterAdd(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitAdd) {
      listener.exitAdd(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitAdd) {
      return visitor.visitAdd(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class MulExprContext extends ParserRuleContext {
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_mulExpr;
  }
  public copyFrom(ctx: MulExprContext): void {
    super.copyFrom(ctx);
  }
}
export class MulexContext extends MulExprContext {
  public composite(): CompositeContext {
    return this.getRuleContext(0, CompositeContext);
  }
  constructor(ctx: MulExprContext) {
    super(ctx.parent, ctx.invokingState);
    this.copyFrom(ctx);
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterMulex) {
      listener.enterMulex(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitMulex) {
      listener.exitMulex(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitMulex) {
      return visitor.visitMulex(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}
export class DivContext extends MulExprContext {
  public _left!: MulExprContext;
  public _right!: CompositeContext;
  public DIVSIGN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.DIVSIGN, 0);
  }
  public mulExpr(): MulExprContext {
    return this.getRuleContext(0, MulExprContext);
  }
  public composite(): CompositeContext {
    return this.getRuleContext(0, CompositeContext);
  }
  constructor(ctx: MulExprContext) {
    super(ctx.parent, ctx.invokingState);
    this.copyFrom(ctx);
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterDiv) {
      listener.enterDiv(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitDiv) {
      listener.exitDiv(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitDiv) {
      return visitor.visitDiv(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}
export class MulContext extends MulExprContext {
  public _left!: MulExprContext;
  public _right!: CompositeContext;
  public MULSIGN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.MULSIGN, 0);
  }
  public mulExpr(): MulExprContext {
    return this.getRuleContext(0, MulExprContext);
  }
  public composite(): CompositeContext {
    return this.getRuleContext(0, CompositeContext);
  }
  constructor(ctx: MulExprContext) {
    super(ctx.parent, ctx.invokingState);
    this.copyFrom(ctx);
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterMul) {
      listener.enterMul(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitMul) {
      listener.exitMul(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitMul) {
      return visitor.visitMul(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class NegContext extends ParserRuleContext {
  public _single!: CompositeContext;
  public MINUS(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.MINUS, 0);
  }
  public composite(): CompositeContext {
    return this.getRuleContext(0, CompositeContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_neg;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterNeg) {
      listener.enterNeg(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitNeg) {
      listener.exitNeg(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitNeg) {
      return visitor.visitNeg(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class CompositeContext extends ParserRuleContext {
  public VAR(): TerminalNode | undefined {
    return this.tryGetToken(ExcelLangGrammarParser.VAR, 0);
  }
  public DI(): TerminalNode | undefined {
    return this.tryGetToken(ExcelLangGrammarParser.DI, 0);
  }
  public AUX(): TerminalNode | undefined {
    return this.tryGetToken(ExcelLangGrammarParser.AUX, 0);
  }
  public NUMBER(): TerminalNode | undefined {
    return this.tryGetToken(ExcelLangGrammarParser.NUMBER, 0);
  }
  public neg(): NegContext | undefined {
    return this.tryGetRuleContext(0, NegContext);
  }
  public fun(): FunContext | undefined {
    return this.tryGetRuleContext(0, FunContext);
  }
  public parens(): ParensContext | undefined {
    return this.tryGetRuleContext(0, ParensContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_composite;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterComposite) {
      listener.enterComposite(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitComposite) {
      listener.exitComposite(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitComposite) {
      return visitor.visitComposite(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class FunContext extends ParserRuleContext {
  public si(): SiContext | undefined {
    return this.tryGetRuleContext(0, SiContext);
  }
  public suma(): SumaContext | undefined {
    return this.tryGetRuleContext(0, SumaContext);
  }
  public clip(): ClipContext | undefined {
    return this.tryGetRuleContext(0, ClipContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_fun;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterFun) {
      listener.enterFun(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitFun) {
      listener.exitFun(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitFun) {
      return visitor.visitFun(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class SiContext extends ParserRuleContext {
  public _first!: CompareExprContext;
  public _second!: CompareExprContext;
  public _third!: CompareExprContext;
  public SI(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.SI, 0);
  }
  public LPAREN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.LPAREN, 0);
  }
  public COMMA(): TerminalNode[];
  public COMMA(i: number): TerminalNode;
  public COMMA(i?: number): TerminalNode | TerminalNode[] {
    if (i === undefined) {
      return this.getTokens(ExcelLangGrammarParser.COMMA);
    } else {
      return this.getToken(ExcelLangGrammarParser.COMMA, i);
    }
  }
  public RPAREN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.RPAREN, 0);
  }
  public compareExpr(): CompareExprContext[];
  public compareExpr(i: number): CompareExprContext;
  public compareExpr(i?: number): CompareExprContext | CompareExprContext[] {
    if (i === undefined) {
      return this.getRuleContexts(CompareExprContext);
    } else {
      return this.getRuleContext(i, CompareExprContext);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_si;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterSi) {
      listener.enterSi(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitSi) {
      listener.exitSi(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitSi) {
      return visitor.visitSi(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class ClipContext extends ParserRuleContext {
  public _first!: CompareExprContext;
  public _second!: CompareExprContext;
  public _third!: CompareExprContext;
  public CLIP(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.CLIP, 0);
  }
  public LPAREN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.LPAREN, 0);
  }
  public COMMA(): TerminalNode[];
  public COMMA(i: number): TerminalNode;
  public COMMA(i?: number): TerminalNode | TerminalNode[] {
    if (i === undefined) {
      return this.getTokens(ExcelLangGrammarParser.COMMA);
    } else {
      return this.getToken(ExcelLangGrammarParser.COMMA, i);
    }
  }
  public RPAREN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.RPAREN, 0);
  }
  public compareExpr(): CompareExprContext[];
  public compareExpr(i: number): CompareExprContext;
  public compareExpr(i?: number): CompareExprContext | CompareExprContext[] {
    if (i === undefined) {
      return this.getRuleContexts(CompareExprContext);
    } else {
      return this.getRuleContext(i, CompareExprContext);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_clip;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterClip) {
      listener.enterClip(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitClip) {
      listener.exitClip(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitClip) {
      return visitor.visitClip(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class SumaContext extends ParserRuleContext {
  public SUMA(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.SUMA, 0);
  }
  public LPAREN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.LPAREN, 0);
  }
  public compareExpr(): CompareExprContext[];
  public compareExpr(i: number): CompareExprContext;
  public compareExpr(i?: number): CompareExprContext | CompareExprContext[] {
    if (i === undefined) {
      return this.getRuleContexts(CompareExprContext);
    } else {
      return this.getRuleContext(i, CompareExprContext);
    }
  }
  public RPAREN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.RPAREN, 0);
  }
  public COMMA(): TerminalNode[];
  public COMMA(i: number): TerminalNode;
  public COMMA(i?: number): TerminalNode | TerminalNode[] {
    if (i === undefined) {
      return this.getTokens(ExcelLangGrammarParser.COMMA);
    } else {
      return this.getToken(ExcelLangGrammarParser.COMMA, i);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_suma;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterSuma) {
      listener.enterSuma(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitSuma) {
      listener.exitSuma(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitSuma) {
      return visitor.visitSuma(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class ParensContext extends ParserRuleContext {
  public _single!: CompareExprContext;
  public LPAREN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.LPAREN, 0);
  }
  public RPAREN(): TerminalNode {
    return this.getToken(ExcelLangGrammarParser.RPAREN, 0);
  }
  public compareExpr(): CompareExprContext {
    return this.getRuleContext(0, CompareExprContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return ExcelLangGrammarParser.RULE_parens;
  }
  // @Override
  public enterRule(listener: ExcelLangGrammarListener): void {
    if (listener.enterParens) {
      listener.enterParens(this);
    }
  }
  // @Override
  public exitRule(listener: ExcelLangGrammarListener): void {
    if (listener.exitParens) {
      listener.exitParens(this);
    }
  }
  // @Override
  public accept<Result>(visitor: ExcelLangGrammarVisitor<Result>): Result {
    if (visitor.visitParens) {
      return visitor.visitParens(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}
